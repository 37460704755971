import { Box, Button, Flex, NumberInput, NumberInputField, SimpleGrid } from '@chakra-ui/react'
import { InputNumberWheel, Currency } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useState } from 'react'

export interface IAddItemToOrderProps {
  quantity: {
    min: number
    max: number
    initial?: number
  }
  price?: number
  currencyCode?: string
  onSubmit: (amount: number) => void
  closePopover: () => void
}

export const AddItemToOrder: React.FC<IAddItemToOrderProps> = ({ quantity, price, currencyCode = 'EUR', onSubmit, closePopover }) => {
  const [currentAmount, setCurrentAmount] = useState(quantity.initial ?? quantity.min)

  return (
    <Flex
      direction="column"
      maxW="xs"
    >
      <InputNumberWheel
        onChange={(amount) => { setCurrentAmount(amount) }}
        value={{ min: quantity.min, max: quantity.max, initial: currentAmount }}
      />
      <Flex p={6} direction="column" gap={6}>
        <Box position="relative" w="full">
          <Flex position="absolute" h="full" alignItems="center" color="secondaryText.300" right={4} pointerEvents="none" my="auto">
            Stück
          </Flex>
          <NumberInput
            onChange={(valueString) => { setCurrentAmount(Number(valueString)) }}
            max={quantity.max}
            min={quantity.min}
            value={currentAmount}
          >
            <NumberInputField borderRadius="none"/>
          </NumberInput>
        </Box>
        <SimpleGrid columns={2} gap={6} mt={3}>
          <Button fontFamily="primary" w="full" colorScheme="accent" onClick={() => { onSubmit(currentAmount) }}>Hinzufügen</Button>
          <Button fontFamily="primary" w="full" colorScheme="gray" onClick={closePopover}>Abbrechen</Button>
        </SimpleGrid>
        {price && (
          <Box>
            <SimpleGrid columns={2} columnGap={3} w="fit-content" mb={1}>
              <Box fontFamily="primary" textAlign="right">
                <Currency amount={(price * currentAmount) / 100} currencyCode={currencyCode}/>
              </Box>
              <Box>Gesamt</Box>
              <Box fontFamily="primary" textAlign="right" color="secondaryText.250">
                <Currency amount={price / 100} currencyCode={currencyCode}/>
              </Box>
              <Box color="secondaryText.300">Stückpreis</Box>
            </SimpleGrid>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
