import { Box, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react'
import cloneDeep from 'lodash/cloneDeep'

interface IRefinementItem {
  id: string
  value: string
  label: string
  count: number
  isRefined: boolean
}

interface IRefinementListProps {
  refine: (payload: string) => void
  items: IRefinementItem[]
  sortOrder: string[] | undefined
}

export const RefinementList: React.FC<IRefinementListProps> = ({
  items,
  refine,
  sortOrder,
}) => {
  let sortedItems: IRefinementItem[] = []

  if (sortOrder) {
    const itemsCopy = cloneDeep(items)
    sortedItems = itemsCopy.sort((a, b) => {
      if (!sortOrder.find(e => e === a.label)) return 0
      if (!sortOrder.find(e => e === b.label)) return -1
      return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label)
    })
  }

  return (
    <CheckboxGroup>
      <Stack spacing={[1, 2]} direction="column">
        {(sortedItems.length === 0 ? items : sortedItems).map((option) => (
          <Checkbox
            isChecked={option.isRefined}
            key={`option-${option.label}`}
            value={option.label}
            colorScheme="accent"
            onChange={event => {
              event.preventDefault()
              refine(option.value)
            }}
          >
            {option.label}
            <Box display="inline" fontWeight="normal" color="black" fontSize="sm" ml="6px" borderRadius="md" px="5px" py="1px" bg="gray.100">
              {option.count}
            </Box>
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  )
}
