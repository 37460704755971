import { Box, Divider, Heading, Text } from '@chakra-ui/react'
import { ProgressSteps } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useIntl } from 'react-intl'
import type { ICheckoutPageProps } from './CheckoutPage.types'

export const CheckoutPage: React.FC<ICheckoutPageProps> = (props) => {
  const intl = useIntl()

  return (
    <>
      <ProgressSteps
        steps={props.steps}
        useStep={props.stepper}
      />
      {props.steps.map((step, index) => (
        <Box
          bg="white"
          shadow="sm"
          padding={{ base: '15px', lg: '25px' }}
          key={index}
          display={props.stepper[0] === index ? 'block' : 'none'}
        >
          <Heading
            as="h3"
            fontSize="xl"
            lineHeight={1}
            mb={0}
          >{intl.formatMessage({ id: step.description })}
          </Heading>
          <Divider borderColor="gray.300" mt={{ base: 3, lg: 5 }} mb={{ base: 5, lg: 7 }}/>
          {step.note &&
            <Text fontWeight="bold" mt={-3} mb={3}>{step.note}</Text>}
          <step.content {...props}/>
        </Box>
      ))}
    </>

  )
}
