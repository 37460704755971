import type { BoxProps } from '@chakra-ui/react'
import { Box, Skeleton } from '@chakra-ui/react'
import type React from 'react'

interface HighlightFirstWordProps extends BoxProps {
  productLine: string
  isLoaded?: boolean
}
interface IColors {
  colorText?: string
  colorAccent?: string
}
interface ICombined extends IColors, HighlightFirstWordProps {}

export const HighlightFirstWord: React.FC<ICombined> = ({ isLoaded = true, productLine, colorText = 'secondaryText.500', colorAccent = 'accent.500', ...props }) => {
  const [red, ...blackArray] = productLine.split(/(?=[A-Z])/)
  const black = blackArray.join('')
  return (
    <Box
      display="inline"
      {...props}
    >
      <Skeleton isLoaded={isLoaded} w="fit-content">
        <Box
          color={colorAccent}
          display="inline"
        >{red}
        </Box>
        <Box
          display="inline"
        >{black}
        </Box>
      </Skeleton>
    </Box>
  )
}
