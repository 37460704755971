import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, useDisclosure } from '@chakra-ui/react'
import type React from 'react'
import type { ReactNode } from 'react'

interface IInformationTabsProps {
  tabs: Array<{
    id: string
    title: string
    content: ReactNode
  }>
  hidden: boolean
  onChange?: (id: string) => void
}

export const InformationTabsMobile: React.FC<IInformationTabsProps> = ({ tabs, hidden, onChange }) => {
  const rerenderToggle = useDisclosure()
  return (
    <Accordion
      w="100%"
      colorScheme="accent"
      bgColor="gray.50"
      hidden={hidden}
      allowToggle
      px={0}
    >
      {tabs.map((tab, index) => (
        <AccordionItem key={index} px={0}>
          <Heading as="h5">
            <AccordionButton
              onClick={() => {
                rerenderToggle.onToggle()
                onChange?.(tab.id)
              }}
              px={3}
              fontFamily="primary"
            >
              {tab.title}
            </AccordionButton>
          </Heading>
          <AccordionPanel px={0}>
            <Box p={3}>
              {tab.content}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
