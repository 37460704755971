import { Text } from '@chakra-ui/react'
import type React from 'react'

import type { TextProps } from '@chakra-ui/react'

interface IAnimatedUnderlineProps extends TextProps {
  isPermaActive?: boolean
}
interface IColors {
  colorAccent?: string
}
interface ICombined extends IColors, IAnimatedUnderlineProps {}

export const AnimatedUnderline: React.FC<ICombined> = ({ isPermaActive, children, colorAccent = 'accent.500', ...textProps }) => {
  return (
    <Text
      position="relative"
      _after={{
        content: '""',
        h: '0.5',
        bgColor: colorAccent,
        w: isPermaActive ? 'calc(100% + 10px)' : 0,
        position: 'absolute',
        transition: 'width .5s ease',
        left: 0,
        bottom: 0,
        transform: 'translateY(0.05rem)',
      }}
      _hover={{
        _after: {
          w: 'calc(100% + 10px)',
        },
      }}
      fontFamily="primary"
      {...textProps}
    >
      {children}
    </Text>
  )
}
