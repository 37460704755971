import type { IGridTableProps, IImageGallery } from '@stocker/ui-components/design-system'
import { testDocumentWithAllPossibleFeatures } from './pimcore_html'

export const tabTableProps: IGridTableProps = {
  title: 'Details',
  tableValues: [
    [
      'Durchmesser (asdf)',
      '60 - 100',
      '60 - 100',
    ],
    [
      'Durchmesser (jklö)',
      '60 - 100',
      '60 - 100',
    ],
    [
      'Durchmesser (mnop)',
      '60 - 100',
      '60 - 100',
    ],
  ],
  tableHead: ['one', 'two', 'three'],
  disclaimer: [
    'Alle Angaben in Millimeter (außer anders angegeben)',
  ],
}

export const tabImageProps: IImageGallery = {
  images: [
    {
      fullpath: '/img/test.png',
    },
    {
      fullpath: '/img/test1.png',
    },
    {
      fullpath: '/img/test2.png',
    },
    {
      fullpath: '/img/test3.png',
    },
    {
      fullpath: '/img/test2.png',
    },
    {
      fullpath: '/img/test3.png',
    },
    {
      fullpath: '/img/test3.png',
    },
  ],
}

export const informationTabProps = {
  tabs: [
    {
      id: 'description',
      name: 'Produktdetails',
      tabContent: tabTableProps,
      contentLoading: false,
    },
    {
      id: 'systeminformation',
      name: 'Systeminformationen',
      tabContent: {
        text: testDocumentWithAllPossibleFeatures,
      },
      contentLoading: false,

    },
    {
      id: 'images',
      name: 'Anlagenbilder',
      tabContent: tabImageProps,
      contentLoading: false,
    },
  ],
}
