import { Box, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Text, Textarea } from '@chakra-ui/react'
import type { FormikContextType } from 'formik'
import { ErrorMessage, Field, Form as FormikForm, Formik, useFormikContext } from 'formik'
import type React from 'react'
import { useEffect } from 'react'
import type { ObjectSchema } from 'yup'
import type { Assign, ObjectShape } from 'yup/lib/object'
import { IncrementAmountInput } from '../IncrementAmountInput/IncrementAmountInput'
import type { FormData, IFormData } from './Form.types'
import { AutoCompleteData, ButtonData, CheckboxData, IncrementAmountInputData, InputData, JsxData, NumberInputData, SelectData, TextAreaData } from './Form.types'
import { Autocomplete } from './Inputs/Autocomplete'
interface IFormProps {
  onSubmit: (values: any) => void
  onChange?: (formikCtx: FormikContextType<unknown>) => void
  initialValues?: Record<string, unknown>
  items: IFormData // Array<Array<DividerData | FormData>>
  validationSchema: ObjectSchema<Assign<ObjectShape, any>>
  blockGap?: string | { horizontal: string, vertical: string }
  fieldGap?: string | { horizontal: string, vertical: string }
  enableReinitialize?: boolean
  editMode?: boolean
}

const FormObserver: React.FC<{ onFormChange: (formikCtx: FormikContextType<unknown>) => void }> = ({ onFormChange }) => {
  const formik = useFormikContext()
  // exposing formik ctx, this should be done with a ref but whatever
  useEffect(() => {
    onFormChange(formik)
  }, [onFormChange, formik])
  return null
}

export const Form: React.FC<IFormProps> = ({ initialValues, items, blockGap, fieldGap, validationSchema, onSubmit, onChange, enableReinitialize, editMode = true }) => {
  return (
    <Formik
      initialValues={
        initialValues ??
        items.blocks
          .flat()
          .map(block => block.fields.flat())
          .flat()
          .filter((item): item is FormData & Required<Pick<FormData, 'name'>> => !!(item as FormData).name)
          .reduce((acc, { name, defaultValue }) => ({ ...acc, [name]: defaultValue ?? '' }), {})
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
    >
      {({ errors, touched, handleReset, setFieldValue }) => (
        <FormikForm>
          <FormObserver onFormChange={formikCtx => onChange?.(formikCtx)}/>
          <Box
            display="flex"
            flexDir="column"
            gap={typeof blockGap === 'string' ? blockGap : blockGap?.vertical}
          >
            {items.blocks.map((block, blockIndex) => (
              <Box
                display="flex"
                flexDir="row"
                key={`${blockIndex}-block`}
                gap={typeof blockGap === 'string' ? blockGap : blockGap?.horizontal}
              >
                {block.map((blockItem, blockItemIndex) => (
                  <Box
                    w="100%"
                    display="flex"
                    flexDir="column"
                    key={`${blockItemIndex}-blockItem`}
                    gap={typeof fieldGap === 'string' ? fieldGap : fieldGap?.vertical}
                    maxW={blockItem.maxW}
                  >
                    {blockItem.blockTitle && (
                      <Heading
                        lineHeight={1.2}
                        mt="10px"
                        as={blockItem.blockTitle.type}
                        color={blockItem.blockTitle.color}
                        size={blockItem.blockTitle.size}
                      >{blockItem.blockTitle.text}
                      </Heading>
                    )}
                    {
                      blockItem.fields.map((row, index) => (
                        <Box
                          key={`row-${row.map((row) => row instanceof JsxData ? index : row.name).join('')}`}
                          display="flex"
                          gap={typeof fieldGap === 'string' ? fieldGap : fieldGap?.horizontal}
                          flexDirection="row"
                          flexWrap="wrap"
                          w="100%"
                        >
                          {row.map((item, index) => {
                            return (item instanceof JsxData)
                              ? item.jsx
                              : (
                                <FormControl
                                  key={`form-control-item-${index}`}
                                  flex={`${item.grow ?? '1'} ${item.shrink ?? '1'} ${item.minWidth ?? 0}`}
                                  display="flex"
                                  flexDirection="column"
                                  // @ts-expect-error ...
                                  isInvalid={!!errors[item.name] && !!touched[item.name]}
                                  {...((item instanceof ButtonData && item.align === 'right') && { alignItems: 'flex-end' })}

                                >
                                  {
                                    (
                                      !(item instanceof CheckboxData) &&
                                      !(item instanceof ButtonData)
                                    ) &&
                                      <FormLabel mb="2px">{item.title}</FormLabel>
                                  }
                                  {/* Render an input field of the type is compatible with an input field */}
                                  {item instanceof InputData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field }) => (
                                        editMode
                                          ? (
                                            <InputGroup>
                                              <Input type={item.type} {...item.inputProps} {...field}/>
                                              {item.rightElement && (
                                                <InputRightElement>
                                                  {item.rightElement}
                                                </InputRightElement>
                                              )}
                                            </InputGroup>
                                            )
                                          : (<Text fontSize="lg" fontWeight="bold">{field.value}</Text>)
                                      )}
                                    </Field>
                                  )}
                                  {/* Render a select if the type is 'select' */}
                                  {item instanceof SelectData && (
                                    <Field name={item.name}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field }) => (
                                        editMode
                                          ? (
                                            <Select {...item.inputProps} {...field}>
                                              {item.options?.map((option) => (
                                                <option key={option.value} value={option.value}>{option.text}</option>
                                              ))}
                                            </Select>
                                            )
                                          : (
                                            // Here we find the selected option and show its text instead of the value
                                            <Text fontSize="lg" fontWeight="bold">{item.options?.find((e) => e.value === field.value)?.text}</Text>
                                            )
                                      )}
                                    </Field>
                                  )}
                                  {/* Render a Button if the type is compatible */}
                                  {item instanceof ButtonData && (
                                    <Box justifySelf="flex-end">
                                      <Field name={item.name} type={item.type}>
                                        {/* @ts-expect-error no specific types yet */}
                                        {({ field }) => (
                                          editMode
                                            ? (
                                              <Button id={`button-${item.type}`} type={item.type} {...item.inputProps} {...field} onClick={item.type === 'reset' ? handleReset : undefined}>
                                                {item.title}
                                              </Button>
                                              )
                                            : null
                                        )}
                                      </Field>
                                    </Box>
                                  )}
                                  {item instanceof CheckboxData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field }) => (
                                        editMode
                                          ? (
                                            <Checkbox {...item.inputProps} {...field} defaultChecked={field.checked} colorScheme="accent">
                                              {item.title}
                                            </Checkbox>
                                            )
                                          : (
                                            <Checkbox {...item.inputProps} {...field} defaultChecked={field.checked} colorScheme="accent" disabled>
                                              {item.title}
                                            </Checkbox>
                                            )
                                      )}
                                    </Field>
                                  )}
                                  {item instanceof TextAreaData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field }) => (
                                        editMode
                                          ? (
                                            <Textarea {...item.inputProps} {...field}/>
                                            )
                                          : (
                                            <Text fontSize="lg" fontWeight="bold" {...item.inputProps} {...field}>
                                              {field.value}
                                            </Text>
                                            )
                                      )}
                                    </Field>
                                  )}
                                  {item instanceof NumberInputData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field, form }) => (
                                        editMode
                                          ? (
                                            <NumberInput
                                              {...item.inputProps}
                                              onChange={(val) =>
                                                form.setFieldValue(field.name, val)}
                                            >
                                              <NumberInputField {...field}/>
                                              <NumberInputStepper>
                                                <NumberIncrementStepper {...item.incrementStepperProps}/>
                                                <NumberDecrementStepper {...item.decrementStepperProps}/>
                                              </NumberInputStepper>
                                            </NumberInput>
                                            )
                                          : (
                                            <Text fontSize="lg" fontWeight="bold" {...item.inputProps} {...field}>
                                              {field.value}
                                            </Text>
                                            )
                                      )}
                                    </Field>
                                  )}
                                  {item instanceof IncrementAmountInputData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field, form }) => (
                                        editMode
                                          ? (
                                            <IncrementAmountInput
                                              {...item.inputProps}
                                              {...field}
                                              min={item.minValue}
                                              max={item.maxValue}
                                              amount={item.defaultValue}
                                              onChange={(val) =>
                                                form.setFieldValue(field.name, val)}
                                            />
                                            )
                                          : (
                                            <Text fontSize="lg" fontWeight="bold" {...item.inputProps} {...field}>
                                              {field.value}
                                            </Text>
                                            )
                                      )}
                                    </Field>
                                  )}
                                  {item instanceof AutoCompleteData && (
                                    <Field name={item.name} type={item.type}>
                                      {/* @ts-expect-error no specific types yet */}
                                      {({ field }) => (
                                        <Autocomplete items={item.options} label={String(item.title)} onSelect={val => { setFieldValue(item.name!, val) }}/>
                                      )}
                                    </Field>
                                  )}
                                  {/*
                                    render error messages only on properties with name
                                    (e.g. NOT on buttons)
                                  */}
                                  {item.name && (
                                    <ErrorMessage name={item.name} component={FormErrorMessage}/>
                                  )}
                                </FormControl>
                                )
                          })}
                        </Box>
                      ))
                    }
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </FormikForm>
      )}
    </Formik>
  )
}
