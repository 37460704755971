import { Box, Stack, Text } from '@chakra-ui/react'
import { ProgressStep } from '../../'
import type { useStep } from '@stocker/ui-components/helpers'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IProgressStepsProps {
  steps: Array<{
    title: string
    description?: string
  }>
  initialStep?: number
  useStep: ReturnType<typeof useStep>
}

export const ProgressSteps: React.FC<IProgressStepsProps> = ({ steps, initialStep = 0, useStep }) => {
  const [currentStep, { setStep }] = useStep
  const intl = useIntl()

  return (
    <>
      <Box py={{ base: '4', md: '8' }} display={{ base: 'none', md: 'block' }}>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <ProgressStep
              key={`${step.title}-${step.description ?? '_'}`}
              cursor="pointer"
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onClick={() => { currentStep > index ? setStep(index) : undefined }}
              title={step.title}
              description={step.description ?? ''}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              isFirstStep={index === 0}
              isLastStep={steps.length === index + 1}
            />
          ))}
        </Stack>
      </Box>
      <Box py={{ base: '1', md: '8' }} display={{ base: 'block', md: 'none' }}>
        <Box pb="4">
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {steps[currentStep].title}
          </Text>
          <Text fontSize="md" textAlign="center">
            {intl.formatMessage({ id: 'progress-bar--current-step-of' }, { currentStep: currentStep + 1, maxStep: steps.length })}
          </Text>
        </Box>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <ProgressStep
              key={`${step.title}-${step.description ?? '_'}`}
              cursor="pointer"
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onClick={() => { currentStep > index ? setStep(index) : undefined }}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              isFirstStep={index === 0}
              isLastStep={steps.length === index + 1}
            />
          ))}
        </Stack>
      </Box>
    </>
  )
}
