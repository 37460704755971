import type { ComponentWithAs, IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'

function createIcon (svg: React.FC): ComponentWithAs<'svg', IconProps> {
  // eslint-disable-next-line react/function-component-definition
  return (props) => <Icon {...props as Record<string, unknown>} as={svg}/>
}

// This is a place for custom icons specific for each project not to be mixed with the design system icons

export const PriceChange = createIcon((props) => (
  <svg viewBox="197 21 477 477" width="1em" height="1em" {...props}>
    <path
      stroke="null"
      fill="currentColor"
      d="M661 143c-6-2-12-1-16 3a239 239 0 00-448 114 14 14 0 0028 0 211 211 0 01395-102 14 14 0 00-2 28l24 7a14 14 0 0017-9l10-23c2-7-1-16-8-18zm-1 103c-8 0-14 6-14 13a211 211 0 01-393 105 14 14 0 003-25l-21-13c-7-4-15-2-19 3l-15 21a14 14 0 0022 18 238 238 0 00451-108c0-8-6-14-14-14z"
    />
    <path
      stroke="null"
      fill="currentColor"
      d="M545 328a107 107 0 01-179-21h102a18 18 0 000-36H355a101 101 0 010-23h113a18 18 0 000-36H366a107 107 0 01179-21 18 18 0 1027-23 143 143 0 00-245 44h-14a18 18 0 000 36h6v23h-6a18 18 0 000 36h14a143 143 0 00245 44 18 18 0 10-27-23z"
    />
  </svg>
))
