import type { LinkProps, TextProps } from '@chakra-ui/react'
import { Box, HStack, Text } from '@chakra-ui/react'
import type React from 'react'
import { NextLink } from '../../utility/NextLink'
import { FiRsEnvelope, FiRsMobileNotch, FiRsPhoneCall } from '../../elements/Icons'
import { FiRsPhoneOffice } from '../../elements/Icons4'

export type contactMode = 'email' | 'fax' | 'telephone' | 'mobilephone'
export interface ILinkSecondaryProps extends LinkProps {
  href: string
  text: string
  textShort?: string
  isShort?: boolean
  mode?: contactMode
  fontSize?: TextProps['fontSize']
}

const getLinkIcon = (mode: contactMode, iconSize: TextProps['fontSize']) => {
  return {
    email: <FiRsEnvelope fontSize={iconSize}/>,
    fax: <FiRsPhoneOffice fontSize={iconSize}/>,
    telephone: <FiRsPhoneCall fontSize={iconSize}/>,
    mobilephone: <FiRsMobileNotch fontSize={iconSize}/>,
  }[mode]
}

export const LinkSecondary: React.FC<ILinkSecondaryProps> = ({ href, text, mode, fontSize, textShort, ...linkProps }) => {
  return (
    <Box w="fit-content">
      <NextLink
        href={href}
        position="relative"
        _hover={{ color: 'white' }}
        {...linkProps}
      >
        <HStack>
          {mode && getLinkIcon(mode, fontSize)}
          <Text display={{ base: 'none', md: 'block' }}>{text}</Text>
          <Text display={{ base: 'block', md: 'none' }}>{textShort ?? text}</Text>
        </HStack>
      </NextLink>
    </Box>
  )
}
