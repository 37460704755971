import { Box, Button, HStack, RadioGroup, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { ShippingRadioButton, type IImage } from '../../'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ICheckoutDeliveryProps {
  quickActions: Array<{
    title: string
    onClick: () => void
    color?: string
    icon?: React.ReactNode
  }>
  shippingMethods: Array<{
    id: string
    name: string
    price: number
    deliveryTime?: string
    currencyCode?: string
    logo?: IImage
    description?: string
    deliveryRank?: 'green' | 'yellow' | 'red'
  }>
  selectedShippingMethod?: string
  onShippingMethodChange?: (value: string) => void
  isLoading?: boolean
}

export const CheckoutShipping: React.FC<ICheckoutDeliveryProps> = ({ shippingMethods, quickActions, selectedShippingMethod, onShippingMethodChange, isLoading = false }) => {
  const intl = useIntl()
  return (
    <Box>
      {quickActions.length > 0 && (
        <>
          <Text fontWeight="bold" fontSize="lg">{intl.formatMessage({ id: '--quick-actions' })}</Text>
          <Stack direction={{ base: 'column', md: 'row' }}>
            {quickActions.map((action, index) => (
              <Button colorScheme={action.color} key={index} onClick={action.onClick} isLoading={isLoading}>
                <HStack>
                  {action.icon}
                  <Text>
                    {action.title}
                  </Text>
                </HStack>
              </Button>
            ))}
          </Stack>
        </>
      )}
      <RadioGroup colorScheme="accent" size="lg" value={selectedShippingMethod} onChange={onShippingMethodChange}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
          {shippingMethods.map((shippingMethod, index) => (
            <ShippingRadioButton
              key={`shipping-method-${index}`}
              id={shippingMethod.id}
              title={shippingMethod.name}
              price={shippingMethod.price}
              deliveryTime={shippingMethod.deliveryTime ?? ''}
              currencyCode={shippingMethod.currencyCode}
              description={shippingMethod.description}
              logo={shippingMethod.logo}
              deliveryRank={shippingMethod.deliveryRank}
            />
          ))}
        </SimpleGrid>
      </RadioGroup>
    </Box>
  )
}
