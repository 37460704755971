import { Icon } from '@chakra-ui/icons'
import type { HeadingProps } from '@chakra-ui/react'
import { Button, Heading, Stack, useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { NextLink } from '@stocker/ui-components/design-system'
import { asComponent } from '@stocker/ui-components/helpers'
import type React from 'react'
import { GoNote } from 'react-icons/go'
import { useIntl } from 'react-intl'

interface IFooterNewsletter {
  innerHtml: string
  titleAs?: HeadingProps['as']
}
interface IColors {
  colorText?: string
  colorTextDeemphasized?: string
  colorTextSecondary?: string
  colorBackgroundSecondary?: string
}
export interface ICombined extends IColors, IFooterNewsletter {}

export const FooterNewsletter: React.FC<ICombined> = ({
  innerHtml,
  titleAs,
  colorText = 'primaryText.500',
  colorTextDeemphasized = 'primaryText.750',
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Stack
      w={{ base: '100%' }}
      spacing="0"
      color={colorTextDeemphasized}
      align={{ base: 'center', md: 'normal' }}
    >
      <Stack
        mb="16px"
        textAlign={{ base: 'center', md: 'left' }}
        css={css`
          @media (max-width: ${theme.breakpoints.md}) {
            display: none;
          }
        `}
      >
        <Stack
          direction="row"
          color={colorText}
          align="center"
          fontSize="2xl"
        >
          <Icon as={GoNote}/>
          <Heading
            fontSize="inherit"
            as={titleAs}
          >{intl.formatMessage({ id: '--newsletter' })}
          </Heading>
        </Stack>
        {asComponent(innerHtml)}
      </Stack>
      <NextLink
        href="/service/newsletter-anmeldung"
      >
        <Button
          variant="primary"
          px="12px"
          py="6px"
          fontWeight={300}
        >{intl.formatMessage({ id: 'footer--to-registration' })}
        </Button>
      </NextLink>
    </Stack>
  )
}
