import { Button, Center, Heading, Text } from '@chakra-ui/react';
import { NextLink } from '@stocker/ui-components/design-system';
import NextImage from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

export interface ILoginNoticeProps {
  redirect?: string;
}

export const LoginNotice: React.FC<ILoginNoticeProps> = ({ redirect }) => {
  const { formatMessage } = useIntl();

  const stockerLoginUrl = (process.env.NEXT_PUBLIC_STOCKER_LOGIN_URL ?? '').replace(/\/+$/, '');
  const redirectUrl = redirect ?? 'https://konfigurator.stocker-kaminsysteme.com';

  // Construct the final URL using URL and URLSearchParams to avoid any potential issues
  const loginUrl = new URL(stockerLoginUrl);
  loginUrl.searchParams.set('redirect', redirectUrl);
  const finalUrl = loginUrl.toString();

  return (
    <Center h="50vh" flexDir="column" gap={6}>
      <NextImage
        src="/img/logo_header.svg"
        width={300}
        height={150}
        alt="Stocker Logo"
      />
      <Heading>{formatMessage({ id: 'configurator-login--please-login' })}</Heading>
      <Text fontSize="2xl">{formatMessage({ id: 'configurator-login--main-text' })}</Text>
      <NextLink href={finalUrl}>
        <Button colorScheme="accent" size="lg">
          {formatMessage({ id: '--login' })}
        </Button>
      </NextLink>
    </Center>
  );
};
