import { Box, Button, Flex, HStack, Spacer, Spinner, Text, Tooltip } from '@chakra-ui/react'
import type {
  SystemConfigByIdQuery,
  useAddConfigToBasketMutation,
  useCreateConfigVariantLineMutation,
  useRemoveConfigVariantLineMutation,
  useUpdateConfigVariantLineMutation,
} from '@stocker/codegen/configurator-backend'
import { useAccessoriesQuery } from '@stocker/codegen/configurator-backend'
import type { IImage } from '@stocker/ui-components/design-system'
import {
  Currency,
  FiRsDocument,
  FiRsPlusSmall,
  FiRsShoppingCart,
} from '@stocker/ui-components/design-system'
import groupBy from 'lodash/groupBy'
import NextImage from 'next/image'
import type React from 'react'
import { Fragment, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AmountSelect } from '../../compounds/AmountSelect/AmountSelect'
import { PriceTooltip } from '../../compounds/PriceTooltip/PriceTooltip'
import { useConfiguration } from '../ConfigurationProvider'
import { SystemAccessoriesSelect } from '../SystemAccessoriesSelect/SystemAccessoriesSelect'
import type { ISystemTotalCartProps } from '../SystemTotalCart/SystemTotalCart'
import { SystemTotalCart } from '../SystemTotalCart/SystemTotalCart'

export interface ISystemBasketProps {
  items: IBasketItemProps[]
  isLoading?: {
    products?: boolean
  }
  isConfigurationInBasket: boolean
  configurationId: string
  addConfigToBasket?: ReturnType<typeof useAddConfigToBasketMutation>
  updateConfigVariantLine?: ReturnType<typeof useUpdateConfigVariantLineMutation>
  removeConfigVariantLine?: ReturnType<typeof useRemoveConfigVariantLineMutation>
  cartProps: ISystemTotalCartProps
  createConfigVariantLine?: ReturnType<typeof useCreateConfigVariantLineMutation>
  isExpertCheckRequested: boolean
  configuration?: SystemConfigByIdQuery['systemConfig'] | undefined
}

export interface IBasketItemProps {
  image: IImage
  name: string
  sku: string
  configVariantLineId: string
  category: string
  price: {
    amount: number
    currencyCode: string
  }
  quantity: number
}

function serializeBasketForOfferTool(items: IBasketItemProps[]) {
  const basketItemsCombinedBySku = items.reduce<Record<string, number | undefined>>((acc, item) => {
    const exisiting = acc[item.sku] ?? 0
    acc[item.sku] = exisiting + item.quantity
    return acc
  }, {})

  const basketItemsForOfferTool = Object.entries(basketItemsCombinedBySku).map(([key, value]) => ({
    i: key,
    q: value,
  }))

  return Buffer.from(JSON.stringify(basketItemsForOfferTool)).toString('base64')
}

function categoryOrder(category: string) {
  switch (category) {
    case 'Accessories':
      return 1
    case 'FootingService':
      return 2
    case 'CleaningPortService':
      return 3
    case 'DraughtRegulatorService':
      return 4
    case 'TConnectorService':
      return 5
    case 'LengthelementService':
      return 6
    case 'WallMountingService':
      return 7
    case 'OffsetService':
      return 8
    case 'RoofductService':
      return 9
    case 'OverRoofService':
      return 10
    default:
      return 11
  }
}

export const SystemBasket: React.FC<ISystemBasketProps> = ({
  updateConfigVariantLine,
  configurationId,
  configuration,
  addConfigToBasket,
  isLoading,
  items,
  isConfigurationInBasket,
  cartProps,
  createConfigVariantLine,
  removeConfigVariantLine,
  isExpertCheckRequested,
}) => {
  const { formatMessage } = useIntl()

  const { configurationName } = useConfiguration()

  const totalPrice = items.reduce((acc, item) => acc + item.price.amount * item.quantity, 0) / 100

  const serializedBasket = serializeBasketForOfferTool(items)

  const basketItemsBySortedCategory = useMemo(
    () =>
      Object.values(groupBy(items, (v) => v.category)).sort((a, b) => {
        return categoryOrder(b[0].category) - categoryOrder(a[0].category)
      }),
    [items],
  )

  const diameterFieldId = configuration?.system?.modules
    .find((module) => module.code === 'general')
    ?.fields?.find((field) => field.code === 'diameter')?.id

  const diameterFieldValue = configuration?.moduleFieldValues.find(
    (fieldValue) => fieldValue.fieldId === diameterFieldId,
  )?.value

  /*const pressureFieldId = configuration?.system
    ?.modules.find((module) => module.code === 'general')
    ?.fields?.find((field) => field.code === 'pressure')
    ?.id*/
  // above code fetches the wrong ID, hence it is now hardcoded, the pressure is needed for accessories
  const pressureFieldId = '6eb673d1-58d7-5c37-8f80-6266f9f89b37'

  let pressureFieldValue = configuration?.moduleFieldValues.find(
    (fieldValue) => fieldValue.fieldId === pressureFieldId,
  )?.value

  if (!pressureFieldValue) {
    const fallbackPressureFieldId = '3f5867b6-9824-5389-83d9-245691aeff36'
    pressureFieldValue = configuration?.moduleFieldValues.find(
      (fieldValue) => fieldValue.fieldId === fallbackPressureFieldId,
    )?.value
  }

  const filter = {
    dn: diameterFieldValue,
    pressure: pressureFieldValue,
  }

  const accessories = useAccessoriesQuery(
    { systemId: configuration?.system?.id ?? '', filter },
    { enabled: !!configuration?.system?.id },
  )
  const availableAccessories = useMemo(
    () =>
      accessories.data?.accessories.map((accessory) => ({
        id: accessory.variantId,
        name: accessory.name?.de ?? '',
        sku: accessory.sku ?? '',
        price: accessory.price?.price ?? 0,
      })) ?? [],
    [accessories.data?.accessories],
  )

  return (
    <Flex direction="column" h="100%">
      <HStack
        justify="space-between"
        w="full"
        p={{ base: 3, xl: 4 }}
        fontSize={{ base: 'lg', xl: 'xl' }}
        fontFamily="primary"
        color="primaryText.500"
        bg="primaryBackground.500"
      >
        <Text>{formatMessage({ id: '--product-listing' })}</Text>
        {isLoading?.products && <Spinner />}
      </HStack>
      <Flex
        p={4}
        direction="column"
        gap={4}
        borderColor="secondaryText.50"
        borderWidth="2px"
        borderTop={0}
        h="100%"
        overflow="auto"
      >
        {basketItemsBySortedCategory.length > 0 ? (
          <Text fontFamily="primary" mt={2}>
            {formatMessage({ id: '--core-components' })}
          </Text>
        ) : (
          <Text>{formatMessage({ id: '--configurator-system-basket--empty-notice' })}</Text>
        )}
        {basketItemsBySortedCategory.map((items) => (
          <Fragment key={items[0].category}>
            <Text fontFamily="primary" fontSize="sm">
              {formatMessage({ id: `category-${items[0].category.toLowerCase()}` })}
            </Text>
            {items.map((item) => (
              <CartItem
                key={item.sku}
                item={item}
                updateConfigVariantLine={updateConfigVariantLine}
                removeConfigVariantLine={removeConfigVariantLine}
                isExpertCheckRequested={isExpertCheckRequested}
              />
            ))}
          </Fragment>
        ))}
        <Spacer />
        <Box ml="auto">
          {availableAccessories.length > 0 && diameterFieldValue && (
            <SystemAccessoriesSelect
              accesories={availableAccessories}
              configurationId={configurationId}
              createConfigVariantLine={createConfigVariantLine}
            >
              <Button
                size={{ base: 'sm', xl: 'md' }}
                leftIcon={<FiRsPlusSmall fontSize="2xl" />}
                variant="outline"
                colorScheme="secondaryText"
                px={4}
                py={2}
                isDisabled={isExpertCheckRequested}
              >
                {formatMessage({ id: '--add-accessories' })}
              </Button>
            </SystemAccessoriesSelect>
          )}
        </Box>
      </Flex>
      <Flex
        p={4}
        py={8}
        direction="column"
        gap={8}
        borderColor="secondaryText.50"
        borderWidth="2px"
        borderTop={0}
      >
        <Flex direction="row" justify="space-between" fontFamily="primary" fontSize="lg">
          <PriceTooltip />
          <Box fontSize="2xl">
            {totalPrice > 0 ? (
              <Currency amount={totalPrice} currencyCode={items[0]?.price.currencyCode} />
            ) : (
              <Box />
            )}
          </Box>
        </Flex>
        <Flex direction="row" justify="space-between">
          <a
            target="_blank"
            href={`${String(
              process.env.NEXT_PUBLIC_STOCKER_URL,
            )}/account/offer/createoffer?subject=${configurationName}&positionData=${serializedBasket}`}
            rel="noreferrer"
          >
            <Button
              size={{ base: 'sm', xl: 'md' }}
              leftIcon={<FiRsDocument fontSize="xl" />}
              variant="outline"
              colorScheme="secondaryText"
            >
              {formatMessage({ id: '--offer-create' })}
            </Button>
          </a>
          {isConfigurationInBasket ? (
            <SystemTotalCart {...cartProps}>
              <Button
                size={{ base: 'sm', xl: 'md' }}
                fontFamily="primary"
                colorScheme="accent"
                leftIcon={<FiRsShoppingCart />}
              >
                {formatMessage({ id: '--open-basket' })}
              </Button>
            </SystemTotalCart>
          ) : (
            <Tooltip
              label="Fügen Sie Artikel zur Konfiguration hinzu"
              isDisabled={items.length > 0}
            >
              <Button
                size={{ base: 'sm', xl: 'md' }}
                fontFamily="primary"
                colorScheme="accent"
                leftIcon={<FiRsShoppingCart />}
                onClick={() => addConfigToBasket?.mutate({ configId: configurationId })}
                isLoading={addConfigToBasket?.isLoading}
                isDisabled={items.length === 0}
              >
                {formatMessage({ id: '--add-to-basket' })}
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const CartItem: React.FC<{
  item: IBasketItemProps
  updateConfigVariantLine?: ReturnType<typeof useUpdateConfigVariantLineMutation>
  removeConfigVariantLine?: ReturnType<typeof useRemoveConfigVariantLineMutation>
  isExpertCheckRequested: boolean
}> = ({ item, updateConfigVariantLine, removeConfigVariantLine, isExpertCheckRequested }) => {
  return (
    <Flex align={{ base: 'center', xl: 'top' }} direction="row" gap={{ base: 2, xl: 3 }}>
      <AmountSelect
        quantity={item.quantity}
        selectData={{
          min: 1,
          max: 100,
        }}
        price={item.price.amount}
        currencyCode={item.price.currencyCode}
        onSubmit={(amount: number) =>
          updateConfigVariantLine?.mutate({
            configVariantLineId: item.configVariantLineId,
            quantity: amount,
          })
        }
        isDisabled={isExpertCheckRequested}
        onDelete={() =>
          removeConfigVariantLine?.mutate({ configVariantLineId: item.configVariantLineId })
        }
      />
      <Box
        flexShrink={0}
        w={{ base: '50px', xl: '60px' }}
        h={{ base: '50px', xl: '60px' }}
        pos="relative"
      >
        <NextImage
          layout="fill"
          objectFit="contain"
          alt=""
          src={`${item.image.fullpath ?? ''}?width=120`}
        />
      </Box>
      <Flex direction="column">
        <Text fontWeight="bold" letterSpacing={0.7} fontSize={{ base: 'sm', xl: 'md' }}>
          {item.name}
        </Text>
        <Box color="secondaryText.400" fontSize={{ base: 'sm', xl: 'md' }} mt={0}>
          {item.sku}
        </Box>
      </Flex>
      <Spacer />
      <Box fontFamily="primary" fontSize={{ base: 'md', xl: 'lg' }}>
        <Currency
          amount={(item.price.amount * item.quantity) / 100}
          currencyCode={item.price.currencyCode}
        />
      </Box>
    </Flex>
  )
}
