import { useAvailableCountriesQuery } from '@stocker/codegen/vendure'
import type { IFormData } from '@stocker/ui-components/design-system'
import {
  ButtonData,
  CheckboxData,
  FiRsCrossedEye,
  FiRsEye,
  Form,
  InputData,
  SelectData,
} from '@stocker/ui-components/design-system'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

export interface IRegisterFormData extends yup.InferType<ReturnType<typeof getSchema>> {}

interface IRegisterFormProps {
  onSubmit: (values: IRegisterFormData) => void
  isLoading: boolean
}

function getSchema(formatMessage: ReturnType<typeof useIntl>['formatMessage']) {
  return yup.object().shape({
    companyName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    vat: yup.string(),
    streetLine1: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    streetLine2: yup.string(),
    postalCode: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    city: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    countryCode: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    province: yup.string(),
    firstName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    lastName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    emailAddress: yup
      .string()
      .email(formatMessage({ id: 'form--field-must-be-a-valid-email' }))
      .required(formatMessage({ id: 'form--field-is-required' })),
    phoneNumber: yup.string(),
    salutation: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    title: yup.string(),
    privacy: yup.boolean().equals([true], formatMessage({ id: 'form--field-is-required' })),
    password: yup
      .string()
      .required(formatMessage({ id: 'form--field-is-required' }))
      .min(8, formatMessage({ id: 'form--field-password-min-length' }))
      .minLowercase(1, formatMessage({ id: 'form--field-password-min-lowercase' }))
      .minUppercase(1, formatMessage({ id: 'form--field-password-min-uppercase' }))
      .minSymbols(1, formatMessage({ id: 'form--field-password-min-symbols' })),
    passwordRepeat: yup
      .string()
      .equals(
        [yup.ref('password'), null],
        formatMessage({ id: 'form--field-password-must-match' }),
      ),
  })
}

export const RegisterForm: React.FC<IRegisterFormProps> = ({ onSubmit, isLoading }) => {
  const { data, error } = useAvailableCountriesQuery()
  const { formatMessage } = useIntl()
  const { query, route } = useRouter()
  const {
    salutation,
    email,
    phone,
    title,
    firstname,
    lastname,
    vat,
    companyName,
    streetLine1,
    streetLine2,
    city,
    postalCode,
    country,
  } = query

  const validationSchema = useMemo(() => getSchema(formatMessage), [formatMessage])

  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isPasswordRepeatShown, setIsPasswordRepeatShown] = useState(false)

  const items: IFormData = useMemo(
    () => ({
      formTitle: 'test',
      blocks: [
        [
          {
            blockTitle: {
              text: formatMessage({ id: '--company' }),
              type: 'h3',
            },
            fields: [
              [
                new InputData({
                  title: formatMessage({ id: '--company' }),
                  name: 'companyName',
                  defaultValue: companyName as string,
                  type: 'text',
                  grow: 1,
                  minWidth: '320px',
                }),
                new InputData({
                  title: formatMessage({ id: '--vat' }),
                  name: 'vat',
                  defaultValue: vat as string,
                  type: 'text',
                  minWidth: '100px',
                  inputProps: {
                    placeholder: 'Bitte ohne Leerzeichen eingeben: z. B. ATU31845206',
                  },
                }),
              ],
            ],
          },
        ],
        [
          {
            blockTitle: {
              text: formatMessage({ id: '--company-address' }),
              type: 'h3',
            },
            fields: [
              [
                new InputData({
                  title: formatMessage({ id: '--street-line-1' }),
                  name: 'streetLine1',
                  defaultValue: streetLine1 as string,
                  type: 'text',
                }),
                new InputData({
                  title: formatMessage({ id: '--street-line-2' }),
                  name: 'streetLine2',
                  defaultValue: streetLine2 as string,
                  type: 'text',
                }),
              ],
              [
                new InputData({
                  title: formatMessage({ id: '--zip-code' }),
                  name: 'postalCode',
                  defaultValue: postalCode as string,
                  type: 'text',
                }),
                new InputData({
                  title: formatMessage({ id: '--city' }),
                  name: 'city',
                  defaultValue: city as string,
                  type: 'text',
                  grow: 2,
                }),
              ],
              [
                new SelectData({
                  title: formatMessage({ id: '--country' }),
                  name: 'countryCode',
                  options:
                    data?.availableCountries
                      .map((country) => ({ value: country.code, text: country.name }))
                      .sort((a, b) => a.text.localeCompare(b.text)) ?? [],
                  inputProps: {
                    placeholder: formatMessage({ id: '--country-select' }),
                  },
                  defaultValue: country as string,
                }),
                new InputData({
                  title: formatMessage({ id: '--province' }),
                  name: 'province',
                  type: 'text',
                  grow: 2,
                }),
              ],
            ],
          },
        ],
        [
          {
            blockTitle: {
              text: formatMessage({ id: '--person' }),
              type: 'h3',
            },
            fields: [
              [
                new SelectData({
                  title: formatMessage({ id: '--salutation' }),
                  name: 'salutation',
                  options: [
                    { value: 'mr', text: 'Herr' },
                    { value: 'mrs', text: 'Frau' },
                  ],
                  minWidth: '200px',
                  defaultValue: salutation === 'H' ? 'mr' : salutation === 'F' ? 'mrs' : '',
                  inputProps: {
                    placeholder: formatMessage({ id: '--select-salutation' }),
                  },
                }),
                new InputData({
                  title: formatMessage({ id: '--title' }),
                  name: 'title',
                  defaultValue: title as string,
                  type: 'text',
                  minWidth: '200px',
                }),
              ],
              [
                new InputData({
                  title: formatMessage({ id: '--firstname' }),
                  defaultValue: firstname as string,
                  name: 'firstName',
                  type: 'text',
                  minWidth: '200px',
                }),
                new InputData({
                  title: formatMessage({ id: '--lastname' }),
                  defaultValue: lastname as string,
                  name: 'lastName',
                  type: 'text',
                  minWidth: '200px',
                }),
              ],
              [
                new InputData({
                  title: formatMessage({ id: '--email' }),
                  defaultValue: email as string,
                  name: 'emailAddress',
                  type: 'email',
                  minWidth: '200px',
                }),
                new InputData({
                  title: formatMessage({ id: '--phone' }),
                  defaultValue: phone as string,
                  name: 'phoneNumber',
                  type: 'text',
                  minWidth: '200px',
                }),
              ],
              [
                new InputData({
                  title: formatMessage({ id: '--password' }),
                  name: 'password',
                  type: isPasswordShown ? 'text' : 'password',
                  minWidth: '200px',
                  rightElement: isPasswordShown ? (
                    <FiRsCrossedEye
                      onClick={() => {
                        setIsPasswordShown(false)
                      }}
                      fontSize="lg"
                      cursor="pointer"
                    />
                  ) : (
                    <FiRsEye
                      onClick={() => {
                        setIsPasswordShown(true)
                      }}
                      fontSize="lg"
                      cursor="pointer"
                    />
                  ),
                }),
                new InputData({
                  title: formatMessage({ id: '--password-repeat' }),
                  name: 'passwordRepeat',
                  type: isPasswordRepeatShown ? 'text' : 'password',
                  minWidth: '200px',
                  rightElement: isPasswordRepeatShown ? (
                    <FiRsCrossedEye
                      onClick={() => {
                        setIsPasswordRepeatShown(false)
                      }}
                      fontSize="lg"
                      cursor="pointer"
                    />
                  ) : (
                    <FiRsEye
                      onClick={() => {
                        setIsPasswordRepeatShown(true)
                      }}
                      fontSize="lg"
                      cursor="pointer"
                    />
                  ),
                }),
              ],
            ],
          },
        ],
        [
          {
            blockTitle: {
              text: 'Sonstiges',
              type: 'h3',
            },
            fields: [
              [
                new CheckboxData({
                  title: formatMessage({ id: 'register--accept-privacy-statement' }),
                  name: 'privacy',
                }),
              ],
              [
                new ButtonData({
                  title: formatMessage({ id: 'register--submit' }),
                  type: 'submit',
                  inputProps: { marginTop: '5', colorScheme: 'accent', isLoading },
                }),
              ],
            ],
          },
        ],
      ],
    }),
    [
      formatMessage,
      companyName,
      vat,
      streetLine1,
      streetLine2,
      postalCode,
      city,
      data?.availableCountries,
      firstname,
      lastname,
      email,
      isPasswordShown,
      isPasswordRepeatShown,
      isLoading,
    ],
  )

  return (
    <Form
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      key={JSON.stringify(query)}
      items={items}
      onSubmit={(values: IRegisterFormData) => {
        onSubmit(values)
      }}
      validationSchema={validationSchema}
      blockGap={{ vertical: '1rem', horizontal: '10rem' }}
      fieldGap="1rem"
    />
  )
}
