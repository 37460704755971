import { Form, ButtonData, InputData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

export interface IUpdatePasswordFormData {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}

interface IUpdatePasswordFormProps {
  onSubmit: (values: IUpdatePasswordFormData) => void
  isLoading: boolean
  defaultValues?: Partial<IUpdatePasswordFormData>
}

export const UpdatePasswordForm: React.FC<IUpdatePasswordFormProps> = ({ onSubmit, isLoading, defaultValues }) => {
  const { formatMessage } = useIntl()

  const passwordValidationSchema = useMemo(() => yup.object().shape({
    currentPassword: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    newPassword: yup
      .string()
      .required(formatMessage({ id: 'form--field-is-required' }))
      .min(8, formatMessage({ id: 'form--field-password-min-length' }))
      .minLowercase(1, formatMessage({ id: 'form--field-password-min-lowercase' }))
      .minUppercase(1, formatMessage({ id: 'form--field-password-min-uppercase' }))
      .minSymbols(1, formatMessage({ id: 'form--field-password-min-symbols' })),
    newPasswordConfirm: yup.string().required(formatMessage({ id: 'form--field-is-required' })).oneOf([yup.ref('newPassword')], formatMessage({ id: 'form--passwords-dont-match' })),
  }), [formatMessage])

  const passwordFormData: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: 'account--account-settings--change-password-current-password' }), name: 'currentPassword', type: 'password', minWidth: '200px' }),
            ],
            [
              new InputData({ title: formatMessage({ id: 'account--account-settings--change-password-new-password' }), name: 'newPassword', type: 'password', minWidth: '200px' }),
            ],
            [
              new InputData({ title: formatMessage({ id: 'account--account-settings--change-password-new-password-confirm' }), name: 'newPasswordConfirm', type: 'password', minWidth: '200px' }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: '--save' }), type: 'submit', align: 'right', inputProps: { colorScheme: 'accent', isLoading, mt: 5 } }),
            ],
          ],
        },
      ],
    ],
  }), [formatMessage, isLoading])

  return (
    <Form
      blockGap={{ vertical: '1rem', horizontal: '10rem' }}
      fieldGap="1rem"
      items={passwordFormData}
      onSubmit={values => { onSubmit(values) }}
      validationSchema={passwordValidationSchema}
    />
  )
}
