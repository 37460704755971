import { Box, Button, Flex, NumberInput, NumberInputField, SimpleGrid } from '@chakra-ui/react'
import { AvailabilityStatus, InputNumberWheel, VariantSelector, Currency } from '@stocker/ui-components/design-system'
import type { IVariant } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

export interface IProductConfiguratorProps {
  variants: IVariant[]
  quantity: {
    min: number
    max: number
    initial?: number
  }
}

export const ProductConfigurator: React.FC<IProductConfiguratorProps> = ({ variants, quantity }) => {
  const [currentAmount, setCurrentAmount] = useState(quantity.initial ?? quantity.min)
  const [currentVariant, setCurrentVariant] = useState<IVariant | null>(null)
  const { formatMessage } = useIntl()

  // TODO: replace with real price
  const dummyPrice = 257.49
  const dummyCurrency = 'EUR'

  return (
    <Flex
      direction="column"
      maxW="xs"
      // gap={6}
    >
      <InputNumberWheel
        onChange={(amount) => { setCurrentAmount(amount) }}
        value={{ min: quantity.min, max: quantity.max, initial: currentAmount }}
      />
      <Flex p={6} direction="column" gap={6}>
        <VariantSelector
          variants={variants}
          onVariantChange={(variant) => { setCurrentVariant(variant) }}
        />
        <SimpleGrid columns={2} gap={6}>
          <Box position="relative" w="full">
            <Flex position="absolute" h="full" alignItems="center" color="secondaryText.300" right={4} pointerEvents="none" my="auto">
              {formatMessage({ id: '--pieces' })}
            </Flex>
            <NumberInput
              onChange={(valueString) => { setCurrentAmount(Number(valueString)) }}
              max={quantity.max}
              min={quantity.min}
              value={currentAmount}
            >
              <NumberInputField borderRadius="none"/>
            </NumberInput>
          </Box>
          <Button fontFamily="primary" w="full" colorScheme="accent" disabled={!currentVariant}>
            {formatMessage({ id: '--add' })}
          </Button>
        </SimpleGrid>
        {currentVariant && (
          <Box>
            <SimpleGrid columns={2} columnGap={3} w="fit-content" mb={1}>
              <Box fontFamily="primary" textAlign="right">
                <Currency amount={dummyPrice * currentAmount} currencyCode={dummyCurrency}/>
              </Box>
              <Box>
                {formatMessage({ id: '--total' })}
              </Box>
              <Box fontFamily="primary" textAlign="right" color="secondaryText.250">
                <Currency amount={dummyPrice} currencyCode={dummyCurrency}/>
              </Box>
              <Box color="secondaryText.300">
                {formatMessage({ id: '--unit-price' })}
              </Box>
            </SimpleGrid>
            {currentVariant.stockLevel && <AvailabilityStatus availabilityStatus={currentVariant.stockLevel}/>}
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
