import { Box, Text } from '@chakra-ui/react'
import { CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import type { IHeading } from '@stocker/ui-components/design-system'
import type React from 'react'

export interface IProductInformationProps {
  heading: IHeading
  subHeadings?: IHeading[]
  text?: string
}

export const ProductInformation: React.FC<IProductInformationProps> = ({
  heading,
  subHeadings,
  text,
}) => {
  const parsedText = asComponent(text ?? '')
  return (
    <Box zIndex={1}>
      <CustomHeading lineHeight={1.2} heading={heading}/>
      {subHeadings?.map((subHeading: IHeading) => (
        <Box pt="5px" key={String(subHeading.text)}>
          <CustomHeading color={subHeading.color} mt="0" mb="0" heading={subHeading}/>
        </Box>
      ))}
      {text && (
        <Text fontSize={{ base: 'md', lg: 'lg' }} pt="10px">
          {parsedText}
        </Text>
      )}
    </Box>
  )
}
