import { Box } from '@chakra-ui/react'
import type { NavigationByIdentifierQuery } from '@stocker/codegen/pimcore'

interface IHierarchicalMenuItem {
  label: string
  value: string
  isRefined: boolean
  count: number
  items: IHierarchicalMenuItem[] | undefined
}

interface IHierarchicalMenuProps {
  refine: (payload: string) => void
  items: IHierarchicalMenuItem[]
  createURL: (payload: string) => string
  currentRefinement?: string | undefined
  navItems: NavigationByIdentifierQuery
}

export const HierarchicalMenu: React.FC<IHierarchicalMenuProps> = ({ items, refine, createURL, currentRefinement, navItems }) => {
  const navigationProductLinks = navItems.navigationByIdentifier?.linkItems?.find(e => e?.title === 'Produkte')?.linkItems

  const sortOrder = navigationProductLinks?.map(e => e?.title) ?? []
  items.sort((a, b) => {
    return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label)
  })

  // here we sort the menu subItems according to how they are sorted in the navBar
  items.map(item => item.items?.sort((a, b) => {
    let navigationTitles: Array<string | undefined> | undefined = []

    // here we use sortOrder since it includes all category titles and depending on the title the subItems are sorted
    sortOrder.map(category => {
      if (a.value.includes(category ?? '') && b.value.includes(category ?? '')) {
        const navigationItems = navigationProductLinks?.find(e => e?.title === category)?.linkItems
        navigationTitles = navigationItems?.map(e => e?.title)
      }
    })

    return navigationTitles.indexOf(a.label) - navigationTitles.indexOf(b.label)
  }))

  return (
    <Box>
      {items.map((item, index) => (
        <Box key={item.label}>
          <a
            href={item.isRefined ? '' : createURL(item.value)}
            onClick={event => {
              event.preventDefault()
              // if the item is refined take the value and add the label at the end since for some reason the last part of the value gets removed when an item is refined
              if (item.isRefined) { refine(`${item.value} > ${item.label}`); return }
              refine(item.value)
            }}
          >
            <Box
              _hover={{
                color: 'accent.500',
              }}
              color={item.isRefined ? 'accent.500' : ''}
              fontWeight={item.isRefined ? 'bold' : 'normal'}
              my="5px"
            >
              {item.label}
              <Box
                display="inline"
                fontWeight="normal"
                color="black"
                fontSize="sm"
                ml="6px"
                borderRadius="md"
                px="5px"
                py="1px"
                bg="gray.100"
              >
                {item.count}
              </Box>
            </Box>
          </a>
          {(item.items && item.items.length > 1) && (
            <Box pl="20px" pb="5px">
              <HierarchicalMenu
                items={item.items}
                refine={refine}
                createURL={createURL}
                currentRefinement={currentRefinement}
                navItems={navItems}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}
