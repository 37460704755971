import { Box, Container, HStack, Spacer } from '@chakra-ui/react'
import type { ILinkSecondaryProps } from '../../'
import { LinkSecondary, NextLink } from '../../'
import NextImage from 'next/image'

import type React from 'react'
import { useIntl } from 'react-intl'
import type { StaticImport } from 'next/dist/shared/lib/get-img-props'

export interface ICheckoutNavigationProps {
  logo: StaticImport | string
}

export const CheckoutNavigation: React.FC<ICheckoutNavigationProps> = ({ logo }) => {
  const intl = useIntl()

  const quickbarContactLinks: ILinkSecondaryProps[] = [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' })}`,
      text: intl.formatMessage({ id: 'config--mobile-phone-number' }),
      mode: 'mobilephone',
    },
    {
      href: `mailto:${intl.formatMessage({ id: 'office@stocker.tirol' })}`,
      text: intl.formatMessage({ id: 'office@stocker.tirol' }),
      mode: 'email',
    },
  ]
  const quickbarContactLinksMobile: ILinkSecondaryProps[] = [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' })}`,
      text: intl.formatMessage({ id: '--call' }),
      mode: 'mobilephone',
    },
    {
      href: `mailto:${intl.formatMessage({ id: 'office@stocker.tirol' })}`,
      text: intl.formatMessage({ id: '--email' }),
      mode: 'email',
    },
  ]

  return (
    <Box
      backgroundColor="primaryBackground.500"
    >
      <Container
        maxW="container.page"
        py="8px"
        fontSize="md"
      >
        <HStack
          h={{ base: '60px', md: '75px', lg: '100px' }}
        >
          <NextLink href="/">
            <Box
              pr={{ base: '0', md: '24px' }}
              width={{ base: '120px', sm: '198px' }}
              mb="auto"
              mt="auto"
            >
              <NextImage
                src={logo}
                width="150"
                height="43"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                alt="logo"
              />
            </Box>
          </NextLink>
          <Spacer/>
          {/* Show shortened links on mobile */}
          <HStack spacing={6} display={{ base: 'none', md: 'flex' }}>
            {quickbarContactLinks.map(link => (
              <LinkSecondary
                key={link.href}
                href={link.href}
                text={link.text}
                mode={link.mode}
                color="primaryText.500"
              />
            ))}
          </HStack>
          <HStack spacing={6} display={{ base: 'flex', md: 'none' }}>
            {quickbarContactLinksMobile.map(link => (
              <LinkSecondary
                key={link.href}
                href={link.href}
                text={link.text}
                mode={link.mode}
                color="primaryText.500"
              />
            ))}
          </HStack>
        </HStack>
      </Container>
    </Box>
  )
}
