import { Button, Divider, Flex, Text } from '@chakra-ui/react'
import { Form, ButtonData, InputData, JsxData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import Link from 'next/link'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export interface IRequestPasswordResetFormData {
  email: string
}

interface IRequestPasswordResetFormProps {
  onSubmit: (data: IRequestPasswordResetFormData) => void
  isLoading: boolean
}
export const RequestPasswordResetForm: React.FC<IRequestPasswordResetFormProps> = ({ onSubmit, isLoading }) => {
  const { formatMessage } = useIntl()

  const validationSchema = useMemo(() => yup.object().shape({
    email: yup.string().email().required(formatMessage({ id: 'form--field-is-required' })),
  }), [formatMessage])

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--email' }), name: 'email', type: 'email' }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: 'request-password-reset--submit' }), type: 'submit', inputProps: { width: 'full', colorScheme: 'accent', isLoading } }),
            ],
          ],
        },
      ],
      [
        {
          fields: [
            [
              new JsxData({
                jsx:
  <Flex align="center" w="100%">
    <Divider/>
    <Text padding="2">{formatMessage({ id: '--or' })}</Text>
    <Divider/>
  </Flex>,
              }),
            ],
            [
              new JsxData({
                jsx:
  <Link href="/auth/login">
    <Button cursor="pointer" as="a" w="full">{formatMessage({ id: 'request-password-reset--return-to-login' })}</Button>
  </Link>,
              }), // as: Link, href: '/auth/register'
            ],
          ],
        },
      ],
    ],

  }), [formatMessage, isLoading])
  return (
    <Form
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  )
}
