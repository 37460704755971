import type { PropsWithChildren } from 'react'
import type React from 'react'
import { createContext, useContext, useState } from 'react'

interface IConfigurationContext {
  configurationName: string
  setConfigurationName: (name: string) => void
}

const ConfigurationContext = createContext<IConfigurationContext | null>(null)

export const useConfiguration = () => {
  if (!ConfigurationContext) throw new Error('useConfiguration must be used within a ConfigurationProvider')
  return useContext(ConfigurationContext)!
}

export const ConfigurationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [configurationName, setConfigurationName] = useState('')
  return (
    <ConfigurationContext.Provider value={{ configurationName, setConfigurationName }}>
      {children}
    </ConfigurationContext.Provider>
  )
}
