import { Box, Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react'
import type React from 'react'

export interface IDebugSummaryProps {
  calculationErrors: ICalculationErrors[]
}

interface ICalculationErrors {
  configurationSourceKey: string
  type: string
  message?: string | null
  filter?: string | null
}

export const DebugSummary: React.FC<IDebugSummaryProps> = ({ calculationErrors }) => {
  return (
    <Flex h="full" direction="column">
      <HStack
        justify="space-between"
        w="full"
        p={{ base: 3, xl: 4 }}
        fontSize={{ base: 'lg', xl: 'xl' }}
        fontFamily="primary"
        color="primaryText.500"
        bg="primaryBackground.500"
      >
        <Text>
          Debug Konsole
        </Text>
      </HStack>
      <Flex p={4} direction="column" gap={4} borderColor="secondaryText.50" borderWidth="2px" borderTop={0} h="100%" overflow="auto">
        <Text fontFamily="primary">Kalkulations Fehler</Text>
        <Stack gap={2}>
          {calculationErrors.map((calculationError, idx) => (
            <Box key={idx}>
              {
                Object.entries(calculationError).map(([key, value]) => (
                  <Text key={key} fontSize="sm">
                    {`${key}: ${String(value)}`}
                  </Text>
                ))
              }
              <Divider py={2}/>
            </Box>
          ),
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}
