import { extendTheme } from '@chakra-ui/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import { chakraComponents } from './chakraComponents'
import { colors } from './colors'
import { customComponents } from './customComponents'
import { styles } from './styles'

export const defaultTheme = extendTheme({
  fonts: {
    heading: 'Sora, sans-serif',
    body: 'Inter, sans-serif',
    primary: 'Sora, sans-serif',
    secondary: 'Inter, sans-serif',
  },
  styles,
  colors,
  sizes: {
    container: {
      page: '1460px',
      content: '920px',
    },
  },
  components: {
    ...chakraComponents,
    ...customComponents,
  },
})
