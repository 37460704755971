import type { ComponentStyleConfig } from '@chakra-ui/react'

const Button: ComponentStyleConfig = {
  baseStyle: {
    rounded: 'none',
  },
  variants: {
    primary: {
      color: 'primaryText.500',
      bgColor: 'primaryBackground.500',
      borderRadius: '0',
      border: '1px solid',
      borderColor: 'primaryText.500',
      _hover: {
        color: 'secondaryText.500',
        bgColor: 'secondaryBackground.500',
      },
      width: 'fit-contet',
      height: 'fit-content',
    },
    secondary: {
      backgroundColor: 'primaryText.500',
      borderRadius: '0',
      border: '1px solid',
      borderColor: 'secondaryText.500',
      _hover: { bgColor: 'primaryBackground.900', color: 'secondaryBackground.500' },
      width: 'fit-contet',
      height: 'fit-content',
    },
    paginationButton: {},
  },
}
const IconButton: ComponentStyleConfig = {
  variants: {
    paginationButton: {
      ...Button.variants?.paginationButton,
    },
  },
}

const Heading: ComponentStyleConfig = {
  // hacky fix to remove standard heading sizes from chakra to be able to use custom sizes
  sizes: null as unknown as undefined,
}
const Text: ComponentStyleConfig = {
  baseStyle: {
    // color: 'secondaryText.500', // Seems a bit ugly to me but the default color is a kind of grey
  },
}
const Link: ComponentStyleConfig = {
  baseStyle: {
    _active: { textDecoration: 'none' },
    _visited: { textDecoration: 'none' },
    _hover: { textDecoration: 'none' },
  },
}

const Select: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: 'primaryBackground.500',
    errorBorderColor: 'failedColor.500',
  },
}

export const chakraComponents = {
  Button,
  IconButton,
  Heading,
  Text,
  Link,
  Select,
}
