export const news = [
  {
    displayDate: '2021.09.01',
    image: { fullpath: 'img/news_cards/2022_07_news_Mondsee@2x.png' },
    headline: 'T-Stück 90° mit Flansch für Übergang auf ZUK 250 eckig',
    text: '<p>Das System NiroLine DW 50 wurde um ein T-Stück mit Flansch (z. B. Art.-Nr: 87350173) für den Übergang auf den Zugbegrenzer ZUK 250 erweitert. Durch die eckige Anschlussplatte ist es nun kompatibel zum Zugbegrenzer aus der ExpertLine. Der Artikel ist aus Edelstahl gefertigt und ist in acht Dimensionen, von DN 350 - DN 700, verfügbar.</p><p>Das System NiroLine DW 50 wurde um ein T-Stück mit Flansch (z. B. Art.-Nr: 87350173) für den Übergang auf den Zugbegrenzer ZUK 250 erweitert. Durch die eckige Anschlussplatte ist es nun kompatibel zum Zugbegrenzer aus der ExpertLine. Der Artikel ist aus Edelstahl gefertigt und ist in acht Dimensionen, von DN 350 - DN 700, verfügbar.</p>',
    link: { absolutePath: 'https://www.stocker-kaminsysteme.com/de/Neuigkeiten/Neuheit_t-Stueck_DW50' },
    highlightedFirstWord: 'NiroLine DW 50:',
  },
  {
    displayDate: '2021.09.01',
    image: { fullpath: 'img/news_cards/2022_07_news_Mondsee@2x.png' },
    headline: 'T-Stück 90° mit Flansch für Übergang auf ZUK 250 eckig',
    text: '<p>Das System NiroLine DW 50 wurde um ein T-Stück mit Flansch (z. B. Art.-Nr: 87350173) für den Übergang auf den Zugbegrenzer ZUK 250 erweitert. Durch die eckige Anschlussplatte ist es nun kompatibel zum Zugbegrenzer aus der ExpertLine. Der Artikel ist aus Edelstahl gefertigt und ist in acht Dimensionen, von DN 350 - DN 700, verfügbar.</p>',
    link: { absolutePath: 'https://www.stocker-kaminsysteme.com/de/Neuigkeiten/Neuheit_t-Stueck_DW50' },
    highlightedFirstWord: 'NiroLine DW 50:',
  },
  {
    displayDate: '2021.09.01',
    image: { fullpath: 'img/news_cards/2022_07_news_Mondsee@2x.png' },
    headline: 'T-Stück 90° mit Flansch für Übergang auf ZUK 250 eckig',
    text: '<p>Das System NiroLine DW 50 wurde um ein T-Stück mit Flansch (z. B. Art.-Nr: 87350173) für den Übergang auf den Zugbegrenzer ZUK 250 erweitert. Durch die eckige Anschlussplatte ist es nun kompatibel zum Zugbegrenzer aus der ExpertLine. Der Artikel ist aus Edelstahl gefertigt und ist in acht Dimensionen, von DN 350 - DN 700, verfügbar.</p>',
    link: { absolutePath: 'https://www.stocker-kaminsysteme.com/de/Neuigkeiten/Neuheit_t-Stueck_DW50' },
    highlightedFirstWord: 'NiroLine DW 50:',
  },
  {
    displayDate: '2021.09.01',
    image: { fullpath: 'img/news_cards/2022_07_news_Mondsee@2x.png' },
    headline: 'T-Stück 90° mit Flansch für Übergang auf ZUK 250 eckig',
    text: '<p>Das System NiroLine DW 50 wurde um ein T-Stück mit Flansch (z. B. Art.-Nr: 87350173) für den Übergang auf den Zugbegrenzer ZUK 250 erweitert. Durch die eckige Anschlussplatte ist es nun kompatibel zum Zugbegrenzer aus der ExpertLine. Der Artikel ist aus Edelstahl gefertigt und ist in acht Dimensionen, von DN 350 - DN 700, verfügbar.</p>',
    link: { absolutePath: 'https://www.stocker-kaminsysteme.com/de/Neuigkeiten/Neuheit_t-Stueck_DW50' },
    highlightedFirstWord: 'NiroLine DW 50:',
  },
]
