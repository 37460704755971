import type { StackProps } from '@chakra-ui/react'
import { Divider, Stack, Text } from '@chakra-ui/react'
import { ProgressStepCircle } from '../../'

export interface StepProps extends StackProps {
  title?: string
  description?: string
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
  isFirstStep: boolean
  /** This is used to show the active step as completed when the user is on the last step instead of being only active */
  showActiveAsCompleted?: boolean
}

export const ProgressStep = (props: StepProps) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title, description, showActiveAsCompleted = false, ...stackProps } = props

  return (
    <Stack spacing="4" direction="column" flex="1" {...stackProps}>
      <Stack spacing="0" align="center" direction="row">
        <Divider
          borderWidth="1px"
          borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? 'accent.500' : 'gray.400'}
        />
        <ProgressStepCircle isActive={isActive} isCompleted={showActiveAsCompleted ? isActive || isCompleted : isCompleted}/>
        <Divider
          borderWidth="1px"
          borderColor={isCompleted ? 'accent.500' : isLastStep ? 'transparent' : 'gray.400'}
        />
      </Stack>
      <Stack
        spacing="0.5"
        align={{ base: 'start', md: 'center' }}
      >
        <Text color="emphasized" fontWeight="bold">
          {title}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
    </Stack>
  )
}
