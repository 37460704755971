import { HStack, NumberInput, NumberInputField, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text, VStack } from '@chakra-ui/react'
import type { IFilterNumericalProps, IHeading } from '../../'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Panel } from '../Panel/Panel'

interface IFilterSliderProps extends IFilterNumericalProps {
  refine: (values: { min: number | undefined, max: number | undefined }) => void
  heading: IHeading
  currentRefinement: {
    min: number | undefined
    max: number | undefined
  }
  setCurrentRefinement?: (value: {
    min: number | undefined
    max: number | undefined
  }) => void
}

export const FilterSlider: React.FC<IFilterSliderProps> = ({ min, max, step = 10, refine, currentRefinement, heading, setCurrentRefinement }) => {
  const intl = useIntl()

  const [localRefinement, setLocalRefinement] = useState({
    min: Number(min),
    max: Number(max),
  })

  useEffect(() => {
    if (currentRefinement.min !== localRefinement.min || currentRefinement.max !== localRefinement.max) {
      setLocalRefinement({
        min: currentRefinement.min ?? 0,
        max: currentRefinement.max ?? 0,
      })
    }
    setCurrentRefinement?.(currentRefinement)
  }, [currentRefinement])

  const debouncedRefine = useCallback(
    debounce((values: { min: number, max: number }) => {
      if (values.min !== localRefinement.min || values.max !== localRefinement.max) {
        const maybeReversed = reverseIfNecessary(values)
        const minMaxed = { min: Math.max(maybeReversed.min, min), max: Math.min(maybeReversed.max, max) }
        refine({ min: minMaxed.min, max: minMaxed.max })
        setLocalRefinement(minMaxed)
      }
    }, 500),
    [refine, min, max],
  )

  // InstantSearch returns undefined at the first render, then the real value
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (min === undefined || max === undefined) {
    return <div/>
  }

  return (
    <Panel heading={heading}>
      <RangeSlider
        colorScheme="accent"
        step={step}
        aria-label={['minimum maximum slider']}
        onChange={([refinementMin, refinementMax]) => {
          if (refinementMin !== localRefinement.min || refinementMax !== localRefinement.max) {
            setLocalRefinement({ min: Math.max(refinementMin, min), max: Math.min(refinementMax, max) })
          }
        }}
        onChangeEnd={([refinementMin, refinementMax]) => {
          refine({ min: refinementMin, max: refinementMax })
        }}
        value={[localRefinement.min, localRefinement.max]}
        min={min}
        max={max}
        focusThumbOnChange={false}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack/>
        </RangeSliderTrack>
        {/* Two same sliders with different ids */}
        {[0, 1].map(idx => (
          <RangeSliderThumb
            w="5"
            h="5"
            borderWidth="1px"
            borderColor="gray.200"
            ml={idx === 0 ? '9px' : '-9px'}
            key={idx}
            index={idx}
          />
        ))}
      </RangeSlider>
      <HStack spacing="3">
        <VStack spacing="1" w="100%" alignItems="left">
          <Text color="gray.500" fontSize="sm">
            {/* intl.formatMessage({ id: 'product-listing--filter-min' }) */}
            Min.
          </Text>
          <NumberInput
            min={min}
            max={max}
            precision={1}
            errorBorderColor="gray.200"
            _invalid={{ borderColor: 'gray.200' }}
            value={localRefinement.min > 0 ? localRefinement.min : min}
            onChange={(valueString) => {
              setLocalRefinement({ min: Number(valueString), max: localRefinement.max })
              debouncedRefine({ min: Number(valueString), max: localRefinement.max })
            }}
          >
            <NumberInputField letterSpacing="wide"/>
          </NumberInput>
        </VStack>
        <VStack spacing="1" w="100%" alignItems="left">
          <Text color="gray.500" fontSize="sm">
            {/* intl.formatMessage({ id: 'product-listing--filter-max' }) */}
            Max.
          </Text>
          <NumberInput
            min={min}
            max={max}
            errorBorderColor="gray.200"
            precision={1}
            _invalid={{ borderColor: 'gray.200' }}
            value={localRefinement.max}
            onChange={(valueString) => {
              setLocalRefinement({ min: localRefinement.min, max: Number(valueString) })
              debouncedRefine({ min: localRefinement.min, max: Number(valueString) })
            }}
          >
            <NumberInputField letterSpacing="wide"/>
          </NumberInput>
        </VStack>
      </HStack>
    </Panel>
  )
}

function reverseIfNecessary (values: { min: number, max: number }) {
  if (values.min > values.max) {
    return { min: values.max, max: values.min }
  }
  return values
}
