import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import type { useUpdateSystemConfigMutation } from '@stocker/codegen/configurator-backend'
import { FiRsDisk, FiRsPencil, FiRsShare } from '@stocker/ui-components/design-system'
import { asComponent } from '@stocker/ui-components/helpers'
import groupBy from 'lodash/groupBy'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ExpertCheckModal } from '../../compounds/ExpertCheckModal/ExpertCheckModal'
import { useConfiguration } from '../ConfigurationProvider'
import type { IBasketItemProps } from '../SystemBasket/SystemBasket'

export interface ISystemSummaryProps {
  isExpertCheckRequested: boolean
  isExpertCheckAcknowledged: boolean
  isExpertCheckFinished: boolean
  isAdmin: boolean
  defaulConfigurationName: string
  items: IBasketItemProps[]
  configuration?: Array<{
    title: string
    text: string
  }>
  updateConfigMutation?: ReturnType<typeof useUpdateSystemConfigMutation>
  currentConfigId: string
  currentConfigName?: string
  isLoaded?: {
    name?: boolean
  }
}

export const SystemSummary: React.FC<ISystemSummaryProps> = ({
  isLoaded = { name: true },
  currentConfigId,
  items,
  currentConfigName,
  updateConfigMutation,
  configuration,
  defaulConfigurationName,
  isExpertCheckRequested,
  isExpertCheckAcknowledged,
  isExpertCheckFinished,
  isAdmin,
}) => {
  const toast = useToast()
  const elementSpacer = { base: '-33px', xl: '-60px' }
  const { formatMessage } = useIntl()

  const [editMode, setEditMode] = useState(false)

  const { configurationName, setConfigurationName } = useConfiguration()

  useEffect(() => {
    setConfigurationName(currentConfigName ?? defaulConfigurationName)
  }, [defaulConfigurationName, currentConfigName])

  const productsGroupedByCategory: Record<string, IBasketItemProps[] | undefined> = groupBy(
    items,
    (v) => v.category,
  )

  return (
    <Flex direction="column" h="100%">
      <Box
        w="full"
        fontSize={{ base: 'lg', xl: 'xl' }}
        fontFamily="primary"
        color="primaryText.500"
        bg="primaryBackground.500"
      >
        <Flex align="center" justify="space-between" gap={4}>
          {editMode ? (
            <>
              <Flex flexDir="column" w="full" p={{ base: 3, xl: 4 }}>
                <Input
                  h="24px"
                  autoFocus
                  fontSize={{ base: 'lg', xl: 'xl' }}
                  onChange={(e) => {
                    setConfigurationName(e.currentTarget.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setEditMode(false)
                      if (configurationName.length > 0 && configurationName.length <= 40) {
                        updateConfigMutation?.mutate({
                          config: { systemConfigId: currentConfigId, name: configurationName },
                        })
                      } else {
                        setConfigurationName(currentConfigName ?? defaulConfigurationName)
                      }
                    }
                  }}
                  focusBorderColor="accent.500"
                  borderBottomColor="accent.500"
                  value={configurationName}
                  variant="flushed"
                  placeholder="Name der Konfiguration"
                />
                {configurationName.length === 0 && (
                  <Text fontFamily="secondary" fontSize="sm" color="accent.500">
                    {formatMessage({ id: 'configurator-name--tooltip-name-empty' })}
                  </Text>
                )}
                {configurationName.length > 40 && (
                  <Text fontFamily="secondary" fontSize="sm" color="accent.500">
                    {formatMessage({ id: 'configurator-name--tooltip-name-too-long' })}
                  </Text>
                )}
              </Flex>
              <IconButton
                py={3}
                onClick={() => {
                  setEditMode(false)
                  if (configurationName.length > 0 && configurationName.length <= 40) {
                    updateConfigMutation?.mutate({
                      config: { systemConfigId: currentConfigId, name: configurationName },
                    })
                  } else {
                    setConfigurationName(currentConfigName ?? defaulConfigurationName)
                  }
                }}
                px={{ base: 3, xl: 4 }}
                lineHeight={0}
                icon={<FiRsDisk cursor="pointer" />}
                aria-label="Auswahl Speichern"
                variant="ghost"
                _hover={{}}
              />
            </>
          ) : (
            <>
              <Skeleton m={{ base: 3, xl: 4 }} isLoaded={isLoaded.name} w="full">
                <Text
                  borderBottomColor="secondaryBackground.500"
                  borderBottomWidth={1}
                  onClick={() => {
                    setEditMode(!editMode)
                  }}
                >
                  {configurationName}
                </Text>
              </Skeleton>
              <Box
                py={{ base: 2, xl: 3 }}
                px={{ base: 3, xl: 4 }}
                onClick={() => {
                  setEditMode(!editMode)
                }}
                lineHeight={0}
              >
                <FiRsPencil cursor="pointer" />
              </Box>
            </>
          )}
        </Flex>
      </Box>
      {items.length === 0 && (
        <Text p={4} borderColor="secondaryText.50" borderWidth="2px" borderY={0}>
          {formatMessage({ id: 'configurator-summary--empty-notice' })}
        </Text>
      )}
      <Flex
        p={4}
        direction="row"
        gap={4}
        position="relative"
        borderColor="secondaryText.50"
        borderWidth="2px"
        borderTop={0}
        h="100%"
        overflowY="scroll"
      >
        <Box width="50%">
          {configuration
            ?.filter((item) => item.title !== '')
            .map((item, index) => (
              <Box key={item.title}>
                <Text my={2} fontFamily="primary">
                  {item.title}
                </Text>
                {asComponent(item.text)}
              </Box>
            ))}
        </Box>
        {/* TODO: refactor all of this in general  */}
        {/* Images */}
        <Box
          width="50%"
          position="sticky"
          top={0}
          right={0}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          flexDirection="column"
          overflowY="scroll"
          overflowX="hidden"
        >
          {/* T-Stück */}
          {productsGroupedByCategory.OverRoofService?.find(
            (item) => item.name.includes('Regenhaube') || item.name.includes('Abström'),
          ) && (
            <Box mt={elementSpacer} width={{ base: '60px', xl: '90px' }} mr="5px">
              <img
                src={`${
                  productsGroupedByCategory.OverRoofService?.filter(
                    (item) => item.name.includes('Regenhaube') || item.name.includes('Abström'),
                  )[0]?.image?.fullpath ?? ''
                }?width=200`}
              />
            </Box>
          )}
          {/* Längenelemente */}
          {productsGroupedByCategory.LengthelementService && (
            <Box display="flex" mr={{ base: '2px', xl: '10px' }}>
              <Flex
                alignItems="flex-end"
                justifyContent="center"
                flexDirection="column"
                mr={{ base: '-10px', xl: '-30px' }}
              >
                <Flex alignItems="center" flexDirection="row">
                  {productsGroupedByCategory.OffsetService?.filter(
                    (item) => !item.name.includes('Rohr'),
                  )
                    .slice(0, 3)
                    .map((item) => (
                      <Box key={item.sku + item.name} width={{ base: '40px', xl: '50px' }}>
                        <img src={`${item.image.fullpath ?? ''}?width=200`} />
                      </Box>
                    ))}
                </Flex>
                <Flex alignItems="center" flexDirection="row">
                  {productsGroupedByCategory.RoofductService && (
                    <Box width="50px">
                      <img
                        src={`${
                          productsGroupedByCategory.RoofductService[0].image.fullpath ?? ''
                        }?width=200`}
                      />
                    </Box>
                  )}
                  {productsGroupedByCategory.WallMountingService && (
                    <Box width="50px">
                      <img
                        src={`${
                          productsGroupedByCategory.WallMountingService[0].image.fullpath ?? ''
                        }?width=200`}
                      />
                    </Box>
                  )}
                </Flex>
              </Flex>
              <Box flexShrink={0} width={{ base: '60px', xl: '95px' }} height="auto" pos="relative">
                {productsGroupedByCategory.LengthelementService.slice(0, 2).map((item) => {
                  return (
                    <Box
                      key={item.sku + item.name}
                      mt={{ base: '-10px', xl: '-50px' }}
                      width={{ base: '60px', xl: '115px' }}
                    >
                      <img src={`${item.image.fullpath ?? ''}?width=200`} />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )}
          {/* T-Stück */}
          {productsGroupedByCategory.TConnectorService && (
            <Flex alignItems="center">
              {productsGroupedByCategory.TConnectorService.length > 1 && (
                <Box mt={elementSpacer} width={{ base: '60px', xl: '60px' }}>
                  <img
                    src={`${
                      productsGroupedByCategory.TConnectorService.sort(
                        (a, b) => b.price.amount - a.price.amount,
                      )[1].image.fullpath ?? ''
                    }?width=200`}
                  />
                </Box>
              )}
              <Box mt={elementSpacer} width={{ base: '60px', xl: '95px' }} mr="5px">
                <img
                  src={`${
                    productsGroupedByCategory.TConnectorService.sort(
                      (a, b) => b.price.amount - a.price.amount,
                    )[0].image.fullpath ?? ''
                  }?width=200`}
                />
              </Box>
            </Flex>
          )}
          {/* Zugbegrenzer */}
          {productsGroupedByCategory.DraughtRegulatorService && (
            <Box mt={elementSpacer} display="flex" alignItems="center" mr="5px">
              {productsGroupedByCategory.DraughtRegulatorService.sort(
                (a, b) => a.price.amount - b.price.amount,
              ).map((item, idx, allItems) => (
                <Box
                  key={item.sku + item.name}
                  width={
                    idx === allItems.length - 1
                      ? { base: '60px', xl: '95px' }
                      : { base: '30px', xl: '50px' }
                  }
                >
                  <img src={`${item.image.fullpath ?? ''}?width=200`} />
                </Box>
              ))}
            </Box>
          )}
          {/* Reinigungsöffnung */}
          {productsGroupedByCategory.CleaningPortService && (
            <Box mt={elementSpacer} width={{ base: '60px', xl: '95px' }}>
              <img
                src={`${
                  productsGroupedByCategory.CleaningPortService[0].image.fullpath ?? ''
                }?width=200`}
              />
            </Box>
          )}
          {/* Fußteil */}
          {productsGroupedByCategory.FootingService && (
            <Box mt={elementSpacer} width={{ base: '60px', xl: '95px' }}>
              <img
                src={`${
                  productsGroupedByCategory.FootingService[0].image.fullpath ?? ''
                }?width=200`}
              />
            </Box>
          )}
          {/* Wandkonsole */}
          {productsGroupedByCategory.WallConsoleService && (
            <Box mt={elementSpacer} width={{ base: '60px', xl: '95px' }}>
              <img
                src={`${
                  productsGroupedByCategory.WallConsoleService[0].image.fullpath ?? ''
                }?width=200`}
              />
            </Box>
          )}
          {Object.keys(productsGroupedByCategory).length !== 0 && (
            <Box position="absolute" right={0} bottom={0} mb={3}>
              <Text fontWeight="bold" fontSize="xs" mr={5}>
                {formatMessage({ id: 'configurator--symbolic-representation' })}
              </Text>
            </Box>
          )}
        </Box>
      </Flex>
      <Spacer />
      <Flex
        justify="flex-end"
        p={4}
        py={8}
        direction="column"
        gap={4}
        borderColor="secondaryText.50"
        borderWidth="2px"
        borderTop={0}
      >
        <HStack justify="space-between">
          <ExpertCheckModal
            configId={currentConfigId}
            isExpertCheckRequested={isExpertCheckRequested}
            isExpertCheckAcknowledged={isExpertCheckAcknowledged}
            isExpertCheckFinished={isExpertCheckFinished}
            isAdmin={isAdmin}
          />
          <Button
            size={{ base: 'sm', xl: 'md' }}
            variant="outline"
            colorScheme="secondaryText"
            leftIcon={<FiRsShare />}
            onClick={() => {
              navigator.clipboard.writeText(window.location.href)
              toast({
                title: formatMessage({ id: 'configurator-summary-toast--title' }),
                description: formatMessage({ id: 'configurator-summary-toast--description' }),
                status: 'success',
                duration: 4000,
                isClosable: true,
              })
            }}
          >
            {formatMessage({ id: '--share-configuration' })}
          </Button>
        </HStack>
      </Flex>
    </Flex>
  )
}
