import type { ComponentStyleConfig } from '@chakra-ui/react'
import { defineStyleConfig } from '@chakra-ui/react'

const Pagination: ComponentStyleConfig = {
  parts: ['button', 'iconButton'],
  baseStyle: {
    button: {
      bg: 'transparent',
      w: '40px',
      h: '40px',
    },
    iconButton: {
      bg: 'transparent',
      w: '40px',
      h: '40px',
    },
  },
  variants: {
    primary: {
      button: {
        color: 'primaryText.500',
      },
      iconButton: {
        color: 'primaryText.500',
      },
    },
    secondary: {
      button: {
        color: 'secondaryText.500',
      },
      iconButton: {
        color: 'secondaryText.500',
      },
    },
  },
}

const FilterPanel = defineStyleConfig({
  baseStyle: {
    border: '1px',
    borderColor: 'primaryText.550',
    borderRadius: '5px',
    px: '15px',
    py: '12px',
  },
})

// styles for /helper/htmlComponentParser.test.tsx
const Text = defineStyleConfig({
  baseStyle: {
    lineHeight: '1.2',
  },
})

const UnorderedList = defineStyleConfig({
  baseStyle: {
    my: '2',
    lineHeight: '1.3',
  },
})

const OrderedList = defineStyleConfig({
  baseStyle: {
    lineHeight: '1.3',
  },
})

const ListItem = defineStyleConfig({
  baseStyle: {
    mb: '2',
  },
})

export const customComponents = {
  Pagination,
  FilterPanel,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
}
