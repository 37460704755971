import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { FiRsEnvelope, FiRsList } from '@stocker/ui-components/design-system'
import { asComponent } from '@stocker/ui-components/helpers'
import { useRequestExpertCheckMutation, useFinishExpertCheckMutation } from '@stocker/codegen/configurator-backend'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

export interface IExpertCheckModalProps {
  configId: string
  isExpertCheckRequested: boolean
  isExpertCheckAcknowledged: boolean
  isExpertCheckFinished: boolean
  isAdmin: boolean
}

export const ExpertCheckModal: React.FC<IExpertCheckModalProps> = ({
  configId,
  isExpertCheckRequested,
  isExpertCheckAcknowledged,
  isExpertCheckFinished,
  isAdmin
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const queryClient = useQueryClient()

  const isExpertCheckInProgress = isExpertCheckRequested || isExpertCheckAcknowledged

  const { mutate: requestExpertCheck } = useRequestExpertCheckMutation({
    onSuccess: (data) => {
      toast({
        title: 'Erfolgreich abgeschickt!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      queryClient.invalidateQueries(['SystemConfigById'])
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: 'Fehler',
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const { mutate: finishExpertCheck } = useFinishExpertCheckMutation({
    onSuccess: (data) => {
      toast({
        title: 'Expertencheck erfolgreich abgeschlossen!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      queryClient.invalidateQueries(['SystemConfigById'])
      onClose()
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: 'Fehler',
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const toast = useToast()
  const [message, setMessage] = useState('')
  const { formatMessage } = useIntl()

  const handleExpertCheck = () => {
    if (message.trim() === '') {
      toast({
        title: 'Fehler',
        description: 'Bitte geben Sie eine Nachricht ein.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    if (isAdmin && isExpertCheckInProgress) {
      finishExpertCheck({ systemConfigId: configId, message })
    } else {
      requestExpertCheck({ id: configId, message })
    }
    onClose()
  }

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={(isExpertCheckInProgress && !isAdmin) || isExpertCheckFinished}
        size={{ base: 'sm', xl: 'md' }}
        variant="outline"
        colorScheme="secondaryText"
        leftIcon={<FiRsList/>}
      >
        {isAdmin && isExpertCheckInProgress
          ? formatMessage({ id: 'configurations--finish-expert-check' })
          : formatMessage({ id: 'configurations--expert-check' })}
      </Button>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontFamily="primary">
            {isAdmin && isExpertCheckInProgress
              ? formatMessage({ id: 'configurations--finish-expert-check' })
              : formatMessage({ id: 'configurations--expert-check' })}
          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Stack spacing={5}>
              <Stack>
                <Text>
                  {formatMessage({ id: 'configuration-expertcheck--input-title' })}
                </Text>
                <Textarea
                  onChange={(e) => setMessage(e.target.value)}
                  h="200px"
                  placeholder={formatMessage({ id: 'configuration-expertcheck--input-placeholder' })}
                />
              </Stack>
              {!isAdmin && !isExpertCheckFinished && (
                <Stack bg="primaryBackground.500" color="primaryText.500" p={6}>
                  <Text fontFamily="primary" fontSize="lg">
                    {formatMessage({ id: 'configuration-expertcheck--description-title' })}
                  </Text>
                  <Text>
                    {asComponent(formatMessage({ id: 'configuration-expertcheck--description-body' }))}
                  </Text>
                </Stack>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="accent"
              leftIcon={<FiRsEnvelope/>}
              onClick={handleExpertCheck}
            >
              {isAdmin && isExpertCheckInProgress
                ? formatMessage({ id: 'configurations--finish-expert-check' })
                : formatMessage({ id: '--send' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}