import { Box, Flex, Heading, Skeleton, Stack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import type React from 'react'
import { Keyboard, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { IProductCardProps, IHeading } from '../../'
import { ProductCard } from '../ProductCard/ProductCard'

export interface IProductCardSliderProps {
  productCards: IProductCardProps[]
  slidesPerView?: {
    tablet?: number
    desktop?: number
  }
  heading?: IHeading
  isLoading?: boolean
}

export const ProductCardSlider: React.FC<IProductCardSliderProps> = ({ productCards, slidesPerView, heading, isLoading }) => {
  const getSlidesPerView = (slidesPerView: number, min: number) => {
    return Math.min(Math.max(slidesPerView, min), productCards.length)
  }

  return (
    <Box>
      <Heading as={heading?.type} fontSize={heading?.size} mb="10px">
        {heading?.text}
      </Heading>
      <Stack
        align="center"
        direction="row"
        spacing={0}
        css={css`
        .swiper {
          --swiper-pagination-color: var(--chakra-colors-accent-500)
        }
        
        .swiper-slide {
          height: auto
        }

        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          --swiper-navigation-color: var(--chakra-colors-accent-500)
        }

        .swiper-button-next,
        .swiper-button-prev {
          --swiper-navigation-color: #191919;
          top: 110px;
        }
        
        `}
      >
        {isLoading && (
          <Flex gap="5" w="100%">
            <Skeleton w="50%" h="370px" borderRadius="md"/>
            <Skeleton w="50%" h="370px" borderRadius="md"/>
          </Flex>
        )}
        {!isLoading && (
          <Swiper
            style={{ padding: '0' }}
            slidesPerView={1}
            spaceBetween={10}
            modules={[Navigation, Pagination, Keyboard]}
            pagination={{
              clickable: true,
            }}
            keyboard={{
              enabled: true,
            }}
            watchSlidesProgress={true}
            className="mySwiper"
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: getSlidesPerView(slidesPerView?.tablet ?? 0, 1),
              },
              1200: {
                spaceBetween: 30,
                slidesPerView: getSlidesPerView(slidesPerView?.desktop ?? 0, 2),
              },
            }}
            navigation
          >
            {productCards.map((productCard, index) => (
              <SwiperSlide key={index}>
                <Box pb="50px" h="100%" zIndex={-1}>
                  <ProductCard {...productCard}/>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Stack>
    </Box>
  )
}
