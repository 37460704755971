import type { RadioGroupProps, StackProps } from '@chakra-ui/react'
import { Box, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

export type IFilterRadioProps = Omit<RadioGroupProps, 'children'> & {
  options: Array<{ label: string, id: string, count?: number }>
  label?: string
  onChange?: (value: string) => void
  spacing?: StackProps['spacing']
}

export const FilterRadio: FC<IFilterRadioProps> = ({ options, label, spacing = '2', ...rest }) => {
  return (
    <Stack as="fieldset" spacing={spacing}>
      {label && (
        <FormLabel fontWeight="semibold" as="legend" mb="0">
          {label}
        </FormLabel>
      )}
      <RadioGroup {...rest}>
        <Stack>
          {options.map((option) => (
            <Radio key={option.id} value={option.id} colorScheme="blue">
              <span>{option.label}</span>
              {option.count != null && (
                <Box as="span" color="gray.500" fontSize="sm">
                  {` (${option.count})`}
                </Box>
              )}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  )
}
