import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

// Based on ChocUI https://choc-ui.com/docs/elements/carousels
interface ICarouselProps extends FlexProps {
  currentSlideIdx: number
  hideSlideCounter?: boolean
  carouselStyle?: FlexProps
}
interface IColors {
  colorBackground?: string
  colorText?: string
}
interface ICombined extends IColors, ICarouselProps {}

export const Carousel: React.FC<ICombined> = ({
  children, currentSlideIdx, hideSlideCounter = true,
  colorBackground = 'primaryBackground.500',
  colorText = 'primaryText.500',
  carouselStyle,
  ...FlexProps
}) => {
  const carouselDefaultStyle = {
    transition: 'margin-left .5s',
    ml: `-${currentSlideIdx * 100}%`,
  }

  return (
    <Flex
      w="full"
      bg={colorBackground}
      alignItems="center"
      justifyContent="center"
      {...FlexProps}
    >
      <Flex overflow="hidden">
        <Flex pos="relative" h="100%" w="100%" {...carouselDefaultStyle} {...carouselStyle}>
          {React.Children.map(children, (slide, sid) => (
            <Box key={`slide-${sid}`} flex="none" boxSize="100%" alignSelf="flex-end">
              <Text
                color={colorText}
                fontSize="xs"
                p="8px 12px"
                pos="absolute"
                top="0"
                whiteSpace="nowrap"
                hidden={hideSlideCounter}
              >
                {sid + 1} / {React.Children.toArray.length}
              </Text>
              {slide}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
