import type { HeadingProps } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import type React from 'react'
import type { IHeading } from '@stocker/ui-components/design-system'

export interface CustomHeadingProps extends HeadingProps {
  heading: IHeading
}

/**
 * Heading wrapper for easy use with IHeading data, usable with all chakraUI heading props
 * @param param0 IHeading data
 * @returns Chakra UI Heading with text
 */
export const CustomHeading: React.FC<CustomHeadingProps> = ({ heading, ...headingProps }) => {
  return (
    <Heading
      as={heading.asText ? 'p' : heading.type}
      color={heading.color}
      fontSize={heading.size}
      {...headingProps}
    >
      {heading.text}
    </Heading>
  )
}
