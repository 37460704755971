import { Box, Button, Center, Flex, Heading, HStack, Spacer, Stack, StackDivider, Text, Tooltip } from '@chakra-ui/react'
import { FiRsSettings, FiRsEye, FiRsShoppingCart, FiRsShoppingBag, FiRsAngleLeft, NextLink } from '@stocker/ui-components/design-system'
import type { IImage } from '@stocker/ui-components/design-system'
import { formatHeadlineColor, CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import { useActiveCustomerQuery } from '@stocker/codegen/vendure'
import type { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

interface ISystemFinderSystemSelectPage {
  setStep: Dispatch<SetStateAction<number>>
  currentStep: number
  systemsData?: Array<{
    name: string
    description: string
    detailLink: string
    categoryLink: string
    configurationLink: string
    image: IImage
    relatedProduct?: string
    systemValues: Array<{
      valueName: string
      value: string
    }>
  }>
}

export const SystemFinderSystemSelectPage: React.FC<ISystemFinderSystemSelectPage> = ({ setStep, currentStep, systemsData }) => {
  const { formatMessage } = useIntl()

  const { data: activeCustomer } = useActiveCustomerQuery()

  return (
    <Flex py={{ base: 4, lg: 6 }} gap={12} w="100%" flexDir={{ base: 'column', md: 'row' }}>
      <Stack w={{ base: '100%', md: '250px' }}>
        <Heading as="h1" fontSize={{ base: '3xl', lg: '4xl', xl: '5xl' }} lineHeight={1.1}>System Auswahl</Heading>
        <Spacer/>
        <Button
          variant="outline"
          colorScheme="secondaryText"
          w="100%"
          iconSpacing={3}
          alignSelf="center"
          leftIcon={<FiRsAngleLeft/>}
          onClick={() => { setStep(currentStep - 1) }}
          size={{ base: 'sm', md: 'md' }}
        >
          Zum vorherigen Schritt
        </Button>
      </Stack>
      {systemsData?.length !== 0
        ? (
          <HStack spacing={3} overflowX="scroll" pb={2} w="100%" align="flex-start">
            {systemsData?.map(system => (
              <Stack
                maxW="300px"
                key={system.name + system.description}
                border="2px"
                borderColor="gray.200"
                h="full"
              >
                <Box pos="relative" minH="300px" minW="300px" bg="primaryBackground.50">
                  <NextImage
                    src={`${system.image.fullpath ?? ''}?width=600`}
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: 'contain',
                    }}
                    alt="System Finder"
                  />
                </Box>
                <Stack h="100%">
                  <Stack px={6} py={3}>
                    <CustomHeading fontSize="xl" fontFamily="primary" heading={{ text: formatHeadlineColor(system.name) }}/>
                    <Box lineHeight={1.2}>
                      {asComponent(system.description)}
                    </Box>
                  </Stack>
                  <Spacer/>
                  <Stack spacing={0} divider={<StackDivider/>} pt={3}>
                    {system.relatedProduct && (
                      <NextLink href={`/product/${system.relatedProduct}`}>
                        <Button variant="ghost" leftIcon={<FiRsShoppingBag/>} w="100%" justifyContent="flex-start" iconSpacing={3}>
                          {formatMessage({ id: 'system-finder--calculation' })}
                        </Button>
                      </NextLink>
                    )}
                    <NextLink href={system.categoryLink}>
                      <Button variant="ghost" leftIcon={<FiRsShoppingCart/>} w="100%" justifyContent="flex-start" iconSpacing={3}>
                        {formatMessage({ id: 'system-finder--shop-line' })}
                      </Button>
                    </NextLink>
                    <NextLink href={system.detailLink}>
                      <Button variant="ghost" leftIcon={<FiRsEye/>} w="100%" justifyContent="flex-start" iconSpacing={3}>
                        {formatMessage({ id: 'system-finder--see-details' })}
                      </Button>
                    </NextLink>
                    <NextLink href={system.configurationLink}>
                      {!activeCustomer?.activeCustomer
                        ? (
                          <Tooltip
                            bg="primaryBackground.500"
                            color="primaryText.500"
                            fontSize="md"
                            label={formatMessage({ id: '--tooltip-please-login' })}
                            hasArrow
                          >
                            <Button disabled={!activeCustomer?.activeCustomer} variant="ghost" leftIcon={<FiRsSettings/>} w="100%" justifyContent="flex-start" iconSpacing={3}>
                              {formatMessage({ id: 'system-finder--configure-system' })}
                            </Button>
                          </Tooltip>
                          )
                        : (
                          <Button disabled={!activeCustomer.activeCustomer} variant="ghost" leftIcon={<FiRsSettings/>} w="100%" justifyContent="flex-start" iconSpacing={3}>
                            {formatMessage({ id: 'system-finder--configure-system' })}
                          </Button>
                          )}
                    </NextLink>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </HStack>
          )
        : (
          <Center w="100%">
            <Stack align="center">
              <Text fontSize="xl" fontFamily="primary">
                {formatMessage({ id: 'system-finder--no-systems-available' })}
              </Text>
              <NextLink href="/service/kontakt">
                <Button colorScheme="accent">{formatMessage({ id: '--contact-us' })}</Button>
              </NextLink>
            </Stack>
          </Center>
          )}

    </Flex>
  )
}
