import { Button, HStack, Tooltip } from '@chakra-ui/react'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IProgressStepsIteratorProps {
  nextButton?: {
    text?: string
    onClick: () => void
    loadingText?: string
    loading?: boolean
    disabled?: boolean
  }
  prevButton?: {
    text?: string
    onClick: () => void
    loadingText?: string
    loading?: boolean
    disabled?: boolean
  }
}

export const ProgressStepsIterator: React.FC<IProgressStepsIteratorProps> = ({ prevButton, nextButton }) => {
  const intl = useIntl()
  return (
    <HStack mt="30px" justify={(!prevButton && nextButton) ? 'right' : 'space-between'}>
      {prevButton && (
        <Tooltip>
          <Button isDisabled={prevButton.disabled} isLoading={prevButton.loading} loadingText={prevButton.loadingText} colorScheme="gray" onClick={prevButton.onClick}>{prevButton.text ?? intl.formatMessage({ id: '--previous-step' })}</Button>
        </Tooltip>
      )}
      {nextButton && (
        <Tooltip>
          <Button isDisabled={nextButton.disabled} isLoading={nextButton.loading} loadingText={nextButton.loadingText} colorScheme="accent" onClick={nextButton.onClick}>{nextButton.text ?? intl.formatMessage({ id: '--next-step' })}</Button>
        </Tooltip>
      )}
    </HStack>
  )
}
