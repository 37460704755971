import type { ButtonProps } from '@chakra-ui/react'
import { Button, Center, HStack, Text } from '@chakra-ui/react'

interface NavButtonProps extends ButtonProps {
  icon?: JSX.Element
  label: string
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props
  return (
    <Button variant="ghost-on-accent" justifyContent="start" {...buttonProps}>
      <HStack spacing="3">
        {/* <Icon as={icon as unknown as As} boxSize="6" color="on-accent-subtle"/> */}
        <Center boxSize="6" color="on-accent-subtle">
          {icon}
        </Center>
        <Text>{label}</Text>
      </HStack>
    </Button>
  )
}
