import { Form, InputData, TextAreaData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import type { FormikContextType } from 'formik'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export interface ICheckoutFormData extends yup.InferType<ReturnType<typeof getSchema>> { }

interface ICheckoutDetailFormProps {
  onChange: (formikCtx: FormikContextType<unknown>) => void
}

function getSchema (formatMessage: ReturnType<typeof useIntl>['formatMessage']) {
  return yup.object().shape({
    reference: yup.string(),
    commission: yup.string(),
    comment: yup.string(),
  })
}

export const CheckoutDetailForm: React.FC<ICheckoutDetailFormProps> = ({ onChange }) => {
  const { formatMessage } = useIntl()

  const validationSchema = useMemo(() => getSchema(formatMessage), [formatMessage])

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--reference' }), name: 'reference', type: 'text', inputProps: { maxLength: 254 } }),
            ],
            [
              new InputData({ title: formatMessage({ id: '--commission' }), name: 'commission', type: 'text', inputProps: { maxLength: 254 } }),
            ],
            [
              new TextAreaData({ title: formatMessage({ id: '--comment' }), name: 'comment' }),
            ],
          ],
        },
      ],
    ],

  }), [formatMessage])

  return (
    <Form
      onChange={onChange}
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={data => {
        console.log(data)
      }}
      validationSchema={validationSchema}
    />
  )
}
