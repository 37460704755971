import { Box, Center, Grid, GridItem, Spinner, Stack } from '@chakra-ui/react'
import type React from 'react'
import type { ICartCheckoutProps } from '../CartCheckout/CartCheckout'
import { CartCheckout } from '../CartCheckout/CartCheckout'

export interface ICheckoutSummaryProps {
  checkOutCardProps: ICartCheckoutProps
  contentCards: React.ReactNode[]
  priceInfoContentCards?: React.ReactNode[]
}

export const CheckoutSummary: React.FC<ICheckoutSummaryProps> = ({ checkOutCardProps, contentCards, priceInfoContentCards }) => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem h="fit-content" colSpan={{ base: 12, md: 8 }} order={{ base: 2, md: 1 }}>
        {checkOutCardProps.buttonProps.isLoading
          ? (
            <Center>
              <Spinner size="xl"/>
            </Center>
            )
          : (
            <Stack spacing="15px">
              {contentCards.map((contentCard, index) => (
                <Box border="1px" borderColor="gray.200" p="20px" key={index}>
                  {contentCard}
                </Box>
              ))}
            </Stack>
            )}
      </GridItem>
      <GridItem h="fit-content" colSpan={{ base: 12, md: 4 }} order={{ base: 1, md: 2 }}>
        <Stack spacing="15px">
          <Box border="1px" borderColor="gray.200" p="20px">
            <CartCheckout {...checkOutCardProps}/>
          </Box>
          {priceInfoContentCards?.length !== 0 && (
            <Box>
              {priceInfoContentCards?.map((contentCard, index) => (
                <Box border="1px" borderColor="gray.200" p="20px" key={index}>
                  {contentCard}
                </Box>
              ))}
            </Box>
          )}
        </Stack>
      </GridItem>
    </Grid>
  )
}
