import type { NavigationByIdentifierQuery } from '@stocker/codegen/pimcore'

export const navItemsToolbar: NavigationByIdentifierQuery = {
  navigationByIdentifier: {
    linkItems: [
      {
        title: 'Home',
        absolutePath: '/',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: null,
      },
      {
        title: 'Neuigkeiten',
        absolutePath: '/neuigkeiten',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: null,
      },
      {
        title: 'Downloads',
        absolutePath: '/service/downloads',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: null,
      },
    ],
  },
}
