import type React from 'react'
import { Pagination } from './Pagination'

export interface IPaginationProps {
  currentRefinement: number
  nbPages: number
  refine: (payload: number) => void
  createURL: (payload: string) => string
}

interface ICombined extends IPaginationProps {}

export const InstantSearchPagination: React.FC<ICombined> = ({ currentRefinement, nbPages, refine, createURL }) => {
  if (nbPages === 1) {
    return <div/>
  }

  return (
    <ul>
      <Pagination
        totalItems={nbPages}
        itemsPerPage={1}
        currentPage={currentRefinement}
        setCurrentPage={function (payload: number): void {
          window.scrollTo(0, 0)
          refine(payload)
        }}
        variant="secondary"
      />
    </ul>
  )
}
