import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, Select, Stack, Text } from '@chakra-ui/react'
import { InfoPopover } from '../../compounds/InfoPopover/InfoPopover'
import { FiRsBan, FiRsCheck, FiRsInterrogation } from '@stocker/ui-components/design-system'
import type { IImage } from '@stocker/ui-components/design-system'
import { Field, Form, Formik } from 'formik'
import { divideValueDependingOnUnit, multiplyValueDependingOnUnit } from '@stocker/ui-components/helpers'
import inRange from 'lodash/inRange'
import type { useUpdateSystemConfigMutation } from '@stocker/codegen/configurator-backend'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

interface ITooltip {
  title: string
  description: string
  image: IImage
}

interface systemModuleItem {
  id: string
  name: string
  minValue: string
  maxValue: string
  valueType: 'NUMBER' | 'SELECT' | 'BOOL' | 'DYNAMIC_SELECT'
  selectValues?: string[]
  unit?: string
  tooltip?: ITooltip
  isMandatory?: boolean
  defaultValue?: string
  hideConditions?: Array<{
    targetFieldId: string
    operator: string
    targetFieldValue: string
    disableIfHidden?: boolean
    condition?: string
  }>
}
export interface ISystemModuleConfiguratorProps {
  isDisabled: boolean
  accordionItems: Array<{
    title: string
    isSkippable?: boolean
    tooltip?: ITooltip
    fields?: systemModuleItem[]
  }>
  maxClickableModuleIndex: number
  systemConfigId: string
  updateConfigMutation: ReturnType<typeof useUpdateSystemConfigMutation>
  currentConfigValues: Array<{ fieldId: string, value: string }>
  currentConfigName: string
}

interface IModuleFieldValue {
  fieldId: string
  value: string
}

const operatorFunctions = {
  '=': function (a: string | number, b: string | number) { return a === b },
  '>': function (a: string | number, b: string | number) { return a > b },
  '>=': function (a: string | number, b: string | number) { return a >= b },
  '<=': function (a: string | number, b: string | number) { return a <= b },
  '<': function (a: string | number, b: string | number) { return a < b },
  '!=': function (a: string | number, b: string | number) { return a !== b },
}

function parseFieldValue (value: string, unit: string) {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return divideValueDependingOnUnit(value, unit)
  }
}

function getModuleIcon (
  isExpanded: boolean,
  item: ISystemModuleConfiguratorProps['accordionItems'][0],
  index: number,
  openAccordionIndex: number,
  currentFieldIds?: string[],
) {
  if (isExpanded && item.tooltip) {
    return <InfoPopover {...item.tooltip} popOverTrigger={<FiRsInterrogation fontSize="xl"/>}/>
  }

  if (index < openAccordionIndex) {
    // if no fields of a module are on the submitted values also show the skipped icon (if all inputs are skippable)
    const moduleValuesEmpty = item.fields?.filter(field => currentFieldIds?.includes(field.id)).length === 0
    if (moduleValuesEmpty) {
      return <FiRsBan fontSize="xl"/>
    } else {
      return <FiRsCheck fontSize="xl"/>
    }
  }
}

function asModuleFieldValueArray (values: Record<string, any>): IModuleFieldValue[] {
  return Object.keys(values).map(key => {
    return {
      fieldId: key,
      value: String(values[key]),
    }
  })
}

function asModuleFieldValueObject (values: IModuleFieldValue[]): Record<string, string> {
  const obj: Record<string, string> = {}
  values.forEach(value => {
    obj[value.fieldId] = value.value
  })
  return obj
}

function addMissingCheckboxFalseValues (values: Record<string, any>, checkboxFields: systemModuleItem[]) {
  const newValues = structuredClone(values)
  for (const checkboxField of checkboxFields) {
    const checkboxIsNotIncluded = !Object.keys(values).includes(checkboxField.id)
    if (checkboxIsNotIncluded) {
      newValues[checkboxField.id] = 'false'
    }
  }
  return newValues
}

function correctUnits (values: IModuleFieldValue[], numberFieldsWithDifferentUnit: systemModuleItem[]) {
  return values.map(value => {
    const fieldWithChangedUnit = numberFieldsWithDifferentUnit.find(e => e.id === value.fieldId)

    if (fieldWithChangedUnit) {
      return {
        fieldId: fieldWithChangedUnit.id,
        value: String(multiplyValueDependingOnUnit(value.value, fieldWithChangedUnit.unit ?? '')),
      }
    }
    return value
  })
}

// function hasEditableFields (item: ISystemModuleConfiguratorProps['accordionItems'][0], hiddenFieldIds?: string[]) {
//   return (item.fields?.filter(field => !hiddenFieldIds?.includes(field.id)).length ?? 0) > 0
// }

export const SystemModuleConfigurator: React.FC<ISystemModuleConfiguratorProps> = ({
  currentConfigName,
  currentConfigValues,
  updateConfigMutation,
  systemConfigId,
  accordionItems,
  maxClickableModuleIndex,
  isDisabled,
}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0)
  const { formatMessage } = useIntl()

  // Open the last open module on page reload
  useEffect(() => {
    if (openAccordionIndex <= maxClickableModuleIndex) setOpenAccordionIndex(maxClickableModuleIndex)
  }, [maxClickableModuleIndex])

  const formInitialValues: Record<string, any> = {}
  for (const configValue of currentConfigValues) {
    const valueModule = accordionItems.find(e => e.fields?.find(e => e.id === configValue.fieldId))
    const unit = valueModule?.fields?.find(e => e.id === configValue.fieldId)?.unit

    // FIXME: Remove this temporary fix that prevents the double division of values if the value is already mm but the value is m
    const fieldValue = configValue.fieldId === '6da099e9-fe04-5fcf-99e6-d4e6e9988fa7'
      ? configValue.value
      : parseFieldValue(configValue.value, unit ?? '')

    formInitialValues[configValue.fieldId] = fieldValue
  }

  const submittedFieldIds = currentConfigValues.map(value => value.fieldId)

  const clickableAccordionIndexes = accordionItems
    .map((accordionItem, index) => {
      const fieldHasBeenTouched = accordionItem.fields?.filter(field => submittedFieldIds.includes(field.id)).length !== 0
      if (fieldHasBeenTouched || index <= openAccordionIndex) {
        return index
      }
    })
    .filter(e => e !== undefined)

  const [hiddenFieldIds, setHiddenFieldIds] = useState<string[]>()
  const [fieldsWithChangedUnit, setFieldsWithChangedUnit] = useState<systemModuleItem[]>()
  const [selectedDNFieldValue, setSelectedDNFieldValue] = useState<string>()
  const [selectedPressureFieldValue, setSelectedPressureFieldValue] = useState<string>()

  const allFields = accordionItems.map((module) => {
    return module.fields
  }).flat()

  /**
   * Check if the input string is a valid number and convert it to a number if it is,
   * else return the original string.
   * @param input String that might be a number and needs to be converted for comparison.
   * @returns Input string or converted number.
   */
  const parseValueBasedOnString = (input: string): number | string => {
    // Use parseFloat to attempt conversion
    const numberValue = parseFloat(input)
    // Check if the conversion is successful and not NaN
    if (!isNaN(numberValue)) return numberValue
    // Return the original string if it's not a valid number
    else return input
  }

  return (
    <Accordion defaultIndex={maxClickableModuleIndex} index={isDisabled ? -1 : openAccordionIndex}>
      {accordionItems
        // includes at least one editable field
        // .filter(item => hasEditableFields(item, hiddenFieldIds))
        .map((item, index) => (
          <AccordionItem
            key={item.title + String(index)}
            isDisabled={isDisabled || !clickableAccordionIndexes.includes(index)}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => {
                    const isClickable = clickableAccordionIndexes.includes(index)
                    if (isClickable) setOpenAccordionIndex(index)
                  }}
                  _expanded={{ bg: 'black', color: 'white' }}
                  borderLeft="1px"
                  borderRight="1px"
                  borderColor={isExpanded ? 'black' : 'gray.300'}
                >
                  <Box fontSize="lg" fontWeight="bold" as="span" flex="1" textAlign="left">
                    {item.title}
                  </Box>
                  {getModuleIcon(isExpanded, item, index, openAccordionIndex, submittedFieldIds)}
                </AccordionButton>
                <AccordionPanel pb={4} border="1px" borderColor="black">
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                      const checkboxFields = item.fields?.filter(field => field.valueType === 'BOOL') ?? []
                      const newValues = addMissingCheckboxFalseValues(values, checkboxFields)

                      const joinedValues = asModuleFieldValueArray({ ...asModuleFieldValueObject(currentConfigValues), ...newValues })

                      const withCorrectUnit = correctUnits(joinedValues, fieldsWithChangedUnit ?? [])

                      const withoutHiddenFields = withCorrectUnit.filter(e => !hiddenFieldIds?.includes(e.fieldId))

                      updateConfigMutation.mutate({ config: { systemConfigId, name: currentConfigName, moduleFieldValues: withoutHiddenFields } })

                      actions.setSubmitting(false)
                      setOpenAccordionIndex(openAccordionIndex + 1)
                    }}
                  >
                    {(props) => (
                      <Form>
                        <Stack spacing={3} py={2}>
                          {item.fields?.map((fieldValues, index) => {
                            let removeIfDisabled = false
                            const isFieldDisabled = fieldValues.hideConditions ? (fieldValues.id === 'c7d31596-ab3e-5286-9e13-24a14a81e24c' ? 
                              evaluateSpecialHideConditions(fieldValues.hideConditions) : 
                                fieldValues.hideConditions.some(evaluateHideCondition)
                            ) : false;

                            function evaluateSpecialHideConditions(conditions: Array<{
                              targetFieldId: string;
                              operator: string;
                              targetFieldValue: string;
                              disableIfHidden?: boolean;
                              condition?: string;
                            }>): boolean {
                              if (conditions.length !== 3) {
                                return conditions.some(evaluateHideCondition);
                              }

                              const [condition1, condition2, condition3] = conditions;

                              // Check if the first condition is met (always hide if true)
                              if (evaluateHideCondition(condition1)) {
                                return true;
                              }

                              // Check if both the second and third conditions are met
                              return evaluateHideCondition(condition2) && evaluateHideCondition(condition3);
                            }

                            function evaluateHideCondition(condition: {
                              targetFieldId: string;
                              operator: string;
                              targetFieldValue: string;
                              disableIfHidden?: boolean;
                              condition?: string;
                            }): boolean {
                              const { operator, targetFieldValue, targetFieldId, disableIfHidden } = condition;
                              removeIfDisabled = !disableIfHidden
                              // check if there is a condition for this field
                              const relatedField = allFields.find((field) => field?.id === targetFieldId)

                              if (relatedField) {
                                // here we check all values on the form for a match on the relatedField.id and get the value
                                const value = String(Object.entries(props.values).find(e => e[0] === relatedField.id)?.[1] ?? '')
                                // if the currentValue of the target field and the targetFieldValue from the hidecondition return true when compared with the hidecondition operator, hide the source field of the hidecondition
                                const hideField = operatorFunctions[operator as keyof typeof operatorFunctions](parseValueBasedOnString(value), parseValueBasedOnString(targetFieldValue))

                                if (hideField) return true
                              }
                              // here we check if the hide target is a checkbox that is false
                              // and we also check if the checkbox is in the already submitted values since if it isnt there it has to be false so the hide condition should return true
                              if (targetFieldValue === 'false' && !Object.keys(props.values).includes(targetFieldId)) {
                                return true
                              }

                              return false
                            }

                            if (!isFieldDisabled && hiddenFieldIds?.includes(fieldValues.id)) {
                              setHiddenFieldIds(hiddenFieldIds.filter(e => e !== fieldValues.id))
                            }

                            if (isFieldDisabled && !hiddenFieldIds?.includes(fieldValues.id)) {
                              setHiddenFieldIds(hiddenFieldIds ? [...hiddenFieldIds, fieldValues.id] : [fieldValues.id])
                            }

                            // here we get all number inputs that have a different unit than mm so we can convert the input to mm later because the backend handles all values in mm
                            const unitNeedsToChange = fieldValues.valueType === 'NUMBER' && fieldValues.unit !== 'mm'
                            if (unitNeedsToChange && !fieldsWithChangedUnit?.find(e => e.id === fieldValues.id)) {
                              setFieldsWithChangedUnit(fieldsWithChangedUnit ? [...fieldsWithChangedUnit, fieldValues] : [fieldValues])
                            }

                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            if (isFieldDisabled && removeIfDisabled) return undefined

                            const numberField = (
                              <Field
                                id={fieldValues.name + fieldValues.id}
                                name={fieldValues.id}
                                {...(fieldValues.minValue && fieldValues.maxValue) && {
                                  validate: (value: string) => {
                                    return !inRange(Number(value), Number(fieldValues.minValue), Number(fieldValues.maxValue) + 1)
                                      ? `${formatMessage({ id: '--entered-number-is-invalid' })} ${fieldValues.minValue} - ${fieldValues.maxValue} ${fieldValues.unit ?? ''}`
                                      : null
                                  },
                                }}
                              >
                                {({ field, form }: { field: any, form: any }) => {
                                  return (
                                    <FormControl
                                      isRequired={fieldValues.isMandatory}
                                      isDisabled={isFieldDisabled}
                                      tabIndex={0}
                                      isInvalid={!!form.errors[field.name]}
                                    >
                                      <Flex justifyContent="space-between" alignItems="center">
                                        <FormLabel mb={1}>
                                          {fieldValues.name}{fieldValues.unit ? ` (in ${fieldValues.unit})` : ''}
                                        </FormLabel>
                                        {fieldValues.tooltip && (
                                          <Box mb={1} cursor="pointer">
                                            <InfoPopover
                                              {...fieldValues.tooltip}
                                              hideCloseButton={false}
                                              popOverTrigger={<FiRsInterrogation fontSize="sm"/>}
                                            />
                                          </Box>
                                        )}
                                      </Flex>
                                      <Input
                                        rounded={0}
                                        type="number"
                                        min={fieldValues.minValue}
                                        max={fieldValues.maxValue}
                                        step={0.01}
                                        focusBorderColor="black"
                                        {...field}
                                        value={field.value ?? fieldValues.defaultValue}
                                      />
                                      {form.errors[field.name] && (
                                        <FormErrorMessage>{form.errors[field.name]?.toString()}</FormErrorMessage>
                                      )}
                                    </FormControl>
                                  )
                                }}
                              </Field>
                            )
                            const checkBoxField = (
                              <Field key={fieldValues.name + fieldValues.id} id={fieldValues.id} name={fieldValues.id}>
                                {({ field, form }: { field: any, form: any }) => {
                                  return (
                                    <FormControl isRequired={fieldValues.isMandatory} isDisabled={isFieldDisabled}>
                                      <Flex justifyContent="space-between" alignItems="center">
                                        <Checkbox
                                          my={1}
                                          rounded={0}
                                          isChecked={field.value ?? ['true', 'wahr'].includes(fieldValues.defaultValue?.toLowerCase() ?? '')}
                                          {...field}
                                          value={field.value ?? fieldValues.defaultValue}
                                        >
                                          {fieldValues.name}
                                        </Checkbox>
                                        {fieldValues.tooltip && (
                                          <Box cursor="pointer">
                                            <InfoPopover
                                              {...fieldValues.tooltip}
                                              hideCloseButton={false}
                                              popOverTrigger={<FiRsInterrogation fontSize="sm"/>}
                                            />
                                          </Box>
                                        )}
                                      </Flex>
                                      {form.errors[field.name] ? <FormErrorMessage>{form.errors[field.name]?.toString()}</FormErrorMessage> : null}
                                    </FormControl>
                                  )
                                }}
                              </Field>
                            )

                            const selectField = (
                              <Field key={fieldValues.name + fieldValues.id} id={fieldValues.id} name={fieldValues.id}>
                                {({ field, form }: { field: any, form: any }) => {
                                  const isDNField = fieldValues.id === 'd349d6c2-e3ff-5e95-a927-cbb56c074018'
                                  const isFixationRopeSetField =
                                    fieldValues.id === '8ba41a70-b066-5881-9bc5-713ff44ae5d5' ||
                                    fieldValues.id === '810fae5a-548c-5ff6-9bd3-6ae06b58e8e1'

                                  // Pressure Field with Overpressure, other Pressure Field only allows Underpressure
                                  const isPressureFieldWithOverpressure = fieldValues.id === '6eb673d1-58d7-5c37-8f80-6266f9f89b37'

                                  return (
                                    <FormControl
                                      isRequired={fieldValues.isMandatory}
                                      isDisabled={isFieldDisabled}
                                    >
                                      <Flex justifyContent="space-between" alignItems="center">
                                        <FormLabel mb={1}>
                                          {fieldValues.name}{fieldValues.unit ? ` (in ${fieldValues.unit})` : ''}
                                        </FormLabel>
                                        {fieldValues.tooltip && (
                                          <Box mb={1} cursor="pointer">
                                            <InfoPopover
                                              {...fieldValues.tooltip}
                                              hideCloseButton={false}
                                              popOverTrigger={<FiRsInterrogation fontSize="sm"/>}
                                            />
                                          </Box>
                                        )}
                                      </Flex>
                                      <Select
                                        rounded={0}
                                        placeholder="bitte auswählen"
                                        {...field}
                                        value={field.value ?? fieldValues.defaultValue}
                                        onChange={(e) => {
                                          if (isDNField) setSelectedDNFieldValue(e.target.value)
                                          if (isPressureFieldWithOverpressure) setSelectedPressureFieldValue(e.target.value)
                                          field.onChange(e)
                                        }}
                                      >
                                        {fieldValues.selectValues?.map((value, index) => (
                                          <option key={value + String(index)} value={value}>
                                            {fieldValues.unit === 'm' ? Number(value) / 1000 : value}
                                          </option>
                                        ))}
                                      </Select>
                                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                      {isDNField && selectedPressureFieldValue === 'Überdruck' && (selectedDNFieldValue === '100' || selectedDNFieldValue === '80') && (
                                        <Text pt={2} fontSize="sm">{formatMessage({ id: '--dn-note' })}</Text>
                                      )}
                                      {isFixationRopeSetField && (selectedDNFieldValue === '100' || selectedDNFieldValue === '80') && (
                                        <Text pt={2} fontSize="sm">{formatMessage({ id: '--fixation-rop-set-dn-note' })}</Text>
                                      )}
                                    </FormControl>
                                  )
                                }}
                              </Field>
                            )

                            switch (fieldValues.valueType) {
                              case 'NUMBER':
                                return (
                                  <Box key={fieldValues.name + fieldValues.id}>
                                    {numberField}
                                  </Box>
                                )
                              case 'BOOL':
                                return (
                                  <Box key={fieldValues.name + fieldValues.id}>
                                    {checkBoxField}
                                  </Box>
                                )
                              case 'SELECT':
                                return (
                                  <Box key={fieldValues.name + fieldValues.id}>
                                    {selectField}
                                  </Box>
                                )
                              case 'DYNAMIC_SELECT':
                                if (fieldValues.selectValues?.length === 0) {
                                  /* (
                                    <Alert status="info">
                                      <AlertIcon/>
                                      {fieldValues.name} hat keine Werte für diese Konfiguration und wird übersprungen
                                    </Alert>
                                  ) */
                                  return
                                }
                                return (
                                  <Box key={fieldValues.name + fieldValues.id}>
                                    {selectField}
                                  </Box>
                                )
                              default:
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                console.log(`Unknown field type: ${fieldValues.valueType}`)
                                return undefined
                            }
                          })}
                          <HStack justify="space-between">
                            <Text
                              fontSize="sm"
                              color="var(--chakra-colors-red-500)"
                              w="max-content"
                            >
                              {formatMessage({ id: '--required-field' })}
                            </Text>
                            <Button
                              type="submit"
                              w="fit-content"
                              colorScheme="accent"
                              isLoading={props.isSubmitting || updateConfigMutation.isLoading}
                            >
                              {formatMessage({ id: '--next' })}
                            </Button>
                          </HStack>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
    </Accordion>
  )
}
