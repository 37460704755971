import type { InputProps } from '@chakra-ui/react'
import { Box, Input, Stack, Text, VStack } from '@chakra-ui/react'
import { formatHeadlineColor } from '@stocker/ui-components/helpers'
import Downshift from 'downshift'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

interface IAutocompleteProps {
  label?: string
  onSelect?: (value: string) => void
  onChange?: (value: string) => void
  inputProps?: InputProps
  inputValue?: string
  onInputFocus?: (value: string) => void
  onBlur?: (value: string) => void
  // setInputValue?: React.Dispatch<React.SetStateAction<string>>
}

// eslint-disable-next-line react/function-component-definition
export function Autocomplete<T>({
  items,
  label,
  onSelect,
  onChange,
  inputProps,
  inputValue,
  onInputFocus,
  onBlur,
  customListItem,
}: IAutocompleteProps & {
  items: Array<
    T & { label: string; value: string; sku?: string; systemLine?: string; systemName?: string }
  >
  customListItem?: (props: { item: T; isHighlighted: boolean }) => React.ReactNode
}) {
  const [searchInput, setSearchInput] = useState('')
  const { formatMessage } = useIntl()

  return (
    <Downshift
      onChange={(
        selectedItem: {
          label: string
          value: string
          sku?: string
        } | null,
      ) => selectedItem && onSelect?.(selectedItem.value)}
      itemToString={(item) => item?.label ?? ''}
      inputValue={inputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getLabelProps,
        highlightedIndex,
        getRootProps,
        isOpen,
        openMenu,
        clearSelection,
      }) => (
        <Box w="full" position="relative">
          <div {...getRootProps(undefined, { suppressRefError: true })}>
            <label {...getLabelProps()}>{label}</label>
            {/* @ts-expect-error... */}
            <Input
              w="full"
              focusBorderColor="accent.500"
              {...inputProps}
              {...getInputProps({
                onChange: (e) => {
                  onChange?.(e.currentTarget.value)
                  setSearchInput(e.currentTarget.value)
                  if (e.currentTarget.value === '') {
                    clearSelection()
                  }
                },
                onFocus: (e) => {
                  openMenu()
                  onInputFocus?.(e.currentTarget.value)
                },
                onBlur: (e) => {
                  onBlur?.(e.currentTarget.value)
                },
              })}
            />
          </div>
          <VStack
            {...getMenuProps()}
            backgroundColor="secondaryBackground.500"
            height="max-content"
            maxHeight="300px"
            overflowY="auto"
            overflowX="hidden"
            position="absolute"
            zIndex={5}
            left="0"
            top="calc(100% + 5px)"
            width="100%"
            w="full"
            px={2}
            spacing={1}
            borderRadius="md"
            alignItems="flex-start"
            boxShadow="md"
          >
            {isOpen && String(searchInput).length > 2
              ? items.map((item, index) => {
                  return customListItem ? (
                    <Box
                      key={item.label + String(index)}
                      {...getItemProps({
                        item: {
                          label: item.label,
                          value: item.value,
                          sku: item.sku,
                        },
                        index,
                      })}
                    >
                      {customListItem({ item, isHighlighted: highlightedIndex === index })}
                    </Box>
                  ) : (
                    <Box
                      key={item.label + String(index)}
                      {...getItemProps({
                        item: { label: item.label, value: item.value, sku: item.sku },
                        index,
                      })}
                      bgColor={
                        highlightedIndex === index
                          ? 'secondaryBackground.600'
                          : 'secondaryBackground.500'
                      }
                      px={2}
                      py={1}
                      borderRadius="md"
                      cursor="pointer"
                    >
                      <Stack spacing={0} w="100%" whiteSpace="normal">
                        <Text>{formatHeadlineColor(item.label)}</Text>
                        <Text fontSize="sm">
                          {formatMessage({ id: '--sku' })}: {item.sku}
                        </Text>
                        {item.systemLine && <Text fontSize="sm">System: {item.systemLine}</Text>}
                      </Stack>
                    </Box>
                  )
                })
              : null}
          </VStack>
        </Box>
      )}
    </Downshift>
  )
}
