import { Box, Center, Circle, Heading, HStack, Radio, Stack, Text } from '@chakra-ui/react'
import { asComponent, showShippingCost } from '@stocker/ui-components/helpers'
import type { IImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'
import { FormattedNumber } from 'react-intl'
export interface IDeliveryRadioButtonProps {
  title: string
  price: number
  currencyCode?: string
  deliveryTime?: string
  logo?: IImage
  description?: string
  deliveryRank?: 'green' | 'yellow' | 'red'
  id: string
}

const getColor = (rank: 'green' | 'yellow' | 'red' | undefined) => {
  switch (rank) {
    case 'green':
      return 'successColor.500'
    case 'yellow':
      return 'warningColor.500'
    case 'red':
      return 'failedColor.500'
    default:
      return undefined
  }
}

export const ShippingRadioButton: React.FC<IDeliveryRadioButtonProps> = ({ id, deliveryRank, title, price, currencyCode, deliveryTime, description, logo }) => {
  const parsedDescription = asComponent(description ?? '')

  return (
    <Stack height="100%" border="1px" borderColor="gray.200" _hover={{ background: 'gray.50' }} borderRadius="5px" padding="15px" alignItems="center" justify="space-between" direction="row" >
      <Radio spacing="3" value={id} width="100%" height="100%">
        <Stack>
          <Box>
            <Heading as="h6" fontSize="md" mb="0" mt="0">{title}</Heading>
            <Box fontSize="sm">{parsedDescription}</Box>
          </Box>
          {logo?.fullpath && (
            <Center
              borderWidth="1px"
              bg="transparent"
              borderColor="gray.300"
              borderRadius="base"
              width="45px"
              p="2px"
            >
              <NextImage
                src={logo.fullpath}
                width="60"
                height="30"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                alt={title}
              />
            </Center>
          )}
        </Stack>
      </Radio>
      <Box display="flex">
        <HStack>
          {showShippingCost(price) && (
            <Text fontWeight="bold">
              <FormattedNumber
                value={price / 100}
                style="currency"
                currency={currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
          )}
          {deliveryTime && (
            <Box>
              {getColor(deliveryRank) && (
                <Circle size="10px" bg={getColor(deliveryRank)}/>
              )}
              <Text >
                Lieferzeit: {deliveryTime}
              </Text>
            </Box>
          )}
        </HStack>
      </Box>
    </Stack>
  )
}
