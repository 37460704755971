import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type React from 'react'

interface ITextHighlineProps extends BoxProps {
  colorAccent?: string
}

export const Highline: React.FC<ITextHighlineProps> = ({
  children,
  colorAccent = 'accent.500',
  ...boxProps
}) => {
  return (
    <Box
      position="relative"
      _after={{
        content: '""',
        h: '6px',
        w: '100px',
        position: 'absolute',
        left: '0',
        top: '-15px',
        bgColor: colorAccent,
        ...boxProps,
      }}
    >
      {children}
    </Box>
  )
}
