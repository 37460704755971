import { Box, Stack, Text } from '@chakra-ui/react'
import type { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import SystemFinderProgressStep from './SystemFinderProgressStep'

interface ISystemFinderProgressSteps {
  steps: Array<{
    title: string
    selectedValue?: string
  }>
  initialStep?: number
  currentStep: number
  setStep: Dispatch<SetStateAction<number>>
  showActiveAsCompleted?: boolean
  showActiveAsNotActive?: boolean
}

const SystemFinderProgressSteps: React.FC<ISystemFinderProgressSteps> = ({ showActiveAsNotActive = false, showActiveAsCompleted = false, currentStep, steps, initialStep = 0, setStep }) => {
  const intl = useIntl()

  return (
    <>
      {/* Desktop */}
      <Box py={{ base: '4', xl: '8' }} display={{ base: 'none', lg: 'block' }} ml={{ base: 0, lg: '-25px', '2xl': '-60px' }} mx={{ base: 0, '2xl': '-60px' }}>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <SystemFinderProgressStep
              key={`${step.title}-${step.selectedValue ?? '_'}`}
              cursor="pointer"
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onClick={() => { currentStep > index ? setStep(index) : undefined }}
              title={step.title}
              selectedValue={step.selectedValue ?? ''}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              isFirstStep={index === 0}
              isLastStep={steps.length === index + 1}
              currentStep={String(index + 1)}
              showActiveAsCompleted={showActiveAsCompleted}
              showActiveAsNotActive={showActiveAsNotActive}
            />
          ))}
        </Stack>
      </Box>
      {/* Mobile */}
      <Box py={{ base: '4', lg: '8' }} display={{ base: 'block', lg: 'none' }}>
        <Box pb="4">
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {steps[currentStep].title}
          </Text>
          <Text fontSize="md" textAlign="center">
            {intl.formatMessage({ id: 'progress-bar--current-step-of' }, { currentStep: currentStep + 1, maxStep: steps.length })}
          </Text>
        </Box>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <SystemFinderProgressStep
              key={`${step.title}-${step.selectedValue ?? '_'}`}
              cursor="pointer"
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              onClick={() => { currentStep > index ? setStep(index) : undefined }}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              isFirstStep={index === 0}
              isLastStep={steps.length === index + 1}
              currentStep={String(index + 1)}
            />
          ))}
        </Stack>
      </Box>
    </>
  )
}

export default SystemFinderProgressSteps
