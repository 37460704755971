import { Box, Heading, HStack, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react'
import { asComponent, showShippingCost } from '@stocker/ui-components/helpers'
import type React from 'react'
import { FormattedNumber } from 'react-intl'

export interface IShippingAndPaymentMethodProps {
  shipping?: {
    title: string
    name: string
    price: number
    currencyCode?: string
    description?: string
    logo?: string
  }
  payment?: {
    title: string
    name: string
    description?: string
    logo?: string
  }
  isLoaded?: boolean
}

export const ShippingAndPaymentMethod: React.FC<IShippingAndPaymentMethodProps> = ({ isLoaded = true, shipping, payment }) => {
  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={5}>
      {payment?.name && (
        <Stack>
          <Skeleton isLoaded={isLoaded} w="fit-content">
            <Heading as="h4" fontSize="lg">{payment.title}</Heading>
          </Skeleton>
          <Stack>
            <Box>
              <Text color="black">{payment.name}</Text>
              <Box>{asComponent(payment.description ?? '')}</Box>
            </Box>
            {/* {paymentMehodProps.logo?.fullpath && (
          <Center
          borderWidth="1px"
          bg="transparent"
          borderColor="gray.300"
          borderRadius="base"
          width="45px"
          p="2px"
          >
          <NextImage src={paymentMehodProps.logo.fullpath} objectFit="contain" layout="intrinsic" width="60px" height="30"/>
          </Center>
        )} */}
          </Stack>
        </Stack>
      )}
      {shipping?.name && (
        <Stack>
          <Skeleton isLoaded={isLoaded} w="fit-content">
            <Heading as="h4" fontSize="lg">{shipping.title}</Heading>
          </Skeleton>
          <Box>
            <HStack color="black" >
              <Text >{shipping.name}</Text>
              {showShippingCost(shipping.price) && (
                <Text>
                  <FormattedNumber
                    value={shipping.price / 100}
                    style="currency"
                    currency={shipping.currencyCode}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Text>
              )}
            </HStack>
            <Box>{asComponent(shipping.description ?? '')}</Box>
          </Box>
          {/* <Box>
            {shippingMethodProps.logo?.fullpath && (
              <Center
              borderWidth="1px"
              bg="transparent"
              borderColor="gray.300"
              borderRadius="base"
              width="45px"
              p="2px"
              >
              <NextImage src={shippingMethodProps.logo.fullpath} objectFit="contain" layout="intrinsic" width="60px" height="30"/>
              </Center>
              )}
            </Box> */}
        </Stack>
      )}
    </SimpleGrid>
  )
}
