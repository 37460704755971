import type { SimpleGridProps } from '@chakra-ui/react'
import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react'
import type { IDownloadItemProps, IHeading } from '../../'
import { DownloadItem, Wrap } from '../../'
import type React from 'react'

export interface IDownloadGridProps {
  heading?: IHeading
  downloadItems: IDownloadItemProps[]
  wrappedWithContainer?: boolean
}
interface IColors {
  colorText?: string
}
interface ICombined extends IColors, IDownloadGridProps, SimpleGridProps {}

export const DownloadGrid: React.FC<ICombined> = ({
  downloadItems, title,
  colorText = 'secondaryText.500',
  heading,
  columns = { base: 1, md: 2 },
  wrappedWithContainer = true,
  ...simpleGridProps
}) => {
  return (
    <Box color={colorText} pb={6}>
      <Wrap if={wrappedWithContainer} with={(children) => <Container maxW="container.content">{children}</Container>}>
        {heading && (
          <Heading as={heading.type} pb={2} pt={4} fontSize={heading.size}>{heading.text}</Heading>
        )}
        <SimpleGrid columns={columns} gap={{ base: 0, md: 4 }} spacingY={3} {...simpleGridProps}>
          {downloadItems.map(downloadItem => (
            <DownloadItem key={String(downloadItem.text) + String(downloadItem.fileLink)} text={downloadItem.text} fileLink={downloadItem.fileLink}/>
          ))}
        </SimpleGrid>
      </Wrap>
    </Box>
  )
}
