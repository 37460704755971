import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import type { IOffer } from '../../modules/OfferEditor/OfferEditor'
import type {OfferPositionData } from '../../modules/OfferEditor/OfferEditorProductList'
import dayjs from 'dayjs'
import type { OfferSettingsQuery } from '@stocker/codegen/vendure'
import { useMemo } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import uniqBy from 'lodash/uniqBy'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: 35,
    paddingBottom: 140,
    paddingHorizontal: 35,
    fontFamily: 'Helvetica',
  },
  logoSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  emailHeader: {
    fontFamily: 'Helvetica-Bold',
  },
  logo: {
    maxWidth: 150,
    maxHeight: 50,
    objectFit: 'contain',
  },
  companyInfo: {
    fontSize: 9,
    marginBottom: 10,
    maxWidth: 300,
  },
  address: {
    fontSize: 9,
    lineHeight: 1.2,
  },
  addressAndOffer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 36,
  },
  offerAddress: {
    width: 188,
  },
  offerNumber: {
    fontSize: 9,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 2,
  },
  date: {
    fontSize: 9,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 2,
  },
  reference: {
    fontSize: 9,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    lineHeight: 1.1,
    paddingBottom: 2,
  },
  referenceValue: {
    textAlign: 'right',
    paddingLeft: 10,
    maxWidth: 150,
  },
  offerHeading: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 8,
  },
  offer: {

  },
  offerText: {
    fontSize: 10,
    lineHeight: 1.2,
    marginBottom: 8,
  },
  offerTable: {
    marginBottom: 8,
  },
  tableContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    padding: 4,
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    left: 0,
    right: 0,
    bottom: 35,
  },
  footerText: {
    lineHeight: 1.2,
    fontSize: 9,
  },
  footerPagenumber: {
    position: 'absolute',
    bottom: 15,
    right: 40,
  },
  footerData: {
    marginHorizontal: 35,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: 4,
    lineHeight: 1.2,
    borderTop: 0.5,
  },
  text: {
    width: 180,
  },
  textAttributes: {
    fontSize: 6,
  },
})

export interface IPDFOfferData extends IOffer {
  companyName?: string
}
interface IOfferPdfDocumentProps {
  offer: IPDFOfferData
  offerSettings: OfferSettingsQuery['offerSettings']
  showEndCustomerPrices?: boolean
}

const OfferPdfDocument: React.FC<IOfferPdfDocumentProps> = ({ offer, offerSettings, showEndCustomerPrices }) => {
  const calculatePositionTotal = (data: OfferPositionData) => {
    const totalPrice = data.quantity * (showEndCustomerPrices && data.endPrice ? data.endPrice : data.price)
    const discount = (totalPrice * data.discount) / 100
    const tax = ((totalPrice - discount) * data.taxRate) / 100
    const positionTotal = Math.round((tax + totalPrice - discount))
    return (positionTotal)
  }

  const alternativesGrouped = useMemo(() => {
    if (!offer.positions) return []
    return Object.entries(offer.positions.filter(e => e.alternative).reduce<Record<string, OfferPositionData[]>>((acc, pos) => {
      const { taxRate } = pos
      acc[taxRate] = pos.taxRate in acc ? acc[taxRate] : []
      acc[taxRate].push(pos)
      return acc
    }, {}))
  }, [offer.positions])

  const positionsGrouped = useMemo(() => {
    if (!offer.positions) return []
    return Object.entries(offer.positions.filter(e => !e.alternative).reduce<Record<string, OfferPositionData[]>>((acc, pos) => {
      const { taxRate } = pos
      acc[taxRate] = pos.taxRate in acc ? acc[taxRate] : []
      acc[taxRate].push(pos)
      return acc
    }, {}))
  }, [offer.positions])

  const { formatMessage } = useIntl()
  const totalDiscountSum = useMemo(() => offer.positions?.filter(e => !e.alternative).reduce((acc, pos) => acc + (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100), 0) ?? 0, [offer, showEndCustomerPrices])
  const totalDiscountAlternativeSum = useMemo(() => offer.positions?.find(e => e.alternative) ? (offer.positions.filter(e => e.alternative).reduce((acc, pos) => acc + (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100), 0)) : 0, [offer, showEndCustomerPrices])

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginBottom: 60, width: '100%', display: 'flex', alignItems: 'flex-end' }} fixed>
          <View style={styles.logoSection}>
            {offerSettings?.logo ? <Image src={`${offerSettings.logo}?w=150&h=50&mode=resize&format=jpg`} style={styles.logo}/> : <Text style={styles.emailHeader}>{offer.companyName}</Text>}
          </View>
        </View>
        <View style={styles.addressAndOffer}>
          <View style={styles.address}>
            <Text>{offer.address}</Text>
          </View>
          <View style={styles.offerAddress}>
            {!!offer.subject && (
              <View style={styles.reference}>
                <Text style={{ fontFamily: 'Helvetica-Bold' }}>{formatMessage({ id: '--subject' })}</Text>
                <Text style={styles.referenceValue}>{offer.subject}</Text>
              </View>
            )}
            {!!offer.reference && (
              <View style={styles.reference}>
                <Text style={{ fontFamily: 'Helvetica-Bold' }}>{formatMessage({ id: '--offer-reference' })}</Text>
                <Text style={styles.referenceValue}>{offer.reference}</Text>
              </View>
            )}
            <View style={styles.date}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{formatMessage({ id: '--offer-date' })}</Text>
              <Text style={styles.referenceValue}>{(dayjs(offer.date)).format('DD.MM.YYYY')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.offer}>
          <Text style={{ color: offerSettings?.color ?? '', ...styles.offerHeading }}>{formatMessage({ id: '--offer-offer-number' })} {offer.number}</Text>
          <Text style={styles.offerText}>{offer.headerText}</Text>
          <View style={styles.offerTable}>
            <View style={{ marginVertical: offer.positions?.find(e => e.alternative) && 9 }}> {/* wtf does this 9 mean @nreiter29 */}
              <View style={{ backgroundColor: '#EBEBEB', fontFamily: 'Helvetica-Bold', ...styles.tableContent }}>
                <Text style={{ width: 30 }}>{formatMessage({ id: '--offer-position' })}</Text>
                <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-description' })}</Text>
                <Text style={{ width: 80, textAlign: 'right' }}>{formatMessage({ id: '--offer-quantity' })}</Text>
                <Text style={{ width: 120, textAlign: 'right' }}>{formatMessage({ id: '--offer-single-price' })}</Text>
                {offer.positions?.find(e => e.discount) && (
                  <Text style={{ width: 80, textAlign: 'right' }}>{formatMessage({ id: '--offer-discount' })}</Text>
                )}
                <Text style={{ width: 120, textAlign: 'right' }}>{formatMessage({ id: '--offer-total-price' })}</Text>
              </View>
              {/* Product list */}
              {offer.positions?.filter((content) => !content.alternative).map((content, index) => {

                const attributes = [content.sku ? `${formatMessage({ id: '--sku' })}: ${content.sku}` : '']
                const combinedAttributes = [...attributes, uniqBy(content.options, 'code').map((option) => {
                  return [`${formatMessage({ id: `--facet-${String(option.group.name)}` })}: ${String(option.code)}`]
                })].join(' | ')

                return (
                  <View key={index} style={styles.tableContent}>
                    <Text style={{ width: 30 }}>{index + 1}</Text>
                    <Text style={styles.text}>
                      {content.name}{'\n'}
                      <Text style={styles.textAttributes}>{combinedAttributes}</Text>
                    </Text>
                    <Text style={{ width: 80, textAlign: 'right' }}>
                      {!!content.quantity && (
                        <>
                          <FormattedNumber
                            value={content.quantity}
                            currency="EUR"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                          {` ${formatMessage({ id: '--offer-piece' })}`}
                        </>
                      )}
                    </Text>
                    <Text style={{ width: 120, textAlign: 'right' }}>
                      {!!content.price && (
                        <FormattedNumber
                          value={(showEndCustomerPrices && content.endPrice ? content.endPrice / 100 : content.price / 100)}
                          style="currency"
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      )}
                    </Text>
                    {offer.positions?.find(e => e.discount) && (
                      <Text style={{ width: 80, textAlign: 'right' }}>
                        {!!content.discount && (
                          <>
                            {content.discount} %
                          </>
                        )}
                      </Text>
                    )}
                    <Text style={{ width: 120, textAlign: 'right' }}>
                      {!!content.positionTotal && (
                        <FormattedNumber
                          value={showEndCustomerPrices ? (calculatePositionTotal(content) / 100) : (content.positionTotal / 100)}
                          style="currency"
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      )}
                    </Text>
                  </View>
                )
              })}
              {/* Tax, Net, Gross, Discounts */}
              <View style={styles.tableContent}>
                <Text style={{ width: 30 }}/>
                <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-amount-net' })}</Text>
                <Text style={{ width: 80, textAlign: 'right' }}/>
                <Text style={{ width: 120, textAlign: 'right' }}/>
                <Text style={{ width: 120, textAlign: 'right' }}>
                  <FormattedNumber
                    value={offer.positions?.filter(e => !e.alternative).reduce((acc, pos) => acc + (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100)), 0) ?? 0}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Text>
              </View>
              {
                totalDiscountSum > 0 && (
                  <View style={styles.tableContent}>
                    <Text style={{ width: 30 }}/>
                    <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-discount-sum' })}</Text>
                    <Text style={{ width: 80, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}>
                      <FormattedNumber
                        value={totalDiscountSum}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Text>
                  </View>
                )
              }
              {
                (offer.additionalDiscount ?? 0) > 0 && (
                  <View style={styles.tableContent}>
                    <Text style={{ width: 30 }}/>
                    <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-discounts' })}</Text>
                    <Text style={{ width: 80, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}>
                      <FormattedNumber
                        value={offer.positions?.filter(e => !e.alternative).reduce((acc, pos) => {
                          // calculate net
                          const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))

                          const netWithAdditionalDiscount = net * ((offer.additionalDiscount ?? 0) / 100)
                          return acc + netWithAdditionalDiscount
                        }, 0) ?? 0}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Text>
                  </View>
                )
              }
              {positionsGrouped.map(([taxRate, positions]) => (
                <View style={styles.tableContent} key={taxRate}>
                  <Text style={{ width: 30 }}/>
                  <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-plus-sales-tax' })} {taxRate}%</Text>
                  <Text style={{ width: 80, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}>
                    <FormattedNumber
                      value={positions.reduce((acc, pos) => {
                        const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))

                        return acc + (net - ((offer.additionalDiscount ?? 0) / 100 * net)) * (pos.taxRate / 100)
                      }, 0)}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Text>
                </View>
              ))}
              <View style={{ backgroundColor: '#EBEBEB', fontFamily: 'Helvetica-Bold', ...styles.tableContent }}>
                <Text style={{ width: 30 }}/>
                <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-amount-gross' })}</Text>
                <Text style={{ width: 80, textAlign: 'right' }}/>
                <Text style={{ width: 120, textAlign: 'right' }}/>
                <Text style={{ width: 120, textAlign: 'right' }}>
                  <FormattedNumber
                    value={(offer.positions?.filter(e => !e.alternative)
                      .reduce((acc, pos) => {
                        const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))
                        const netWithAdditionalDiscount = net - net * ((offer.additionalDiscount ?? 0) / 100)

                        return acc + netWithAdditionalDiscount * (1 + (pos.taxRate / 100))
                      }, 0)) ?? 0}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Text>
              </View>
            </View>
            {offer.positions?.find(e => e.alternative) && (
              <>
                <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, marginBottom: 5, color: offerSettings?.color ?? '' }}>{formatMessage({ id: '--offer-alternatives' })}</Text>
                <View style={{ backgroundColor: '#EBEBEB', fontFamily: 'Helvetica-Bold', ...styles.tableContent }}>
                  <Text style={{ width: 30 }}>{formatMessage({ id: '--offer-position' })}</Text>
                  <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-description' })}</Text>
                  <Text style={{ width: 80, textAlign: 'right' }}>{formatMessage({ id: '--offer-quantity' })}</Text>
                  <Text style={{ width: 120, textAlign: 'right' }}>{formatMessage({ id: '--offer-single-price' })}</Text>
                  {offer.positions.find(e => e.discount) && (
                    <Text style={{ width: 80, textAlign: 'right' }}>{formatMessage({ id: '--offer-discount' })}</Text>
                  )}
                  <Text style={{ width: 120, textAlign: 'right' }}>{formatMessage({ id: '--offer-total-price' })}</Text>
                </View>
              </>
            )}
            {/* Alternative Products */}
            {offer.positions?.filter((content) => content.alternative).map((content, index) => {
              return (
                <View key={index} style={styles.tableContent}>
                  <Text style={{ width: 30 }}>{index + 1}</Text>
                  <Text style={{ width: 180 }}>{content.name}</Text>
                  <Text style={{ width: 80, textAlign: 'right' }}>
                    {!!content.quantity && (
                      <>
                        <FormattedNumber
                          value={content.quantity}
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                        {formatMessage({ id: '--offer-piece' })}
                      </>
                    )}
                  </Text>
                  <Text style={{ width: 120, textAlign: 'right' }}>
                    {!!content.price && (
                      <FormattedNumber
                        value={(showEndCustomerPrices && content.endPrice ? content.endPrice / 100 : content.price / 100)}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                  </Text>
                  {offer.positions?.find(e => e.discount) && (
                    <Text style={{ width: 80, textAlign: 'right' }}>
                      {!!content.discount && (
                        <>
                          {content.discount} %
                        </>
                      )}
                    </Text>
                  )}
                  <Text style={{ width: 120, textAlign: 'right' }}>
                    {!!content.positionTotal && (
                      <FormattedNumber
                        value={content.positionTotal / 100}
                        currency="EUR"
                        style="currency"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                  </Text>
                </View>
              )
            })}
            {/* Alternative Products Taxes */}
            {offer.positions?.find(e => e.alternative) && (
              <>
                <View style={styles.tableContent}>
                  <Text style={{ width: 30 }}/>
                  <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-amount-net' })}</Text>
                  <Text style={{ width: 80, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}>
                    <FormattedNumber
                      value={offer.positions.filter(e => e.alternative).reduce((acc, pos) => acc + (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100)), 0)}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Text>
                </View>
                {
                  totalDiscountAlternativeSum > 0 && (
                    <View style={styles.tableContent}>
                      <Text style={{ width: 30 }}/>
                      <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-discount-sum' })}</Text>
                      <Text style={{ width: 80, textAlign: 'right' }}/>
                      <Text style={{ width: 120, textAlign: 'right' }}/>
                      <Text style={{ width: 120, textAlign: 'right' }}>
                        <FormattedNumber
                          value={totalDiscountAlternativeSum}
                          style="currency"
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </Text>
                    </View>
                  )
                }
                {
                  (offer.additionalDiscount ?? 0) > 0 && (
                    <View style={styles.tableContent}>
                      <Text style={{ width: 30 }}/>
                      <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-discounts' })}</Text>
                      <Text style={{ width: 80, textAlign: 'right' }}/>
                      <Text style={{ width: 120, textAlign: 'right' }}/>
                      <Text style={{ width: 120, textAlign: 'right' }}>
                        <FormattedNumber
                          value={offer.positions.filter(e => e.alternative)
                            .reduce((acc, pos) => {
                              const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))
                              const netWithAdditionalDiscount = net * ((offer.additionalDiscount ?? 0) / 100)
                              return acc + netWithAdditionalDiscount
                            }, 0)}
                          style="currency"
                          currency="EUR"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </Text>
                    </View>
                  )
                }
                {alternativesGrouped.map(([taxRate, positions]) => (
                  <View style={styles.tableContent} key={taxRate}>
                    <Text style={{ width: 30 }}/>
                    <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-plus-sales-tax' })} {taxRate}%</Text>
                    <Text style={{ width: 80, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}/>
                    <Text style={{ width: 120, textAlign: 'right' }}>
                      <FormattedNumber
                        value={positions.reduce((acc, pos) => {
                          const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))

                          return acc + (net - ((offer.additionalDiscount ?? 0) / 100 * net)) * (pos.taxRate / 100)
                        }, 0)}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Text>
                  </View>
                ))}
                <View style={{ backgroundColor: '#EBEBEB', fontFamily: 'Helvetica-Bold', marginBottom: 9, ...styles.tableContent }}>
                  <Text style={{ width: 30 }}/>
                  <Text style={{ width: 180 }}>{formatMessage({ id: '--offer-total-amount-gross' })}</Text>
                  <Text style={{ width: 80, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}/>
                  <Text style={{ width: 120, textAlign: 'right' }}>
                    <FormattedNumber
                      value={offer.positions.filter(e => e.alternative)
                        .reduce((acc, pos) => {
                          const net = (((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) - (pos.discount * ((showEndCustomerPrices && pos.endPrice ? pos.endPrice / 100 : pos.price / 100) * pos.quantity) / 100))
                          const netWithAdditionalDiscount = net - net * ((offer.additionalDiscount ?? 0) / 100)
                          return acc + netWithAdditionalDiscount * (1 + (pos.taxRate / 100))
                        }, 0)}
                      style="currency"
                      currency="EUR"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Text>
                </View>
              </>
            )}
          </View>
        </View>
        {/*****************************/}
        {/** ********* FOOTER ****** **/}
        {/*****************************/}

        <View style={styles.footerText}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{formatMessage({ id: '--offer-terms-of-delivery' })}</Text>
            <Text style={{ marginLeft: '2px' }}>{offer.termsOfDelivery}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 9 }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{formatMessage({ id: '--offer-terms-of-payment' })}</Text>
            <Text style={{ marginLeft: '2px' }}>{offer.termsOfPayment}</Text>
          </View>
          <Text>{offer.footerText}</Text>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerData}>
            <View>
              <Text>{offer.companyName}</Text>
              <Text>{offerSettings?.address}</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <Text>{offerSettings?.phoneNumber}</Text>
              <Text>{offerSettings?.email}</Text>
              {!!offerSettings?.vat &&
              // TODO: i18n
                <Text>UID: {offerSettings.vat}</Text>}
              {!!offerSettings?.vat &&
                <Text>{offerSettings.companyRegister}</Text>}
            </View>
            <View style={{ alignItems: 'flex-end' }}>
              <Text>{`IBAN ${offerSettings?.iban ?? '-'}`}</Text>
              <Text>{`BIC ${offerSettings?.bankCode ?? '-'}`}</Text>
              <Text>{offerSettings?.bankName}</Text>
            </View>
          </View>
          <Text
            style={styles.footerPagenumber}
            render={({ pageNumber, totalPages }) => (`${formatMessage({ id: '--page' })} ${pageNumber} ${formatMessage({ id: '--of' })} ${totalPages}`)}
          />
        </View>
      </Page>
    </Document>
  )
}

export default OfferPdfDocument
