import { Box, Flex, Grid, HStack, Icon, Link, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'
import { FilterCheckbox, FilterRadio, FilterSlider } from '../../'
import { useReducer } from 'react'
import { MdFilterList } from 'react-icons/md'
import { useIntl } from 'react-intl'
import { FilterDrawer } from './FilterDrawer'

export interface IFilterCategoricalProps {
  label: string
  filterId: string
  type: 'radio' | 'checkbox'
  options: Array<{
    id: string
    label: string
    count: number
  }>
}

export interface IFilterNumericalProps {
  label: string
  filterId: string
  type: 'slider'
  min: number
  max: number
  step?: number
}

export interface IFiltersFacetsProps {
  filter: Array<IFilterCategoricalProps | IFilterNumericalProps>
}

function reducer (state: object, action: { key?: string, value?: any, reset?: boolean }): Record<string, any> {
  if (action.key && action.value) {
    return {
      ...state,
      [action.key]: action.value,
    }
  } else if (action.reset) {
    return {}
  }
  return state
}

export const FiltersFacets: React.FC<IFiltersFacetsProps> = ({ filter }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [filterState, dispatchFilterState] = useReducer(reducer, {})

  const filtersFacets = filter.map((filter) => {
    const onChange = (value: any) => { dispatchFilterState({ key: filter.filterId, value }) }
    switch (filter.type) {
      case 'radio':
        return <FilterRadio key={filter.filterId} label={filter.label} options={filter.options} onChange={onChange} value={filterState[filter.filterId] ?? ''}/>
      case 'checkbox':
        return <FilterCheckbox key={filter.filterId} label={filter.label} options={filter.options} onChange={onChange} value={filterState[filter.filterId] ?? []}/>
      case 'slider':
        return <FilterSlider heading={{ text: filter.label }} key={filter.filterId} {...filter} refine={onChange} currentRefinement={filterState[filter.filterId] ?? [filter.min, filter.max]}/>
    }
  })

  const intl = useIntl()

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
    >
      <Box mt={{ base: '8', md: '16' }}>
        <Grid templateColumns={{ base: '1fr', md: '240px' }} gap="14">
          {/* Desktop Layout */}
          <Stack spacing="10" maxW="240px" display={{ base: 'none', md: 'flex' }}>
            {filtersFacets}
            <Link
              textDecor="underline"
              fontSize="sm"
              onClick={() => {
                dispatchFilterState({ reset: true })
              }}
            >
              {intl.formatMessage({ id: '--clear' })}
            </Link>
          </Stack>
          {/* Mobile Layout */}
          <Stack
            spacing={{ base: '6', md: '4' }}
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            align="flex-start"
            width="full"
            display={{ base: 'flex', md: 'none' }}
          >
            <Flex width="full" justify="space-between" >
              <HStack
                as="button"
                fontSize="sm"
                type="button"
                px="3"
                py="1"
                onClick={onOpen}
                borderWidth="1px"
                rounded="md"
              >
                <Icon as={MdFilterList}/>
                <Text>{intl.formatMessage({ id: '--filters' })}</Text>
              </HStack>
            </Flex>
            <FilterDrawer isOpen={isOpen} onClose={onClose} onClearAll={() => { dispatchFilterState({ reset: true }) }}>
              <Stack spacing="6" divider={<StackDivider/>}>
                {filtersFacets}
              </Stack>
            </FilterDrawer>
          </Stack>
        </Grid>
      </Box>
    </Box>
  )
}
