import { Box, SimpleGrid, Stack } from '@chakra-ui/react'
import type React from 'react'
import type { IProductCardProps } from '../ProductCard/ProductCard'
import { ProductCard } from '../ProductCard/ProductCard'

export interface IProductListingProps {
  variant: 'grid' | 'list'
  products: IProductCardProps[]
}

export const ProductListing: React.FC<IProductListingProps> = ({ variant, products }) => {
  return (
    <Box>
      {variant === 'list' && (
        <>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={5} display={{ base: 'grid', lg: 'none' }}>
            {products.map((product, index) => (
              <ProductCard key={index} {...product} layout="grid"/>
            ))}
          </SimpleGrid>
          <Stack spacing="20px" display={{ base: 'none', lg: 'inline' }}>
            {products.map((product, index) => (
              <ProductCard key={index} {...product} layout="list"/>
            ))}
          </Stack>
        </>
      )}
      {variant === 'grid' && (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={5}>
          {products.map((product, index) => (
            <ProductCard key={index} {...product} layout="grid"/>
          ))}
        </SimpleGrid>
      )}
    </Box>
  )
}
