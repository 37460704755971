import { Box, Select } from '@chakra-ui/react'
import { useState } from 'react'
import type { SortByProps } from 'react-instantsearch-dom'

export interface ISSortByProps extends SortByProps {
  refine: (value: number) => void
}

export const ISSortBy: React.FC<ISSortByProps> = ({ items, defaultRefinement, refine }) => {
  const [value, setValue] = useState<string | undefined>()
  return (
    <Box>
      <Select
        value={value ?? defaultRefinement}
        size="sm"
        defaultValue={defaultRefinement}
        onChange={({ currentTarget: { value } }) => {
          localStorage.setItem('sortBy', JSON.stringify(value))
          setValue(value)
          refine(Number(value))
        }}
      >
        {items.map(item => {
          return <option key={item.label} value={item.value}>{item.label}</option>
        })}
      </Select>
    </Box>
  )
}
