import {
  useNavigationByIdentifierQuery,
  useWebsiteConfigurationQuery,
} from '@stocker/codegen/pimcore'

export async function getTranslations (lang = 'de') {
  const res = await fetch(`${process.env.NEXT_PUBLIC_PIMCORE_BASE_URL}/translations?locale=${lang}`)
  const result: Record<string, string> = await res.json()
  return result
}

export async function getServerData (lang = 'de') {
  const [translations, navItems, navItemsToolbar, websiteConfiguration] =
    await Promise.all([
      getTranslations(lang),
      useNavigationByIdentifierQuery.fetcher({ identifier: 'root', lang })(),
      useNavigationByIdentifierQuery.fetcher({
        identifier: 'navigationToolbar',
        lang,
      })(),
      useWebsiteConfigurationQuery.fetcher({
        id: Number(process.env.NEXT_PUBLIC_WEBSITE_CONFIG_ID),
        language: 'de',
      })(),
    ])

  return {
    translations,
    navItems,
    navItemsToolbar,
    websiteConfiguration,
  }
}
