import { Form, ButtonData, InputData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

export interface IUpdateEmailFormData {
  newEmail: string
  password: string
}

interface IUpdateEmailFormProps {
  onSubmit: (values: IUpdateEmailFormData) => void
  isLoading: boolean
}

export const UpdateEmailForm: React.FC<IUpdateEmailFormProps> = ({ onSubmit, isLoading }) => {
  const { formatMessage } = useIntl()

  const emailValidationSchema = useMemo(() => yup.object().shape({
    newEmail: yup.string().email(formatMessage({ id: 'form--field-email-invalid' })).required(formatMessage({ id: 'form--field-is-required' })),
    password: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
  }), [formatMessage])

  const emailFormData: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--password' }), name: 'password', type: 'password', minWidth: '200px' }),
            ],
            [
              new InputData({ title: formatMessage({ id: 'account--account-settings--change-change-new-email' }), name: 'newEmail', type: 'email', minWidth: '200px' }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: '--save' }), type: 'submit', align: 'right', inputProps: { colorScheme: 'accent', isLoading, mt: 5 } }),
            ],
          ],
        },
      ],
    ],
  }), [formatMessage, isLoading])

  return (
    <Form
      blockGap={{ vertical: '1rem', horizontal: '10rem' }}
      fieldGap="1rem"
      items={emailFormData}
      onSubmit={values => { onSubmit(values) }}
      validationSchema={emailValidationSchema}
    />
  )
}
