import type { ButtonProps } from '@chakra-ui/react'
import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react'
import { FiRsAngleDown } from '../'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

interface IShowMoreProps extends ButtonProps {
  maxH: number
  rerenderToggle: boolean
}

export const ShowMore: React.FC<React.PropsWithChildren<IShowMoreProps>> = ({ maxH, children, rerenderToggle, backgroundColor = 'white', ...buttonProps }) => {
  const [height, setHeight] = useState(0)
  const ref = useRef<any>(null)
  const { isOpen, onToggle } = useDisclosure()
  const intl = useIntl()

  useEffect(() => {
    setHeight(ref.current?.clientHeight ?? 0)
  }, [rerenderToggle, children])

  return (
    <>
      <Box
        maxH={isOpen ? 'auto' : `${maxH}px`}
        overflow="hidden"
        position="relative"
      >
        <Box ref={ref}>
          {children}
        </Box>
        <Box
          hidden={height < maxH && !isOpen}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height={3}
          bgGradient={`linear-gradient(to bottom, rgba(255,255,255,0), ${String(backgroundColor)})`}
        />
      </Box>
      <Button
        w="100%"
        h="60px"
        aria-label={intl.formatMessage({ id: '--show-more' })}
        onClick={onToggle}
        hidden={height < maxH}
        backgroundColor={backgroundColor}
        {...buttonProps}
      >
        <HStack
          pl={5}
          py={2}
          spacing={1}
        >
          <Text >
            {isOpen ? intl.formatMessage({ id: '--show-less' }) : intl.formatMessage({ id: '--show-more' })}
          </Text>
          <FiRsAngleDown
            transform={`rotate(${!isOpen ? '0' : '180'}deg)`}
            transition="transform 0.6s"
          />
        </HStack>
      </Button>
    </>
  )
}
