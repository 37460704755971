export const testDocumentWithAllPossibleFeatures = `
<h1>h1</h1>

<h2>h2</h2>

<h3>h3</h3>

<h4>h4</h4>

<h5>h5</h5>

<p>Headings different format</p>

<h1><span style="font-size:9px">h1</span></h1>

<h2><span style="font-size:12px">h2</span></h2>

<h3><span style="font-size:16px">h3</span></h3>

<h4><span style="font-size:18px">h4</span></h4>

<h5><span dir="ltr"><span style="font-size:26px">h5 rtl</span></span></h5>

<div style="page-break-after:always"><span style="display:none">&nbsp;</span></div>

<h6>h6</h6>

<address>address</address>

<div>normal div</div>

<p><span style="font-family:Arial,Helvetica,sans-serif">Different Font&nbsp;as Arial</span></p>

<p><span style="font-size:26px">Bigger Size Hardcoded</span></p>

<p><span style="font-size:12px">Smaller Size Hardcoded</span></p>

<p><span style="color:#1abc9c">Other Text Color</span></p>

<p><span style="background-color:#e74c3c">Other Background Color</span></p>

<p style="text-align:center">Text Centered</p>

<p style="text-align:right">Text right aligned</p>

<p style="text-align:justify">BlockText&nbsp;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur</p>

<p style="text-align:right">Text right aligned</p>

<p style="text-align:justify">BlockText&nbsp;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur</p>

<p style="text-align:justify">BlockText&nbsp;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetursadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

<p style="margin-left:40px">Increased Indent</p>

<p style="margin-left:80px">DoubleIncreased INdent</p>

<blockquote>
<p>BlockQuote&nbsp;Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
</blockquote>

<p>&nbsp;</p>

<blockquote>
<p style="margin-left:40px">BlockQuote with increased indent Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
</blockquote>

<p>&nbsp;</p>

<p>Hyperlink&nbsp;<a href="http://google.com" tabindex="-1">To Google</a>&nbsp;&nbsp;<a href="http://google.com" tabindex="-1" target="_blank">To Google</a>&nbsp;New Window</p>

<p><img alt="Alternative Image text" src="https://www.stocker-kaminsysteme.com/Website/Startseite%20und%20News/2020/image-thumb__28367__hero-slider/Luftbild_Drohnenvideo@2x.jpeg" style="float:left; height:362px; width:500px" /></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<table align="center" border="5" cellpadding="1" cellspacing="1" class="extraClass" id="tableId" style="width:500px" summary="Table Summary">
 <caption>Table Caption</caption>
 <thead>
  <tr>
   <th scope="row">table first heading</th>
   <th scope="col">table second heading</th>
  </tr>
 </thead>
 <tbody>
  <tr>
   <th scope="row">f<del>ormatting in table</del></th>
   <td>TestTestTEst</td>
  </tr>
  <tr>
   <th scope="row">third row as captions</th>
   <td>&nbsp;</td>
  </tr>
 </tbody>
</table>

<p>&nbsp;</p>

<p>Horizontal line</p>

<hr />
<p>&nbsp;</p>

<p>©</p>

<p>&nbsp;</p>

<p><strong>Bold</strong></p>

<p><em>Italic</em></p>

<p><u>Underline</u></p>

<p><s>Strikethrough</s></p>

<p>Prefix<sub>Subscript</sub></p>

<p>Prefix<sup>Superscript</sup></p>

<p>&nbsp;</p>

<p><sup>Numbered List</sup></p>

<ol>
 <li>one</li>
 <li>two three</li>
</ol>

<p><span style="font-size:10px">Unordered List</span></p>

<ul>
 <li>one</li>
 <li>two</li>
 <li>three</li>
</ul>
`
