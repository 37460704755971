/* eslint-disable */
import * as Types from './generated-types';

import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchVendure } from './fetcher';

export const CountryFragmentDoc = `
    fragment Country on Country {
  id
  languageCode
  code
  name
  enabled
  customFields
}
    `;
export const AddressFragmentDoc = `
    fragment Address on Address {
  id
  createdAt
  updatedAt
  fullName
  streetLine1
  streetLine2
  city
  province
  postalCode
  country {
    ...Country
  }
  customFields
}
    `;
export const CompanyAddressFragmentDoc = `
    fragment CompanyAddress on CompanyAddress {
  id
  fullName
  streetLine1
  streetLine2
  province
  countryCode
  city
  postalCode
  phoneNumber
  defaultBillingAddress
  defaultShippingAddress
  customFields {
    erpId
  }
}
    `;
export const BreadcrumbFragmentDoc = `
    fragment Breadcrumb on CollectionBreadcrumb {
  name
  id
  slug
}
    `;
export const ActiveOrderFragmentDoc = `
    fragment ActiveOrder on Order {
  id
  state
  updatedAt
  createdAt
  total
  totalWithTax
  subTotal
  subTotalWithTax
  shipping
  currencyCode
  shippingWithTax
  totalQuantity
  lines {
    id
    linePrice
    discountedLinePrice
    unitPrice
    quantity
    productVariant {
      currencyCode
      id
      name
      sku
      price
      assets {
        id
        source
      }
    }
  }
}
    `;
export const OrderAddressFragmentDoc = `
    fragment OrderAddress on OrderAddress {
  fullName
  company
  streetLine1
  streetLine2
  city
  province
  postalCode
  country
  countryCode
  phoneNumber
  customFields
}
    `;
export const ProductVariantCustomFieldFragmentDoc = `
    fragment ProductVariantCustomField on ProductVariantCustomFields {
  productDetailName @include(if: $isPreRender)
  infoText @include(if: $isPreRender)
  customAttributes @include(if: $isPreRender) {
    itemText
    itemHeadline
  }
  onSiteImages @skip(if: $isPreRender) {
    name
    source
  }
  systemInformationItems @skip(if: $isPreRender) {
    itemText
    itemHeadline
  }
  additionalProducts @skip(if: $isPreRender) {
    id
    name
  }
}
    `;
export const AssetFragmentDoc = `
    fragment Asset on Asset {
  width
  height
  source
}
    `;
export const ProductVariantFragmentDoc = `
    fragment ProductVariant on ProductVariant {
  id
  name
  sku
  stockLevel
  currencyCode
  price
  options {
    code
    group {
      name
    }
  }
  customFields @include(if: $withCustomFields) {
    ...ProductVariantCustomField
  }
  assets {
    ...Asset
  }
}
    `;
export const CustomerDataFragmentDoc = `
    fragment CustomerData on Customer {
  id
  firstName
  lastName
  emailAddress
  title
  phoneNumber
  customFields @include(if: $withCustomFields) {
    salutation
  }
}
    `;
export const OfferPriceFragmentDoc = `
    fragment OfferPrice on OfferPrice {
  id
  price
  currencyCode
  productVariant {
    name
    price
    product {
      name
    }
    id
    sku
    featuredAsset {
      ...Asset
    }
  }
}
    `;
export const OfferFragmentFragmentDoc = `
    fragment OfferFragment on Offer {
  id
  reference
  status
  createdAt
  number
  date
  address
  subject
  headerText
  footerText
  termsOfDelivery
  termsOfPayment
  additionalDiscount
  customOfferLines {
    id
    name
    price
    quantity
    taxRate
    discount
    positionTotal
    alternative
  }
  order {
    id
    totalWithTax
    total
    subTotal
    subTotalWithTax
    lines {
      customFields {
        offerOrderLine {
          price
          positionTotal
        }
      }
    }
  }
}
    `;
 const AddItemToOrderDocument = `
    mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!, $withCustomFields: Boolean = false) {
  addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
    __typename
    ... on Order {
      id
      customer {
        ...CustomerData
      }
      state
    }
    ... on OrderModificationError {
      errorCode
      message
      __typename
    }
    ... on OrderLimitError {
      message
      maxItems
      __typename
    }
    ... on NegativeQuantityError {
      errorCode
      message
      __typename
    }
    ... on InsufficientStockError {
      errorCode
      message
      quantityAvailable
      __typename
    }
  }
}
    ${CustomerDataFragmentDoc}`;

export const useAddItemToOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddItemToOrderMutation, TError, Types.AddItemToOrderMutationVariables, TContext>) => {
    
    return useMutation<Types.AddItemToOrderMutation, TError, Types.AddItemToOrderMutationVariables, TContext>(
      ['AddItemToOrder'],
      (variables?: Types.AddItemToOrderMutationVariables) => fetchVendure<Types.AddItemToOrderMutation, Types.AddItemToOrderMutationVariables>(AddItemToOrderDocument, variables)(),
      options
    )};


useAddItemToOrderMutation.fetcher = (variables: Types.AddItemToOrderMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.AddItemToOrderMutation, Types.AddItemToOrderMutationVariables>(AddItemToOrderDocument, variables, options);

 const AddItemToOrderBySkuDocument = `
    mutation addItemToOrderBySku($input: AddItemToOrderBySkuInput!) {
  addItemToOrderBySku(input: $input) {
    ... on Order {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useAddItemToOrderBySkuMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddItemToOrderBySkuMutation, TError, Types.AddItemToOrderBySkuMutationVariables, TContext>) => {
    
    return useMutation<Types.AddItemToOrderBySkuMutation, TError, Types.AddItemToOrderBySkuMutationVariables, TContext>(
      ['addItemToOrderBySku'],
      (variables?: Types.AddItemToOrderBySkuMutationVariables) => fetchVendure<Types.AddItemToOrderBySkuMutation, Types.AddItemToOrderBySkuMutationVariables>(AddItemToOrderBySkuDocument, variables)(),
      options
    )};


useAddItemToOrderBySkuMutation.fetcher = (variables: Types.AddItemToOrderBySkuMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.AddItemToOrderBySkuMutation, Types.AddItemToOrderBySkuMutationVariables>(AddItemToOrderBySkuDocument, variables, options);

 const AddPaymentDocument = `
    mutation AddPayment($input: PaymentInput!) {
  addPaymentToOrder(input: $input) {
    __typename
    ...ActiveOrder
    ... on Order {
      id
      code
    }
    ... on OrderPaymentStateError {
      errorCode
      message
    }
    ... on IneligiblePaymentMethodError {
      errorCode
      message
    }
    ... on PaymentFailedError {
      errorCode
      message
    }
    ... on PaymentDeclinedError {
      errorCode
      message
      paymentErrorMessage
    }
    ... on OrderStateTransitionError {
      errorCode
      message
      transitionError
      fromState
      toState
    }
    ... on NoActiveOrderError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;

export const useAddPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddPaymentMutation, TError, Types.AddPaymentMutationVariables, TContext>) => {
    
    return useMutation<Types.AddPaymentMutation, TError, Types.AddPaymentMutationVariables, TContext>(
      ['AddPayment'],
      (variables?: Types.AddPaymentMutationVariables) => fetchVendure<Types.AddPaymentMutation, Types.AddPaymentMutationVariables>(AddPaymentDocument, variables)(),
      options
    )};


useAddPaymentMutation.fetcher = (variables: Types.AddPaymentMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.AddPaymentMutation, Types.AddPaymentMutationVariables>(AddPaymentDocument, variables, options);

 const AddToWishlistDocument = `
    mutation AddToWishlist($productVariantId: ID!, $wishlistId: ID) {
  addToWishlist(productVariantId: $productVariantId, wishlistId: $wishlistId) {
    id
    itemCount
    items {
      id
    }
  }
}
    `;

export const useAddToWishlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddToWishlistMutation, TError, Types.AddToWishlistMutationVariables, TContext>) => {
    
    return useMutation<Types.AddToWishlistMutation, TError, Types.AddToWishlistMutationVariables, TContext>(
      ['AddToWishlist'],
      (variables?: Types.AddToWishlistMutationVariables) => fetchVendure<Types.AddToWishlistMutation, Types.AddToWishlistMutationVariables>(AddToWishlistDocument, variables)(),
      options
    )};


useAddToWishlistMutation.fetcher = (variables: Types.AddToWishlistMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.AddToWishlistMutation, Types.AddToWishlistMutationVariables>(AddToWishlistDocument, variables, options);

 const UpdateCompanyAddressDocument = `
    mutation UpdateCompanyAddress($input: UpdateCompanyAddressInput!) {
  updateCompanyAddress(address: $input) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on CompanyAddress {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Node {
      id
    }
  }
}
    `;

export const useUpdateCompanyAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCompanyAddressMutation, TError, Types.UpdateCompanyAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateCompanyAddressMutation, TError, Types.UpdateCompanyAddressMutationVariables, TContext>(
      ['UpdateCompanyAddress'],
      (variables?: Types.UpdateCompanyAddressMutationVariables) => fetchVendure<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>(UpdateCompanyAddressDocument, variables)(),
      options
    )};


useUpdateCompanyAddressMutation.fetcher = (variables: Types.UpdateCompanyAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateCompanyAddressMutation, Types.UpdateCompanyAddressMutationVariables>(UpdateCompanyAddressDocument, variables, options);

 const DeleteCompanyAddressDocument = `
    mutation DeleteCompanyAddress($id: ID!) {
  deleteCompanyAddress(addressId: $id) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Success {
      success
    }
  }
}
    `;

export const useDeleteCompanyAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteCompanyAddressMutation, TError, Types.DeleteCompanyAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.DeleteCompanyAddressMutation, TError, Types.DeleteCompanyAddressMutationVariables, TContext>(
      ['DeleteCompanyAddress'],
      (variables?: Types.DeleteCompanyAddressMutationVariables) => fetchVendure<Types.DeleteCompanyAddressMutation, Types.DeleteCompanyAddressMutationVariables>(DeleteCompanyAddressDocument, variables)(),
      options
    )};


useDeleteCompanyAddressMutation.fetcher = (variables: Types.DeleteCompanyAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.DeleteCompanyAddressMutation, Types.DeleteCompanyAddressMutationVariables>(DeleteCompanyAddressDocument, variables, options);

 const CreateCompanyAddressDocument = `
    mutation CreateCompanyAddress($input: CreateCompanyAddressInput!) {
  addCompanyAddress(address: $input) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on CompanyAddress {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Node {
      id
    }
  }
}
    `;

export const useCreateCompanyAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateCompanyAddressMutation, TError, Types.CreateCompanyAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateCompanyAddressMutation, TError, Types.CreateCompanyAddressMutationVariables, TContext>(
      ['CreateCompanyAddress'],
      (variables?: Types.CreateCompanyAddressMutationVariables) => fetchVendure<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>(CreateCompanyAddressDocument, variables)(),
      options
    )};


useCreateCompanyAddressMutation.fetcher = (variables: Types.CreateCompanyAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateCompanyAddressMutation, Types.CreateCompanyAddressMutationVariables>(CreateCompanyAddressDocument, variables, options);

 const AdjustOrderLineDocument = `
    mutation AdjustOrderLine($orderLineId: ID!, $quantity: Int!) {
  adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
    ... on Order {
      id
      totalQuantity
      lines {
        quantity
      }
    }
    ... on OrderModificationError {
      errorCode
      message
    }
    ... on OrderLimitError {
      errorCode
      message
    }
    ... on NegativeQuantityError {
      errorCode
      message
    }
    ... on InsufficientStockError {
      errorCode
      message
      quantityAvailable
    }
  }
}
    `;

export const useAdjustOrderLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AdjustOrderLineMutation, TError, Types.AdjustOrderLineMutationVariables, TContext>) => {
    
    return useMutation<Types.AdjustOrderLineMutation, TError, Types.AdjustOrderLineMutationVariables, TContext>(
      ['AdjustOrderLine'],
      (variables?: Types.AdjustOrderLineMutationVariables) => fetchVendure<Types.AdjustOrderLineMutation, Types.AdjustOrderLineMutationVariables>(AdjustOrderLineDocument, variables)(),
      options
    )};


useAdjustOrderLineMutation.fetcher = (variables: Types.AdjustOrderLineMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.AdjustOrderLineMutation, Types.AdjustOrderLineMutationVariables>(AdjustOrderLineDocument, variables, options);

 const ApplyCouponCodeDocument = `
    mutation ApplyCouponCode($couponCode: String!) {
  applyCouponCode(couponCode: $couponCode) {
    ... on Order {
      id
    }
    ... on CouponCodeExpiredError {
      errorCode
      message
      couponCode
    }
    ... on CouponCodeInvalidError {
      errorCode
      message
      couponCode
    }
    ... on CouponCodeLimitError {
      errorCode
      message
      couponCode
      limit
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useApplyCouponCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ApplyCouponCodeMutation, TError, Types.ApplyCouponCodeMutationVariables, TContext>) => {
    
    return useMutation<Types.ApplyCouponCodeMutation, TError, Types.ApplyCouponCodeMutationVariables, TContext>(
      ['ApplyCouponCode'],
      (variables?: Types.ApplyCouponCodeMutationVariables) => fetchVendure<Types.ApplyCouponCodeMutation, Types.ApplyCouponCodeMutationVariables>(ApplyCouponCodeDocument, variables)(),
      options
    )};


useApplyCouponCodeMutation.fetcher = (variables: Types.ApplyCouponCodeMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.ApplyCouponCodeMutation, Types.ApplyCouponCodeMutationVariables>(ApplyCouponCodeDocument, variables, options);

 const CreateAddressDocument = `
    mutation CreateAddress($city: String!, $company: String!, $countryCode: String!, $fullName: String!, $postalCode: String!, $province: String!, $streetLine1: String!, $streetLine2: String!, $phoneNumber: String!) {
  createCustomerAddress(
    input: {city: $city, company: $company, countryCode: $countryCode, fullName: $fullName, postalCode: $postalCode, province: $province, streetLine1: $streetLine1, streetLine2: $streetLine2, phoneNumber: $phoneNumber}
  ) {
    id
  }
}
    `;

export const useCreateAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateAddressMutation, TError, Types.CreateAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateAddressMutation, TError, Types.CreateAddressMutationVariables, TContext>(
      ['CreateAddress'],
      (variables?: Types.CreateAddressMutationVariables) => fetchVendure<Types.CreateAddressMutation, Types.CreateAddressMutationVariables>(CreateAddressDocument, variables)(),
      options
    )};


useCreateAddressMutation.fetcher = (variables: Types.CreateAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateAddressMutation, Types.CreateAddressMutationVariables>(CreateAddressDocument, variables, options);

 const CreateAssetDocument = `
    mutation CreateAsset($input: [CreateAssetInput!]!) {
  createAssets(input: $input) {
    __typename
    ... on Asset {
      id
      preview
      source
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on MimeTypeError {
      errorCode
      message
      mimeType
    }
  }
}
    `;

export const useCreateAssetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateAssetMutation, TError, Types.CreateAssetMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateAssetMutation, TError, Types.CreateAssetMutationVariables, TContext>(
      ['CreateAsset'],
      (variables?: Types.CreateAssetMutationVariables) => fetchVendure<Types.CreateAssetMutation, Types.CreateAssetMutationVariables>(CreateAssetDocument, variables)(),
      options
    )};


useCreateAssetMutation.fetcher = (variables: Types.CreateAssetMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateAssetMutation, Types.CreateAssetMutationVariables>(CreateAssetDocument, variables, options);

 const CreateCustomerAddressDocument = `
    mutation createCustomerAddress($input: CreateAddressInput!) {
  createCustomerAddress(input: $input) {
    id
    createdAt
  }
}
    `;

export const useCreateCustomerAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateCustomerAddressMutation, TError, Types.CreateCustomerAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateCustomerAddressMutation, TError, Types.CreateCustomerAddressMutationVariables, TContext>(
      ['createCustomerAddress'],
      (variables?: Types.CreateCustomerAddressMutationVariables) => fetchVendure<Types.CreateCustomerAddressMutation, Types.CreateCustomerAddressMutationVariables>(CreateCustomerAddressDocument, variables)(),
      options
    )};


useCreateCustomerAddressMutation.fetcher = (variables: Types.CreateCustomerAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateCustomerAddressMutation, Types.CreateCustomerAddressMutationVariables>(CreateCustomerAddressDocument, variables, options);

 const CreateOfferDocument = `
    mutation CreateOffer($address: String!, $subject: String!, $headerText: String!, $footerText: String!, $reference: String!, $positions: [CreateOfferPositionInput!], $termsOfDelivery: String!, $termsOfPayment: String!, $date: DateTime!, $number: String!, $additionalDiscount: Int) {
  createOffer(
    input: {address: $address, subject: $subject, headerText: $headerText, footerText: $footerText, reference: $reference, positions: $positions, termsOfPayment: $termsOfPayment, termsOfDelivery: $termsOfDelivery, date: $date, number: $number, additionalDiscount: $additionalDiscount}
  ) {
    __typename
    ... on Offer {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useCreateOfferMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOfferMutation, TError, Types.CreateOfferMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateOfferMutation, TError, Types.CreateOfferMutationVariables, TContext>(
      ['CreateOffer'],
      (variables?: Types.CreateOfferMutationVariables) => fetchVendure<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>(CreateOfferDocument, variables)(),
      options
    )};


useCreateOfferMutation.fetcher = (variables: Types.CreateOfferMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>(CreateOfferDocument, variables, options);

 const CreateWishlistDocument = `
    mutation CreateWishlist($name: String!, $description: String) {
  createWishlist(input: {name: $name, description: $description}) {
    id
    name
  }
}
    `;

export const useCreateWishlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateWishlistMutation, TError, Types.CreateWishlistMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateWishlistMutation, TError, Types.CreateWishlistMutationVariables, TContext>(
      ['CreateWishlist'],
      (variables?: Types.CreateWishlistMutationVariables) => fetchVendure<Types.CreateWishlistMutation, Types.CreateWishlistMutationVariables>(CreateWishlistDocument, variables)(),
      options
    )};


useCreateWishlistMutation.fetcher = (variables: Types.CreateWishlistMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateWishlistMutation, Types.CreateWishlistMutationVariables>(CreateWishlistDocument, variables, options);

 const DeleteOfferDocument = `
    mutation DeleteOffer($id: ID!) {
  deleteOffer(offerId: $id) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useDeleteOfferMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteOfferMutation, TError, Types.DeleteOfferMutationVariables, TContext>) => {
    
    return useMutation<Types.DeleteOfferMutation, TError, Types.DeleteOfferMutationVariables, TContext>(
      ['DeleteOffer'],
      (variables?: Types.DeleteOfferMutationVariables) => fetchVendure<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>(DeleteOfferDocument, variables)(),
      options
    )};


useDeleteOfferMutation.fetcher = (variables: Types.DeleteOfferMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.DeleteOfferMutation, Types.DeleteOfferMutationVariables>(DeleteOfferDocument, variables, options);

 const DeleteWishlistDocument = `
    mutation DeleteWishlist($id: ID!) {
  deleteWishlist(id: $id) {
    result
    message
  }
}
    `;

export const useDeleteWishlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteWishlistMutation, TError, Types.DeleteWishlistMutationVariables, TContext>) => {
    
    return useMutation<Types.DeleteWishlistMutation, TError, Types.DeleteWishlistMutationVariables, TContext>(
      ['DeleteWishlist'],
      (variables?: Types.DeleteWishlistMutationVariables) => fetchVendure<Types.DeleteWishlistMutation, Types.DeleteWishlistMutationVariables>(DeleteWishlistDocument, variables)(),
      options
    )};


useDeleteWishlistMutation.fetcher = (variables: Types.DeleteWishlistMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.DeleteWishlistMutation, Types.DeleteWishlistMutationVariables>(DeleteWishlistDocument, variables, options);

 const LoginUserDocument = `
    mutation LoginUser($username: String!, $password: String!, $rememberMe: Boolean!) {
  login(username: $username, password: $password, rememberMe: $rememberMe) {
    __typename
    ... on CurrentUser {
      id
      identifier
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on NotVerifiedError {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
  }
}
    `;

export const useLoginUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LoginUserMutation, TError, Types.LoginUserMutationVariables, TContext>) => {
    
    return useMutation<Types.LoginUserMutation, TError, Types.LoginUserMutationVariables, TContext>(
      ['LoginUser'],
      (variables?: Types.LoginUserMutationVariables) => fetchVendure<Types.LoginUserMutation, Types.LoginUserMutationVariables>(LoginUserDocument, variables)(),
      options
    )};


useLoginUserMutation.fetcher = (variables: Types.LoginUserMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.LoginUserMutation, Types.LoginUserMutationVariables>(LoginUserDocument, variables, options);

 const LogoutUserDocument = `
    mutation LogoutUser {
  logout {
    success
    __typename
  }
}
    `;

export const useLogoutUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.LogoutUserMutation, TError, Types.LogoutUserMutationVariables, TContext>) => {
    
    return useMutation<Types.LogoutUserMutation, TError, Types.LogoutUserMutationVariables, TContext>(
      ['LogoutUser'],
      (variables?: Types.LogoutUserMutationVariables) => fetchVendure<Types.LogoutUserMutation, Types.LogoutUserMutationVariables>(LogoutUserDocument, variables)(),
      options
    )};


useLogoutUserMutation.fetcher = (variables?: Types.LogoutUserMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.LogoutUserMutation, Types.LogoutUserMutationVariables>(LogoutUserDocument, variables, options);

 const SubscribeToNewsletterDocument = `
    mutation SubscribeToNewsletter($input: NewsletterAddressInput!) {
  subscribeToNewsletter(input: $input) {
    success
  }
}
    `;

export const useSubscribeToNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SubscribeToNewsletterMutation, TError, Types.SubscribeToNewsletterMutationVariables, TContext>) => {
    
    return useMutation<Types.SubscribeToNewsletterMutation, TError, Types.SubscribeToNewsletterMutationVariables, TContext>(
      ['SubscribeToNewsletter'],
      (variables?: Types.SubscribeToNewsletterMutationVariables) => fetchVendure<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>(SubscribeToNewsletterDocument, variables)(),
      options
    )};


useSubscribeToNewsletterMutation.fetcher = (variables: Types.SubscribeToNewsletterMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>(SubscribeToNewsletterDocument, variables, options);

 const UnsubscribeToNewsletterDocument = `
    mutation UnsubscribeToNewsletter($input: NewsletterAddressInput!) {
  unsubscribeToNewsletter(input: $input) {
    success
  }
}
    `;

export const useUnsubscribeToNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UnsubscribeToNewsletterMutation, TError, Types.UnsubscribeToNewsletterMutationVariables, TContext>) => {
    
    return useMutation<Types.UnsubscribeToNewsletterMutation, TError, Types.UnsubscribeToNewsletterMutationVariables, TContext>(
      ['UnsubscribeToNewsletter'],
      (variables?: Types.UnsubscribeToNewsletterMutationVariables) => fetchVendure<Types.UnsubscribeToNewsletterMutation, Types.UnsubscribeToNewsletterMutationVariables>(UnsubscribeToNewsletterDocument, variables)(),
      options
    )};


useUnsubscribeToNewsletterMutation.fetcher = (variables: Types.UnsubscribeToNewsletterMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UnsubscribeToNewsletterMutation, Types.UnsubscribeToNewsletterMutationVariables>(UnsubscribeToNewsletterDocument, variables, options);

 const CreateOfferPriceDocument = `
    mutation CreateOfferPrice($productVariantId: ID!, $offerPrice: Int!, $currencyCode: String!) {
  createOfferPrice(
    input: {productVariantId: $productVariantId, price: $offerPrice, currencyCode: $currencyCode}
  ) {
    ... on OfferPrice {
      id
      price
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
  }
}
    `;

export const useCreateOfferPriceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOfferPriceMutation, TError, Types.CreateOfferPriceMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateOfferPriceMutation, TError, Types.CreateOfferPriceMutationVariables, TContext>(
      ['CreateOfferPrice'],
      (variables?: Types.CreateOfferPriceMutationVariables) => fetchVendure<Types.CreateOfferPriceMutation, Types.CreateOfferPriceMutationVariables>(CreateOfferPriceDocument, variables)(),
      options
    )};


useCreateOfferPriceMutation.fetcher = (variables: Types.CreateOfferPriceMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateOfferPriceMutation, Types.CreateOfferPriceMutationVariables>(CreateOfferPriceDocument, variables, options);

 const UpdateOfferPriceDocument = `
    mutation UpdateOfferPrice($id: ID!, $price: Int!) {
  updateOfferPrice(input: {offerPriceId: $id, price: $price}) {
    __typename
    ... on OfferPrice {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useUpdateOfferPriceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOfferPriceMutation, TError, Types.UpdateOfferPriceMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateOfferPriceMutation, TError, Types.UpdateOfferPriceMutationVariables, TContext>(
      ['UpdateOfferPrice'],
      (variables?: Types.UpdateOfferPriceMutationVariables) => fetchVendure<Types.UpdateOfferPriceMutation, Types.UpdateOfferPriceMutationVariables>(UpdateOfferPriceDocument, variables)(),
      options
    )};


useUpdateOfferPriceMutation.fetcher = (variables: Types.UpdateOfferPriceMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateOfferPriceMutation, Types.UpdateOfferPriceMutationVariables>(UpdateOfferPriceDocument, variables, options);

 const DeleteOfferPriceDocument = `
    mutation DeleteOfferPrice($id: ID!) {
  deleteOfferPrice(offerPriceId: $id) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useDeleteOfferPriceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteOfferPriceMutation, TError, Types.DeleteOfferPriceMutationVariables, TContext>) => {
    
    return useMutation<Types.DeleteOfferPriceMutation, TError, Types.DeleteOfferPriceMutationVariables, TContext>(
      ['DeleteOfferPrice'],
      (variables?: Types.DeleteOfferPriceMutationVariables) => fetchVendure<Types.DeleteOfferPriceMutation, Types.DeleteOfferPriceMutationVariables>(DeleteOfferPriceDocument, variables)(),
      options
    )};


useDeleteOfferPriceMutation.fetcher = (variables: Types.DeleteOfferPriceMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.DeleteOfferPriceMutation, Types.DeleteOfferPriceMutationVariables>(DeleteOfferPriceDocument, variables, options);

 const CreateOfferPricesDocument = `
    mutation CreateOfferPrices($input: [CreateOfferPriceInput!]!) {
  createOfferPrices(input: $input) {
    __typename
    ... on OfferPriceList {
      totalItems
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useCreateOfferPricesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOfferPricesMutation, TError, Types.CreateOfferPricesMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateOfferPricesMutation, TError, Types.CreateOfferPricesMutationVariables, TContext>(
      ['CreateOfferPrices'],
      (variables?: Types.CreateOfferPricesMutationVariables) => fetchVendure<Types.CreateOfferPricesMutation, Types.CreateOfferPricesMutationVariables>(CreateOfferPricesDocument, variables)(),
      options
    )};


useCreateOfferPricesMutation.fetcher = (variables: Types.CreateOfferPricesMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.CreateOfferPricesMutation, Types.CreateOfferPricesMutationVariables>(CreateOfferPricesDocument, variables, options);

 const PasswordResetDocument = `
    mutation PasswordReset($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    ... on CurrentUser {
      __typename
      id
    }
    ... on ErrorResult {
      __typename
      errorCode
      message
    }
    ... on NotVerifiedError {
      __typename
      errorCode
      message
    }
    ... on PasswordResetTokenExpiredError {
      __typename
      errorCode
      message
    }
    ... on PasswordResetTokenInvalidError {
      __typename
      errorCode
      message
    }
    ... on PasswordValidationError {
      __typename
      errorCode
      message
      validationErrorMessage
    }
  }
}
    `;

export const usePasswordResetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.PasswordResetMutation, TError, Types.PasswordResetMutationVariables, TContext>) => {
    
    return useMutation<Types.PasswordResetMutation, TError, Types.PasswordResetMutationVariables, TContext>(
      ['PasswordReset'],
      (variables?: Types.PasswordResetMutationVariables) => fetchVendure<Types.PasswordResetMutation, Types.PasswordResetMutationVariables>(PasswordResetDocument, variables)(),
      options
    )};


usePasswordResetMutation.fetcher = (variables: Types.PasswordResetMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.PasswordResetMutation, Types.PasswordResetMutationVariables>(PasswordResetDocument, variables, options);

 const RegisterDocument = `
    mutation Register($input: B2BRegisterInput!) {
  registerB2BCustomer(input: $input) {
    __typename
    ... on Success {
      success
    }
    ... on MissingPasswordError {
      errorCode
      message
    }
    ... on PasswordValidationError {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on InvalidVatError {
      errorCode
      message
    }
  }
}
    `;

export const useRegisterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RegisterMutation, TError, Types.RegisterMutationVariables, TContext>) => {
    
    return useMutation<Types.RegisterMutation, TError, Types.RegisterMutationVariables, TContext>(
      ['Register'],
      (variables?: Types.RegisterMutationVariables) => fetchVendure<Types.RegisterMutation, Types.RegisterMutationVariables>(RegisterDocument, variables)(),
      options
    )};


useRegisterMutation.fetcher = (variables: Types.RegisterMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RegisterMutation, Types.RegisterMutationVariables>(RegisterDocument, variables, options);

 const RemoveAllOrderLinesDocument = `
    mutation RemoveAllOrderLines {
  removeAllOrderLines {
    __typename
    ... on Order {
      id
    }
    ... on OrderModificationError {
      message
    }
  }
}
    `;

export const useRemoveAllOrderLinesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveAllOrderLinesMutation, TError, Types.RemoveAllOrderLinesMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveAllOrderLinesMutation, TError, Types.RemoveAllOrderLinesMutationVariables, TContext>(
      ['RemoveAllOrderLines'],
      (variables?: Types.RemoveAllOrderLinesMutationVariables) => fetchVendure<Types.RemoveAllOrderLinesMutation, Types.RemoveAllOrderLinesMutationVariables>(RemoveAllOrderLinesDocument, variables)(),
      options
    )};


useRemoveAllOrderLinesMutation.fetcher = (variables?: Types.RemoveAllOrderLinesMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RemoveAllOrderLinesMutation, Types.RemoveAllOrderLinesMutationVariables>(RemoveAllOrderLinesDocument, variables, options);

 const RemoveAllOrderLinesAndStockMovementsDocument = `
    mutation removeAllOrderLinesAndStockMovements {
  removeAllOrderLinesAndStockMovements {
    __typename
    ... on Order {
      id
    }
    ... on OrderModificationError {
      errorCode
      message
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useRemoveAllOrderLinesAndStockMovementsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveAllOrderLinesAndStockMovementsMutation, TError, Types.RemoveAllOrderLinesAndStockMovementsMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveAllOrderLinesAndStockMovementsMutation, TError, Types.RemoveAllOrderLinesAndStockMovementsMutationVariables, TContext>(
      ['removeAllOrderLinesAndStockMovements'],
      (variables?: Types.RemoveAllOrderLinesAndStockMovementsMutationVariables) => fetchVendure<Types.RemoveAllOrderLinesAndStockMovementsMutation, Types.RemoveAllOrderLinesAndStockMovementsMutationVariables>(RemoveAllOrderLinesAndStockMovementsDocument, variables)(),
      options
    )};


useRemoveAllOrderLinesAndStockMovementsMutation.fetcher = (variables?: Types.RemoveAllOrderLinesAndStockMovementsMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RemoveAllOrderLinesAndStockMovementsMutation, Types.RemoveAllOrderLinesAndStockMovementsMutationVariables>(RemoveAllOrderLinesAndStockMovementsDocument, variables, options);

 const RemoveCouponCodeDocument = `
    mutation RemoveCouponCode($couponCode: String!) {
  removeCouponCode(couponCode: $couponCode) {
    code
    ... on Order {
      id
    }
  }
}
    `;

export const useRemoveCouponCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveCouponCodeMutation, TError, Types.RemoveCouponCodeMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveCouponCodeMutation, TError, Types.RemoveCouponCodeMutationVariables, TContext>(
      ['RemoveCouponCode'],
      (variables?: Types.RemoveCouponCodeMutationVariables) => fetchVendure<Types.RemoveCouponCodeMutation, Types.RemoveCouponCodeMutationVariables>(RemoveCouponCodeDocument, variables)(),
      options
    )};


useRemoveCouponCodeMutation.fetcher = (variables: Types.RemoveCouponCodeMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RemoveCouponCodeMutation, Types.RemoveCouponCodeMutationVariables>(RemoveCouponCodeDocument, variables, options);

 const RemoveFromWishlistDocument = `
    mutation RemoveFromWishlist($itemIds: [ID!]!, $wishlistId: ID!) {
  removeFromWishlist(itemIds: $itemIds, wishlistId: $wishlistId) {
    id
    name
  }
}
    `;

export const useRemoveFromWishlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveFromWishlistMutation, TError, Types.RemoveFromWishlistMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveFromWishlistMutation, TError, Types.RemoveFromWishlistMutationVariables, TContext>(
      ['RemoveFromWishlist'],
      (variables?: Types.RemoveFromWishlistMutationVariables) => fetchVendure<Types.RemoveFromWishlistMutation, Types.RemoveFromWishlistMutationVariables>(RemoveFromWishlistDocument, variables)(),
      options
    )};


useRemoveFromWishlistMutation.fetcher = (variables: Types.RemoveFromWishlistMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RemoveFromWishlistMutation, Types.RemoveFromWishlistMutationVariables>(RemoveFromWishlistDocument, variables, options);

 const RemoveItemFromOrderDocument = `
    mutation RemoveItemFromOrder($orderLineID: ID!) {
  removeOrderLine(orderLineId: $orderLineID) {
    ... on Order {
      id
    }
    ... on OrderModificationError {
      errorCode
      message
    }
  }
}
    `;

export const useRemoveItemFromOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveItemFromOrderMutation, TError, Types.RemoveItemFromOrderMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveItemFromOrderMutation, TError, Types.RemoveItemFromOrderMutationVariables, TContext>(
      ['RemoveItemFromOrder'],
      (variables?: Types.RemoveItemFromOrderMutationVariables) => fetchVendure<Types.RemoveItemFromOrderMutation, Types.RemoveItemFromOrderMutationVariables>(RemoveItemFromOrderDocument, variables)(),
      options
    )};


useRemoveItemFromOrderMutation.fetcher = (variables: Types.RemoveItemFromOrderMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RemoveItemFromOrderMutation, Types.RemoveItemFromOrderMutationVariables>(RemoveItemFromOrderDocument, variables, options);

 const RequestPasswordResetDocument = `
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(emailAddress: $email) {
    ... on Success {
      __typename
      success
    }
    ... on ErrorResult {
      __typename
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      __typename
      errorCode
      message
    }
  }
}
    `;

export const useRequestPasswordResetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RequestPasswordResetMutation, TError, Types.RequestPasswordResetMutationVariables, TContext>) => {
    
    return useMutation<Types.RequestPasswordResetMutation, TError, Types.RequestPasswordResetMutationVariables, TContext>(
      ['RequestPasswordReset'],
      (variables?: Types.RequestPasswordResetMutationVariables) => fetchVendure<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, variables)(),
      options
    )};


useRequestPasswordResetMutation.fetcher = (variables: Types.RequestPasswordResetMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RequestPasswordResetMutation, Types.RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, variables, options);

 const SetActiveCompanyDocument = `
    mutation SetActiveCompany($companyId: ID!) {
  setActiveCompany(companyId: $companyId) {
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Customer {
      id
      firstName
      lastName
    }
  }
}
    `;

export const useSetActiveCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetActiveCompanyMutation, TError, Types.SetActiveCompanyMutationVariables, TContext>) => {
    
    return useMutation<Types.SetActiveCompanyMutation, TError, Types.SetActiveCompanyMutationVariables, TContext>(
      ['SetActiveCompany'],
      (variables?: Types.SetActiveCompanyMutationVariables) => fetchVendure<Types.SetActiveCompanyMutation, Types.SetActiveCompanyMutationVariables>(SetActiveCompanyDocument, variables)(),
      options
    )};


useSetActiveCompanyMutation.fetcher = (variables: Types.SetActiveCompanyMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SetActiveCompanyMutation, Types.SetActiveCompanyMutationVariables>(SetActiveCompanyDocument, variables, options);

 const SetShippingAddressDocument = `
    mutation SetShippingAddress($input: CreateAddressInput!) {
  setOrderShippingAddress(input: $input) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;

export const useSetShippingAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetShippingAddressMutation, TError, Types.SetShippingAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.SetShippingAddressMutation, TError, Types.SetShippingAddressMutationVariables, TContext>(
      ['SetShippingAddress'],
      (variables?: Types.SetShippingAddressMutationVariables) => fetchVendure<Types.SetShippingAddressMutation, Types.SetShippingAddressMutationVariables>(SetShippingAddressDocument, variables)(),
      options
    )};


useSetShippingAddressMutation.fetcher = (variables: Types.SetShippingAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SetShippingAddressMutation, Types.SetShippingAddressMutationVariables>(SetShippingAddressDocument, variables, options);

 const SetBillingAddressDocument = `
    mutation SetBillingAddress($input: CreateAddressInput!) {
  setOrderBillingAddress(input: $input) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;

export const useSetBillingAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetBillingAddressMutation, TError, Types.SetBillingAddressMutationVariables, TContext>) => {
    
    return useMutation<Types.SetBillingAddressMutation, TError, Types.SetBillingAddressMutationVariables, TContext>(
      ['SetBillingAddress'],
      (variables?: Types.SetBillingAddressMutationVariables) => fetchVendure<Types.SetBillingAddressMutation, Types.SetBillingAddressMutationVariables>(SetBillingAddressDocument, variables)(),
      options
    )};


useSetBillingAddressMutation.fetcher = (variables: Types.SetBillingAddressMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SetBillingAddressMutation, Types.SetBillingAddressMutationVariables>(SetBillingAddressDocument, variables, options);

 const SetOrderCustomFieldsDocument = `
    mutation SetOrderCustomFields($comment: String, $reference: String, $commission: String) {
  setOrderCustomFields(
    input: {customFields: {comment: $comment, reference: $reference, commission: $commission}}
  ) {
    ... on Order {
      id
    }
    ... on NoActiveOrderError {
      errorCode
      message
    }
  }
}
    `;

export const useSetOrderCustomFieldsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetOrderCustomFieldsMutation, TError, Types.SetOrderCustomFieldsMutationVariables, TContext>) => {
    
    return useMutation<Types.SetOrderCustomFieldsMutation, TError, Types.SetOrderCustomFieldsMutationVariables, TContext>(
      ['SetOrderCustomFields'],
      (variables?: Types.SetOrderCustomFieldsMutationVariables) => fetchVendure<Types.SetOrderCustomFieldsMutation, Types.SetOrderCustomFieldsMutationVariables>(SetOrderCustomFieldsDocument, variables)(),
      options
    )};


useSetOrderCustomFieldsMutation.fetcher = (variables?: Types.SetOrderCustomFieldsMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SetOrderCustomFieldsMutation, Types.SetOrderCustomFieldsMutationVariables>(SetOrderCustomFieldsDocument, variables, options);

 const SetOrderShippingMethodDocument = `
    mutation SetOrderShippingMethod($shippingMethodId: [ID!]!) {
  setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;

export const useSetOrderShippingMethodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetOrderShippingMethodMutation, TError, Types.SetOrderShippingMethodMutationVariables, TContext>) => {
    
    return useMutation<Types.SetOrderShippingMethodMutation, TError, Types.SetOrderShippingMethodMutationVariables, TContext>(
      ['SetOrderShippingMethod'],
      (variables?: Types.SetOrderShippingMethodMutationVariables) => fetchVendure<Types.SetOrderShippingMethodMutation, Types.SetOrderShippingMethodMutationVariables>(SetOrderShippingMethodDocument, variables)(),
      options
    )};


useSetOrderShippingMethodMutation.fetcher = (variables: Types.SetOrderShippingMethodMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.SetOrderShippingMethodMutation, Types.SetOrderShippingMethodMutationVariables>(SetOrderShippingMethodDocument, variables, options);

 const ToogleCustomerSpecificPriceDocument = `
    mutation ToogleCustomerSpecificPrice {
  toggleCustomerSpecificPrices {
    ... on Order {
      id
      total
      lines {
        quantity
        unitPrice
        productVariant {
          price
          id
        }
      }
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useToogleCustomerSpecificPriceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ToogleCustomerSpecificPriceMutation, TError, Types.ToogleCustomerSpecificPriceMutationVariables, TContext>) => {
    
    return useMutation<Types.ToogleCustomerSpecificPriceMutation, TError, Types.ToogleCustomerSpecificPriceMutationVariables, TContext>(
      ['ToogleCustomerSpecificPrice'],
      (variables?: Types.ToogleCustomerSpecificPriceMutationVariables) => fetchVendure<Types.ToogleCustomerSpecificPriceMutation, Types.ToogleCustomerSpecificPriceMutationVariables>(ToogleCustomerSpecificPriceDocument, variables)(),
      options
    )};


useToogleCustomerSpecificPriceMutation.fetcher = (variables?: Types.ToogleCustomerSpecificPriceMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.ToogleCustomerSpecificPriceMutation, Types.ToogleCustomerSpecificPriceMutationVariables>(ToogleCustomerSpecificPriceDocument, variables, options);

 const TransformOfferToCartDocument = `
    mutation transformOfferToCart($offerId: ID!) {
  transformOfferToCart(offerId: $offerId) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useTransformOfferToCartMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.TransformOfferToCartMutation, TError, Types.TransformOfferToCartMutationVariables, TContext>) => {
    
    return useMutation<Types.TransformOfferToCartMutation, TError, Types.TransformOfferToCartMutationVariables, TContext>(
      ['transformOfferToCart'],
      (variables?: Types.TransformOfferToCartMutationVariables) => fetchVendure<Types.TransformOfferToCartMutation, Types.TransformOfferToCartMutationVariables>(TransformOfferToCartDocument, variables)(),
      options
    )};


useTransformOfferToCartMutation.fetcher = (variables: Types.TransformOfferToCartMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.TransformOfferToCartMutation, Types.TransformOfferToCartMutationVariables>(TransformOfferToCartDocument, variables, options);

 const TransitionOrderDocument = `
    mutation TransitionOrder($state: String!) {
  transitionOrderToState(state: $state) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
    ... on OrderStateTransitionError {
      errorCode
      message
      transitionError
      fromState
      toState
    }
  }
}
    ${ActiveOrderFragmentDoc}`;

export const useTransitionOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.TransitionOrderMutation, TError, Types.TransitionOrderMutationVariables, TContext>) => {
    
    return useMutation<Types.TransitionOrderMutation, TError, Types.TransitionOrderMutationVariables, TContext>(
      ['TransitionOrder'],
      (variables?: Types.TransitionOrderMutationVariables) => fetchVendure<Types.TransitionOrderMutation, Types.TransitionOrderMutationVariables>(TransitionOrderDocument, variables)(),
      options
    )};


useTransitionOrderMutation.fetcher = (variables: Types.TransitionOrderMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.TransitionOrderMutation, Types.TransitionOrderMutationVariables>(TransitionOrderDocument, variables, options);

 const UpdateCustomerDocument = `
    mutation UpdateCustomer($title: String, $firstName: String!, $lastName: String!, $phoneNumber: String, $salutation: String) {
  updateCustomer(
    input: {title: $title, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, customFields: {salutation: $salutation}}
  ) {
    id
  }
}
    `;

export const useUpdateCustomerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerMutation, TError, Types.UpdateCustomerMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateCustomerMutation, TError, Types.UpdateCustomerMutationVariables, TContext>(
      ['UpdateCustomer'],
      (variables?: Types.UpdateCustomerMutationVariables) => fetchVendure<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>(UpdateCustomerDocument, variables)(),
      options
    )};


useUpdateCustomerMutation.fetcher = (variables: Types.UpdateCustomerMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>(UpdateCustomerDocument, variables, options);

 const UpdateCustomerPasswordDocument = `
    mutation UpdateCustomerPassword($currentPassword: String!, $newPassword: String!) {
  updateCustomerPassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    __typename
    ... on Success {
      success
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on PasswordValidationError {
      errorCode
      message
    }
  }
}
    `;

export const useUpdateCustomerPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCustomerPasswordMutation, TError, Types.UpdateCustomerPasswordMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateCustomerPasswordMutation, TError, Types.UpdateCustomerPasswordMutationVariables, TContext>(
      ['UpdateCustomerPassword'],
      (variables?: Types.UpdateCustomerPasswordMutationVariables) => fetchVendure<Types.UpdateCustomerPasswordMutation, Types.UpdateCustomerPasswordMutationVariables>(UpdateCustomerPasswordDocument, variables)(),
      options
    )};


useUpdateCustomerPasswordMutation.fetcher = (variables: Types.UpdateCustomerPasswordMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateCustomerPasswordMutation, Types.UpdateCustomerPasswordMutationVariables>(UpdateCustomerPasswordDocument, variables, options);

 const RequestUpdateCustomerEmailDocument = `
    mutation RequestUpdateCustomerEmail($password: String!, $newEmailAddress: String!) {
  requestUpdateCustomerEmailAddress(
    password: $password
    newEmailAddress: $newEmailAddress
  ) {
    __typename
    ... on Success {
      success
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on EmailAddressConflictError {
      errorCode
      message
    }
  }
}
    `;

export const useRequestUpdateCustomerEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RequestUpdateCustomerEmailMutation, TError, Types.RequestUpdateCustomerEmailMutationVariables, TContext>) => {
    
    return useMutation<Types.RequestUpdateCustomerEmailMutation, TError, Types.RequestUpdateCustomerEmailMutationVariables, TContext>(
      ['RequestUpdateCustomerEmail'],
      (variables?: Types.RequestUpdateCustomerEmailMutationVariables) => fetchVendure<Types.RequestUpdateCustomerEmailMutation, Types.RequestUpdateCustomerEmailMutationVariables>(RequestUpdateCustomerEmailDocument, variables)(),
      options
    )};


useRequestUpdateCustomerEmailMutation.fetcher = (variables: Types.RequestUpdateCustomerEmailMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.RequestUpdateCustomerEmailMutation, Types.RequestUpdateCustomerEmailMutationVariables>(RequestUpdateCustomerEmailDocument, variables, options);

 const UpdateOfferDocument = `
    mutation UpdateOffer($id: ID!, $address: String, $subject: String, $headerText: String, $footerText: String, $reference: String, $positions: [CreateOfferPositionInput!], $termsOfDelivery: String, $termsOfPayment: String, $date: DateTime, $number: String, $status: OfferStatus, $additionalDiscount: Int) {
  updateOffer(
    input: {id: $id, address: $address, subject: $subject, headerText: $headerText, footerText: $footerText, reference: $reference, positions: $positions, termsOfPayment: $termsOfPayment, termsOfDelivery: $termsOfDelivery, date: $date, number: $number, status: $status, additionalDiscount: $additionalDiscount}
  ) {
    __typename
    ... on Offer {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useUpdateOfferMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOfferMutation, TError, Types.UpdateOfferMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateOfferMutation, TError, Types.UpdateOfferMutationVariables, TContext>(
      ['UpdateOffer'],
      (variables?: Types.UpdateOfferMutationVariables) => fetchVendure<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>(UpdateOfferDocument, variables)(),
      options
    )};


useUpdateOfferMutation.fetcher = (variables: Types.UpdateOfferMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateOfferMutation, Types.UpdateOfferMutationVariables>(UpdateOfferDocument, variables, options);

 const UpdateOfferSettingsDocument = `
    mutation UpdateOfferSettings($color: String, $vat: String, $iban: String, $accountNumber: String, $bankName: String, $bankCode: String, $address: String, $email: String, $phoneNumber: String, $companyRegister: String, $logo: String) {
  updateOfferSettings(
    input: {color: $color, vat: $vat, iban: $iban, accountNumber: $accountNumber, bankName: $bankName, bankCode: $bankCode, address: $address, email: $email, phoneNumber: $phoneNumber, companyRegister: $companyRegister, logo: $logo}
  ) {
    __typename
    ... on OfferSettings {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;

export const useUpdateOfferSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOfferSettingsMutation, TError, Types.UpdateOfferSettingsMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateOfferSettingsMutation, TError, Types.UpdateOfferSettingsMutationVariables, TContext>(
      ['UpdateOfferSettings'],
      (variables?: Types.UpdateOfferSettingsMutationVariables) => fetchVendure<Types.UpdateOfferSettingsMutation, Types.UpdateOfferSettingsMutationVariables>(UpdateOfferSettingsDocument, variables)(),
      options
    )};


useUpdateOfferSettingsMutation.fetcher = (variables?: Types.UpdateOfferSettingsMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateOfferSettingsMutation, Types.UpdateOfferSettingsMutationVariables>(UpdateOfferSettingsDocument, variables, options);

 const UpdateWishlistDocument = `
    mutation UpdateWishlist($id: ID!, $name: String!, $description: String) {
  updateWishlist(input: {id: $id, name: $name, description: $description}) {
    id
  }
}
    `;

export const useUpdateWishlistMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateWishlistMutation, TError, Types.UpdateWishlistMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateWishlistMutation, TError, Types.UpdateWishlistMutationVariables, TContext>(
      ['UpdateWishlist'],
      (variables?: Types.UpdateWishlistMutationVariables) => fetchVendure<Types.UpdateWishlistMutation, Types.UpdateWishlistMutationVariables>(UpdateWishlistDocument, variables)(),
      options
    )};


useUpdateWishlistMutation.fetcher = (variables: Types.UpdateWishlistMutationVariables, options?: RequestInit['headers']) => fetchVendure<Types.UpdateWishlistMutation, Types.UpdateWishlistMutationVariables>(UpdateWishlistDocument, variables, options);

 const ActiveChannelDocument = `
    query ActiveChannel {
  activeChannel {
    id
    code
    currencyCode
    pricesIncludeTax
    token
    defaultLanguageCode
    defaultShippingZone {
      id
      name
      members {
        id
        name
      }
    }
  }
}
    `;

export const useActiveChannelQuery = <
      TData = Types.ActiveChannelQuery,
      TError = unknown
    >(
      variables?: Types.ActiveChannelQueryVariables,
      options?: UseQueryOptions<Types.ActiveChannelQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveChannelQuery, TError, TData>(
      variables === undefined ? ['ActiveChannel'] : ['ActiveChannel', variables],
      fetchVendure<Types.ActiveChannelQuery, Types.ActiveChannelQueryVariables>(ActiveChannelDocument, variables),
      options
    )};

useActiveChannelQuery.getKey = (variables?: Types.ActiveChannelQueryVariables) => variables === undefined ? ['ActiveChannel'] : ['ActiveChannel', variables];


useActiveChannelQuery.fetcher = (variables?: Types.ActiveChannelQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveChannelQuery, Types.ActiveChannelQueryVariables>(ActiveChannelDocument, variables, options);

 const ActiveCompanyDocument = `
    query ActiveCompany {
  activeCompany {
    id
    name
    customFields {
      erpId
      vat
      ust
      isConfiguratorAdmin
    }
    termsOfPayment {
      paymentId
      contactPerson
      text
      amount
    }
    discounts {
      id
      discountType
      discountGroup
      discountType2
      customerDiscountPercentages {
        id
        value
        index
      }
    }
  }
}
    `;

export const useActiveCompanyQuery = <
      TData = Types.ActiveCompanyQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCompanyQueryVariables,
      options?: UseQueryOptions<Types.ActiveCompanyQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCompanyQuery, TError, TData>(
      variables === undefined ? ['ActiveCompany'] : ['ActiveCompany', variables],
      fetchVendure<Types.ActiveCompanyQuery, Types.ActiveCompanyQueryVariables>(ActiveCompanyDocument, variables),
      options
    )};

useActiveCompanyQuery.getKey = (variables?: Types.ActiveCompanyQueryVariables) => variables === undefined ? ['ActiveCompany'] : ['ActiveCompany', variables];


useActiveCompanyQuery.fetcher = (variables?: Types.ActiveCompanyQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCompanyQuery, Types.ActiveCompanyQueryVariables>(ActiveCompanyDocument, variables, options);

 const ActiveCompanyAddressesDocument = `
    query ActiveCompanyAddresses {
  activeCompany {
    addresses {
      ...CompanyAddress
    }
  }
}
    ${CompanyAddressFragmentDoc}`;

export const useActiveCompanyAddressesQuery = <
      TData = Types.ActiveCompanyAddressesQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCompanyAddressesQueryVariables,
      options?: UseQueryOptions<Types.ActiveCompanyAddressesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCompanyAddressesQuery, TError, TData>(
      variables === undefined ? ['ActiveCompanyAddresses'] : ['ActiveCompanyAddresses', variables],
      fetchVendure<Types.ActiveCompanyAddressesQuery, Types.ActiveCompanyAddressesQueryVariables>(ActiveCompanyAddressesDocument, variables),
      options
    )};

useActiveCompanyAddressesQuery.getKey = (variables?: Types.ActiveCompanyAddressesQueryVariables) => variables === undefined ? ['ActiveCompanyAddresses'] : ['ActiveCompanyAddresses', variables];


useActiveCompanyAddressesQuery.fetcher = (variables?: Types.ActiveCompanyAddressesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCompanyAddressesQuery, Types.ActiveCompanyAddressesQueryVariables>(ActiveCompanyAddressesDocument, variables, options);

 const ContactPersonIdDocument = `
    query ContactPersonId {
  activeCompany {
    termsOfPayment {
      contactPerson
    }
  }
}
    `;

export const useContactPersonIdQuery = <
      TData = Types.ContactPersonIdQuery,
      TError = unknown
    >(
      variables?: Types.ContactPersonIdQueryVariables,
      options?: UseQueryOptions<Types.ContactPersonIdQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ContactPersonIdQuery, TError, TData>(
      variables === undefined ? ['ContactPersonId'] : ['ContactPersonId', variables],
      fetchVendure<Types.ContactPersonIdQuery, Types.ContactPersonIdQueryVariables>(ContactPersonIdDocument, variables),
      options
    )};

useContactPersonIdQuery.getKey = (variables?: Types.ContactPersonIdQueryVariables) => variables === undefined ? ['ContactPersonId'] : ['ContactPersonId', variables];


useContactPersonIdQuery.fetcher = (variables?: Types.ContactPersonIdQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ContactPersonIdQuery, Types.ContactPersonIdQueryVariables>(ContactPersonIdDocument, variables, options);

 const ActiveCustomerDocument = `
    query ActiveCustomer($withCustomFields: Boolean = false) {
  activeCustomer {
    ...CustomerData
  }
}
    ${CustomerDataFragmentDoc}`;

export const useActiveCustomerQuery = <
      TData = Types.ActiveCustomerQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCustomerQueryVariables,
      options?: UseQueryOptions<Types.ActiveCustomerQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCustomerQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables],
      fetchVendure<Types.ActiveCustomerQuery, Types.ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables),
      options
    )};

useActiveCustomerQuery.getKey = (variables?: Types.ActiveCustomerQueryVariables) => variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables];


useActiveCustomerQuery.fetcher = (variables?: Types.ActiveCustomerQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCustomerQuery, Types.ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables, options);

 const ActiveCustomerRolesDocument = `
    query ActiveCustomerRoles {
  activeCustomer {
    groups {
      id
      name
    }
    customFields {
      useCSP
    }
  }
}
    `;

export const useActiveCustomerRolesQuery = <
      TData = Types.ActiveCustomerRolesQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCustomerRolesQueryVariables,
      options?: UseQueryOptions<Types.ActiveCustomerRolesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCustomerRolesQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomerRoles'] : ['ActiveCustomerRoles', variables],
      fetchVendure<Types.ActiveCustomerRolesQuery, Types.ActiveCustomerRolesQueryVariables>(ActiveCustomerRolesDocument, variables),
      options
    )};

useActiveCustomerRolesQuery.getKey = (variables?: Types.ActiveCustomerRolesQueryVariables) => variables === undefined ? ['ActiveCustomerRoles'] : ['ActiveCustomerRoles', variables];


useActiveCustomerRolesQuery.fetcher = (variables?: Types.ActiveCustomerRolesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCustomerRolesQuery, Types.ActiveCustomerRolesQueryVariables>(ActiveCustomerRolesDocument, variables, options);

 const ActiveCustomerForCheckoutDocument = `
    query ActiveCustomerForCheckout {
  activeCustomer {
    firstName
    lastName
    addresses {
      ...Address
    }
  }
}
    ${AddressFragmentDoc}
${CountryFragmentDoc}`;

export const useActiveCustomerForCheckoutQuery = <
      TData = Types.ActiveCustomerForCheckoutQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCustomerForCheckoutQueryVariables,
      options?: UseQueryOptions<Types.ActiveCustomerForCheckoutQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCustomerForCheckoutQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomerForCheckout'] : ['ActiveCustomerForCheckout', variables],
      fetchVendure<Types.ActiveCustomerForCheckoutQuery, Types.ActiveCustomerForCheckoutQueryVariables>(ActiveCustomerForCheckoutDocument, variables),
      options
    )};

useActiveCustomerForCheckoutQuery.getKey = (variables?: Types.ActiveCustomerForCheckoutQueryVariables) => variables === undefined ? ['ActiveCustomerForCheckout'] : ['ActiveCustomerForCheckout', variables];


useActiveCustomerForCheckoutQuery.fetcher = (variables?: Types.ActiveCustomerForCheckoutQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCustomerForCheckoutQuery, Types.ActiveCustomerForCheckoutQueryVariables>(ActiveCustomerForCheckoutDocument, variables, options);

 const ActiveCustomerOrdersDocument = `
    query ActiveCustomerOrders {
  activeCustomer {
    orders {
      items {
        id
        code
        state
        total
        currencyCode
        orderPlacedAt
        customFields {
          erpId
        }
      }
    }
  }
}
    `;

export const useActiveCustomerOrdersQuery = <
      TData = Types.ActiveCustomerOrdersQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCustomerOrdersQueryVariables,
      options?: UseQueryOptions<Types.ActiveCustomerOrdersQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCustomerOrdersQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomerOrders'] : ['ActiveCustomerOrders', variables],
      fetchVendure<Types.ActiveCustomerOrdersQuery, Types.ActiveCustomerOrdersQueryVariables>(ActiveCustomerOrdersDocument, variables),
      options
    )};

useActiveCustomerOrdersQuery.getKey = (variables?: Types.ActiveCustomerOrdersQueryVariables) => variables === undefined ? ['ActiveCustomerOrders'] : ['ActiveCustomerOrders', variables];


useActiveCustomerOrdersQuery.fetcher = (variables?: Types.ActiveCustomerOrdersQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveCustomerOrdersQuery, Types.ActiveCustomerOrdersQueryVariables>(ActiveCustomerOrdersDocument, variables, options);

 const ActiveOrderCartDocument = `
    query ActiveOrderCart {
  activeOrder {
    state
    subTotal
    shipping
    total
    totalWithTax
    currencyCode
    subTotalWithTax
    shippingWithTax
    totalQuantity
    promotions {
      id
      name
      couponCode
    }
    couponCodes
    discounts {
      amount
      description
    }
    taxSummary {
      taxRate
      taxBase
      taxTotal
    }
    lines {
      id
      discountedLinePrice
      productVariant {
        currencyCode
        price
        assets {
          source
        }
        facetValues {
          id
          name
          facet {
            name
          }
        }
        options {
          code
          group {
            name
          }
        }
        name
        sku
        product {
          name
          slug
        }
      }
      unitPrice
      linePrice
      quantity
    }
  }
}
    `;

export const useActiveOrderCartQuery = <
      TData = Types.ActiveOrderCartQuery,
      TError = unknown
    >(
      variables?: Types.ActiveOrderCartQueryVariables,
      options?: UseQueryOptions<Types.ActiveOrderCartQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveOrderCartQuery, TError, TData>(
      variables === undefined ? ['ActiveOrderCart'] : ['ActiveOrderCart', variables],
      fetchVendure<Types.ActiveOrderCartQuery, Types.ActiveOrderCartQueryVariables>(ActiveOrderCartDocument, variables),
      options
    )};

useActiveOrderCartQuery.getKey = (variables?: Types.ActiveOrderCartQueryVariables) => variables === undefined ? ['ActiveOrderCart'] : ['ActiveOrderCart', variables];


useActiveOrderCartQuery.fetcher = (variables?: Types.ActiveOrderCartQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveOrderCartQuery, Types.ActiveOrderCartQueryVariables>(ActiveOrderCartDocument, variables, options);

 const ActiveOrderCheckoutDocument = `
    query ActiveOrderCheckout {
  activeOrder {
    id
    state
    updatedAt
    createdAt
    total
    totalWithTax
    subTotal
    subTotalWithTax
    shipping
    currencyCode
    shippingWithTax
    totalQuantity
    promotions {
      id
      name
      couponCode
    }
    couponCodes
    discounts {
      amount
      description
    }
    taxSummary {
      taxRate
      taxBase
      taxTotal
    }
    shippingAddress {
      ...OrderAddress
    }
    billingAddress {
      ...OrderAddress
    }
    shippingLines {
      shippingMethod {
        id
        languageCode
        code
        name
        description
      }
    }
    lines {
      id
      linePrice
      discountedLinePrice
      unitPrice
      quantity
      productVariant {
        currencyCode
        id
        name
        sku
        price
        product {
          id
          name
          slug
        }
        facetValues {
          id
          name
          facet {
            name
          }
        }
        sku
        assets {
          id
          source
        }
      }
    }
  }
}
    ${OrderAddressFragmentDoc}`;

export const useActiveOrderCheckoutQuery = <
      TData = Types.ActiveOrderCheckoutQuery,
      TError = unknown
    >(
      variables?: Types.ActiveOrderCheckoutQueryVariables,
      options?: UseQueryOptions<Types.ActiveOrderCheckoutQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveOrderCheckoutQuery, TError, TData>(
      variables === undefined ? ['ActiveOrderCheckout'] : ['ActiveOrderCheckout', variables],
      fetchVendure<Types.ActiveOrderCheckoutQuery, Types.ActiveOrderCheckoutQueryVariables>(ActiveOrderCheckoutDocument, variables),
      options
    )};

useActiveOrderCheckoutQuery.getKey = (variables?: Types.ActiveOrderCheckoutQueryVariables) => variables === undefined ? ['ActiveOrderCheckout'] : ['ActiveOrderCheckout', variables];


useActiveOrderCheckoutQuery.fetcher = (variables?: Types.ActiveOrderCheckoutQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveOrderCheckoutQuery, Types.ActiveOrderCheckoutQueryVariables>(ActiveOrderCheckoutDocument, variables, options);

 const ActiveOrderNavigationDocument = `
    query ActiveOrderNavigation {
  activeOrder {
    lines {
      quantity
    }
  }
}
    `;

export const useActiveOrderNavigationQuery = <
      TData = Types.ActiveOrderNavigationQuery,
      TError = unknown
    >(
      variables?: Types.ActiveOrderNavigationQueryVariables,
      options?: UseQueryOptions<Types.ActiveOrderNavigationQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveOrderNavigationQuery, TError, TData>(
      variables === undefined ? ['ActiveOrderNavigation'] : ['ActiveOrderNavigation', variables],
      fetchVendure<Types.ActiveOrderNavigationQuery, Types.ActiveOrderNavigationQueryVariables>(ActiveOrderNavigationDocument, variables),
      options
    )};

useActiveOrderNavigationQuery.getKey = (variables?: Types.ActiveOrderNavigationQueryVariables) => variables === undefined ? ['ActiveOrderNavigation'] : ['ActiveOrderNavigation', variables];


useActiveOrderNavigationQuery.fetcher = (variables?: Types.ActiveOrderNavigationQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveOrderNavigationQuery, Types.ActiveOrderNavigationQueryVariables>(ActiveOrderNavigationDocument, variables, options);

 const ActiveOrderOfferDocument = `
    query ActiveOrderOffer {
  activeOrder {
    id
  }
}
    `;

export const useActiveOrderOfferQuery = <
      TData = Types.ActiveOrderOfferQuery,
      TError = unknown
    >(
      variables?: Types.ActiveOrderOfferQueryVariables,
      options?: UseQueryOptions<Types.ActiveOrderOfferQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveOrderOfferQuery, TError, TData>(
      variables === undefined ? ['ActiveOrderOffer'] : ['ActiveOrderOffer', variables],
      fetchVendure<Types.ActiveOrderOfferQuery, Types.ActiveOrderOfferQueryVariables>(ActiveOrderOfferDocument, variables),
      options
    )};

useActiveOrderOfferQuery.getKey = (variables?: Types.ActiveOrderOfferQueryVariables) => variables === undefined ? ['ActiveOrderOffer'] : ['ActiveOrderOffer', variables];


useActiveOrderOfferQuery.fetcher = (variables?: Types.ActiveOrderOfferQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveOrderOfferQuery, Types.ActiveOrderOfferQueryVariables>(ActiveOrderOfferDocument, variables, options);

 const ActiveUserWishlistDocument = `
    query ActiveUserWishlist($id: ID!) {
  activeUserWishlist(id: $id) {
    id
    itemCount
    name
    createdAt
    items {
      id
      product {
        id
        name
        slug
        featuredAsset {
          source
        }
      }
      productVariant {
        id
        sku
        name
        price
        priceWithTax
        stockLevel
      }
      purchased
      lastPurchasedAt
    }
    description
    isPublic
    publicCode
  }
}
    `;

export const useActiveUserWishlistQuery = <
      TData = Types.ActiveUserWishlistQuery,
      TError = unknown
    >(
      variables: Types.ActiveUserWishlistQueryVariables,
      options?: UseQueryOptions<Types.ActiveUserWishlistQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveUserWishlistQuery, TError, TData>(
      ['ActiveUserWishlist', variables],
      fetchVendure<Types.ActiveUserWishlistQuery, Types.ActiveUserWishlistQueryVariables>(ActiveUserWishlistDocument, variables),
      options
    )};

useActiveUserWishlistQuery.getKey = (variables: Types.ActiveUserWishlistQueryVariables) => ['ActiveUserWishlist', variables];


useActiveUserWishlistQuery.fetcher = (variables: Types.ActiveUserWishlistQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveUserWishlistQuery, Types.ActiveUserWishlistQueryVariables>(ActiveUserWishlistDocument, variables, options);

 const ActiveUserWishlistsDocument = `
    query ActiveUserWishlists {
  activeUserWishlists {
    id
    itemCount
    name
    createdAt
    items {
      id
      product {
        id
        name
        slug
      }
      productVariant {
        id
        sku
        name
        price
        priceWithTax
        stockLevel
      }
      purchased
      lastPurchasedAt
    }
    description
    isPublic
    publicCode
  }
}
    `;

export const useActiveUserWishlistsQuery = <
      TData = Types.ActiveUserWishlistsQuery,
      TError = unknown
    >(
      variables?: Types.ActiveUserWishlistsQueryVariables,
      options?: UseQueryOptions<Types.ActiveUserWishlistsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveUserWishlistsQuery, TError, TData>(
      variables === undefined ? ['ActiveUserWishlists'] : ['ActiveUserWishlists', variables],
      fetchVendure<Types.ActiveUserWishlistsQuery, Types.ActiveUserWishlistsQueryVariables>(ActiveUserWishlistsDocument, variables),
      options
    )};

useActiveUserWishlistsQuery.getKey = (variables?: Types.ActiveUserWishlistsQueryVariables) => variables === undefined ? ['ActiveUserWishlists'] : ['ActiveUserWishlists', variables];


useActiveUserWishlistsQuery.fetcher = (variables?: Types.ActiveUserWishlistsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ActiveUserWishlistsQuery, Types.ActiveUserWishlistsQueryVariables>(ActiveUserWishlistsDocument, variables, options);

 const AllCollectionsDocument = `
    query AllCollections {
  collections {
    items {
      breadcrumbs {
        ...Breadcrumb
      }
      slug
    }
  }
}
    ${BreadcrumbFragmentDoc}`;

export const useAllCollectionsQuery = <
      TData = Types.AllCollectionsQuery,
      TError = unknown
    >(
      variables?: Types.AllCollectionsQueryVariables,
      options?: UseQueryOptions<Types.AllCollectionsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.AllCollectionsQuery, TError, TData>(
      variables === undefined ? ['AllCollections'] : ['AllCollections', variables],
      fetchVendure<Types.AllCollectionsQuery, Types.AllCollectionsQueryVariables>(AllCollectionsDocument, variables),
      options
    )};

useAllCollectionsQuery.getKey = (variables?: Types.AllCollectionsQueryVariables) => variables === undefined ? ['AllCollections'] : ['AllCollections', variables];


useAllCollectionsQuery.fetcher = (variables?: Types.AllCollectionsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.AllCollectionsQuery, Types.AllCollectionsQueryVariables>(AllCollectionsDocument, variables, options);

 const AvailableCompaniesDocument = `
    query AvailableCompanies {
  companies {
    items {
      id
      name
      customFields {
        erpId
        vat
        ust
      }
      addresses {
        fullName
        streetLine1
      }
    }
  }
}
    `;

export const useAvailableCompaniesQuery = <
      TData = Types.AvailableCompaniesQuery,
      TError = unknown
    >(
      variables?: Types.AvailableCompaniesQueryVariables,
      options?: UseQueryOptions<Types.AvailableCompaniesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.AvailableCompaniesQuery, TError, TData>(
      variables === undefined ? ['AvailableCompanies'] : ['AvailableCompanies', variables],
      fetchVendure<Types.AvailableCompaniesQuery, Types.AvailableCompaniesQueryVariables>(AvailableCompaniesDocument, variables),
      options
    )};

useAvailableCompaniesQuery.getKey = (variables?: Types.AvailableCompaniesQueryVariables) => variables === undefined ? ['AvailableCompanies'] : ['AvailableCompanies', variables];


useAvailableCompaniesQuery.fetcher = (variables?: Types.AvailableCompaniesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.AvailableCompaniesQuery, Types.AvailableCompaniesQueryVariables>(AvailableCompaniesDocument, variables, options);

 const AvailableCountriesDocument = `
    query AvailableCountries {
  availableCountries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

export const useAvailableCountriesQuery = <
      TData = Types.AvailableCountriesQuery,
      TError = unknown
    >(
      variables?: Types.AvailableCountriesQueryVariables,
      options?: UseQueryOptions<Types.AvailableCountriesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.AvailableCountriesQuery, TError, TData>(
      variables === undefined ? ['AvailableCountries'] : ['AvailableCountries', variables],
      fetchVendure<Types.AvailableCountriesQuery, Types.AvailableCountriesQueryVariables>(AvailableCountriesDocument, variables),
      options
    )};

useAvailableCountriesQuery.getKey = (variables?: Types.AvailableCountriesQueryVariables) => variables === undefined ? ['AvailableCountries'] : ['AvailableCountries', variables];


useAvailableCountriesQuery.fetcher = (variables?: Types.AvailableCountriesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.AvailableCountriesQuery, Types.AvailableCountriesQueryVariables>(AvailableCountriesDocument, variables, options);

 const CollectionDocument = `
    query Collection($slug: String) {
  collection(slug: $slug) {
    id
    slug
    customFields {
      seoDescription
    }
    breadcrumbs {
      ...Breadcrumb
    }
    name
    description
    assets {
      ...Asset
    }
    customFields {
      hasDetailPage
    }
  }
}
    ${BreadcrumbFragmentDoc}
${AssetFragmentDoc}`;

export const useCollectionQuery = <
      TData = Types.CollectionQuery,
      TError = unknown
    >(
      variables?: Types.CollectionQueryVariables,
      options?: UseQueryOptions<Types.CollectionQuery, TError, TData>
    ) => {
    
    return useQuery<Types.CollectionQuery, TError, TData>(
      variables === undefined ? ['Collection'] : ['Collection', variables],
      fetchVendure<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, variables),
      options
    )};

useCollectionQuery.getKey = (variables?: Types.CollectionQueryVariables) => variables === undefined ? ['Collection'] : ['Collection', variables];


useCollectionQuery.fetcher = (variables?: Types.CollectionQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, variables, options);

 const CustomerSpecificPriceDocument = `
    query CustomerSpecificPrice($channelCode: String!, $variantId: String!) {
  customerSpecificPrice(channelCode: $channelCode, variantId: $variantId) {
    basePrice
    price
  }
}
    `;

export const useCustomerSpecificPriceQuery = <
      TData = Types.CustomerSpecificPriceQuery,
      TError = unknown
    >(
      variables: Types.CustomerSpecificPriceQueryVariables,
      options?: UseQueryOptions<Types.CustomerSpecificPriceQuery, TError, TData>
    ) => {
    
    return useQuery<Types.CustomerSpecificPriceQuery, TError, TData>(
      ['CustomerSpecificPrice', variables],
      fetchVendure<Types.CustomerSpecificPriceQuery, Types.CustomerSpecificPriceQueryVariables>(CustomerSpecificPriceDocument, variables),
      options
    )};

useCustomerSpecificPriceQuery.getKey = (variables: Types.CustomerSpecificPriceQueryVariables) => ['CustomerSpecificPrice', variables];


useCustomerSpecificPriceQuery.fetcher = (variables: Types.CustomerSpecificPriceQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.CustomerSpecificPriceQuery, Types.CustomerSpecificPriceQueryVariables>(CustomerSpecificPriceDocument, variables, options);

 const EligiblePaymentMethodsDocument = `
    query EligiblePaymentMethods {
  eligiblePaymentMethods {
    id
    code
    name
    description
    isEligible
    eligibilityMessage
    customFields
  }
}
    `;

export const useEligiblePaymentMethodsQuery = <
      TData = Types.EligiblePaymentMethodsQuery,
      TError = unknown
    >(
      variables?: Types.EligiblePaymentMethodsQueryVariables,
      options?: UseQueryOptions<Types.EligiblePaymentMethodsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.EligiblePaymentMethodsQuery, TError, TData>(
      variables === undefined ? ['EligiblePaymentMethods'] : ['EligiblePaymentMethods', variables],
      fetchVendure<Types.EligiblePaymentMethodsQuery, Types.EligiblePaymentMethodsQueryVariables>(EligiblePaymentMethodsDocument, variables),
      options
    )};

useEligiblePaymentMethodsQuery.getKey = (variables?: Types.EligiblePaymentMethodsQueryVariables) => variables === undefined ? ['EligiblePaymentMethods'] : ['EligiblePaymentMethods', variables];


useEligiblePaymentMethodsQuery.fetcher = (variables?: Types.EligiblePaymentMethodsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.EligiblePaymentMethodsQuery, Types.EligiblePaymentMethodsQueryVariables>(EligiblePaymentMethodsDocument, variables, options);

 const EligibleShippingMethodsDocument = `
    query EligibleShippingMethods {
  eligibleShippingMethods {
    id
    price
    priceWithTax
    code
    name
    description
    metadata
    customFields
  }
}
    `;

export const useEligibleShippingMethodsQuery = <
      TData = Types.EligibleShippingMethodsQuery,
      TError = unknown
    >(
      variables?: Types.EligibleShippingMethodsQueryVariables,
      options?: UseQueryOptions<Types.EligibleShippingMethodsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.EligibleShippingMethodsQuery, TError, TData>(
      variables === undefined ? ['EligibleShippingMethods'] : ['EligibleShippingMethods', variables],
      fetchVendure<Types.EligibleShippingMethodsQuery, Types.EligibleShippingMethodsQueryVariables>(EligibleShippingMethodsDocument, variables),
      options
    )};

useEligibleShippingMethodsQuery.getKey = (variables?: Types.EligibleShippingMethodsQueryVariables) => variables === undefined ? ['EligibleShippingMethods'] : ['EligibleShippingMethods', variables];


useEligibleShippingMethodsQuery.fetcher = (variables?: Types.EligibleShippingMethodsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.EligibleShippingMethodsQuery, Types.EligibleShippingMethodsQueryVariables>(EligibleShippingMethodsDocument, variables, options);

 const NextOrderStatesDocument = `
    query NextOrderStates {
  nextOrderStates
}
    `;

export const useNextOrderStatesQuery = <
      TData = Types.NextOrderStatesQuery,
      TError = unknown
    >(
      variables?: Types.NextOrderStatesQueryVariables,
      options?: UseQueryOptions<Types.NextOrderStatesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.NextOrderStatesQuery, TError, TData>(
      variables === undefined ? ['NextOrderStates'] : ['NextOrderStates', variables],
      fetchVendure<Types.NextOrderStatesQuery, Types.NextOrderStatesQueryVariables>(NextOrderStatesDocument, variables),
      options
    )};

useNextOrderStatesQuery.getKey = (variables?: Types.NextOrderStatesQueryVariables) => variables === undefined ? ['NextOrderStates'] : ['NextOrderStates', variables];


useNextOrderStatesQuery.fetcher = (variables?: Types.NextOrderStatesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.NextOrderStatesQuery, Types.NextOrderStatesQueryVariables>(NextOrderStatesDocument, variables, options);

 const OfferByIdDocument = `
    query OfferById($offerId: ID!) {
  offerById(offerId: $offerId) {
    id
    number
    date
    address
    subject
    headerText
    footerText
    termsOfDelivery
    termsOfPayment
    reference
    status
    additionalDiscount
    customOfferLines {
      id
      name
      price
      quantity
      taxRate
      discount
      positionTotal
      alternative
      productVariant {
        price
      }
    }
    company {
      id
      name
      customFields {
        erpId
        vat
        ust
      }
      addresses {
        streetLine1
        streetLine2
        province
        countryCode
        city
        postalCode
        phoneNumber
        defaultBillingAddress
      }
    }
    order {
      id
      totalWithTax
      lines {
        id
        customFields {
          offerOrderLine {
            id
            price
            quantity
            taxRate
            discount
            name
            alternative
            positionTotal
            productVariant {
              id
              price
              sku
              product {
                description
              }
              options {
                id
                name
                code
                group {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

export const useOfferByIdQuery = <
      TData = Types.OfferByIdQuery,
      TError = unknown
    >(
      variables: Types.OfferByIdQueryVariables,
      options?: UseQueryOptions<Types.OfferByIdQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OfferByIdQuery, TError, TData>(
      ['OfferById', variables],
      fetchVendure<Types.OfferByIdQuery, Types.OfferByIdQueryVariables>(OfferByIdDocument, variables),
      options
    )};

useOfferByIdQuery.getKey = (variables: Types.OfferByIdQueryVariables) => ['OfferById', variables];


useOfferByIdQuery.fetcher = (variables: Types.OfferByIdQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OfferByIdQuery, Types.OfferByIdQueryVariables>(OfferByIdDocument, variables, options);

 const OfferPositionVariantsDocument = `
    query OfferPositionVariants($skus: [String!]!) {
  variants(skus: $skus) {
    id
    name
    sku
    price
    options {
      id
      code
      name
      group {
        name
      }
    }
  }
}
    `;

export const useOfferPositionVariantsQuery = <
      TData = Types.OfferPositionVariantsQuery,
      TError = unknown
    >(
      variables: Types.OfferPositionVariantsQueryVariables,
      options?: UseQueryOptions<Types.OfferPositionVariantsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OfferPositionVariantsQuery, TError, TData>(
      ['OfferPositionVariants', variables],
      fetchVendure<Types.OfferPositionVariantsQuery, Types.OfferPositionVariantsQueryVariables>(OfferPositionVariantsDocument, variables),
      options
    )};

useOfferPositionVariantsQuery.getKey = (variables: Types.OfferPositionVariantsQueryVariables) => ['OfferPositionVariants', variables];


useOfferPositionVariantsQuery.fetcher = (variables: Types.OfferPositionVariantsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OfferPositionVariantsQuery, Types.OfferPositionVariantsQueryVariables>(OfferPositionVariantsDocument, variables, options);

 const OfferPriceDocument = `
    query offerPrice($productVariantId: ID!) {
  offerPrices(productVariantId: $productVariantId) {
    items {
      id
      price
      currencyCode
    }
  }
}
    `;

export const useOfferPriceQuery = <
      TData = Types.OfferPriceQuery,
      TError = unknown
    >(
      variables: Types.OfferPriceQueryVariables,
      options?: UseQueryOptions<Types.OfferPriceQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OfferPriceQuery, TError, TData>(
      ['offerPrice', variables],
      fetchVendure<Types.OfferPriceQuery, Types.OfferPriceQueryVariables>(OfferPriceDocument, variables),
      options
    )};

useOfferPriceQuery.getKey = (variables: Types.OfferPriceQueryVariables) => ['offerPrice', variables];


useOfferPriceQuery.fetcher = (variables: Types.OfferPriceQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OfferPriceQuery, Types.OfferPriceQueryVariables>(OfferPriceDocument, variables, options);

 const OfferPricesDocument = `
    query OfferPrices($take: Int, $skip: Int) {
  offerPrices(options: {take: $take, skip: $skip}) {
    totalItems
    items {
      ...OfferPrice
    }
  }
}
    ${OfferPriceFragmentDoc}
${AssetFragmentDoc}`;

export const useOfferPricesQuery = <
      TData = Types.OfferPricesQuery,
      TError = unknown
    >(
      variables?: Types.OfferPricesQueryVariables,
      options?: UseQueryOptions<Types.OfferPricesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OfferPricesQuery, TError, TData>(
      variables === undefined ? ['OfferPrices'] : ['OfferPrices', variables],
      fetchVendure<Types.OfferPricesQuery, Types.OfferPricesQueryVariables>(OfferPricesDocument, variables),
      options
    )};

useOfferPricesQuery.getKey = (variables?: Types.OfferPricesQueryVariables) => variables === undefined ? ['OfferPrices'] : ['OfferPrices', variables];


useOfferPricesQuery.fetcher = (variables?: Types.OfferPricesQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OfferPricesQuery, Types.OfferPricesQueryVariables>(OfferPricesDocument, variables, options);

 const OfferSettingsDocument = `
    query OfferSettings {
  offerSettings {
    id
    logo
    color
    vat
    iban
    accountNumber
    bankName
    bankCode
    address
    phoneNumber
    email
    companyRegister
  }
}
    `;

export const useOfferSettingsQuery = <
      TData = Types.OfferSettingsQuery,
      TError = unknown
    >(
      variables?: Types.OfferSettingsQueryVariables,
      options?: UseQueryOptions<Types.OfferSettingsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OfferSettingsQuery, TError, TData>(
      variables === undefined ? ['OfferSettings'] : ['OfferSettings', variables],
      fetchVendure<Types.OfferSettingsQuery, Types.OfferSettingsQueryVariables>(OfferSettingsDocument, variables),
      options
    )};

useOfferSettingsQuery.getKey = (variables?: Types.OfferSettingsQueryVariables) => variables === undefined ? ['OfferSettings'] : ['OfferSettings', variables];


useOfferSettingsQuery.fetcher = (variables?: Types.OfferSettingsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OfferSettingsQuery, Types.OfferSettingsQueryVariables>(OfferSettingsDocument, variables, options);

 const LatestOfferDocument = `
    query LatestOffer {
  offers(options: {sort: {number: DESC}, take: 1, skip: 0}) {
    id
    reference
    status
    createdAt
    number
    date
    address
    subject
    headerText
    footerText
    termsOfDelivery
    termsOfPayment
    order {
      lines {
        customFields {
          offerOrderLine {
            id
          }
        }
      }
    }
  }
}
    `;

export const useLatestOfferQuery = <
      TData = Types.LatestOfferQuery,
      TError = unknown
    >(
      variables?: Types.LatestOfferQueryVariables,
      options?: UseQueryOptions<Types.LatestOfferQuery, TError, TData>
    ) => {
    
    return useQuery<Types.LatestOfferQuery, TError, TData>(
      variables === undefined ? ['LatestOffer'] : ['LatestOffer', variables],
      fetchVendure<Types.LatestOfferQuery, Types.LatestOfferQueryVariables>(LatestOfferDocument, variables),
      options
    )};

useLatestOfferQuery.getKey = (variables?: Types.LatestOfferQueryVariables) => variables === undefined ? ['LatestOffer'] : ['LatestOffer', variables];


useLatestOfferQuery.fetcher = (variables?: Types.LatestOfferQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.LatestOfferQuery, Types.LatestOfferQueryVariables>(LatestOfferDocument, variables, options);

 const OffersDocument = `
    query Offers($filter: OfferFilterOptions, $sort: OfferSortOptions, $take: Int, $skip: Int) {
  offers(options: {filter: $filter, sort: $sort, take: $take, skip: $skip}) {
    ...OfferFragment
  }
}
    ${OfferFragmentFragmentDoc}`;

export const useOffersQuery = <
      TData = Types.OffersQuery,
      TError = unknown
    >(
      variables?: Types.OffersQueryVariables,
      options?: UseQueryOptions<Types.OffersQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OffersQuery, TError, TData>(
      variables === undefined ? ['Offers'] : ['Offers', variables],
      fetchVendure<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, variables),
      options
    )};

useOffersQuery.getKey = (variables?: Types.OffersQueryVariables) => variables === undefined ? ['Offers'] : ['Offers', variables];


useOffersQuery.fetcher = (variables?: Types.OffersQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, variables, options);

 const OrderDocument = `
    query Order($code: String!) {
  orderByCode(code: $code) {
    id
    customer {
      id
      firstName
      lastName
      phoneNumber
    }
    code
    state
    active
    currencyCode
    shipping
    shippingAddress {
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
    billingAddress {
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
    shippingLines {
      shippingMethod {
        id
        languageCode
        code
        name
        description
      }
    }
    lines {
      id
      linePrice
      discountedLinePrice
      unitPrice
      quantity
      productVariant {
        currencyCode
        id
        name
        sku
        price
        product {
          id
          name
          slug
        }
        assets {
          id
          source
        }
      }
    }
    payments {
      method
    }
  }
}
    `;

export const useOrderQuery = <
      TData = Types.OrderQuery,
      TError = unknown
    >(
      variables: Types.OrderQueryVariables,
      options?: UseQueryOptions<Types.OrderQuery, TError, TData>
    ) => {
    
    return useQuery<Types.OrderQuery, TError, TData>(
      ['Order', variables],
      fetchVendure<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, variables),
      options
    )};

useOrderQuery.getKey = (variables: Types.OrderQueryVariables) => ['Order', variables];


useOrderQuery.fetcher = (variables: Types.OrderQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.OrderQuery, Types.OrderQueryVariables>(OrderDocument, variables, options);

 const PriceDocument = `
    query price($variantId: Int!) {
  pimcoreObjects(ids: [$variantId]) {
    productVariants {
      price
    }
  }
}
    `;

export const usePriceQuery = <
      TData = Types.PriceQuery,
      TError = unknown
    >(
      variables: Types.PriceQueryVariables,
      options?: UseQueryOptions<Types.PriceQuery, TError, TData>
    ) => {
    
    return useQuery<Types.PriceQuery, TError, TData>(
      ['price', variables],
      fetchVendure<Types.PriceQuery, Types.PriceQueryVariables>(PriceDocument, variables),
      options
    )};

usePriceQuery.getKey = (variables: Types.PriceQueryVariables) => ['price', variables];


usePriceQuery.fetcher = (variables: Types.PriceQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.PriceQuery, Types.PriceQueryVariables>(PriceDocument, variables, options);

 const ProductDocument = `
    query Product($slug: String, $withCustomFields: Boolean = false, $isPreRender: Boolean = true) {
  product(slug: $slug) {
    id
    name
    description
    customFields {
      productDetailName
    }
    assets {
      ...Asset
    }
    collections {
      id
      breadcrumbs {
        ...Breadcrumb
      }
    }
    variants {
      ...ProductVariant
    }
    customFields {
      downloads {
        id
        fileName
        fileSize
        path
      }
    }
  }
}
    ${AssetFragmentDoc}
${BreadcrumbFragmentDoc}
${ProductVariantFragmentDoc}
${ProductVariantCustomFieldFragmentDoc}`;

export const useProductQuery = <
      TData = Types.ProductQuery,
      TError = unknown
    >(
      variables?: Types.ProductQueryVariables,
      options?: UseQueryOptions<Types.ProductQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ProductQuery, TError, TData>(
      variables === undefined ? ['Product'] : ['Product', variables],
      fetchVendure<Types.ProductQuery, Types.ProductQueryVariables>(ProductDocument, variables),
      options
    )};

useProductQuery.getKey = (variables?: Types.ProductQueryVariables) => variables === undefined ? ['Product'] : ['Product', variables];


useProductQuery.fetcher = (variables?: Types.ProductQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ProductQuery, Types.ProductQueryVariables>(ProductDocument, variables, options);

 const ProductsDocument = `
    query Products($skip: Int) {
  products(options: {take: 20, skip: $skip}) {
    items {
      slug
      variants {
        id
      }
    }
  }
}
    `;

export const useProductsQuery = <
      TData = Types.ProductsQuery,
      TError = unknown
    >(
      variables?: Types.ProductsQueryVariables,
      options?: UseQueryOptions<Types.ProductsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ProductsQuery, TError, TData>(
      variables === undefined ? ['Products'] : ['Products', variables],
      fetchVendure<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, variables),
      options
    )};

useProductsQuery.getKey = (variables?: Types.ProductsQueryVariables) => variables === undefined ? ['Products'] : ['Products', variables];


useProductsQuery.fetcher = (variables?: Types.ProductsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, variables, options);

 const SearchDocument = `
    query Search($input: String!, $prefixMode: Boolean = true) {
  search(input: {term: $input, prefixMode: $prefixMode}) {
    items {
      id
      price {
        ... on SinglePrice {
          value
        }
      }
      productVariantAsset {
        preview
      }
      sku
      slug
      productId
      productName
      productVariantId
      productVariantName
      description
      customMappings {
        systemLine
        systemName
      }
    }
  }
}
    `;

export const useSearchQuery = <
      TData = Types.SearchQuery,
      TError = unknown
    >(
      variables: Types.SearchQueryVariables,
      options?: UseQueryOptions<Types.SearchQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SearchQuery, TError, TData>(
      ['Search', variables],
      fetchVendure<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, variables),
      options
    )};

useSearchQuery.getKey = (variables: Types.SearchQueryVariables) => ['Search', variables];


useSearchQuery.fetcher = (variables: Types.SearchQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.SearchQuery, Types.SearchQueryVariables>(SearchDocument, variables, options);

 const VariantDocument = `
    query Variant($id: ID!, $withCustomFields: Boolean = false, $isPreRender: Boolean = true) {
  variant(variantId: $id) {
    ...ProductVariant
  }
}
    ${ProductVariantFragmentDoc}
${ProductVariantCustomFieldFragmentDoc}
${AssetFragmentDoc}`;

export const useVariantQuery = <
      TData = Types.VariantQuery,
      TError = unknown
    >(
      variables: Types.VariantQueryVariables,
      options?: UseQueryOptions<Types.VariantQuery, TError, TData>
    ) => {
    
    return useQuery<Types.VariantQuery, TError, TData>(
      ['Variant', variables],
      fetchVendure<Types.VariantQuery, Types.VariantQueryVariables>(VariantDocument, variables),
      options
    )};

useVariantQuery.getKey = (variables: Types.VariantQueryVariables) => ['Variant', variables];


useVariantQuery.fetcher = (variables: Types.VariantQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.VariantQuery, Types.VariantQueryVariables>(VariantDocument, variables, options);

 const VariantDetailsDocument = `
    query VariantDetails($id: ID!, $includeImages: Boolean = false, $includeSystemInformation: Boolean = false, $includeDownloads: Boolean = false, $includeAdditionalProducts: Boolean = false) {
  variant(variantId: $id) {
    customFields {
      onSiteImages @include(if: $includeImages) {
        name
        source
      }
      onSiteImages @include(if: $includeImages) {
        name
        source
      }
      systemInformationItems @include(if: $includeSystemInformation) {
        itemText
        itemHeadline
      }
      downloads @include(if: $includeDownloads) {
        id
        fileName
        fileSize
        path
      }
      additionalProducts @include(if: $includeAdditionalProducts) {
        id
        slug
        featuredAsset {
          source
        }
        name
      }
    }
  }
}
    `;

export const useVariantDetailsQuery = <
      TData = Types.VariantDetailsQuery,
      TError = unknown
    >(
      variables: Types.VariantDetailsQueryVariables,
      options?: UseQueryOptions<Types.VariantDetailsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.VariantDetailsQuery, TError, TData>(
      ['VariantDetails', variables],
      fetchVendure<Types.VariantDetailsQuery, Types.VariantDetailsQueryVariables>(VariantDetailsDocument, variables),
      options
    )};

useVariantDetailsQuery.getKey = (variables: Types.VariantDetailsQueryVariables) => ['VariantDetails', variables];


useVariantDetailsQuery.fetcher = (variables: Types.VariantDetailsQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.VariantDetailsQuery, Types.VariantDetailsQueryVariables>(VariantDetailsDocument, variables, options);

 const VariantVendureIdDocument = `
    query variantVendureId($variantId: Int!) {
  pimcoreObjects(ids: [$variantId]) {
    productVariants {
      id
    }
  }
}
    `;

export const useVariantVendureIdQuery = <
      TData = Types.VariantVendureIdQuery,
      TError = unknown
    >(
      variables: Types.VariantVendureIdQueryVariables,
      options?: UseQueryOptions<Types.VariantVendureIdQuery, TError, TData>
    ) => {
    
    return useQuery<Types.VariantVendureIdQuery, TError, TData>(
      ['variantVendureId', variables],
      fetchVendure<Types.VariantVendureIdQuery, Types.VariantVendureIdQueryVariables>(VariantVendureIdDocument, variables),
      options
    )};

useVariantVendureIdQuery.getKey = (variables: Types.VariantVendureIdQueryVariables) => ['variantVendureId', variables];


useVariantVendureIdQuery.fetcher = (variables: Types.VariantVendureIdQueryVariables, options?: RequestInit['headers']) => fetchVendure<Types.VariantVendureIdQuery, Types.VariantVendureIdQueryVariables>(VariantVendureIdDocument, variables, options);
