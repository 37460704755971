import { Box, Button, Center, Flex, HStack, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, Spinner, Stack } from '@chakra-ui/react'
import { FiRsAngleLeft, type IHeading, type IImage } from '@stocker/ui-components/design-system'
import { CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import type { ProductCategoryEdge } from '@stocker/codegen/pimcore'
import type { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

interface ISystemFinderSelectionPage {
  heading: IHeading
  generalInfoText: string
  cardData: Array<{
    id: string
    heading: IHeading
    image: IImage
    infoText: string
  }>
  currentStep: number
  setStep: Dispatch<SetStateAction<number>>
  setSystemFinderStarted: Dispatch<SetStateAction<boolean>>
  setStepValue: Dispatch<{
    step: number
    name: string
    id: string
  }>
  alreadySelectedValue?: string
  allCurrentPossibleSystem: Array<ProductCategoryEdge | null> | []
}

export const SystemFinderSelectionPage: React.FC<ISystemFinderSelectionPage> = ({ allCurrentPossibleSystem, alreadySelectedValue, setStepValue, setSystemFinderStarted, heading, generalInfoText, cardData, currentStep, setStep }) => {
  const { formatMessage } = useIntl()

  const possibleSystemsIds = allCurrentPossibleSystem.map(e => e?.node?.systemFinderFilters?.map(e => e?.id))
  const currentStepValuesIds = cardData.map(e => e.id)

  // here we map over all possibleSystemFilters and check if the currentValueIds are included if its not included that means this option would return no systems
  const optionsResultInSystem = currentStepValuesIds.map((id, index) => !!possibleSystemsIds.map(e => e?.includes(id)).includes(true))
  const amountOfEnabledResults = cardData.filter((card, index) => {
    return optionsResultInSystem[index]
  })

  if (amountOfEnabledResults.length === 1) {
    const card = amountOfEnabledResults[0]
    setStepValue({ step: currentStep, name: String(card.heading.text), id: card.id })
    setStep(currentStep + 1)
  }

  if (amountOfEnabledResults.length <= 1) {
    return (
      <Center h="700px">
        <Spinner size="xl"/>
      </Center>
    )
  }

  return (
    <Box w="100%" py={{ base: 4, xl: 6 }}>
      <CustomHeading fontSize={{ base: '3xl', xl: '5xl' }} pb={{ base: 4, xl: 8 }} heading={heading}/>
      {/* Desktop */}
      <HStack spacing={12} align="flex-start" display={{ base: 'none', xl: 'flex' }}>
        <Stack justify="space-between" w="400px" h="400px" bg="primaryBackground.50" p="8">
          {asComponent(generalInfoText)}
          <Button
            variant="outline"
            colorScheme="secondaryText"
            w="100%"
            iconSpacing={3}
            alignSelf="center"
            leftIcon={<FiRsAngleLeft/>}
            // if the back button is pressed on the first configuration step set the started state back to false
            onClick={() => { currentStep === 0 ? setSystemFinderStarted(false) : setStep(currentStep - 1) }}
          >
            {formatMessage({ id: 'system-finder--previous-step' })}
          </Button>
        </Stack>
        {cardData.length <= 2 && (
          <SimpleGrid flex={1} columns={2} gap={6}>
            {cardData.map((card, index) => {
              if (!optionsResultInSystem[index]) {
                return (
                  <Popover key={String(card.heading.text) + String(card.infoText)} placement="top">
                    <PopoverTrigger>
                      <Stack
                        border="2px"
                        borderColor="gray.200"
                        cursor="not-allowed"
                        opacity={0.5}
                      >
                        <Box pos="relative" minH="400px" minW="400px" bg="white">
                          <NextImage
                            src={`${card.image.fullpath ?? ''}?width=600`}
                            fill
                            sizes="100vw"
                            style={{
                              objectFit: 'contain',
                            }}
                            alt="System Finder Image"
                          />
                        </Box>
                        <Stack p="6">
                          <CustomHeading fontSize="xl" fontFamily="primary" heading={card.heading}/>
                          <Box lineHeight={1.2} >
                            {asComponent(card.infoText)}
                          </Box>
                        </Stack>
                      </Stack>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow/>
                      <PopoverCloseButton/>
                      <PopoverHeader fontFamily="primary">Keine Systeme vorhanden mit dieser Option</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                )
              }
              return (
                <Stack
                  key={String(card.heading.text) + String(card.infoText)}
                  onClick={() => { setStepValue({ step: currentStep, name: String(card.heading.text), id: card.id }); setStep(currentStep + 1) }}
                  cursor="pointer"
                  _hover={{ transform: 'scale(1.02)' }}
                  transition="0.3s"
                  border="2px"
                  borderColor={alreadySelectedValue === String(card.heading.text) ? 'accent.200' : 'gray.200'}
                >
                  <Box pos="relative" minH="400px" minW="400px" bg="white">
                    <NextImage
                      src={`${card.image.fullpath ?? ''}?width=600`}
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: 'contain',
                      }}
                      alt="System Finder Image"
                    />
                  </Box>
                  <Stack p="6">
                    <CustomHeading fontSize="xl" fontFamily="primary" heading={card.heading}/>
                    <Box lineHeight={1.2}>
                      {asComponent(card.infoText)}
                    </Box>
                  </Stack>
                </Stack>
              )
            },
            )}
          </SimpleGrid>
        )}
        {cardData.length > 2 && (
          <SimpleGrid flex={1} columns={2} gap={6}>
            {cardData.map((card, index) => {
              if (!optionsResultInSystem[index]) {
                return (
                  <Popover key={String(card.heading.text) + String(card.infoText)} placement="top">
                    <PopoverTrigger>
                      <Flex
                        key={String(card.heading.text) + String(card.infoText)}
                        border="2px"
                        borderColor={alreadySelectedValue === String(card.heading.text) ? 'accent.200' : 'gray.200'}
                        cursor="not-allowed"
                        opacity={0.5}
                      >
                        <Box pos="relative" minH="200px" minW="200px" bg="white">
                          <NextImage
                            src={`${card.image.fullpath ?? ''}?width=600`}
                            fill
                            sizes="100vw"
                            style={{
                              objectFit: 'contain',
                            }}
                            alt="System Finder Image"
                          />
                        </Box>
                        <Stack p="6">
                          <CustomHeading fontSize="xl" fontFamily="primary" heading={card.heading}/>
                          <Box lineHeight={1.2}>
                            {asComponent(card.infoText)}
                          </Box>
                        </Stack>
                      </Flex>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow/>
                      <PopoverCloseButton/>
                      <PopoverHeader fontFamily="primary">Keine Systeme vorhanden mit dieser Option</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                )
              }
              return (
                <Flex
                  key={String(card.heading.text) + String(card.infoText)}
                  onClick={() => { setStepValue({ step: currentStep, name: String(card.heading.text), id: card.id }); setStep(currentStep + 1) }}
                  cursor="pointer"
                  _hover={{ transform: 'scale(1.02)' }}
                  transition="0.3s"
                  border="2px"
                  borderColor={alreadySelectedValue === String(card.heading.text) ? 'accent.200' : 'gray.200'}
                >
                  <Box pos="relative" minH="200px" minW="200px" bg="white">
                    <NextImage
                      src={`${card.image.fullpath ?? ''}?width=600`}
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: 'contain',
                      }}
                      alt="System Finder Image"
                    />
                  </Box>
                  <Stack p="6">
                    <CustomHeading fontSize="xl" fontFamily="primary" heading={card.heading}/>
                    <Box lineHeight={1.2}>
                      {asComponent(card.infoText)}
                    </Box>
                  </Stack>
                </Flex>
              )
            },
            )}
          </SimpleGrid>
        )}
      </HStack>
      {/* Mobile */}
      <HStack align="flex-start" display={{ base: 'initial', xl: 'none' }}>
        <Stack spacing={5}>
          <Box w="100%" bg="primaryBackground.50" p="6">
            {asComponent(generalInfoText)}
          </Box>
          <SimpleGrid flex={1} columns={{ base: 1, md: 2 }} gap={6}>
            {cardData.map((card, index) => {
              if (!optionsResultInSystem[index]) {
                return (
                  <Popover key={String(card.heading.text) + String(card.infoText)} placement="top">
                    <PopoverTrigger>
                      <Flex
                        key={String(card.heading.text) + String(card.infoText)}
                        border="2px"
                        borderColor={alreadySelectedValue === String(card.heading.text) ? 'accent.200' : 'gray.200'}
                        cursor="not-allowed"
                        opacity={0.5}
                      >
                        <Box pos="relative" minH="100px" minW="100px" bg="primaryBackground.50">
                          <NextImage
                            src={`${card.image.fullpath ?? ''}?width=600`}
                            fill
                            sizes="100vw"
                            style={{
                              objectFit: 'contain',
                            }}
                            alt="System Finder Image"
                          />
                        </Box>
                        <Stack p="6">
                          <CustomHeading fontSize="lg" fontFamily="primary" heading={card.heading}/>
                          <Box lineHeight={1.2}>
                            {asComponent(card.infoText)}
                          </Box>
                        </Stack>
                      </Flex>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow/>
                      <PopoverCloseButton/>
                      <PopoverHeader fontFamily="primary">Keine Systeme vorhanden mit dieser Option</PopoverHeader>
                    </PopoverContent>
                  </Popover>
                )
              }
              return (
                <Flex
                  key={String(card.heading.text) + String(card.infoText)}
                  onClick={() => { setStepValue({ step: currentStep, name: String(card.heading.text), id: card.id }); setStep(currentStep + 1) }}
                  cursor="pointer"
                  _hover={{ transform: 'scale(1.02)' }}
                  transition="0.3s"
                  border="2px"
                  borderColor={alreadySelectedValue === String(card.heading.text) ? 'accent.200' : 'gray.200'}
                >
                  <Box pos="relative" minH="100px" minW="100px" bg="primaryBackground.50">
                    <NextImage
                      src={`${card.image.fullpath ?? ''}?width=600`}
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: 'contain',
                      }}
                      alt="System Finder Image"
                    />
                  </Box>
                  <Stack p="6">
                    <CustomHeading fontSize="lg" fontFamily="primary" heading={card.heading}/>
                    <Box lineHeight={1.2} fontSize="sm">
                      {asComponent(card.infoText)}
                    </Box>
                  </Stack>
                </Flex>
              )
            },
            )}
          </SimpleGrid>
          <Button
            variant="outline"
            colorScheme="secondaryText"
            w="100%"
            alignSelf="center"
            leftIcon={<FiRsAngleLeft/>}
            iconSpacing={3}
            // if the back button is pressed on the first configuration step set the started state back to false
            onClick={() => { currentStep === 0 ? setSystemFinderStarted(false) : setStep(currentStep - 1) }}
          >
            {formatMessage({ id: 'system-finder--previous-step' })}
          </Button>
        </Stack>
      </HStack>
    </Box>
  )
}
