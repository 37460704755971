import { defineStyleConfig } from '@chakra-ui/react'

const FilterPanel = defineStyleConfig({
  baseStyle: {
    borderRadius: '0px',
  },
})

export const customComponents = {
  FilterPanel,
}
