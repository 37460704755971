import { Heading, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IShippingAndPaymentAddressProps {
  shipping: {
    title: string
    company?: string | null
    name?: string | null
    streetLine1: string
    streetLine2?: string | null
    city: string
    postalCode: string
    country: string
  }
  payment: {
    title: string
    company?: string | null
    name?: string | null
    streetLine1: string
    streetLine2?: string | null
    city: string
    postalCode: string
    country: string
    uid?: string
  }
  isLoaded?: boolean
}

export const ShippingAndPaymentAddress: React.FC<IShippingAndPaymentAddressProps> = ({ isLoaded = true, shipping, payment }) => {
  const intl = useIntl()
  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={5}>
      <Stack>
        <Skeleton isLoaded={isLoaded} w="fit-content">
          <Heading as="h4" fontSize="lg">{payment.title}</Heading>
        </Skeleton>
        <Stack spacing="0">
          {(payment.name ?? payment.company) && <Text>{payment.name}{payment.name && payment.company && ' / '}{payment.company}</Text>}
          <Text>{payment.streetLine1} </Text>
          {payment.streetLine2 && <Text>{payment.streetLine2}</Text>}
          <Text>{payment.postalCode} {payment.city}</Text>
          <Text>{payment.country}</Text>
        </Stack>
        {payment.uid && <Text>{intl.formatMessage({ id: '--uid' })}: {payment.uid}</Text>}
      </Stack>
      <Stack>
        <Skeleton isLoaded={isLoaded} w="fit-content">
          <Heading as="h4" fontSize="lg">{shipping.title}</Heading>
        </Skeleton>
        <Stack spacing="0">
          {(shipping.name ?? shipping.company) && <Text>{shipping.name}{shipping.name && shipping.company && ' / '}{shipping.company}</Text>}
          <Text>{shipping.streetLine1} </Text>
          {shipping.streetLine2 && <Text>{shipping.streetLine2}</Text>}
          <Text>{shipping.postalCode} {shipping.city}</Text>
          <Text>{shipping.country}</Text>
        </Stack>
      </Stack>
    </SimpleGrid>
  )
}
