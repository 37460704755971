import { Box, Divider, Grid, GridItem, Skeleton, Stack } from '@chakra-ui/react'
import type React from 'react'
import { useIntl } from 'react-intl'
import type { ICartProductItemProps } from '../CartProductItem/CartProductItem'
import { CartProductItem } from '../CartProductItem/CartProductItem'

export interface ICartProductListingProps {
  articles: ICartProductItemProps[]
  minimalVariant?: boolean
  alwaysMobile?: boolean
  isLoaded?: boolean
  noTotalPrice?: boolean
}

export const CartProductListing: React.FC<ICartProductListingProps> = ({ noTotalPrice, alwaysMobile, isLoaded = true, articles, minimalVariant = false }) => {
  const intl = useIntl()
  return (
    <Box>
      <Grid py="15px" templateColumns={(minimalVariant || noTotalPrice) ? 'repeat(11, 1fr)' : 'repeat(13, 1fr)'} gap={4} display={alwaysMobile ? 'none' : { base: 'none', lg: 'grid' }}>
        <GridItem colSpan={5} fontWeight="bold">
          <Skeleton isLoaded={isLoaded} w="fit-content">
            {intl.formatMessage({ id: '--article-info' })}
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2} alignSelf="center" fontWeight="bold">
          <Skeleton isLoaded={isLoaded} w="fit-content">
            {intl.formatMessage({ id: '--single-price' })}
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2} alignSelf="center" fontWeight="bold">
          <Skeleton isLoaded={isLoaded} w="fit-content">
            {intl.formatMessage({ id: '--amount' })}
          </Skeleton>
        </GridItem>
        {!noTotalPrice && (
          <GridItem colSpan={2} alignSelf="center" fontWeight="bold">
            <Skeleton isLoaded={isLoaded} w="fit-content">
              {intl.formatMessage({ id: '--total-price' })}
            </Skeleton>
          </GridItem>
        )}
        {!minimalVariant && (
          <GridItem colSpan={2} alignSelf="center" fontWeight="bold">
            <Skeleton isLoaded={isLoaded} w="fit-content">
              {intl.formatMessage({ id: '--actions' })}
            </Skeleton>
          </GridItem>
        )}
      </Grid>
      <Divider display={alwaysMobile ? 'none' : { base: 'none', lg: 'block' }} borderWidth="1px" borderColor="gray.300"/>
      <Stack py={alwaysMobile ? '0' : { base: '0', md: '30px' }} spacing="30px">
        {articles.map((article, index) => (
          <Box key={article.id}>
            <CartProductItem
              id={article.id}
              image={article.image}
              heading={article.heading}
              originalSinglePrice={article.originalSinglePrice}
              singlePrice={article.singlePrice}
              linePrice={article.linePrice}
              amountAvailable={article.amountAvailable}
              sku={article.sku}
              detailLink={article.detailLink}
              actions={article.actions}
              packageUnits={article.packageUnits}
              currentAmount={article.currentAmount}
              onAmountChange={article.onAmountChange}
              currencyCode={article.currencyCode}
              disableInteractions={minimalVariant}
              facetValues={article.facetValues}
              alwaysMobile={alwaysMobile}
            />
          </Box>
        ))}
      </Stack>
      <Divider display={alwaysMobile ? 'none' : { base: 'none', lg: 'block' }} borderWidth="1px" borderColor="gray.300"/>
    </Box>
  )
}
