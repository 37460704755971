import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { Avatar, Box, Heading, Icon, Link, Skeleton, SkeletonCircle, SkeletonText, Text, VStack } from '@chakra-ui/react'
import type { IImage, IHeading } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'
import { AiOutlineMobile } from 'react-icons/ai'
import { Panel } from '../Panel/Panel'

export interface IEmployeeCardProps {
  name?: string
  image?: IImage
  position?: string
  telephoneNumber?: string
  mobileNumber?: string
  email?: string
  heading?: IHeading
  isLoaded?: boolean
  positionInfo?: string
}
interface IColors {
  colorBorder?: string
  colorAccent?: string
}
interface ICombined extends IColors, IEmployeeCardProps {}

export const EmployeeCard: React.FC<ICombined> = ({
  name, image, position, telephoneNumber, mobileNumber, email, heading, isLoaded = true,
  colorBorder = 'secondaryBackground.550', colorAccent = 'accent.500',
  positionInfo,
}) => {
  return (
    <Panel heading={heading} textAlign="center">
      <VStack
        w="100%"
        justify="center"
        align="center"
        fontSize="md"
        spacing={1}
      >
        <Box
          display="flex" // center containing image
          w="100px"
          h="100px"
          borderRadius="50%"
          overflow="hidden"
          mb={1}
        >
          <Box
            h="110px"
            w="110px"
            position="relative"
          >
            {!isLoaded
              ? <SkeletonCircle size="100px"/>
              : image?.fullpath
                ? (
                  <NextImage
                    src={image.fullpath}
                    placeholder={image.blurDataUrl ? 'blur' : 'empty'}
                    blurDataURL={image.blurDataUrl}
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: 'contain',
                    }}
                    alt="Employee Image"
                  />
                  )
                : (
                  <Avatar size="100px" name={name}/>
                  )}
          </Box>
        </Box>
        <Skeleton isLoaded={isLoaded}>
          <Text>
            {positionInfo}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <Heading
            as="h4"
            fontSize="xl"
            fontWeight="700"
          >{name}
          </Heading>
        </Skeleton>
        <Skeleton isLoaded={isLoaded}>
          <Text
            pb="2"
          >
            {position}
          </Text>
        </Skeleton>
        <SkeletonText noOfLines={3} isLoaded={isLoaded} minW="200px" spacing={4}/>
        {telephoneNumber && (
          <Link href={`tel:${telephoneNumber}`}>
            <Text
              color={colorAccent}
              fontWeight="medium"
            >
              <PhoneIcon color="secondaryText.500"/> {telephoneNumber}
            </Text>
          </Link>
        )}
        {mobileNumber && (
          <Link href={`tel:${mobileNumber}`}>
            <Text
              color={colorAccent}
              fontWeight="medium"
            >
              <Icon as={AiOutlineMobile} fontSize="lg" color="secondaryText.500"/>
              {mobileNumber}
            </Text>
          </Link>
        )}
        {email && (
          <Link href={`mailto:${email}`}>
            <Text
              color={colorAccent}
              fontWeight="medium"
            >
              <EmailIcon color="secondaryText.500"/>
              {` ${email}`}
            </Text>
          </Link>
        )}
      </VStack>
    </Panel>
  )
}
