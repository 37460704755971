import { Alert, AlertDescription, AlertIcon, Box, Center, Heading, Radio, Stack } from '@chakra-ui/react'
import { asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import type React from 'react'
import type { IImage } from '../../'

export interface IPaymentRadioButtonProps {
  id: string
  title: string
  description: string
  logo?: IImage
  disabled?: boolean
  disabledMessage?: string
}

export const PaymentRadioButton: React.FC<IPaymentRadioButtonProps> = ({ id, title, description, logo, disabled, disabledMessage }) => {
  const parsedDescription = asComponent(description)

  return (
    <Stack width="100%" height="100%" align={{ base: 'left', md: 'center' }} justify="space-between" direction={{ base: 'column', md: 'row' }}>
      <Radio width="100%" height="100%" spacing="3" display="flex" alignItems="center" value={id} isDisabled={disabled}>
        <Stack height="100%">
          <Box>
            <Heading as="h6" fontSize="md" mb="0" mt="0">{title}</Heading>
            <Box fontSize="sm">{parsedDescription}</Box>
          </Box>
          {logo?.fullpath && (
            <Center
              borderWidth="1px"
              bg="transparent"
              borderColor="gray.300"
              borderRadius="base"
              width="45px"
              p="2px"
            >
              <NextImage
                src={logo.fullpath}
                width="60"
                height="30"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
                alt="Payment"
              />
            </Center>
          )}
        </Stack>
      </Radio>
      {(disabled && disabledMessage) && (
        <Alert status="warning" w="fit-content" maxW="400px">
          <AlertIcon/>
          <AlertDescription>{disabledMessage}</AlertDescription>
        </Alert>
      )}
    </Stack>
  )
}
