import type { NextRouter } from 'next/router'
import type { TypesenseInstantsearchAdapterOptions, AdditionalSearchParametersWithQueryBy } from 'typesense-instantsearch-adapter'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const A11TypesenseOptions = {
  server: {
    apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_ONLY_API_KEY,
    nodes: [
      {
        host: process.env.NEXT_PUBLIC_TYPESENSE_HOST,
        protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL,
        port: Number(process.env.NEXT_PUBLIC_TYPESENSE_PORT),
      },
    ],
    numRetries: 8,
    connectionTimeoutSeconds: 10,
  },
  additionalSearchParameters: {
    query_by: 'description',
    group_by: 'productId',
    group_limit: 1,
    sort_by: 'customMapping_sortIndex:asc',
    filter_by: 'languageCode:=[`de`]',
  },
} as TypesenseInstantsearchAdapterOptions

export class A11TypesenseInstantSearchAdapter extends TypesenseInstantSearchAdapter {
  constructor (router: NextRouter, options: TypesenseInstantsearchAdapterOptions) {
    if ((options.additionalSearchParameters as AdditionalSearchParametersWithQueryBy & { filter_by: string }).filter_by) {
      (options.additionalSearchParameters as AdditionalSearchParametersWithQueryBy & { filter_by: string }).filter_by = `languageCode:=[\`${router.locale ?? 'de'}\`]`
    }

    super(options)
  }
}
