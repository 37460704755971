import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Button, Text, VStack } from '@chakra-ui/react'
import type React from 'react'
import { NavigationMenuMobileItem } from '../NavigationMenuMobileItem/NavigationMenuMobileItem'
import type { IMenu } from '../../'

interface INavigationMenuMobileProps {
  slug: string
  menus?: IMenu[]
  menuTitle: string
  previousMenuTitle?: string
  distanceTop: string[]
  pageIsOpen: boolean
  closePage?: () => void
  menuIsOpen: boolean
  closeMenu: () => void
  layer: number
}
interface IColors {
  colorText?: string
  colorBackground?: string
  colorAccent?: string
}
interface ICombined extends IColors, INavigationMenuMobileProps {}

export const NavigationMenuMobile: React.FC<ICombined> = ({
  menus, pageIsOpen, distanceTop, closePage, layer, menuTitle, previousMenuTitle, menuIsOpen, slug, closeMenu,
  colorText = 'primaryText.500', colorBackground = 'primaryBackground.500', colorAccent = 'accent.500',
}) => {
  return (
    <Box
      zIndex={layer}
      position="fixed"
      top={distanceTop}
      left={0}
      w="100vw"
      h={distanceTop.map(distance => `calc(100vh - ${distance})`)}
      bg={colorBackground}
      color={colorText}
      ml={menuIsOpen && pageIsOpen ? '0 !important' : '100vw !important'}
      transition="margin-left 0.2s"
    >
      <VStack align="left" p={5} spacing={3}>
        <VStack align="left" spacing={0}>
          <Text
            color="primaryText.800"
            pl={4}
            pb={0}
            fontSize="3xl"
            fontFamily="primary"
          >
            {menuTitle}
          </Text>
          {closePage && (
            <Box color={colorAccent} >
              <Button
                onClick={closePage}
                bg={colorBackground}
                leftIcon={<ArrowBackIcon/>}
                fontSize="lg"
                mt={-2}
                _active={{}}
                _hover={{}}
                _focus={{}}
              >{previousMenuTitle}
              </Button>
            </Box>
          )}
        </VStack>
        {menus?.map(menu => {
          return (
            <NavigationMenuMobileItem
              slug={slug}
              previousMenuTitle={menuTitle}
              layer={layer}
              key={menu.title}
              menu={menu}
              distanceTop={distanceTop}
              menuIsOpen={menuIsOpen}
              closeMenu={closeMenu}
            />
          )
        })}
      </VStack>
    </Box>
  )
}
