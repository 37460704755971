import { Box, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'

export const PageSpinner: FC = () => {
  return (
    <Box w="100%" display="flex">
      <Spinner size="xl" mx="auto" my={10}/>
    </Box>
  )
}
