export const styles = {
  global: {
    h1: {
      fontSize: ['4xl', '5xl'],
    },
    h2: {
      fontSize: ['3xl', '4xl'],
    },
    h3: {
      fontSize: ['2xl', '3xl'],
    },
    h4: {
      fontSize: ['xl', '2xl'],
    },
    h5: {
      fontSize: ['lg', 'xl'],
    },
    h6: {
      fontSize: ['md', 'lg'],
    },
    _selection: {
      color: 'primaryText.500',
      backgroundColor: 'accent.500',
    },
    // Copied and adapted from https://github.com/nikolovlazar/chakra-ui-prose/blob/main/packages/chakra-ui-prose/src/theme.ts
    blockquote: {
      paddingStart: 4,
      my: { base: 6, md: 8 },
      borderStartWidth: '3px',
      borderStartColor: 'accent.500',
    },
    table: {
      my: 8,
      display: 'block',
      textAlign: 'start',
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      thead: {
        borderBottomWidth: '1px',
        borderBottomColor: 'gray.300',
      },
      th: {
        textAlign: 'inherit',
        fontWeight: 600,
        p: { base: 2, md: 3 },
      },
      td: {
        p: { base: 2, md: 3 },
        verticalAlign: 'baseline',
      },
      tbody: {
        tr: {
          borderBottomWidth: '1px',
          borderBottomColor: 'gray.200',

          _dark: {
            borderBottomColor: 'gray.700',
          },

          ':last-of-type': {
            borderBottomWidth: '0px',
            borderBottomColor: 'transparent',
          },
        },
      },
      tfoot: {
        tr: {
          borderTopWidth: '1px',
          borderTopColor: 'gray.300',

          _dark: {
            borderTopColor: 'gray.600',
          },
        },
      },
    },
  },
}
