import { Box } from '@chakra-ui/react'
import { ProgressStepsIterator, CheckoutSummary } from '@stocker/ui-components/design-system'
import type React from 'react'
import type { ICheckoutPageProps } from '../CheckoutPage.types'

export const CheckoutStepSummary: React.FC<ICheckoutPageProps> = (props) => {
  return (
    <Box>
      <CheckoutSummary {...props.summaryProps}/>
      <ProgressStepsIterator prevButton={{ onClick: props.stepper[1].goToPrevStep }}/>
    </Box>
  )
}
