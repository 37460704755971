import { Button, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { FiRsCheck, FiRsHeart } from '@stocker/ui-components/design-system'
import { useActiveUserWishlistsQuery, useAddToWishlistMutation, useCreateWishlistMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

export interface IWishlistModalProps {
  productId: string
}

export const WishlistModal: React.FC<IWishlistModalProps> = ({ productId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()

  const { data: activeUserWishlists } = useActiveUserWishlistsQuery()
  const { mutate: addToWishlist } = useAddToWishlistMutation({
    onSuccess: () => {
      toast({
        title: formatMessage({ id: 'wishlist-toast--added-to-wishlist' }),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      queryClient.invalidateQueries({
        queryKey: useActiveUserWishlistsQuery.getKey(),
      })
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const { mutate: createWishlist } = useCreateWishlistMutation({
    onSuccess: (data) => {
      toast({
        title: formatMessage({ id: 'wishlist-toast--created-new-wishlist' }),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      addToWishlist({ productVariantId: productId, wishlistId: data.createWishlist.id })
      queryClient.invalidateQueries({
        queryKey: useActiveUserWishlistsQuery.getKey(),
      })
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const [newWishlistName, setNewWishlistName] = useState('')
  return (
    <>
      <IconButton variant="ghost" rounded="md" colorScheme="accent" w="fit-content" aria-label="wishlist Button" onClick={onOpen} icon={<FiRsHeart fontSize="xl"/>}/>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontFamily="primary">{formatMessage({ id: 'wishlist-modal--header' })}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Stack spacing={6}>
              <Stack spacing={3}>
                <Text>{formatMessage({ id: 'wishlist-modal--create-new-wishlist' })}</Text>
                <HStack>
                  <Input
                    value={newWishlistName}
                    onChange={e => { setNewWishlistName(e.target.value) }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        // updateWishlist({ id: activeUserWishlist?.activeUserWishlist?.id ?? '', name: (wishlistName ?? activeUserWishlist?.activeUserWishlist?.name ?? '') })
                        createWishlist({ name: newWishlistName })
                        setNewWishlistName('')
                        onClose()
                      }
                    }}
                  />
                  <IconButton
                    variant="ghost"
                    rounded="md"
                    colorScheme="accent"
                    w="fit-content"
                    aria-label="new wishlist"
                    onClick={() => {
                      createWishlist({ name: newWishlistName })
                      setNewWishlistName('')
                      onClose()
                    }}
                    icon={<FiRsCheck fontSize="xl"/>}
                  />
                </HStack>
              </Stack>
              <Stack spacing={3}>
                <Text>{formatMessage({ id: 'wishlist-modal--add-to-wishlist' })}</Text>
                {activeUserWishlists?.activeUserWishlists.map(wishlist => {
                  return (
                    <HStack key={wishlist.id} justify="space-between">
                      <Text fontFamily="primary">{wishlist.name}</Text>
                      <HStack spacing={4}>
                        <Text>{dayjs(wishlist.createdAt).format('DD.MM.YYYY')}</Text>
                        <IconButton
                          variant="ghost"
                          rounded="md"
                          colorScheme="accent"
                          w="fit-content"
                          aria-label="add to wishlist"
                          onClick={() => {
                            addToWishlist({ productVariantId: productId, wishlistId: wishlist.id })
                            onClose()
                          }}
                          icon={<FiRsHeart fontSize="xl"/>}
                        />
                      </HStack>
                    </HStack>
                  )
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button>{formatMessage({ id: '--close' })}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
