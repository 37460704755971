import { Container, VStack } from '@chakra-ui/react'
import type React from 'react'
import type { INewsCardProps } from '../../compounds/NewsCard/NewsCard'
import { NewsCard } from '../../compounds/NewsCard/NewsCard'

export interface INewsCardContainerProps {
  news: INewsCardProps[]
  isLoaded?: boolean
}
interface IColors {
  colorText?: string
  colorBackground?: string
  colorAccent?: string
}
interface ICombined extends IColors, INewsCardContainerProps {}

export const NewsCardContainer: React.FC<ICombined> = ({
  news,
  isLoaded = true,
  colorText = '',
  colorBackground = '',
  colorAccent = '',
}) => {
  return (
    <Container maxW="container.page" pt="20px">
      <VStack spacing={8} maxW={{ base: '85%', xl: 'container.content' }} mx="auto">
        {news.map((newsItem) => (
          <NewsCard key={newsItem.headline} {...newsItem} isLoaded={isLoaded} />
        ))}
      </VStack>
    </Container>
  )
}
