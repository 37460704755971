import { Box, Divider, Flex } from '@chakra-ui/react'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'

export interface IInputNumberWheelProps {
  onChange: (value: number) => void
  value: {
    max: number
    min?: number
    initial?: number
  }
}

function inPixel (px: number) {
  return `${px}px`
}
const HEIGHT = 50
const VISIBLE_ITEMS = 5
const TOTAL_HEIGHT = HEIGHT * VISIBLE_ITEMS
const HALF_VISIBLE_ITEMS = Math.floor(VISIBLE_ITEMS / 2)

export const InputNumberWheel: React.FC<IInputNumberWheelProps> = ({ onChange, value }) => {
  const [activeElement, setActiveElement] = useState(0) // to highlight the active element
  const [scrollSnapTimeout, setScrollSnapTimeout] = useState<ReturnType<typeof setTimeout> | undefined>()
  const scrollBox = useRef<HTMLDivElement>(null)
  const min = value.min ?? 1
  const range = Array.from(Array((value.max - min + 1)), (_, i) => i + min)

  useEffect(() => {
    // scroll on prop value change
    if (value.initial && scrollBox.current) {
      scrollBox.current.scrollTo({ top: (value.initial - 1) * HEIGHT, behavior: 'smooth' })
      setActiveElement(value.initial)
    }
  }, [value])

  const snapToClosestElement = (e: React.UIEvent<HTMLDivElement | undefined>) => {
    // Debounced
    clearTimeout(scrollSnapTimeout)
    const scrollTop = e.currentTarget.scrollTop
    const newActiveElementPosition = Math.round(scrollTop / HEIGHT)
    setActiveElement(newActiveElementPosition + min)
    setScrollSnapTimeout(setTimeout(() => {
      if (scrollBox.current && !isMouseDown.current) {
        // snap to the nearest element
        scrollBox.current.scrollTo({ top: newActiveElementPosition * HEIGHT, behavior: 'smooth' })
        onChange(newActiveElementPosition + min)
      }
    }, 100))
  }

  // drag scrolling
  const isMouseDown = useRef(false)
  const startY = useRef(0)
  const scrollTop = useRef(0)
  const isDragging = useRef(false)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    isMouseDown.current = true
    startY.current = e.pageY - scrollBox.current!.offsetTop
    scrollTop.current = scrollBox.current!.scrollTop
  }
  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    snapToClosestElement(e)
    isMouseDown.current = false
  }
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown.current || !scrollBox.current) return
    e.preventDefault()

    const x = e.clientY - scrollBox.current.offsetTop
    const walk = (x - startY.current)
    if (Math.abs(walk) > 10) isDragging.current = true
    const target = scrollTop.current - walk
    scrollBox.current.scrollTop = target
  }

  return (
    <Box pos="relative" overscrollBehavior="none" w="full" bg="white">
      {/* Gradient Overlay */}
      <Box pos="absolute" w="full" h="full" pointerEvents="none" bgGradient="linear(to bottom, white, rgba(255,0,0,0), white)"/>
      {/* Scroll Box */}
      <Box
        ref={scrollBox}
        h={inPixel(TOTAL_HEIGHT)}
        overflow="scroll"
        onScroll={snapToClosestElement}
        fontSize="xl"
        fontFamily="primary"
        overscrollBehavior="none"
        userSelect="none"
        onMouseUp={snapToClosestElement}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        {Array.from({ length: HALF_VISIBLE_ITEMS }).map((_, idx) => (<Box key={idx} h={inPixel(HEIGHT)}/>))}
        {range.map((currentNumber, idx) => (
          <Flex
            direction="column"
            cursor={isDragging.current ? 'grabbing' : 'grab'}
            alignItems="center"
            lineHeight={inPixel(HEIGHT)}
            key={currentNumber}
            h={inPixel(HEIGHT)}
            w="full"
            fontWeight={currentNumber === activeElement ? 'black' : 'normal'}
            onMouseUp={() => {
              // Not OnClick to prevent lock on drag
              if (scrollBox.current && !isDragging.current) {
                scrollBox.current.scrollTo({ top: idx * HEIGHT, behavior: 'smooth' })
              }
              isMouseDown.current = false
              isDragging.current = false
            }}
          ><Box cursor={isDragging.current ? 'grabbing' : 'pointer'}>{currentNumber}</Box>
          </Flex>
        ))}
        {Array.from({ length: HALF_VISIBLE_ITEMS }).map((_, idx) => (<Box key={idx} h={inPixel(HEIGHT)}/>))}
        <Divider pos="absolute" top={inPixel(HALF_VISIBLE_ITEMS * HEIGHT)}/>
        <Divider pos="absolute" top={inPixel((HALF_VISIBLE_ITEMS + 1) * HEIGHT)}/>
      </Box>
    </Box>
  )
}
