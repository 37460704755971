import type { NavigationByIdentifierQuery } from '@stocker/codegen/pimcore'
import type { IMenu } from '@stocker/ui-components/design-system'

const produkte: IMenu = {
  title: 'Produkte',
  linkItems: [
    {
      title: 'Alle Produkte',
      absolutePath: '/products',
    },
    {
      title: 'Kunststoff',
      linkItems: [
        {
          title: 'Polyline PP1',
          absolutePath: '/Produkte/kunststoff/polyline-pp~c600',
        },
        {
          title: 'Polyline Outdoor PP',
          absolutePath: '',
        },
        {
          title: 'Polyline PVDF',
          absolutePath: '',
        },
      ],
    },
    {
      title: 'Edelstahl',
      linkItems: [
        {
          title: 'NiroLine EW 06',
          absolutePath: '',
        },
        {
          title: 'NiroLine EW 5000',
          absolutePath: '',
        },
        {
          title: 'NiroLine DW 25/50',
          absolutePath: '',
        },
        {
          title: 'NiroLine DW Design',
          absolutePath: '',
        },
        {
          title: 'NiroLine DW 5000',
          absolutePath: '',
        },
      ],
    },
    {
      title: 'Keramik',
      linkItems: [
        {
          title: 'KeraLine EW Keramik',
          absolutePath: '',
        },
        {
          title: 'KeraLine EW Schamotte',
          absolutePath: '',
        },
        {
          title: 'KeraLine DW Modul',
          absolutePath: '',
        },
      ],
    },
    {
      title: 'Schächte & Durchführungen',
      linkItems: [
        {
          title: 'KombiLine F90 Mantelstein',
          absolutePath: '',
        },
        {
          title: 'KombiLine F90 / F30 Leichtbauschacht',
          absolutePath: '',
        },
        {
          title: 'KombiLine DWD-Schott',
          absolutePath: '',
        },
      ],
    },
    {
      title: 'Zubehör',
      linkItems: [
        {
          title: 'ExpertLine',
          absolutePath: '',
        },
        {
          title: 'ConnectLine',
          absolutePath: '',
        },
        {
          title: 'FixLine',
          absolutePath: '',
        },
        {
          title: 'CompleteLine',
          absolutePath: '',
        },
        {
          title: 'CareLine',
          absolutePath: '',
        },
      ],
    },
  ],
}

const unternehmen: IMenu = {
  title: 'Unternehmen',
  linkItems: [
    {
      title: 'Über uns',
      absolutePath: '/Unternehmen',
    },
    {
      title: 'Team',
    },
    {
      title: 'Firmengeschichte',
    },
    {
      title: 'Leitbild',
    },
    {
      title: 'Karriere',
    },
    {
      title: 'Musterheizraum',
    },
  ],
}

const service: IMenu = {
  title: 'Service',
  linkItems: [
    {
      title: 'Unser Service',
      absolutePath: '/Unternehmen',
    },
    {
      title: 'Downloads',
    },
    {
      title: 'Häufig gestellte Fragen',
    },
    {
      title: 'Newsletter',
    },
    {
      title: 'Kontakt',
    },
  ],
}

const neuigkeiten: IMenu = {
  title: 'Neuigkeiten',
  absolutePath: '/Neuigkeiten',
}

export const menus: IMenu[] = [
  produkte,
  unternehmen,
  service,
  neuigkeiten,
]

export const navItems: NavigationByIdentifierQuery = {
  navigationByIdentifier: {
    linkItems: [
      {
        title: 'Produkte',
        absolutePath: '/produkte',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: [
          {
            title: 'Kunststoff',
            absolutePath: '/products/kunststoff',
            additionalData: '[]',
            className: 'ProductCategory',
            linkItems: [
              {
                title: 'PolyLine PP',
                additionalData: '[]',
                absolutePath: '/products/plastic/polyline-pp',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'PolyLine Outdoor PP',
                additionalData: '[]',
                absolutePath: '/products/plastic/polyline-outdoor-pp',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'PolyLine PVDF',
                additionalData: '[]',
                absolutePath: '/products/plastic/polyline-pvdf',
                className: 'ProductCategory',
                linkItems: null,
              },
            ],
          },
          {
            title: 'Edelstahl',
            absolutePath: '/products/produkte-edelstahl',
            additionalData: '[]',
            className: 'ProductCategory',
            linkItems: [
              {
                title: 'NiroLine EW 06',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-ew-06',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'NiroLine EW 5000',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-ew-5000',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'NiroLine DW 25/50',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-dw-25-50',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'NiroLine DW Design',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-dw-design',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'NiroLine DW 5000',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-dw-5000',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'NiroLine DW Basic',
                additionalData: '[]',
                absolutePath: '/products/stainless-steel/niroline-dw-basic',
                className: 'ProductCategory',
                linkItems: null,
              },
            ],
          },
          {
            title: 'Keramik',
            absolutePath: '/products/keramik',
            additionalData: '[]',
            className: 'ProductCategory',
            linkItems: [
              {
                title: 'KeraLine EW Keramik',
                additionalData: '[]',
                absolutePath: '/products/keramik/keramik-kera-line-ew-keramik',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'KeraLine EW Schamotte',
                additionalData: '[]',
                absolutePath: '/products/keraline-ew-chamotte',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'KeraLine DW Modul',
                additionalData: '[]',
                absolutePath: '/products/keraline-dw-modul',
                className: 'ProductCategory',
                linkItems: null,
              },
            ],
          },
          {
            title: 'Schächte & Durchführungen',
            absolutePath: '/products/schaechte-durchfuehrungen',
            additionalData: '[]',
            className: 'ProductCategory',
            linkItems: [
              {
                title: 'KombiLine F90 Mantelstein',
                additionalData: '[]',
                absolutePath: '/products/shafts-feedthroughs/kombiline-f90-concrete-bricks',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'KombiLine F90 / F30 Leichtbauschacht',
                additionalData: '[]',
                absolutePath: '/products/shafts-feedthroughs/kombiline-f90-f30-lightweight-shaft',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'KombiLine DWD-Schott',
                additionalData: '[]',
                absolutePath: '/products/shafts-feedthroughs/kombiline-dwd-schott',
                className: 'ProductCategory',
                linkItems: null,
              },
            ],
          },
          {
            title: 'Zubehör',
            absolutePath: '/products/produkte-zubehoer',
            additionalData: '[]',
            className: 'ProductCategory',
            linkItems: [
              {
                title: 'ExpertLine',
                additionalData: '[]',
                absolutePath: '/products/expertline',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'ConnectLine',
                additionalData: '[]',
                absolutePath: '/products/zubehoer/zubehoer-connect-line',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'FixLine',
                additionalData: '[]',
                absolutePath: '/products/zubehoer/zubehoer-fix-line',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'CompleteLine',
                additionalData: '[]',
                absolutePath: '/products/zubehoer/zubehoer-complete-line',
                className: 'ProductCategory',
                linkItems: null,
              },
              {
                title: 'CareLine',
                additionalData: '[]',
                absolutePath: '/products/careline',
                className: 'ProductCategory',
                linkItems: null,
              },
            ],
          },
        ],
      },
      {
        title: 'Unternehmen',
        absolutePath: '/unternehmen',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: [
          {
            title: 'Unternehmen',
            absolutePath: '/unternehmen',
            additionalData: '[]',
            className: 'ContentPage',
            linkItems: null,
          },
          {
            title: 'Team',
            absolutePath: '/unternehmen/team',
            additionalData: '[]',
            className: 'ContentPage',
            linkItems: null,
          },
        ],
      },
      {
        title: 'Service',
        absolutePath: '/service',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: [
          {
            title: 'Service',
            absolutePath: '/service',
            additionalData: '[]',
            className: 'ContentPage',
            linkItems: null,
          },
          {
            title: 'Downloads',
            absolutePath: '/service/downloads',
            additionalData: '[]',
            className: 'ContentPage',
            linkItems: null,
          },
        ],
      },
      {
        title: 'Neuigkeiten',
        absolutePath: '/neuigkeiten',
        additionalData: '[]',
        className: 'ContentPage',
        linkItems: null,
      },
    ],
  },
}
