import { Box, Button, Select, SimpleGrid, Text } from '@chakra-ui/react'
import type { IVariant } from '../../'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { filterVariants, getInitialVariant, getOptionsFromVariant, getSelectableOptions, removeEmptyValuesFromObject } from '@stocker/ui-components/design-system'

export interface IVariantSelectorProps {
  variants: IVariant[]
  onVariantChange: (variant: IVariant | null) => void
  initialSku?: string
  hideResetButton?: boolean
  setFirstVariantAsInitial?: boolean
}
export interface IOptionGroup {
  title: string
  inputPlaceholder?: string
  required?: boolean
  options: IOption[]
}

export interface IOption {
  label: string
  value: string
}

export const VariantSelector: React.FC<IVariantSelectorProps> = ({
  variants,
  onVariantChange,
  initialSku,
  hideResetButton = false,
  setFirstVariantAsInitial = true,
}) => {
  const form = useForm<Record<string, string | undefined>>()
  const intl = useIntl()

  const initialVariant = getInitialVariant(variants, initialSku, setFirstVariantAsInitial)
  const initialVariantOptions = removeEmptyValuesFromObject(getOptionsFromVariant(initialVariant))

  const [selectedOptions, setSelectedOptions] = useState<Record<string, string | undefined>>(initialVariantOptions)
  const [selectableOptions, setSelectableOptions] = useState<IOptionGroup[]>(
    getSelectableOptions(
      variants,
      Object.entries(initialVariantOptions)
        .map(([key, value]) => ({ label: key, value }))),
  )

  useEffect(() => {
    onVariantChange(initialVariant)
  }, [])

  useEffect(() => {
    // Gets called each time the form changes
    // Sets the selectable options and possibly updates the variant
    const subscription = form.watch((newSelection) => {
      const newSelectedOptions = removeEmptyValuesFromObject(newSelection)
      setSelectedOptions(newSelectedOptions)

      const selectedOptions: IOption[] = Object.entries(newSelectedOptions).map(([key, value]) => ({ label: key, value }))

      const newSelectableOptions = getSelectableOptions(variants, selectedOptions)
      setSelectableOptions(newSelectableOptions)

      const isFinished = filterVariants(variants, selectedOptions).length === 1
      // TODO: STOCKER only update if variant is not null
      const newVariant = isFinished ? filterVariants(variants, selectedOptions)[0] : null
      onVariantChange(newVariant)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [form, onVariantChange, variants])

  return (
    <form>
      {/* Wrapped with a form so it limits the scope of the options but doesn't function as a form */}
      <SimpleGrid columns={1} gap={2}>
        {selectableOptions.map((selectOption, index) => (
          <Box key={selectOption.title + String(index)}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: `--facet-${selectOption.title}` })}
            </Text>
            <Select
              placeholder={intl.formatMessage({ id: 'product-configurator--nothing-selected' })}
              value={selectedOptions[selectOption.title]}
              {...form.register(selectOption.title, { required: selectOption.required })}
            >
              {selectOption.options.sort((a: IOption, b: IOption) => {
                return parseInt(a.value) > 0 ? parseInt(a.value) - parseInt(b.value) : a.value.localeCompare(b.value)
              }).map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </Select>
          </Box>
        ))}
        {(!hideResetButton && selectableOptions.length > 0) && (
          <Button
            size="sm"
            onClick={() => {
              // Without params, the reset doesn't reset disabled form elements
              form.reset(selectableOptions.reduce((acc: Record<string, string>, option) => { acc[option.title] = ''; return acc }, {}))
            }}
          >
            {intl.formatMessage({ id: 'product-configurator--reset-selection' })}
          </Button>
        )}
      </SimpleGrid>
    </form>
  )
}
