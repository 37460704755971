import { Button, Divider, Flex, Text } from '@chakra-ui/react'
import { Form, ButtonData, CheckboxData, InputData, JsxData, FiRsCrossedEye, FiRsEye } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import Link from 'next/link'
import type React from 'react'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export interface ILoginFormData {
  email: string
  password: string
  rememberMe: boolean
}

interface ILoginFormProps {
  isLoading: boolean
  onSubmit: (data: ILoginFormData) => void
}

export const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit, isLoading }) => {
  const { formatMessage } = useIntl()

  const validationSchema = useMemo(() => yup.object().shape({
    email: yup.string().email().required(formatMessage({ id: 'form--field-is-required' })),
    password: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
  }), [formatMessage])

  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--email' }), name: 'email', type: 'email' }),
            ],
            [
              new InputData({
                title: formatMessage({ id: '--password' }),
                name: 'password',
                type: (isPasswordShown ? 'text' : 'password'),
                rightElement: (isPasswordShown ? <FiRsCrossedEye onClick={() => { setIsPasswordShown(false) }} fontSize="lg" cursor="pointer"/> : <FiRsEye onClick={() => { setIsPasswordShown(true) }} fontSize="lg" cursor="pointer"/>),
              }),
            ],
            [
              new CheckboxData({ title: formatMessage({ id: 'login--remember-me' }), name: 'rememberMe' }),
              new JsxData({ jsx: <Link href="/auth/password-reset">{formatMessage({ id: 'login--forgot-password' })}</Link> }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: 'login--submit' }), type: 'submit', inputProps: { width: 'full', colorScheme: 'accent', isLoading } }),
            ],
          ],
        },
      ],
      [
        {
          fields: [
            [
              new JsxData({
                jsx:
  <Flex align="center" w="100%">
    <Divider/>
    <Text padding="2">{formatMessage({ id: '--or' }).toUpperCase()}</Text>
    <Divider/>
  </Flex>,
              }),
            ],
            [
              new JsxData({
                jsx: <Button cursor="pointer" as={Link} href="/auth/register" w="full">{formatMessage({ id: '--register' })}</Button>,
              }), // as: Link, href: '/auth/register'
            ],
          ],
        },
      ],
    ],

  }), [formatMessage, isLoading, isPasswordShown])
  return (
    <Form
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  )
}
