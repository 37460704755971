import { Box, Button, Container, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Grid, GridItem, HStack, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'
import type { IBreadcrumbsProps, IEmployeeCardProps, IHeading } from '../../'
import { Breadcrumbs, EmployeeCard, Panel, FiRsBurgerMenu, FiRsLogOut, NavButton, NextLink } from '../../'
import { CustomHeading } from '@stocker/ui-components/helpers'
import { useRouter } from 'next/router'
import type { FC, PropsWithChildren } from 'react'
import { useRef } from 'react'
import { useIntl } from 'react-intl'

export interface IAccountPageSideBarProps {
  breadcrumbs: IBreadcrumbsProps
  subPages: Array<{
    category: string
    items: Array<{
      text: string
      externalLink?: string
      absolutePath?: string
      icon?: JSX.Element
    }>
  }>
  contactPerson?: IEmployeeCardProps | null
  welcomeHeading: IHeading
  onLogOut: () => void
  isLoggingOut?: boolean
  isLoading?: boolean
}

export const AccountPageSideBar: FC<PropsWithChildren<IAccountPageSideBarProps>> = ({ isLoading = true, isLoggingOut, children, breadcrumbs, subPages, contactPerson, welcomeHeading, onLogOut }) => {
  const intl = useIntl()
  const router = useRouter()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)

  return (
    <>
      <Box display={{ base: 'initial', lg: 'none' }}>
        <Button
          h="50px"
          fontSize="lg"
          leftIcon={<FiRsBurgerMenu/>}
          ref={btnRef}
          onClick={onOpen}
          w="100%"
        >
          Account Navigation
        </Button>
      </Box>
      <Container maxW="container.page" my={{ base: 0, lg: 7 }}>
        {/* Desktop */}
        <Box display={{ base: 'none', lg: 'initial' }}>
          <Breadcrumbs data={breadcrumbs.data}/>
          <Grid
            mt={7}
            templateColumns="repeat(12, 1fr)"
            gap={7}
          >
            <GridItem
              colSpan={{ base: 12, lg: 3 }}
            >
              <Stack
                spacing={7}
                position={{ base: 'static', md: 'sticky' }}
                top={{ base: undefined, md: '125px' }}
              >
                <Box>
                  <EmployeeCard
                    {...contactPerson ?? {}}
                    isLoaded={!!contactPerson}
                  />
                </Box>
                <Panel>
                  <Stack spacing={2}>
                    {subPages.map((category, index) => (
                      <Stack spacing={2} key={category.category + String(index)}>
                        <Text fontFamily="primary">
                          {category.category}
                        </Text>
                        {category.items.map((link) => (
                          <HStack pl="10px" key={link.absolutePath} color={router.asPath === link.absolutePath ? 'accent.500' : ''} _hover={{ color: 'accent.500' }}>
                            {link.icon}
                            <NextLink
                              fontSize="lg"
                              href={link.absolutePath ?? link.externalLink}
                              target={link.externalLink ? '_blank' : '_self'}
                            >{link.text}
                            </NextLink>
                          </HStack>
                        ))}
                      </Stack>
                    ))}
                    <Box pt={2} w="100%">
                      <Button
                        w="100%"
                        aria-label="log-out"
                        leftIcon={<FiRsLogOut/>}
                        isLoading={isLoggingOut}
                        onClick={onLogOut}
                      >
                        {intl.formatMessage({ id: '--logout' })}
                      </Button>
                    </Box>
                  </Stack>
                </Panel>
              </Stack>
            </GridItem>
            <GridItem
              colSpan={{ base: 12, lg: 9 }}
            >
              <Stack spacing={0}>
                <CustomHeading heading={welcomeHeading}/>
                {children}
              </Stack>
            </GridItem>
          </Grid>
        </Box>
        {/* Mobile */}
        <Box display={{ base: 'initial', lg: 'none' }}>
          <Box mt={3}>
            <Breadcrumbs data={breadcrumbs.data}/>
          </Box>
          <Box>
            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              finalFocusRef={btnRef}
              size="full"
            >
              <DrawerOverlay/>
              <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader p="16px">Account Navigation</DrawerHeader>
                <DrawerBody p={0}>
                  <Divider mb="4" borderColor="gray.300"/>
                  <Stack spacing={4} divider={<StackDivider borderColor="gray.300"/>}>
                    {subPages.map((category, index) => (
                      <Stack key={category.category + String(index)} spacing={4}>
                        <Text fontFamily="primary" pl="16px">{category.category}</Text>
                        {category.items.map((link) => (
                          <NextLink
                            key={link.absolutePath}
                            href={link.absolutePath}
                          >
                            <NavButton
                              onClick={onClose}
                              fontSize="lg"
                              color={router.asPath === link.absolutePath ? 'accent.500' : ''}
                              _hover={{ color: 'accent.500' }}
                              icon={link.icon}
                              label={link.text}
                              py="10px"
                            />
                          </NextLink>
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                </DrawerBody>
                <DrawerFooter>
                  <Box pt={2} w="100%">
                    <Button
                      w="100%"
                      aria-label="log-out"
                      leftIcon={<FiRsLogOut/>}
                      isLoading={isLoggingOut}
                      onClick={onLogOut}
                    >
                      {intl.formatMessage({ id: '--logout' })}
                    </Button>
                  </Box>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Box>
          <Stack spacing={7}>
            <Stack spacing={0}>
              <CustomHeading heading={welcomeHeading}/>
              {children}
            </Stack>
            <Box pb={7}>
              <EmployeeCard
                {...contactPerson ?? {}}
                isLoaded={!isLoading}
              />
            </Box>
          </Stack>
        </Box>
      </Container>
      <Box pt={2} w="100%" display={{ base: 'initial', lg: 'none' }}>
        <Button
          w="100%"
          aria-label="log-out"
          leftIcon={<FiRsLogOut/>}
          isLoading={isLoggingOut}
          onClick={onLogOut}
        >
          {intl.formatMessage({ id: '--logout' })}
        </Button>
      </Box>
    </>
  )
}
