import type { useDisclosure } from '@chakra-ui/react'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import type { useSendBasketToOrderMutation } from '@stocker/codegen/configurator-backend'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IConfirmOrderModalProps {
  disclosure: ReturnType<typeof useDisclosure>
  sendBasketToOrder?: ReturnType<typeof useSendBasketToOrderMutation>
  basketId?: string
  addConfigToBasket?: () => void
}

export const ConfirmOrderModal: React.FC<IConfirmOrderModalProps> = ({ disclosure: confirmOrderModal, sendBasketToOrder, basketId, addConfigToBasket }) => {
  const { formatMessage } = useIntl()
  return (
    <Modal isOpen={confirmOrderModal.isOpen} onClose={confirmOrderModal.onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          {formatMessage({ id: 'configurator-confirm-order--title' })}
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {formatMessage({ id: 'configurator-confirm-order--body' })}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="secondaryText"
            variant="outline"
            onClick={confirmOrderModal.onClose}
            mr={3}
          >{formatMessage({ id: '--return' })}
          </Button>
          <Button
            colorScheme="accent"
            isLoading={sendBasketToOrder?.isLoading}
            onClick={() => {
              if (addConfigToBasket) addConfigToBasket()
              if (basketId) sendBasketToOrder?.mutate({ id: basketId })
            }}
          >{formatMessage({ id: '--to-checkout' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
