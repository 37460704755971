import type { SquareProps } from '@chakra-ui/react'
import { Circle, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { HiCheck } from 'react-icons/hi'

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean
  isActive: boolean
}

export const ProgressStepCircle: FC<RadioCircleProps> = ({ isCompleted, isActive, ...squareProps }) => {
  return (
    <Circle
      size={{ base: 4, lg: 8 }}
      bg={isCompleted ? 'accent.500' : 'inherit'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'accent.500' : 'gray.400'}
      {...squareProps}
    >
      {isCompleted
        ? (
          <Icon as={HiCheck} color="primaryText.500" boxSize={{ base: 3, lg: 5 }}/>
          )
        : (
          <Circle bg={isActive ? 'accent.500' : 'border'} size="3"/>
          )}
    </Circle>
  )
}
