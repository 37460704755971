import { Box, Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'
import { NextLink } from '../../'
import type React from 'react'
import { HiChevronRight } from 'react-icons/hi'

export interface IBreadcrumbsProps {
  data: Array<{
    label: string
    slug?: string
  }>
  shortenBreadcrumbs?: boolean
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ data, shortenBreadcrumbs }) => {
  if ((data.length > 2) && shortenBreadcrumbs) {
    data = data.slice(-2)
    data.unshift({ label: '...' })
  }
  return (
    <Breadcrumb
      fontSize="md"
      fontWeight="medium"
      color="secondaryText.400"
      separator={<Box as={HiChevronRight} color="secondaryText.300"/>}
    >
      {data.map((breadcrumb, index) => (
        <BreadcrumbItem key={breadcrumb.label} isCurrentPage={index === data.length - 1}>
          {breadcrumb.slug
            ? (
              <NextLink
                onClick={index === data.length - 1 ? (e) => (e.preventDefault) : undefined}
                href={breadcrumb.slug}
                fontWeight={index === data.length - 1 ? 'semibold' : 'inherit'}
              >
                {breadcrumb.label}
              </NextLink>
              )
            : (
              <Box fontWeight={index === data.length - 1 ? 'semibold' : 'inherit'}>
                {breadcrumb.label}
              </Box>
              )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}
