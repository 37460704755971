import type { GetStaticPropsResult } from 'next'

export class NotFoundError extends Error {
  constructor (message?: string) {
    super(message)
    this.name = 'NotFoundError'
  }
}

export class InternalServerError extends Error {
  constructor (message?: string) {
    super(message)
    this.name = 'InternalServerError'
  }
}

export function handlePrefetchError<T> (error: unknown | any): GetStaticPropsResult<T> {
  if (error instanceof NotFoundError) {
    return {
      notFound: true,
      revalidate: 600,
    }
  } else {
    console.log('=============== ERROR ===============')
    console.log('There was an error building the page')
    console.log(error.message)
    console.log('=====================================')
    return ({
      notFound: true,
      revalidate: 1,
    })
    // throw new InternalServerError(error.message)
  }
}
