import type { CheckboxGroupProps, StackProps } from '@chakra-ui/react'
import { Box, Checkbox, CheckboxGroup, FormLabel, Stack } from '@chakra-ui/react'

export type IFilterCheckboxProps = Omit<CheckboxGroupProps, 'onChange'> & {
  options: Array<{ label: string, id: string, count?: number }>
  label?: string
  onChange?: (value: string[]) => void
  spacing?: StackProps['spacing']
}

export const FilterCheckbox = (props: IFilterCheckboxProps) => {
  const { options, label, spacing = '2', ...rest } = props

  return (
    <Stack as="fieldset" spacing={spacing}>
      {label && (
        <FormLabel fontWeight="semibold" as="legend" mb="0">
          {label}
        </FormLabel>
      )}
      <CheckboxGroup {...rest}>
        {options.map((option) => (
          <Checkbox key={option.id} value={option.id} colorScheme="blue">
            <span>{option.label}</span>
            {option.count != null && (
              <Box as="span" color="gray.500" fontSize="sm">
                {` (${option.count})`}
              </Box>
            )}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Stack>
  )
}
