import type { DrawerProps } from '@chakra-ui/react'
import { Button, CloseButton, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, Flex, Link, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useIntl } from 'react-intl'

export type AddFilterDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose' | 'children'> & {
  onClickCancel?: VoidFunction
  isCancelDisabled?: boolean
  onClickApply?: VoidFunction
  onClearAll?: VoidFunction
}
export const FilterDrawer: FC<AddFilterDrawerProps> = ({ onClose, onClearAll, isOpen, children, onClickApply }) => {
  const intl = useIntl()
  return (
    <Drawer
      placement="bottom"
      isFullHeight
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerContent>
        <DrawerHeader px="4" borderBottomWidth="1px">
          <Flex justify="space-between" align="center">
            <CloseButton onClick={onClose}/>
            <Text fontWeight="semibold" fontSize="md">
              {intl.formatMessage({ id: '--filter-by' })}
            </Text>
            <Link
              textDecor="underline"
              fontSize="sm"
              onClick={() => {
                onClearAll?.()
              }}
            >
              {intl.formatMessage({ id: '--clear' })}
            </Link>
          </Flex>
        </DrawerHeader>
        <DrawerBody padding="6">{children}</DrawerBody>
        <DrawerFooter px="4" borderTopWidth="1px">
          <Button
            width="full"
            size="lg"
            fontSize="md"
            colorScheme="blue"
            onClick={() => {
              onClickApply?.()
              onClose()
            }}
          >
            {intl.formatMessage({ id: '--show-results' })}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
