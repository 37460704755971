import type { ButtonProps } from '@chakra-ui/react'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import type React from 'react'

export interface IDeleteModalProps {
  modalProps: {
    deleteButtonProps: ButtonProps & {
      label: string
      functionOnClick: () => void
    }
    modalHeader?: string
    modalText?: string
    dontDeleteButtonLabel?: string
  }
  primaryButtonProps?: ButtonProps & {
    label: string
  }
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({ modalProps, primaryButtonProps }) => {
  const { isOpen: deleteModalIsOpen, onOpen: deleteModalOnOpen, onClose: deleteModalOnClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={deleteModalOnOpen}
        {...primaryButtonProps}
      >{primaryButtonProps?.label}
      </Button>
      <Modal isOpen={deleteModalIsOpen} onClose={deleteModalOnClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>{modalProps.modalHeader}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            {modalProps.modalText}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={modalProps.deleteButtonProps.colorScheme}
              mr={3}
              isLoading={modalProps.deleteButtonProps.isLoading}
              {...modalProps.deleteButtonProps}
              onClick={() => {
                modalProps.deleteButtonProps.functionOnClick()
                deleteModalOnClose()
              }}
            >
              {modalProps.deleteButtonProps.label}
            </Button>
            <Button mr={3} onClick={deleteModalOnClose}>
              {modalProps.dontDeleteButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
