import type React from 'react'
import { FormattedNumber } from 'react-intl'

export interface ICurrencyProps {
  amount: number
  currencyCode: string
}

export const Currency: React.FC<ICurrencyProps> = ({ amount, currencyCode }) => {
  return (
    <FormattedNumber
      value={amount}
      style="currency"
      currency={currencyCode}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  )
}
