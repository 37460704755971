import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, IconButton, Stack, StackDivider, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { Currency, FiRsPlusSmall } from '@stocker/ui-components/design-system'
import type { useCreateConfigVariantLineMutation } from '@stocker/codegen/configurator-backend'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

export interface IAccessory {
  id: string
  name: string
  sku: string
  price: number
}

export interface ISystemAccessoriesSelectProps {
  currencyCode?: string
  accesories: IAccessory[]
  createConfigVariantLine?: ReturnType<typeof useCreateConfigVariantLineMutation>
  configurationId: string
}

export const SystemAccessoriesSelect: React.FC<PropsWithChildren<ISystemAccessoriesSelectProps>> = ({ children, currencyCode = 'EUR', accesories, createConfigVariantLine, configurationId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { formatMessage } = useIntl()
  const toast = useToast()

  return (
    <>
      <Box onClick={onOpen}>
        {children}
      </Box>
      <Drawer
        placement="right"
        onClose={onClose}
        isOpen={isOpen}
        size="md"
      >
        <DrawerOverlay
          motionProps={{
            initial: 'none',
            animate: 'none',
            exit: 'none',
          }}
        />
        {/* motionProps are set to none here because of chakra issue with drawers
          https://github.com/chakra-ui/chakra-ui/issues/7091 */}
        <DrawerContent
          motionProps={{
            initial: 'none',
            animate: 'none',
            exit: 'none',
          }}
        >
          <DrawerCloseButton color="white"/>
          <DrawerHeader bg="gray.500" color="white" borderBottomWidth="1px" p={{ base: 3, xl: 4 }}>
            <Text fontSize={{ base: 'lg', xl: 'xl' }} fontFamily="primary" mb={1}>
              {formatMessage({ id: '--accessories' })}
            </Text>
            <Text fontSize={{ base: 'sm', xl: 'md' }}>
              {formatMessage({ id: '--accessories-subtext' })}
            </Text>
          </DrawerHeader>
          <DrawerBody px={0}>
            <Stack
              divider={<StackDivider borderColor="gray.200"/>}
              spacing={4}
              mt={2}
            >
              {accesories.map(item => {
                return (
                  <HStack key={item.id} justify="space-between" mx={{ base: 3, xl: 4 }}>
                    <Flex direction="column">
                      <Text fontWeight="bold" letterSpacing={0.7} fontSize={{ base: 'sm', xl: 'md' }}>{item.name}</Text>
                      <Box color="secondaryText.400" fontSize={{ base: 'sm', xl: 'md' }} mt={0}>
                        {formatMessage({ id: '--sku' })}:{item.sku}
                      </Box>
                    </Flex>
                    <HStack fontFamily="primary" textAlign="right" fontSize="lg">
                      <Currency amount={item.price / 100} currencyCode={currencyCode}/>
                      <Box w="right-start">
                        <IconButton
                          rounded="sm"
                          colorScheme="primaryBackground"
                          minW="25px"
                          h="25px"
                          aria-label="zubehör hinzufügen"
                          icon={<FiRsPlusSmall fontSize="2xl" color="primaryText.500"/>}
                          ml={8}
                          onClick={() => {
                            createConfigVariantLine?.mutate({
                              input: {
                                configurationSourceKey: 'Accessories',
                                quantity: 1,
                                systemConfigId: configurationId,
                                variantId: item.id,
                              },
                            })

                            toast({
                              title: formatMessage({ id: '--accessory-added-title' }),
                              description: formatMessage({ id: '--accessory-added-description' }),
                              status: 'success',
                              duration: 9000,
                              isClosable: true,
                            })
                          }}
                        />
                        {/* <Popover placement="right-start">
                          {({ onClose }) => (
                            <>
                              <PopoverTrigger>
                                <IconButton rounded="sm" colorScheme="primaryBackground" minW="25px" h="25px" aria-label="zubehör hinzufügen" icon={<FiRsPlusSmall fontSize="2xl" color="primaryText.500"/>} ml={8}/>
                              </PopoverTrigger>
                              <PopoverContent borderRadius="none">
                                <PopoverArrow/>
                                <PopoverCloseButton/>
                                <PopoverBody p={0}>
                                  <AddItemToOrder
                                    onSubmit={quantity => {
                                      // createConfigVariantLine?.mutate({ input: { configurationSourceKey: 'Accessories', quantity, systemConfigId: configurationId, variantId: item.id } })
                                      // onClose()
                                    }}
                                    price={item.price}
                                    currencyCode={currencyCode}
                                    quantity={{ min: 1, max: 999 }}
                                    closePopover={onClose}
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </>
                          )}
                        </Popover> */}
                      </Box>
                    </HStack>
                  </HStack>
                )
              })}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
