import { Box, Button, Flex, HStack, IconButton, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { A11CommerceConfig } from '@stocker/commerce-config'
import { ProductVariantSelector, WishlistModal } from '@stocker/ui-components/custom'
import type { IVariant, IHeading, IImage } from '../../'
import { FiRsShoppingCart, IncrementAmountInput, NextLink, ProductInformation } from '../../'
import NextImage from 'next/image'
import { useActiveOrderNavigationQuery, useAddItemToOrderMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useCallback, useState } from 'react'
import { BsArrowUpRight } from 'react-icons/bs'
import { useIntl } from 'react-intl'

export interface ICurrentRefinemt {
  min: number | undefined
  max: number | undefined
}

export interface IProductCardProps {
  heading: IHeading
  image: IImage
  description?: string
  sku?: string
  detailLink: string
  variants?: IVariant[]
  layout?: 'grid' | 'list'
  onVariantChange?: (variant: IVariant | null) => void
  hideResetButton?: boolean
  hidePrice?: boolean
  hideStock?: boolean
  userLoggedIn?: boolean
  discountPrice?: number
  basePrice?: number
  onAddToCart?: (selectedVariant: IVariant | null | undefined, quantity: number) => void
  isAddToCartLoading?: boolean
  searchString?: string
  currentRefinemnt?: ICurrentRefinemt
  // TODO: Remove this prop in the future
  // added in this pull request https://github.com/alpin11/stocker-webshop-frontend/pull/471
  doNotUseNextLink?: boolean
}

export interface IProductVariantOption {
  label: string
  values: IProductVariantOptionValue[]
}

export interface IProductVariantOptionValue {
  value: string
  id: string | number
}

export const ProductCard: React.FC<IProductCardProps> = ({
  heading,
  image,
  description,
  sku,
  detailLink,
  discountPrice,
  basePrice,
  layout = 'grid',
  variants = [],
  onVariantChange = (payload) => {},
  hideResetButton = false,
  hidePrice = false,
  hideStock = false,
  userLoggedIn = false,
  searchString,
  currentRefinemnt,
  doNotUseNextLink,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const addItemToOrder = useAddItemToOrderMutation({
    onSuccess: () => {
      toast({
        title: 'Produkt hinzugefügt',
        description: 'Das gewählte Produkt wurde erfolgreich in den Warenkorb gelegt',
        status: 'success',
        duration: 2000,
      })
      queryClient.invalidateQueries({ queryKey: useActiveOrderNavigationQuery.getKey() })
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: 'Error',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const onAddToCart = useCallback(
    (selectedVariant: IVariant | null | undefined, quantity: number) => {
      if (selectedVariant?.productVariantId) {
        addItemToOrder.mutate({ productVariantId: String(selectedVariant.productVariantId), quantity })
      }
    },
    [addItemToOrder],
  )

  return (
    <Box width="100%" height="100%">
      {layout === 'grid' && (
        <ProductCardGridVariant
          heading={heading}
          image={image}
          description={description}
          detailLink={detailLink}
          sku={sku}
          basePrice={basePrice}
          discountPrice={discountPrice}
          variants={variants}
          hideResetButton={hideResetButton}
          hidePrice={hidePrice}
          hideStock={hideStock}
          onVariantChange={onVariantChange}
          userLoggedIn={userLoggedIn}
          onAddToCart={onAddToCart}
          isAddToCartLoading={addItemToOrder.isLoading}
          searchString={searchString}
          currentRefinemnt={currentRefinemnt}
          doNotUseNextLink={doNotUseNextLink}
        />
      )}
      {layout === 'list' && (
        <>
          <Box display={{ base: 'none', lg: 'block' }}>
            <ProductCardListingVariant
              heading={heading}
              image={image}
              description={description}
              detailLink={detailLink}
              sku={sku}
              basePrice={basePrice}
              discountPrice={discountPrice}
              variants={variants}
              hideResetButton={hideResetButton}
              hidePrice={hidePrice}
              hideStock={hideStock}
              onVariantChange={onVariantChange}
              userLoggedIn={userLoggedIn}
              onAddToCart={onAddToCart}
              isAddToCartLoading={addItemToOrder.isLoading}
              searchString={searchString}
              currentRefinemnt={currentRefinemnt}
            />
          </Box>
          <Box display={{ base: 'block', lg: 'none' }}>
            <ProductCardGridVariant
              heading={heading}
              image={image}
              description={description}
              detailLink={detailLink}
              sku={sku}
              basePrice={basePrice}
              discountPrice={discountPrice}
              variants={variants}
              hideResetButton={hideResetButton}
              hidePrice={hidePrice}
              hideStock={hideStock}
              onVariantChange={onVariantChange}
              userLoggedIn={userLoggedIn}
              onAddToCart={onAddToCart}
              isAddToCartLoading={addItemToOrder.isLoading}
              searchString={searchString}
              currentRefinemnt={currentRefinemnt}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export const ProductCardGridVariant: React.FC<IProductCardProps> = ({ doNotUseNextLink, isAddToCartLoading, currentRefinemnt, heading, image, description, sku, detailLink, variants, onVariantChange, discountPrice, basePrice, hideResetButton = false, hidePrice = false, hideStock = false, userLoggedIn = false, onAddToCart, searchString }) => {
  const intl = useIntl()

  const [selectedVariant, setSelectedVariant] = useState<IVariant | null>()

  const [amount, setAmount] = useState(1)

  return (
    <Stack
      border="1px"
      borderColor="primaryText.550"
      p={{ base: '10px', md: '20px' }}
      rounded="md"
      width="100%"
      height="100%"
      spacing="15px"
      position="relative"
    >
      {doNotUseNextLink
        ? (
          <a href={detailLink}>
            <Flex
              justify="center"
              align="center"
              pos="relative"
              height="150px"
              width="100%"
            >
              <NextImage
                src={image.fullpath ?? ''}
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
                alt="Product Image"
              />
            </Flex>
          </a>
          )
        : (
          <NextLink href={detailLink}>
            <Flex
              justify="center"
              align="center"
              pos="relative"
              height="150px"
              width="100%"
            >
              <NextImage
                src={image.fullpath ?? ''}
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
                alt="Product Image"
              />
            </Flex>
          </NextLink>
          )}
      {doNotUseNextLink
        ? (
          <a href={detailLink}>
            <ProductInformation
              heading={heading}
              text={description}
              subHeadings={sku
                ? [
                    {
                      text: `Artikel-Nr: ${sku}`,
                      type: 'h6',
                      size: 'md',
                      color: 'accent.500',
                    }]
                : []}
            />
          </a>
          )
        : (
          <NextLink href={detailLink}>
            <ProductInformation
              heading={heading}
              text={description}
              subHeadings={sku
                ? [
                    {
                      text: `Artikel-Nr: ${sku}`,
                      type: 'h6',
                      size: 'md',
                      color: 'accent.500',
                    }]
                : []}
            />
          </NextLink>
          )}
      <Spacer/>
      {(variants && variants.length > 0) && (
        <ProductVariantSelector
          variantSelectorProps={{
            variants,
            hideResetButton,
          }}
          hidePrice={hidePrice}
          basePrice={basePrice}
          discountPrice={discountPrice}
          hideStock={hideStock}
          onVariantChange={(variant: IVariant | null) => {
            setSelectedVariant(variant)
            onVariantChange?.(variant)
          }}
          searchString={searchString}
          currentRefinemnt={currentRefinemnt}
          enablePreSelectedOption
        />
      )}
      <HStack justify="space-between">
        {(selectedVariant?.productVariantId && userLoggedIn) && (
          <HStack>
            <IncrementAmountInput
              min={1}
              max={999}
              amount={amount}
              onChange={setAmount}
            />
            <IconButton
              rounded="md"
              h="42px"
              w="42px"
              aria-label="Cart Button"
              onClick={() => {
                if (onAddToCart) { onAddToCart(selectedVariant, amount) }
              }}
              disabled={A11CommerceConfig.product.allowOrderingOutOfStockProducts ? false : selectedVariant.stockLevel === 'OUT_OF_STOCK'}
              isLoading={isAddToCartLoading}
              icon={<FiRsShoppingCart fontSize="xl"/>}
            />
          </HStack>
        )}
        {(selectedVariant?.productVariantId && userLoggedIn) && (
          <WishlistModal productId={String(selectedVariant.productVariantId)}/>
        )}
      </HStack>
      {doNotUseNextLink
        ? (
          <a href={detailLink}>
            <Button w="100%" rounded="md" colorScheme="gray">
              <Stack direction="row">
                <BsArrowUpRight/>
                <Text>
                  {intl.formatMessage({ id: '--show-details' })}
                </Text>
              </Stack>
            </Button>
          </a>
          )
        : (
          <NextLink href={detailLink} w="100%">
            <Button w="100%" rounded="md" colorScheme="gray">
              <Stack direction="row">
                <BsArrowUpRight/>
                <Text>
                  {intl.formatMessage({ id: '--show-details' })}
                </Text>
              </Stack>
            </Button>
          </NextLink>
          )}
    </Stack>
  )
}

export const ProductCardListingVariant: React.FC<IProductCardProps> = ({ isAddToCartLoading, currentRefinemnt, heading, image, description, sku, detailLink, variants, onVariantChange, basePrice, discountPrice, hideResetButton = false, hidePrice = false, hideStock = false, userLoggedIn = false, onAddToCart, searchString }) => {
  const intl = useIntl()

  const [selectedVariant, setSelectedVariant] = useState<IVariant | null>()

  const [amount, setAmount] = useState(1)

  return (
    <Stack
      border="1px"
      borderColor="primaryText.550"
      p={{ base: '10px', md: '20px' }}
      rounded="md"
      width="100%"
      height="100%"
      spacing="15px"
      direction="row"
      justify="space-between"
    >
      <Stack direction="row">
        <NextLink href={detailLink}>
          <Flex
            justify="center"
            align="center"
            pos="relative"
            height="150px"
            width="150"
            mr="10px"
          >
            <NextImage
              src={image.fullpath ?? ''}
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
              }}
              alt="Product Image"
            />
          </Flex>
        </NextLink>
        <NextLink href={detailLink}>
          <ProductInformation
            heading={heading}
            text={description}
            subHeadings={sku
              ? [
                  {
                    text: `Artikel-Nr: ${sku}`,
                    type: 'h6',
                    size: 'md',
                    color: 'accent.500',
                  }]
              : []}
          />
        </NextLink>
      </Stack>
      <Stack justify="space-between">
        {/* <Stack direction="row" justify="right">
          <IconButton aria-label="plus button" icon={<AiOutlinePlus/>}/>
          <IconButton aria-label="menu button" icon={<BsThreeDots/>}/>
        </Stack> */}
        <Box minWidth="250px">
          <Spacer/>
          {(variants && variants.length > 0) && (
            <Box mb="15px">
              <ProductVariantSelector
                variantSelectorProps={{
                  variants,
                  hideResetButton,
                }}
                hidePrice={hidePrice}
                basePrice={basePrice}
                discountPrice={discountPrice}
                hideStock={hideStock}
                onVariantChange={(variant: IVariant | null) => {
                  setSelectedVariant(variant)
                  onVariantChange?.(variant)
                }}
                searchString={searchString}
                currentRefinemnt={currentRefinemnt}
                enablePreSelectedOption
              />
            </Box>
          )}
          <HStack pb={4} justify="space-between">
            {(selectedVariant?.productVariantId && userLoggedIn) && (
              <HStack>
                <IncrementAmountInput
                  min={1}
                  max={999}
                  amount={amount}
                  onChange={setAmount}
                />
                <IconButton
                  rounded="md"
                  h="42px"
                  w="42px"
                  aria-label="Cart Button"
                  onClick={() => {
                    if (onAddToCart) { onAddToCart(selectedVariant, amount) }
                  }}
                  disabled={A11CommerceConfig.product.allowOrderingOutOfStockProducts ? false : selectedVariant.stockLevel === 'OUT_OF_STOCK'}
                  isLoading={isAddToCartLoading}
                  icon={<FiRsShoppingCart fontSize="xl"/>}
                />
              </HStack>
            )}
            {(selectedVariant?.productVariantId && userLoggedIn) && (
              <WishlistModal productId={String(selectedVariant.productVariantId)}/>
            )}
          </HStack>
          <NextLink href={detailLink} w="100%">
            <Button w="100%" rounded="md" colorScheme="gray">
              <Stack direction="row">
                <BsArrowUpRight/>
                <Text>
                  {intl.formatMessage({ id: '--show-details' })}
                </Text>
              </Stack>
            </Button>
          </NextLink>
        </Box>
      </Stack>
    </Stack>
  )
}
