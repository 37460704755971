import CryptoJS from 'crypto-js'

// encrypt
export function encryptBase64 (input: any) {
  const rawStr = JSON.stringify(input)
  const wordArray = CryptoJS.enc.Utf8.parse(rawStr)
  const base64 = CryptoJS.enc.Base64.stringify(wordArray)
  return base64
}

// decrypt
export function decryptBase64 (input: string) {
  const parsedWordArray = CryptoJS.enc.Base64.parse(input)
  const parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8)
  return JSON.parse(parsedStr)
}
