import type { IntlShape } from 'react-intl'
import type { ILinkSecondaryProps } from '@stocker/ui-components/design-system'

export function getContactInformation (intl: IntlShape): ILinkSecondaryProps[] {
  return [
    {
      href: `tel:${intl.formatMessage({ id: 'config--mobile-phone-number' }).replace(/\s/g, '')}`,
      text: intl.formatMessage({ id: 'config--mobile-phone-number' }),
      textShort: intl.formatMessage({ id: '--call' }),
      mode: 'mobilephone',
    },
    {
      href: `tel:${intl.formatMessage({ id: 'config--fax-number' }).replace(/\s/g, '')}`,
      text: intl.formatMessage({ id: 'config--fax-number' }),
      textShort: intl.formatMessage({ id: '--fax' }),
      mode: 'fax',
    },
    {
      // href: `mailto:${intl.formatMessage({ id: 'config--email-adress' })}`,
      // text: intl.formatMessage({ id: 'config--email-adress' }),
      // TODO: remove hardcoded email once pimcore-sync works again
      href: `mailto:${intl.formatMessage({ id: 'office@stocker.tirol' })}`,
      text: intl.formatMessage({ id: 'office@stocker.tirol' }),
      textShort: intl.formatMessage({ id: '--email' }),
      mode: 'email',
    },
  ]
}
