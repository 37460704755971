import { Box, Button, Divider, Grid, GridItem, Heading, HStack, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { FiRsAngleRight, FiRsMagicWand, FiRsInfo, FiRsCrossSmall, NextLink } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import React from 'react'
import { useIntl } from 'react-intl'

interface ISystem {
  id: string
  name: string
  brennstoff: string
  druck: string
  raumluft: string
  ausfuehrung: string
  buttonIsDisabled?: boolean
}

export interface ISystemSelectorProps {
  onClick: (systemId: string) => void
  systems: ISystem[]
}

export const SystemSelector: React.FC<ISystemSelectorProps> = ({ systems, onClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { formatMessage } = useIntl()

  return (
    <Box my={{ base: 5, xl: 8 }}>
      <Grid
        templateColumns="repeat(6, 1fr)"
        columnGap={10}
        rowGap={4}
        alignItems="center"
        fontSize="xl"
        // font size might be too big
      >
        <GridItem w="270px">
          <Box pb={3}>
            <NextImage
              src="/img/logo_header.svg"
              width={120}
              height={35}
              alt="Stocker Logo"
            />
            <Heading lineHeight={1} fontSize="4xl">
              {formatMessage({ id: '--configurator' })}
            </Heading>
          </Box>
        </GridItem>
        <Text fontFamily="primary" pt={10} color="black">{formatMessage({ id: '--fuel' })}</Text>
        <Text fontFamily="primary" pt={10} color="black">{formatMessage({ id: '--pressure' })}</Text>
        <Text fontFamily="primary" pt={10} color="black">{formatMessage({ id: '--room-air' })}</Text>
        <Text fontFamily="primary" pt={10} color="black">{formatMessage({ id: '--version' })}</Text>
        <Box/>
        {systems.map((system, idx) => (
          <React.Fragment key={system.id}>
            <Text textAlign="right" fontFamily="primary">{system.name}</Text>
            <Text>{system.brennstoff}</Text>
            <Text>{system.druck}</Text>
            <Text>{system.raumluft}</Text>
            <Text>{system.ausfuehrung}</Text>
            <Button
              isDisabled={system.buttonIsDisabled}
              onClick={() => { onClick(system.id) }}
              colorScheme="accent"
              _active={{ transform: 'scale(0.98)', transition: 'scale 0.2s ease-in-out' }}
              aria-label="Konfigurieren"
              rightIcon={<FiRsMagicWand/>}
              fontWeight="semibold"
              py={6}
            >{formatMessage({ id: '--configure' })}
            </Button>
            <div/>{idx !== systems.length - 1 && <Divider gridColumnStart={2} gridColumnEnd={6}/>}<div/>
          </React.Fragment>
        ))}
        <GridItem gridColumnStart={2} gridColumnEnd={7}>
          {isOpen
            ? (
              <Box bg="primaryBackground.500" color="primaryText.500" p={6}>
                <HStack mb={4}>
                  <Text fontFamily="primary">
                    {formatMessage({ id: 'configurator-system-selector--which-system-is-for-me' })}
                  </Text>
                  <Spacer/>
                  <FiRsCrossSmall
                    fontSize="3xl"
                    onClick={onClose}
                    cursor="pointer"
                  />
                </HStack>
                <HStack>
                  <Text color="primaryText.550">
                    {formatMessage({ id: 'configurator-system-selector--system-finder-tooltip' })}
                  </Text>
                  <Spacer/>
                  <NextLink href="https://stocker-kaminsysteme.com/systemfinder">
                    <Button
                      colorScheme="primaryBackground"
                      _hover={{ bg: 'primaryBackground.400' }}
                      variant="outline"
                      color="primaryText.550"
                      px={8}
                      rightIcon={<FiRsAngleRight/>}
                    >{formatMessage({ id: 'configurator-system-selector--to-system-finder' })}
                    </Button>
                  </NextLink>
                </HStack>
              </Box>
              )
            : (
              <HStack p={6}>
                <Spacer/>
                <Text onClick={onOpen} fontFamily="primary" pr={3} cursor="pointer">
                  {formatMessage({ id: 'configurator-system-selector--which-system-is-for-me' })}
                </Text>
                <FiRsInfo onClick={onOpen} fontSize="2xl" cursor="pointer"/>
              </HStack>
              )}
        </GridItem>
      </Grid>
    </Box>
  )
}
