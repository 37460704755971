import { Box, Button, Flex, HStack, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Td, Text, Tooltip, Tr, useToast } from '@chakra-ui/react'
import { MaskedInputFlushed } from '@stocker/ui-components/custom'
import { FiRsTrash, FiRsWarningTriangle } from '../../'
import { formatHeadlineColor, usePriceType } from '@stocker/ui-components/helpers'
import Image from 'next/image'
import { useRouter } from 'next/router'
import type { OfferPriceFragment } from '@stocker/codegen/vendure'
import { useActiveChannelQuery, useCustomerSpecificPriceQuery, useDeleteOfferPriceMutation, useUpdateOfferPriceMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useEffect } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'

export interface IArticleListItemProps {
  item: OfferPriceFragment & { isNew?: boolean, isChanged?: boolean }
  px: { base: number, md: number }
  py: { base: number, md: number }
  onUpdate?: () => void
  setListData?: React.Dispatch<React.SetStateAction<OfferPriceFragment[]>>
}

export const ArticleListItem: React.FC<IArticleListItemProps> = ({ item, py, px, onUpdate, setListData }) => {
  const { formatMessage } = useIntl()
  const toast = useToast()
  const router = useRouter()

  const { priceType } = usePriceType()
  const { data: activeChannel } = useActiveChannelQuery()

  const customerSpecificPrice = useCustomerSpecificPriceQuery({
    channelCode: activeChannel?.activeChannel.code ?? '__default_channel__',
    variantId: item.productVariant.id,
  }).data?.customerSpecificPrice

  function getArticlePrice () {
    if (priceType === '0' && customerSpecificPrice?.price && ((customerSpecificPrice.price - customerSpecificPrice.basePrice) * 100 / (customerSpecificPrice.basePrice)).toFixed(0) !== '0') {
      return (
        <>
          <HStack>
            <Text display="inline" fontSize="sm" color="red" as="del" fontWeight="bold" fontFamily="primary">
              <FormattedNumber
                value={customerSpecificPrice.basePrice / 100}
                style="currency"
                currency={item.currencyCode}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Text>
            <Text display="inline" color="red" fontSize="md" fontFamily="primary">
              {`${((customerSpecificPrice.price - customerSpecificPrice.basePrice) * 100 / (customerSpecificPrice.basePrice)).toFixed(0)}%`}
            </Text>
          </HStack>
          <FormattedNumber
            value={customerSpecificPrice.price / 100}
            style="currency"
            currency={item.currencyCode}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        </>
      )
    }

    if (priceType === '1' && customerSpecificPrice?.basePrice) {
      return (
        <FormattedNumber
          value={customerSpecificPrice.basePrice / 100}
          style="currency"
          currency={item.currencyCode}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      )
    }

    return (
      <FormattedNumber
        value={item.productVariant.price / 100}
        style="currency"
        currency={item.currencyCode}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    )
  }

  const { mutate: updateOfferPrice, isLoading: isLoadingUpdateOfferPrice } = useUpdateOfferPriceMutation({
    onSuccess: data => {
      switch (data.updateOfferPrice.__typename) {
        case 'OfferPrice':
          onUpdate?.()
          break
        case 'EntityNotFoundError':
          toast({
            title: formatMessage({ id: 'account--offer-article-update-error' }),
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
      }
    },
  })
  const { mutate: deleteOfferPrice, isLoading: isDeleteOfferPriceLoading } = useDeleteOfferPriceMutation({
    onSuccess: data => {
      switch (data.deleteOfferPrice.__typename) {
        case 'Success':
          onUpdate?.()
          break
        case 'EntityNotFoundError':
          toast({
            title: formatMessage({ id: 'account--offer-article-delete-error' }),
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
      }
    },
  })

  useEffect(() => {
    if (item.isNew) {
      // when changing the priceType updates the product prices accordingly as long as no custom price is set
      setListData?.(prev => prev.map(prevItem => {
        if (priceType === '0' && item.price === customerSpecificPrice?.basePrice) {
          if (prevItem.id === item.id) {
            return {
              ...item,
              isChanged: true,
              price: customerSpecificPrice.price,
            }
          }
        }

        if (priceType === '1' && item.price === customerSpecificPrice?.price) {
          if (prevItem.id === item.id) {
            return {
              ...item,
              isChanged: true,
              price: customerSpecificPrice.basePrice,
            }
          }
        }

        return prevItem
      }))
    }
  }, [customerSpecificPrice, setListData, priceType, item])

  return (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    <Tr key={item.id} borderBottom={(item.isNew || item.isChanged) ? '2px solid' : undefined} borderBottomColor={(item.isNew || item.isChanged) ? 'yellow.400' : undefined} maxW="100%" overflow="hidden">
      <Td>
        <Flex gap={4} maxW="100%" whiteSpace="normal">
          {item.productVariant.featuredAsset && (
            <Box w={{ base: '30px', md: '50px' }} h={{ base: '30px', md: '50px' }} pos="relative">
              <Image
                src={`${item.productVariant.featuredAsset.source}?w=75&h=75&fit=resize`}
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
                alt="Product Image"
              />
            </Box>
          )}
          <Flex flexDir="column" justifyContent="space-between">
            <Text fontSize={{ base: 'md', md: 'lg' }}>{formatHeadlineColor(item.productVariant.product.name)}</Text>
            <Text fontSize={{ base: 'sm', md: 'md' }}>{formatMessage({ id: 'account--offer-article-sku' })}: {item.productVariant.sku}</Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <NumericFormat
          value={item.price / 100}
          customInput={MaskedInputFlushed}
          allowedDecimalSeparators={[',']}
          decimalSeparator=","
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator="."
          thousandsGroupStyle="thousand"
          onValueChange={e => setListData?.((prev) => prev.map((prevItem) => {
            if (prevItem.id === item.id) {
              return {
                ...item,
                isChanged: true,
                price: (e.floatValue ?? 0) * 100,
              }
            }
            return prevItem
          }),
          )}
        />
      </Td>
      <Td>
        {getArticlePrice()}
      </Td>
      <Td >
        <Flex gap="1" justify="flex-end">
          {!item.isNew && (
            <Button
              isLoading={isLoadingUpdateOfferPrice}
              isDisabled={isDeleteOfferPriceLoading}
              // create new no longer needed, is handled by creatMultiple in parent
              onClick={() => {
                updateOfferPrice({
                  id: item.id,
                  price: item.price,
                })
              }}
            >
              {formatMessage({ id: '--update' })}
            </Button>
          )}
          {item.isNew && (
            <Button onClick={() => onUpdate?.()}>{formatMessage({ id: 'account--offer-article-table--cancel-new-item' })}</Button>
          )}
          {!item.isNew && (
            <Popover>
              <PopoverTrigger>
                <IconButton isLoading={isDeleteOfferPriceLoading} icon={<FiRsTrash/>} aria-label="Delete Entry"/>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverHeader>{formatMessage({ id: 'account--offer-article-table--confirm-delete-header' })}</PopoverHeader>
                <PopoverBody>{formatMessage({ id: 'account--offer-article-table--confirm-delete-message' })}</PopoverBody>
                <PopoverFooter textAlign="right">
                  <Button isLoading={isDeleteOfferPriceLoading} colorScheme="red" onClick={() => { deleteOfferPrice({ id: item.id }) }}>{formatMessage({ id: '--delete' })}</Button>
                </PopoverFooter>

              </PopoverContent>
            </Popover>
          )}
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          {(item.isNew || item.isChanged) && (
            <Tooltip label={formatMessage({ id: 'account--offer-article-table--unsaved-changes' })} fontSize="md">
              <Button _hover={{ backgroundColor: 'transparent' }} variant="ghost" cursor="default">
                <Icon as={FiRsWarningTriangle}/>
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}
