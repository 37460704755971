import { useToast } from '@chakra-ui/react'
import type { IBreadcrumbsProps, IHeading } from '@stocker/ui-components/design-system'
import { AccountPageSideBar, FiRsAddressBook, FiRsBadgePercent, FiRsCommentQuestion, FiRsDownload, FiRsFileInvoiceDollar, FiRsFolder, FiRsHeart, FiRsHome, FiRsList, FiRsSettings, FiRsUndoAlt } from '@stocker/ui-components/design-system'
import { useQueryClient } from '@tanstack/react-query'
import { roles, usePermittedCustomer } from '@stocker/ui-components/helpers'
import { useRouter } from 'next/router'
import { useStaffListingQuery } from '@stocker/codegen/pimcore'
import { useActiveCompanyQuery, useLogoutUserMutation } from '@stocker/codegen/vendure'
import type { FC, PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export interface IAccountPageWrapperProps {
  welcomeHeading: IHeading
  breadcrumbs: IBreadcrumbsProps
}

function getContactPersonFilter (contactId?: string) {
  if (!contactId) {
    return ''
  }
  return `{"shortCode": "${contactId}" }`
}

export const AccountPageWrapper: FC<PropsWithChildren<IAccountPageWrapperProps>> = ({ breadcrumbs, welcomeHeading, children }) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const router = useRouter()
  const intl = useIntl()

  // check if user is logged in and reroute
  const { isPermitted: isLoggedIn } = usePermittedCustomer()
  // check permissions only
  const { isPermitted } = usePermittedCustomer({ permissions: [roles.fullAccess], redirect: false })

  const subPages = [
    {
      category: intl.formatMessage({ id: 'account-wrapper--category-personal' }),
      items: [
        {
          text: intl.formatMessage({ id: 'account-wrapper--start-page' }),
          absolutePath: '/account',
          icon: <FiRsHome/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--my-data' }),
          absolutePath: '/account/settings',
          icon: <FiRsFolder/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--addresses' }),
          absolutePath: '/account/addresses',
          icon: <FiRsAddressBook/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--wishlist' }),
          absolutePath: '/account/wishlist',
          icon: <FiRsHeart/>,
        },
      ],
    },
    {
      category: intl.formatMessage({ id: 'account-wrapper--category-company' }),
      items: [
        ...(isPermitted
          ? [
              {
                text: intl.formatMessage({ id: 'account-wrapper--conditions' }),
                absolutePath: '/account/conditions',
                icon: <FiRsBadgePercent/>,
              },
              {
                text: intl.formatMessage({ id: 'account--order-history' }),
                absolutePath: '/account/orders',
                icon: <FiRsList/>,
              },
              // {
              //   text: intl.formatMessage({ id: 'account-wrapper--receipts' }),
              //   absolutePath: '/account/receipts',
              //   icon: <FiRsDocument/>,
              // },
            ]
          : []
        ),
      ],
    },
    {
      category: intl.formatMessage({ id: 'account-wrapper--category-offer-tool' }),
      items: [
        {
          text: intl.formatMessage({ id: 'account-wrapper--offer-articles' }),
          absolutePath: '/account/offer/articles',
          icon: <FiRsList/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--offers' }),
          absolutePath: '/account/offer',
          icon: <FiRsFileInvoiceDollar/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--offer-settings' }),
          absolutePath: '/account/offer/settings',
          icon: <FiRsSettings/>,
        },
      ],
    },
    // {
    //   category: intl.formatMessage({ id: 'account-wrapper--category-configurator' }),
    //   items: [
    //     {
    //       text: intl.formatMessage({ id: 'account-wrapper--configurations' }),
    //       absolutePath: '/account/configurations',
    //       icon: <FiRsMagicWand/>,
    //     },
    //   ],
    // },
    {
      category: intl.formatMessage({ id: 'account-wrapper--category-other' }),
      items: [
        {
          text: intl.formatMessage({ id: 'account-wrapper--configurator' }),
          externalLink: process.env.NEXT_PUBLIC_CONFIGURATOR_FRONTEND_URL ?? 'https://konfigurator.stocker-kaminsysteme.com/',
          icon: <FiRsSettings/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--downloads' }),
          absolutePath: '/account/downloads',
          icon: <FiRsDownload/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--faq' }),
          absolutePath: '/account/faq',
          icon: <FiRsCommentQuestion/>,
        },
        {
          text: intl.formatMessage({ id: 'account-wrapper--return' }),
          absolutePath: '/account/return',
          icon: <FiRsUndoAlt/>,
        },
      ],
    },
  ]

  // Get Contact Person
  const { data: activeCompany } = useActiveCompanyQuery()
  const contactPersonId = activeCompany?.activeCompany ? activeCompany.activeCompany.termsOfPayment?.contactPerson ?? 'GS' : undefined
  const { data: contactPerson } = useStaffListingQuery({ shortCodeFilter: getContactPersonFilter(contactPersonId) }, { enabled: !!contactPersonId })

  const { mutate: logoutUser, isLoading } = useLogoutUserMutation({
    onSuccess: () => {
      toast({
        title: intl.formatMessage({ id: '--logout-successful' }),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      queryClient.invalidateQueries()
    },
    onError: () => {
      toast({
        title: intl.formatMessage({ id: '--logout-failed' }),
        description: intl.formatMessage({ id: '--please-try-again' }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const mappedContactPerson = useMemo(() => {
    if (contactPerson?.getStaffListing?.edges) {
      const contact = contactPerson.getStaffListing.edges[0]?.node
      return {
        image: { fullpath: contact?.image?.fullpath ?? '' },
        name: contact?.name ?? '',
        position: contact?.position ?? '',
        telephoneNumber: contact?.phone ?? undefined, // turn null to undefined
        email: contact?.email ?? undefined,
        mobileNumber: contact?.mobile ?? undefined,
        positionInfo: intl.formatMessage({ id: 'account--your-contact-partner' }),
      }
    }
  }, [contactPerson?.getStaffListing?.edges, intl])

  return (
    <AccountPageSideBar
      breadcrumbs={breadcrumbs}
      subPages={subPages.filter(e => e.items.length !== 0)}
      contactPerson={mappedContactPerson}
      welcomeHeading={welcomeHeading}
      onLogOut={async () => {
        await router.push('/')
        logoutUser({})
      }}
      isLoggingOut={isLoading}
      isLoading={!isLoggedIn || !activeCompany?.activeCompany}
    >
      {children}
    </AccountPageSideBar>
  )
}
