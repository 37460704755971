import type { HeadingProps } from '@chakra-ui/react'
import { Box, Heading } from '@chakra-ui/react'
import type { ElementsFragment, LatestNewsQuery, NewsQuery } from '@stocker/codegen/pimcore'
import type { INewsCardProps } from '@stocker/ui-components/custom'
import type { ITextWithImageProps } from '@stocker/ui-components/design-system'
import { TextWithImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import { asComponent, asImageProps, formatHeadlineColor, getDataUrl } from './'

export function prepareNewsResponse(
  newsResponse: NewsQuery,
  previousNewsSlug?: string,
  nextNewsSlug?: string,
) {
  const headerTitleColorOrFallback =
    newsResponse.getNewsItemBySlugOrPath?.newsItem?.headerTitleAccentColor ?? 'accent.500'
  const headerColorMap: Record<string, string> = { red: 'accent.500' }
  const headerTitleColor =
    headerTitleColorOrFallback in headerColorMap
      ? headerColorMap[headerTitleColorOrFallback]
      : headerTitleColorOrFallback

  return {
    elements:
      (newsResponse.getNewsItemBySlugOrPath?.newsItem?.elements?.filter(
        (element) => element?.__typename,
      ) as ElementsFragment[] | undefined) ?? [],
    header: newsResponse.getNewsItemBySlugOrPath?.newsItem?.headerImage?.image
      ? {
          image: asImageProps(newsResponse.getNewsItemBySlugOrPath.newsItem.headerImage.image),
          title: newsResponse.getNewsItemBySlugOrPath.newsItem.headerTitle ?? '',
          titleColor: headerTitleColor,
        }
      : null,
    seo: {
      title: newsResponse.getNewsItemBySlugOrPath?.newsItem?.seoTitle ?? '',
      description: newsResponse.getNewsItemBySlugOrPath?.newsItem?.seoDescription ?? '',
      canonicals:
        (
          newsResponse.getNewsItemBySlugOrPath?.newsItem?.canonicals?.filter(
            (e) => e?.absolutePath,
          ) as Array<{ absolutePath: string }> | undefined
        )?.map((e) => ({ absolutePath: e.absolutePath })) ?? [],
    },
    headline: newsResponse.getNewsItemBySlugOrPath?.newsItem?.name ?? '',
    navigation: renderNewsNavigation(previousNewsSlug, nextNewsSlug),
  }
}

export const getNewsBlocks = (res: NewsQuery) => {
  return res.getNewsItemBySlugOrPath?.newsItem?.elements?.map((element, index) => {
    switch (element?.__typename) {
      case 'fieldcollection_Headline': {
        const props = {
          headlineType: element.headlineType,
          headline: element.headline,
        }
        return (
          <Heading
            fontSize={28}
            lineHeight={1.2}
            mb={5}
            mt={10}
            key={`${element.__typename}-${index}`}
            as={props.headlineType as HeadingProps['as']}
          >
            {formatHeadlineColor(props.headline ?? '')}
          </Heading>
        )
      }
      case 'fieldcollection_Text': {
        const parsedText = asComponent(element.text ?? '')

        return (
          <Box fontSize="lg" mb={6}>
            {parsedText}
          </Box>
        )
      }
      case 'fieldcollection_Image': {
        return (
          <Box>
            <NextImage
              alt=""
              src={element.image?.fullpath ?? ''}
              height="400"
              width="600"
              placeholder={getDataUrl(element.image) ? 'blur' : 'empty'}
              blurDataURL={getDataUrl(element.image)}
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
            />
          </Box>
        )
      }
      case 'fieldcollection_TextWithImage': {
        const props: ITextWithImageProps = {
          text: element.text ?? '',
          image: element.image?.fullpath ?? '',
          imageLeft: element.imagePosition === 'left',
        }

        return (
          <Box mb={6}>
            <TextWithImage {...props} key={`${element.__typename}-${index}`} />
          </Box>
        )
      }
    }
  })
}

export const mapNewsResponseToProps = (
  res: LatestNewsQuery,
  highlightedFirstWord?: string,
  isLoaded?: boolean,
): INewsCardProps[] => {
  return (
    res.getNewsPageListing?.edges?.map((edge, index) => ({
      displayDate: edge?.node?.displayDate ?? '',
      headline: edge?.node?.name ?? '',
      image: asImageProps(edge?.node?.image),
      link: {
        absolutePath: edge?.node?.absolutePath ?? '',
      },
      text: edge?.node?.text ?? '',
      highlightedFirstWord,
      isLoaded,
    })) ?? []
  )
}

export const renderNewsNavigation = (previousNewsSlug?: string, nextNewsSlug?: string) => {
  return {
    previousNewsSlug,
    nextNewsSlug,
  }
}
