import { Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react'
import { HighlightFirstWord } from '@stocker/ui-components/custom'
import { CustomHeading } from '@stocker/ui-components/helpers'
import type React from 'react'
import type { IHeading } from '../..'

export interface IDiscountTableProps {
  heading?: IHeading
  discountList: Array<{
    category: string
    products: Array<{
      categoryNumber?: string
      productName: string
      discountAmount: string
      discountAmountSign?: string
    }>
  }>
}

export const DiscountTable: React.FC<IDiscountTableProps> = ({ discountList, heading }) => {
  return (
    <TableContainer>
      {heading && (
        <CustomHeading heading={heading}/>
      )}
      <Table m={0}>
        {discountList.map((discount, index) => (
          <Tbody key={index}>
            <Tr>
              <Td fontWeight="bold" p={0} pr={{ base: '10px', sm: '30px' }}>
                <HighlightFirstWord productLine={discount.category}/>
              </Td>
              <Td w="100%" p={0}>
                <Table my="15px" variant="unstyled">
                  <Tbody display="flex" flexDirection="column" textAlign="center" justifyContent="space-between">
                    {discount.products.map((product, index) => (
                      <Tr
                        borderBottom={0}
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Td width={{ base: '100%', sm: '25%' }} px={0} textAlign="left" py="5px">{product.categoryNumber}</Td>
                        <Td width={{ base: '100%', sm: '50%' }} px={0} textAlign="center" py="5px" fontWeight="bold">{product.productName}</Td>
                        <Td width={{ base: '100%', sm: '25%' }} px={0} textAlign="right" py="5px" isNumeric>{product.discountAmount} {product.discountAmountSign ?? '%'}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Td>
            </Tr>
          </Tbody>
        ))}
      </Table>
    </TableContainer>
  )
}
