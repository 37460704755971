import { Box, Divider, Heading } from '@chakra-ui/react'
import type React from 'react'
import type { PropsWithChildren } from 'react'

interface IOfferEditorSectionProps {
  title: string
  hideDivider?: boolean
}

export const OfferEditorSection: React.FC<PropsWithChildren<IOfferEditorSectionProps>> = ({ children, hideDivider, title }) => {
  return (
    <Box mt="6">
      <Heading
        as="h2"
        fontSize="lg"
        fontWeight="semibold"
        color="secondaryText.400"
        mb={2}
        textTransform="uppercase"
      >
        {title}
      </Heading>
      {children}
      {!hideDivider && <Divider mt="9" borderColor="gray.300"/>}
    </Box>
  )
}
