/* eslint-disable */
import * as Types from './generated-types';

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export const ConfigurationFragmentDoc = gql`
    fragment Configuration on SystemConfig {
  id
  name
  moduleFieldValues {
    value
    fieldId
  }
  expertCheckStatus
  configVariantLines {
    id
    configurationSourceKey
    variant {
      variantId
      sku
      name {
        de
        en
      }
      images {
        fileName
        fileSize
        frontendFullPath
      }
      price {
        basePrice
        price
      }
    }
    quantity
  }
}
    `;
export const LanguageFragmentDoc = gql`
    fragment Language on TranslatedString {
  de
  en
}
    `;
export const SystemTooltipFragmentDoc = gql`
    fragment SystemTooltip on SystemTooltip {
  title
  description
  image
}
    `;
export const SystemFragmentDoc = gql`
    fragment System on System {
  id
  name
  modules {
    id
    name
    optional
    code
    tooltip {
      ...SystemTooltip
    }
    fields {
      id
      validation {
        min
        max
      }
      name
      valueType
      unit
      options
      defaultValue
      hideConditions {
        field
        operator
        value
        disableIfHidden
        condition
      }
      code
      tooltip {
        ...SystemTooltip
      }
    }
  }
}
    ${SystemTooltipFragmentDoc}`;
 const AcknowledgeExpertCheckDocument = gql`
    mutation AcknowledgeExpertCheck($systemConfigId: String!) {
  acknowledgeExpertCheck(systemConfigId: $systemConfigId) {
    __typename
    ... on SystemConfig {
      id
      name
      expertCheckStatus
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
 const AddConfigToBasketDocument = gql`
    mutation AddConfigToBasket($configId: String!) {
  addConfigToBasket(configId: $configId) {
    ... on Basket {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;
 const ClearBasketDocument = gql`
    mutation ClearBasket {
  clearBasket {
    __typename
    ... on Basket {
      id
    }
    ... on BasketNotFoundError {
      message
    }
  }
}
    `;
 const CreateConfigVariantLineDocument = gql`
    mutation createConfigVariantLine($input: CreateConfigVariantLineInput!) {
  createConfigVariantLine(createConfigVariantLineInput: $input) {
    __typename
  }
}
    `;
 const CreateSystemConfigDocument = gql`
    mutation CreateSystemConfig($systemId: String!) {
  createSystemConfig(systemId: $systemId) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
  }
}
    `;
 const DeleteSystemConfigDocument = gql`
    mutation DeleteSystemConfig($systemConfigId: String!) {
  deleteSystemConfig(systemConfigId: $systemConfigId) {
    ... on SystemConfig {
      id
      name
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;
 const FinishExpertCheckDocument = gql`
    mutation FinishExpertCheck($systemConfigId: String!, $message: String!) {
  finishExpertCheck(systemConfigId: $systemConfigId, message: $message) {
    __typename
    ... on SystemConfig {
      id
      name
      expertCheckStatus
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
 const RemoveBasketLineDocument = gql`
    mutation RemoveBasketLine($id: String!) {
  removeBasketLine(id: $id) {
    ... on BasketLine {
      id
    }
    ... on BasketLineNotFoundError {
      message
    }
  }
}
    `;
 const RemoveConfigVariantLineDocument = gql`
    mutation RemoveConfigVariantLine($configVariantLineId: String!) {
  removeConfigVariantLine(id: $configVariantLineId) {
    ... on ConfigVariantLine {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
 const RequestExpertCheckDocument = gql`
    mutation RequestExpertCheck($id: String!, $message: String!) {
  requestExpertCheck(systemConfigId: $id, message: $message) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
 const SendBasketToOrderDocument = gql`
    mutation SendBasketToOrder($id: String!) {
  sendBasketToOrder(id: $id) {
    __typename
  }
}
    `;
 const UpdateBasketLineDocument = gql`
    mutation UpdateBasketLine($input: UpdateBasketLineInput!) {
  updateBasketLine(updateBasketLineInput: $input) {
    id
    quantity
  }
}
    `;
 const UpdateConfigVariantLineDocument = gql`
    mutation UpdateConfigVariantLine($configVariantLineId: String!, $quantity: Int!) {
  updateConfigVariantLine(
    updateConfigVariantLineInput: {id: $configVariantLineId, quantity: $quantity}
  ) {
    ... on ConfigVariantLine {
      id
      quantity
      variant {
        variantId
      }
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
 const UpdateSystemConfigDocument = gql`
    mutation UpdateSystemConfig($config: UpdateSystemConfigInput!) {
  updateSystemConfig(config: $config) {
    ... on SystemConfig {
      ...Configuration
      system {
        ...System
      }
      calculationErrors {
        configurationSourceKey
        type
        message
        filter
      }
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}`;
 const AccessoriesDocument = gql`
    query Accessories($systemId: String!, $filter: JSONObject!) {
  accessories(systemId: $systemId, filter: $filter) {
    variantId
    sku
    name {
      ...Language
    }
    slug {
      ...Language
    }
    absolutePath {
      ...Language
    }
    images {
      fileName
      fileSize
      frontendFullPath
    }
    price {
      basePrice
      price
    }
  }
}
    ${LanguageFragmentDoc}`;
 const ActiveBasketDocument = gql`
    query ActiveBasket {
  activeBasket {
    id
    basketLines {
      id
      totalCost
      quantity
      systemConfig {
        id
        name
      }
      basket {
        id
        state
      }
    }
  }
}
    `;
 const ActiveCustomerDocument = gql`
    query ActiveCustomer {
  activeCustomer {
    id
    firstName
    lastName
    emailAddress
    title
  }
}
    `;
 const ContactPersonDocument = gql`
    query ContactPerson {
  contactPerson {
    image
    name
    position
    phone
    mobile
    email
  }
}
    `;
 const IsAdminDocument = gql`
    query IsAdmin($companyId: String!) {
  isAdmin(companyId: $companyId)
}
    `;
 const SystemConfigByIdDocument = gql`
    query SystemConfigById($systemConfigId: String!) {
  systemConfig(systemConfigId: $systemConfigId) {
    ...Configuration
    system {
      ...System
    }
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}`;
 const SystemConfigsDocument = gql`
    query SystemConfigs {
  systemConfigs {
    id
    createdAt
    name
    expertCheckStatus
    companyId
    customerId
  }
}
    `;
 const SystemsDocument = gql`
    query Systems {
  systems {
    id
    name
    modules {
      name
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AcknowledgeExpertCheck(variables: Types.AcknowledgeExpertCheckMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AcknowledgeExpertCheckMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AcknowledgeExpertCheckMutation>(AcknowledgeExpertCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcknowledgeExpertCheck', 'mutation', variables);
    },
    AddConfigToBasket(variables: Types.AddConfigToBasketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AddConfigToBasketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddConfigToBasketMutation>(AddConfigToBasketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddConfigToBasket', 'mutation', variables);
    },
    ClearBasket(variables?: Types.ClearBasketMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ClearBasketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ClearBasketMutation>(ClearBasketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ClearBasket', 'mutation', variables);
    },
    createConfigVariantLine(variables: Types.CreateConfigVariantLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateConfigVariantLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateConfigVariantLineMutation>(CreateConfigVariantLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createConfigVariantLine', 'mutation', variables);
    },
    CreateSystemConfig(variables: Types.CreateSystemConfigMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateSystemConfigMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateSystemConfigMutation>(CreateSystemConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSystemConfig', 'mutation', variables);
    },
    DeleteSystemConfig(variables: Types.DeleteSystemConfigMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.DeleteSystemConfigMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteSystemConfigMutation>(DeleteSystemConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSystemConfig', 'mutation', variables);
    },
    FinishExpertCheck(variables: Types.FinishExpertCheckMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.FinishExpertCheckMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.FinishExpertCheckMutation>(FinishExpertCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FinishExpertCheck', 'mutation', variables);
    },
    RemoveBasketLine(variables: Types.RemoveBasketLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveBasketLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveBasketLineMutation>(RemoveBasketLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveBasketLine', 'mutation', variables);
    },
    RemoveConfigVariantLine(variables: Types.RemoveConfigVariantLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveConfigVariantLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveConfigVariantLineMutation>(RemoveConfigVariantLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveConfigVariantLine', 'mutation', variables);
    },
    RequestExpertCheck(variables: Types.RequestExpertCheckMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RequestExpertCheckMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RequestExpertCheckMutation>(RequestExpertCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestExpertCheck', 'mutation', variables);
    },
    SendBasketToOrder(variables: Types.SendBasketToOrderMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SendBasketToOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SendBasketToOrderMutation>(SendBasketToOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SendBasketToOrder', 'mutation', variables);
    },
    UpdateBasketLine(variables: Types.UpdateBasketLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateBasketLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateBasketLineMutation>(UpdateBasketLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBasketLine', 'mutation', variables);
    },
    UpdateConfigVariantLine(variables: Types.UpdateConfigVariantLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateConfigVariantLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateConfigVariantLineMutation>(UpdateConfigVariantLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateConfigVariantLine', 'mutation', variables);
    },
    UpdateSystemConfig(variables: Types.UpdateSystemConfigMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateSystemConfigMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateSystemConfigMutation>(UpdateSystemConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSystemConfig', 'mutation', variables);
    },
    Accessories(variables: Types.AccessoriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AccessoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AccessoriesQuery>(AccessoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Accessories', 'query', variables);
    },
    ActiveBasket(variables?: Types.ActiveBasketQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveBasketQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveBasketQuery>(ActiveBasketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveBasket', 'query', variables);
    },
    ActiveCustomer(variables?: Types.ActiveCustomerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCustomerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCustomerQuery>(ActiveCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCustomer', 'query', variables);
    },
    ContactPerson(variables?: Types.ContactPersonQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ContactPersonQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ContactPersonQuery>(ContactPersonDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContactPerson', 'query', variables);
    },
    IsAdmin(variables: Types.IsAdminQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.IsAdminQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.IsAdminQuery>(IsAdminDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IsAdmin', 'query', variables);
    },
    SystemConfigById(variables: Types.SystemConfigByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SystemConfigByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SystemConfigByIdQuery>(SystemConfigByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SystemConfigById', 'query', variables);
    },
    SystemConfigs(variables?: Types.SystemConfigsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SystemConfigsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SystemConfigsQuery>(SystemConfigsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SystemConfigs', 'query', variables);
    },
    Systems(variables?: Types.SystemsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SystemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SystemsQuery>(SystemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Systems', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;