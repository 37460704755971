import { Box, Select, Text } from '@chakra-ui/react'
import { useIntl } from 'react-intl'

export interface IISPaginationProps {
  currentRefinement: number
  nbPages: number
  refine: (page: string) => void
  createURL: (currentTarget: number) => string
}

export const ISPagination: React.FC<IISPaginationProps> = ({ currentRefinement, nbPages, refine, createURL }) => {
  const intl = useIntl()

  return (
    <>
      <Box>
        <Select
          size={['xs', 'sm', 'md']}
          value={currentRefinement}
          onChange={({ currentTarget: { value } }) => {
            refine(value)
          }}

        >
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1
            return (
              <option key={page} value={page}>
                {page}
              </option>
            )
          })}
        </Select>
      </Box>
      <Text fontSize={['sm', 'md']}>{`${intl.formatMessage({ id: '--of' })} ${nbPages}`}
      </Text>
    </>
  )
}
