import { Box, Divider, Flex, Heading, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text, useToast } from '@chakra-ui/react'
import { FiRsCheck, FiRsBuilding } from '@stocker/ui-components/design-system'
import { useActiveCompanyQuery, useAvailableCompaniesQuery, useSetActiveCompanyMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ICompanySelectProps {

}

export const CompanySelect: React.FC<ICompanySelectProps> = () => {
  const intl = useIntl()
  const toast = useToast()
  const { data: activeCompany, refetch: refetchActiveCompany } = useActiveCompanyQuery()
  const { data: availableCompanies } = useAvailableCompaniesQuery()

  const setActiveCompanyMutation = useSetActiveCompanyMutation({
    onSuccess: (company) => {
      refetchActiveCompany()
      if ('errorCode' in company.setActiveCompany) {
        return toast({
          title: intl.formatMessage({ id: '--error' }),
          description: company.setActiveCompany.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: intl.formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const initialVatNumber = activeCompany?.activeCompany ? (activeCompany?.activeCompany?.customFields?.vat ?? '') : ''

  return (
    <Box>
      <Popover>
        {({ onClose }) => (
          <Box>
            {availableCompanies?.companies && (
              <>
                <PopoverTrigger>
                  <HStack cursor="pointer" _hover={{ color: 'white' }} ml={6}>
                    <FiRsBuilding/>
                    {activeCompany?.activeCompany &&
                      <Text>{activeCompany.activeCompany.name}</Text>}
                    {!activeCompany?.activeCompany &&
                      <Text>{intl.formatMessage({ id: 'company-select--select-company' })}</Text>}
                  </HStack>
                </PopoverTrigger>
                <PopoverContent mt="5px" textColor="black">
                  <PopoverArrow/>
                  <PopoverHeader>
                    <Heading as="h4" fontSize="md" fontWeight="bolder">{intl.formatMessage({ id: 'company-select--select-company' })}</Heading>
                  </PopoverHeader>
                  <PopoverCloseButton mt="3px"/>
                  <PopoverBody padding="0">
                    <Box>
                      {availableCompanies.companies.items.map((company, index) => (
                        <Box key={company.id}>
                          {(initialVatNumber === company?.customFields?.vat)
                            ? (
                              <Flex
                                padding="10px"
                                cursor="pointer"
                                _hover={{
                                  background: 'gray.200',
                                }}
                                bg="gray.100"
                              >
                                <HStack justify="space-between" w="100%">
                                  <Stack spacing={0}>
                                    <Text lineHeight="140%" fontSize="lg" fontWeight="bold" color="accent.500">
                                      {company.name}
                                    </Text>
                                    <Text>
                                      {company?.customFields?.vat}
                                    </Text>
                                  </Stack>
                                  <FiRsCheck color="accent.500"/>
                                </HStack>
                              </Flex>
                              )
                            : (
                              <Flex
                                padding="10px"
                                cursor="pointer"
                                _hover={{
                                  background: 'gray.100',
                                }}
                                onClick={() => {
                                  setActiveCompanyMutation.mutate({ companyId: company.id })
                                  onClose()
                                }}
                              >
                                <Stack spacing={0}>
                                  <Text lineHeight="140%" fontSize="lg" fontWeight="bold">
                                    {company.name}
                                  </Text>
                                  <Text>
                                    {company?.customFields?.vat}
                                  </Text>
                                </Stack>
                              </Flex>
                              )}
                          <Divider/>
                        </Box>
                      ))}
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Box>
        )}
      </Popover>
    </Box>
  )
}
