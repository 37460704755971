import { Box, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { useSwiperRef } from '@stocker/ui-components/helpers'
import type { IImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'
import { useState } from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import type { Swiper as SwiperType } from 'swiper'
import { Keyboard, Navigation, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export interface IProductImageSliderProps {
  slides: Array<{
    image: IImage
    thumbnailImages: IImage
  }>
  thumbnailFillContainer?: boolean
  previewSliderBreakpoints?: Record<number, {
    slidesPerView: number
  }>
}

const combineArrays = (first: any[], second: Array<{ slidesPerView: number }>) => {
  return first.reduce((acc, val, ind) => {
    acc[val] = second[ind]
    return acc
  }, {})
}

export const ProductImageSlider: React.FC<IProductImageSliderProps> = ({
  slides,
  thumbnailFillContainer = false,
  previewSliderBreakpoints = {
    640: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 3,
    },
  },
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  // Here we get all the values of the previewSliderBreakpoints param
  // and check if the lenght of the slides is bigger than 3
  // if it is we set the slidesPerView to the given number otherwise we set it to the length of the slides
  const breakpointValues = Object.values(previewSliderBreakpoints).map((item) => {
    if (slides.length > 3) return { slidesPerView: item.slidesPerView }
    return { slidesPerView: slides.length }
  })

  const breakpoints = Object.keys(previewSliderBreakpoints)

  const combinedArray = combineArrays(breakpoints, breakpointValues)

  const { isOpen, onToggle, onClose } = useDisclosure()
  const [image, setImage] = useState<IImage>()

  return (
    <Box
      width="100%"
      css={css`
        .swiper {
          --swiper-navigation-color: var(--chakra-colors-primaryText-700)
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
          font-size: 30px;
        }

        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          --swiper-navigation-color: var(--chakra-colors-accent-500)
        }

        .mySwiper .swiper-slide {
          opacity: 0.4;
        }

        .mySwiper .swiper-slide-thumb-active {
          opacity: 1;
        }
      `}
      padding="5px"
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        modules={[Navigation, Keyboard, Thumbs]}
        navigation={true}
        keyboard={{
          enabled: true,
        }}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box h={['150px', '300px']} onClick={() => { setImage(slide.image); onToggle() }} cursor="pointer">
              <NextImage
                src={slide.image.fullpath ?? ''}
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
                alt="Swiper Thumbnail"
              />
              <Modal isOpen={isOpen} size="6xl" onClose={onClose}>
                <ModalOverlay>
                  <ModalCloseButton color="white" cursor="pointer"/>
                </ModalOverlay>
                <ModalContent
                  bgColor="transparent"
                  onClick={onClose}
                  w={{ base: '100%', lg: '1000px' }}
                  h={{ base: '700px', lg: '1000px' }}
                  alignSelf="center"
                  justifySelf="center"
                >
                  <ModalBody
                    bg="white"
                    alignSelf="center"
                    justifySelf="center"
                    w={{ base: '100%', lg: '1000px' }}
                    h={{ base: '700px', lg: '1000px' }}
                    p="6"
                  >
                    <NextImage
                      src={image?.fullpath ?? '/'}
                      placeholder={image?.blurDataUrl ? 'blur' : 'empty'}
                      blurDataURL={image?.blurDataUrl}
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: 'contain',
                      }}
                      alt="Product Image"
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {slides.length > 0 && (
        <Stack direction="row" align="center" justify="center" m="auto" pt="40px" maxW="330px">
          <Box maxW="20px">
            <IconButton
              color="primaryText.700"
              display="flex"
              variant="unstyled"
              aria-label="Previous Button"
              disabled={currentIndex === 0}
              cursor="pointer"
              ref={prevElRef}
              icon={<MdArrowBackIosNew/>}
              _hover={{ color: 'accent.500' }}
            />
          </Box>
          <Swiper
            slidesPerView={slides.length > 3 ? 3 : slides.length}
            // spaceBetween={8}
            modules={[Navigation, Thumbs]}
            watchSlidesProgress={true}
            className="mySwiper"
            breakpoints={combinedArray}
            onSwiper={setThumbsSwiper}
            // custom navigation
            navigation={{
              prevEl,
              nextEl,
            }}
            // set the current index so we can control when the custom buttons should be disabled
            onSlideChange={(state) => { setCurrentIndex(state.activeIndex) }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index} style={{ padding: '10px' }}>
                <Box
                  height={{ base: '50px', md: '70px' }}
                  width={{ base: '50px', md: '70px' }}
                  border="1px"
                  borderColor="secondaryBackground.600"
                  rounded="md"
                  transition="0.3s"
                  cursor="pointer"
                  _hover={{ borderColor: 'accent.500', transform: 'scale(1.05)' }}
                  // for some reason setting scale is the only way I found to center the preview images swiper sometimes is weird
                  transform="scale(1)"
                >
                  <NextImage
                    objectFit={thumbnailFillContainer ? 'cover' : 'contain'}
                    src={slide.thumbnailImages.fullpath ?? ''}
                    fill
                    sizes="100vw"
                    alt="Swiper Thumbnail"
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box maxW="20px">
            <IconButton
              color="primaryText.700"
              variant="unstyled"
              aria-label="Next Button"
              disabled={currentIndex === (slides.length - 2)}
              cursor="pointer"
              ref={nextElRef}
              icon={<MdArrowForwardIos/>}
              _hover={{ color: 'accent.500' }}
            />
          </Box>
        </Stack>
      )}
    </Box>
  )
}
