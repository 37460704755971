export const downloadElements = [
  {
    text: 'Montagehinweise SV-Auflager (PDF | 454.52 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
  {
    text: 'Produktflyer (PDF | 1.20 MB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
  {
    text: 'Anlagenbilder (PDF | 1.92 MB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
  {
    text: 'Bestellformular (PDF | 314.77 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
  {
    text: 'Bestellformular Konzentrisch (PDF | 269.54 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
  {
    text: 'Bestellformular (PDF | 314.77 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,

  },
  {
    text: 'Datenerfassungsblatt (PDF | 300.99 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,

  },
  {
    text: 'Montageanleitung (PDF | 3.28 MB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,

  },
  {
    text: 'Preisbeispiele (PDF | 288.25 KB)',
    fileLink: '/img/logo_header.svg',
    fileName: 'Image',
    download: true,
  },
]
