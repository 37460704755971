import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { type IMenu, NextLink, Wrap } from '../../'
import type React from 'react'
import { NavigationMenuMobile } from '../NavigationMenuMobile/NavigationMenuMobile'

interface INavigationMenuMobileItemProps {
  slug: string
  menu: IMenu
  distanceTop: string[]
  layer: number
  previousMenuTitle?: string
  menuIsOpen: boolean
  closeMenu: () => void
}
interface IColors {
  colorText?: string
  colorTextDeemphasized?: string
  colorBackground?: string
  colorAccent?: string
}
export interface ICombined extends IColors, INavigationMenuMobileItemProps {}

export const NavigationMenuMobileItem: React.FC<ICombined> = ({
  menu, distanceTop, layer, previousMenuTitle, menuIsOpen, slug, closeMenu,
  colorText = 'primaryText.500', colorTextDeemphasized = 'primaryText.650', colorBackground = '', colorAccent = '',
}) => {
  const nav = useDisclosure()

  return (
    <Box>
      <Wrap
        if={Boolean(menu.absolutePath) && !menu.linkItems}
        with={(children) => <NextLink href={menu.absolutePath} onClick={closeMenu}>{children}</NextLink>}
      >
        <Button
          onClick={nav.onToggle}
          _active={{}}
          _hover={{}}
          _focus={{}}
          bg={colorBackground}
          color={isSlugInMenu(slug, menu) ? colorTextDeemphasized : colorText}
          fontFamily="primary"
          fontSize="xl"
        >
          {menu.title}
        </Button>
      </Wrap>
      {menu.linkItems && (
        <NavigationMenuMobile
          slug={slug}
          previousMenuTitle={previousMenuTitle}
          menuTitle={menu.title}
          layer={layer + 1}
          distanceTop={distanceTop}
          menus={menu.linkItems}
          pageIsOpen={nav.isOpen}
          menuIsOpen={menuIsOpen}
          closePage={nav.onClose}
          closeMenu={closeMenu}
        />
      )}
    </Box>
  )
}

const isSlugInMenu = (slug: string, menu: IMenu): boolean => {
  if (menu.absolutePath === slug) {
    return true
  }
  if (menu.linkItems) {
    return menu.linkItems.some(subMenu => isSlugInMenu(slug, subMenu))
  }
  return false
}
