import { Heading } from '@chakra-ui/react'
import type React from 'react'

interface IRegisterDividerProps {
  text: string
}
const RegisterDivider: React.FC<IRegisterDividerProps> = ({ text }) => {
  return (
    <Heading fontSize="xl">{text}</Heading>
  )
}

export default RegisterDivider
