import { Box, Button, Flex, Heading, VStack } from '@chakra-ui/react'
import type { IHeading, IImage, ILink } from '../../'
import { NextLink, Wrap } from '../../'
import { asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import type React from 'react'

export interface IBeforeContainerProps {
  heading: IHeading
  backgroundImage: IImage
  text?: string
  link?: ILink
}

export const HeroProductListing: React.FC<IBeforeContainerProps> = ({ heading, backgroundImage, text, link }) => {
  const parsedText = asComponent(text ?? '')
  return (
    <Box pos="relative" bg="black">
      <Box as="span" opacity={0.7} bg="white">
        <NextImage
          src={backgroundImage.fullpath ?? ''}
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
          }}
          alt="Hero"
        />
      </Box>
      <Wrap if={!!link?.absolutePath} with={(children) => <NextLink href={link?.absolutePath}>{children}</NextLink>}>
        <Flex minH={['200px', '350px']} justifyContent="center" position="relative" alignContent="space-between" p={{ base: '10px', lg: '25px' }}>
          <Box width="100%">
            <VStack align="start" justifyContent="end" h="full" textAlign="left">
              <Heading as={heading.type} size={heading.size} color="white" lineHeight={1.1}>
                {heading.text}
              </Heading>
              <Box color="white">
                {parsedText}
              </Box>
              {link?.text &&
                <Button>{link.text}</Button>}
            </VStack>
          </Box>
        </Flex>
      </Wrap>
    </Box>
  )
}
