import { Box, RadioGroup, SimpleGrid } from '@chakra-ui/react'
import { PaymentRadioButton, type IImage } from '../../'
import type React from 'react'

export interface ICheckoutPaymentOptionsProps {
  paymentOptions: Array<{
    id: string
    name: string
    description?: string
    logo?: IImage
    disabled?: boolean
    disabledMessage?: string
  }>
  selectedPaymentOption?: string
  onPaymentOptionChange?: (value: string) => void
}

export const CheckoutPaymentOptions: React.FC<ICheckoutPaymentOptionsProps> = ({ paymentOptions, onPaymentOptionChange, selectedPaymentOption }) => {
  return (
    <RadioGroup colorScheme="accent" size="lg" value={selectedPaymentOption} onChange={onPaymentOptionChange}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
        {paymentOptions.map((paymentOption, index) => (
          <Box key={`payment-method-${index}`} height="100%" border="1px" borderColor="gray.200" _hover={{ background: 'gray.50' }} borderRadius="5px" padding="15px" alignItems="center" >
            <PaymentRadioButton
              id={paymentOption.id}
              title={paymentOption.name}
              description={paymentOption.description ?? ''}
              logo={paymentOption.logo}
              disabled={paymentOption.disabled}
              disabledMessage={paymentOption.disabledMessage}
            />
          </Box>
        ))}
      </SimpleGrid>
    </RadioGroup>
  )
}
