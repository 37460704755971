import { Button, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import type React from 'react'

export interface IUnitSelectionProps {
  heading?: string
  units: string[]
  selectedUnit: string
  onChangeAmount: (amount: string) => void
}

export const UnitSelection: React.FC<IUnitSelectionProps> = ({ heading, units, onChangeAmount, selectedUnit }) => {
  return (
    <Stack>
      {heading && (
        <Text>
          {heading}
        </Text>
      )}
      {units.length <= 1 && (
        <Text>
          {units[0]}
        </Text>
      )}
      {units.length > 1 && (
        <SimpleGrid spacing="8px" columns={3}>
          {units.map((unit, index) => (
            <Button
              key={index}
              colorScheme={selectedUnit === unit ? 'primaryBackground' : ''}
              color={selectedUnit === unit ? 'primaryText.500' : 'secondaryText.500'}
              onClick={() => { onChangeAmount(unit) }}
              rounded="md"
              border="1px"
              borderColor="primaryText.600"
              px="0"
            >
              {unit}
            </Button>
          ))}
        </SimpleGrid>
      )}
    </Stack>
  )
}
