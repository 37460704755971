import { asComponent } from './htmlComponentParser'

export function formatHeadlineColor (string: string | null) {
  if (!string) return ''

  if (!String(string).includes('_')) return string

  const regex = /(_([\s\S]*?)_)/g
  const match = string.match(regex)
  // if there is no match, return the string
  if (!match) return string
  const target = match[0]

  return asComponent(string.replace(target, `<span style="color: red"> ${target.replace(/_/g, '')}</span>`))
}
