import { AspectRatio, Box, Button, HStack, Stack } from '@chakra-ui/react'
import { FiRsMagicWand, type IHeading, type IImage } from '@stocker/ui-components/design-system'
import { CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import type { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

interface ISystemFinderStartPage {
  setSystemFinderStarted: Dispatch<SetStateAction<boolean>>
  heading: IHeading
  image: IImage
  description: string
}

export const SystemFinderStartPage: React.FC<ISystemFinderStartPage> = ({ heading, image, description, setSystemFinderStarted }) => {
  const { formatMessage } = useIntl()

  return (
    <Stack w="100%" py={{ base: 4, lg: 6 }}>
      <CustomHeading as="h1" fontSize={{ base: '3xl', xl: '5xl' }} pb={{ base: 4, md: 8 }} heading={heading}/>
      {/* Desktop */}
      <HStack w="100%" display={{ base: 'none', md: 'flex' }}>
        <AspectRatio ratio={16 / 9} zIndex={1} minW="calc(100% - 360px)" minH="500px" pos="relative">
          <NextImage
            src={image.fullpath ?? ''}
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
            alt="System Finder"
          />
        </AspectRatio>
        <Box position="relative">
          <Stack
            py="35px"
            position="absolute"
            left="-50px"
            top={{ base: '-270px', xl: '-330px' }}
            w="400px"
            h="auto"
            bg="primaryBackground.50"
            justify="space-between"
            pl="75px"
            pr="45px"
          >
            {asComponent(description)}
            <Button
              w="100%"
              colorScheme="accent"
              iconSpacing={3}
              leftIcon={<FiRsMagicWand fontSize="xl"/>}
              onClick={() => { setSystemFinderStarted(true) }}
            >System finden
            </Button>
          </Stack>
        </Box>
      </HStack>
      {/* Mobile */}
      <Stack w="100%" spacing={5} display={{ base: 'initial', md: 'none' }}>
        <AspectRatio ratio={16 / 9} minW="100%" minH="300px" pos="relative">
          <NextImage
            src={image.fullpath ?? ''}
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
            alt="System Finder"
          />
        </AspectRatio>
        <Box
          w="100%"
          bg="primaryBackground.50"
          p={5}
        >
          {asComponent(description)}
        </Box>
        <Button
          w="100%"
          colorScheme="accent"
          iconSpacing={3}
          leftIcon={<FiRsMagicWand fontSize="xl"/>}
          onClick={() => { setSystemFinderStarted(true) }}
        >
          {formatMessage({ id: 'system-finder--find-system' })}
        </Button>
      </Stack>
    </Stack>
  )
}
