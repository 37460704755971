import { Container, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import type React from 'react'
import { useIntl } from 'react-intl'

interface IColors {
  colorText?: string
  colorBackground?: string
}

interface ICopyrightNoticeProps extends IColors {
}

export const CopyrightNotice: React.FC<ICopyrightNoticeProps> = ({
  colorText = 'secondaryText.500', colorBackground = 'secondaryBackground.500',
}) => {
  const intl = useIntl()
  return (
    <Container maxW="container.page" py="16px" bgColor={colorBackground} color={colorText}>
      <Text>© H.Stocker GmbH {dayjs().year()}</Text>
    </Container>
  )
}
