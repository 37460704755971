import { Form, ButtonData, InputData, SelectData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

export interface ICustomerEditFormData {
  title: string
  firstName: string
  lastName: string
  phone: string
  salutation: string
}

interface ICustomerEditFormProps {
  onSubmit: (values: ICustomerEditFormData) => void
  isLoading: boolean
  defaultValues?: Partial<ICustomerEditFormData>
  editMode?: boolean
}

export const CustomerEditForm: React.FC<ICustomerEditFormProps> = ({ onSubmit, isLoading, defaultValues, editMode }) => {
  const { formatMessage } = useIntl()

  const customerValidationSchema = useMemo(() => yup.object().shape({
    firstName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    lastName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    phone: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    salutation: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
  }), [formatMessage])

  const customerFormData: IFormData = useMemo(() => ({
    formTitle: '',
    blocks: [
      [
        {
          fields: [
            [
              new SelectData({
                title: formatMessage({ id: '--salutation' }),
                name: 'salutation',
                options: [
                  { value: 'mr', text: 'Herr' },
                  { value: 'mrs', text: 'Frau' },
                ],
                minWidth: '200px',
                inputProps: {
                  placeholder: formatMessage({ id: '--select-salutation' }),
                },
                defaultValue: defaultValues?.salutation,
              }),
              new InputData({ defaultValue: defaultValues?.title, title: formatMessage({ id: '--title' }), name: 'title', type: 'text', minWidth: '200px' }),
            ],
            [
              new InputData({ defaultValue: defaultValues?.firstName, title: formatMessage({ id: '--firstname' }), name: 'firstName', type: 'text', minWidth: '200px' }),
              new InputData({ defaultValue: defaultValues?.lastName, title: formatMessage({ id: '--lastname' }), name: 'lastName', type: 'text', minWidth: '200px' }),
            ],
            [
              new InputData({ defaultValue: defaultValues?.phone, title: formatMessage({ id: '--phone' }), name: 'phone', type: 'text', minWidth: '200px' }),
            ],
          ],
        },
      ],
      [
        {
          fields: [
            [
              new ButtonData({ title: formatMessage({ id: '--save' }), type: 'submit', inputProps: { colorScheme: 'accent', isLoading } }),
            ],
          ],
        },
      ],
    ],
  }), [defaultValues?.firstName, defaultValues?.lastName, defaultValues?.phone, defaultValues?.salutation, defaultValues?.title, formatMessage, isLoading])

  return (
    <Form
      editMode={editMode}
      blockGap={{ vertical: '1rem', horizontal: '10rem' }}
      fieldGap="1rem"
      items={customerFormData}
      onSubmit={values => { onSubmit(values) }}
      validationSchema={customerValidationSchema}
    />
  )
}
