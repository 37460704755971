/* eslint-disable no-extra-parens */
import { Alert, AlertIcon, Divider, GridItem, Heading, Select, SimpleGrid, useToast } from '@chakra-ui/react'
import type { IAddressFormData } from '@stocker/ui-components/custom'
import { Form, InputData, SelectData, ProgressStepsIterator } from '@stocker/ui-components/design-system'
import type { FormikErrors } from 'formik'
import { isEqual } from 'lodash'
import type { Address, Country, CreateAddressInput, OrderAddress } from '@stocker/codegen/vendure'
import { useAvailableCountriesQuery, useCreateCustomerAddressMutation, useSetBillingAddressMutation, useSetShippingAddressMutation } from '@stocker/codegen/vendure'
import { useCallback, useState } from 'react'
import type { IntlShape } from 'react-intl'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import type { ICheckoutPageProps } from '../CheckoutPage.types'

export interface IBillingFormData extends yup.InferType<ReturnType<typeof getBillingSchema>> { }
export interface IShippingFormData extends yup.InferType<ReturnType<typeof getShippingSchema>> { }

function mapCountriesToSelectData (countries: Country[]) {
  return countries.filter(country => country.enabled).map(country => ({
    text: `(${country.code}) ${country.name}`,
    value: country.code,
  }))
}

function mapAddressToForm (address?: Address | OrderAddress): CreateAddressInput | undefined {
  if (!address) {
    return undefined
  } else if ('id' in address) {
    const { country, createdAt, updatedAt, id, customFields, ...rest } = address
    return {
      ...rest,
      streetLine2: rest.streetLine2 ?? '',
      province: rest.province ?? '',
      countryCode: country.code,
      phoneNumber: rest.phoneNumber ?? '',
    }
  } else {
    const { country, customFields, ...rest } = address
    return {
      ...rest,
      streetLine1: address.streetLine1 ?? '',
      streetLine2: address.streetLine2 ?? '',
      countryCode: address.countryCode ?? '',
      phoneNumber: rest.phoneNumber ?? '',
      province: rest.province ?? '',
    }
  }
}

function getBillingSchema (intl: IntlShape) {
  return yup.object().shape({
    fullName: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    phoneNumber: yup.string(),
    company: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    streetLine1: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    streetLine2: yup.string(),
    postalCode: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    city: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    countryCode: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    province: yup.string(),
    uid: yup.string(),
  })
}

function getShippingSchema (intl: IntlShape) {
  return yup.object().shape({
    fullName: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    company: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    phoneNumber: yup.string(),
    streetLine1: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    streetLine2: yup.string(),
    postalCode: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    city: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    countryCode: yup.string().required(intl.formatMessage({ id: 'form--field-is-required' })),
    province: yup.string(),
  })
}

export const CheckoutStepAddress: React.FC<ICheckoutPageProps> = ({ addressProps, stepper, refetchActiveCustomer, refetchActiveOrder }) => {
  const intl = useIntl()
  const toast = useToast()

  const { data: availableCountries } = useAvailableCountriesQuery()

  const [quickselectShippingAddressId, setQuickselectShippingAddressId] = useState<string | undefined>()

  const initialBillingFormData = () => {
    if (addressProps.activeBillingAddress) return addressProps.activeBillingAddress
    return addressProps.addresses?.find((address) => address.defaultBillingAddress)
  }

  const initialShippingFormData = () => {
    if (quickselectShippingAddressId) return addressProps.addresses?.find((address) => address.id === quickselectShippingAddressId)
    if (addressProps.activeShippingAddress) return addressProps.activeShippingAddress
    return addressProps.addresses?.find((address) => address.defaultShippingAddress)
  }

  const defaultBillingSameAsShipping = isEqual(initialBillingFormData, initialShippingFormData)
  const [isSameAsBillingAddress, setIsSameAsBillingAddress] = useState(!!defaultBillingSameAsShipping)

  // const [saveBillingAddress, setSaveBillingAddress] = useState(false)
  // const [saveShippingAddress, setSaveShippingAddress] = useState(false)

  const [billingAddressIsChanged, setBillingAddressIsChanged] = useState(false)
  const [shippingAddressIsChanged, setShippingAddressIsChanged] = useState(false)

  const [billingAddress, setBillingAddress] = useState(mapAddressToForm(initialBillingFormData()))
  const [shippingAddress, setShippingAddress] = useState(mapAddressToForm(initialShippingFormData()))

  // gets set by the formik form onChange, so we can handle this from outside the form
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [submitFormBilling, setSubmitFormBilling] = useState<() => Promise<() => void>>(() => () => Promise.resolve(() => {}))
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [validateFormBilling, setValidateFormBilling] = useState<(values?: any) => Promise<FormikErrors<unknown>>>(() => () => Promise.resolve(() => {}))
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [submitFormShipping, setSubmitFormShipping] = useState<() => Promise<() => void>>(() => () => Promise.resolve(() => {}))
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [validateFormShipping, setValidateFormShipping] = useState<(values?: any) => Promise<FormikErrors<unknown>>>(() => () => Promise.resolve(() => {}))

  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const createAddressMutation = useCreateCustomerAddressMutation({
    onSuccess: () => {
      toast({
        title: intl.formatMessage({ id: 'checkout-toast--new-address' }),
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
      refetchActiveCustomer()
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: intl.formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })
  const setOrderShippingAddressMutation = useSetShippingAddressMutation({
    onSuccess: (address) => {
      if ('errorCode' in address.setOrderShippingAddress) {
        return toast({
          title: intl.formatMessage({ id: '--error' }),
          description: address.setOrderShippingAddress.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
      // setSaveBillingAddress(false)
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: intl.formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })
  const setOrderBillingAddressMutation = useSetBillingAddressMutation({
    onSuccess: (address) => {
      if ('errorCode' in address.setOrderBillingAddress) {
        return toast({
          title: intl.formatMessage({ id: '--error' }),
          description: address.setOrderBillingAddress.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
      // setSaveBillingAddress(false)
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: intl.formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  const onNextPageClick = useCallback(
    () => {
      const callback = async () => {
        setButtonIsLoading(true)
        try {
          await submitFormBilling() // to show errors in the form
          // check for errors in shipping
          if (!isSameAsBillingAddress) {
            const errors = await validateFormShipping()
            if (Object.entries(errors).length > 0) {
              await submitFormShipping()
              toast({
                title: intl.formatMessage({ id: '--error' }),
                description: intl.formatMessage({ id: '--please-check-input' }),
                status: 'error',
                duration: 9000,
              })
              setButtonIsLoading(false)
              return
            }
          }
          // check for errors in billing
          const errors = await validateFormBilling()
          if (Object.entries(errors).length > 0) {
            toast({
              title: intl.formatMessage({ id: '--error' }),
              description: intl.formatMessage({ id: '--please-check-input' }),
              status: 'error',
              duration: 9000,
            })
            setButtonIsLoading(false)
            return
          }
        } catch (error) {
          toast({
            title: intl.formatMessage({ id: '--something-went-wrong' }),
            description: intl.formatMessage({ id: '--refresh-and-try-again' }),
            status: 'error',
            duration: 9000,
          })
          setButtonIsLoading(false)
          return
        }
        // create new addresses if needed
        // if (billingAddress && billingAddressIsChanged && saveBillingAddress) {
        //   createAddressMutation.mutate({ input: billingAddress })
        // }
        // if (shippingAddress && shippingAddressIsChanged && saveShippingAddress) {
        //   createAddressMutation.mutate({ input: shippingAddress })
        // }
        // set billing and shipping addresses, then await for the mutations to finish before continuing
        if (billingAddress && (shippingAddress ?? isSameAsBillingAddress)) {
          // TODO: fix this, this is a hack to remove the erpId from the address, because the mutation doesnt accept it
          // delete (billingAddress).erpId
          // delete (shippingAddress)?.erpId

          await setOrderBillingAddressMutation.mutateAsync({ input: billingAddress })
          if (isSameAsBillingAddress) {
            await setOrderShippingAddressMutation.mutateAsync({ input: billingAddress })
          } else if (shippingAddress) {
            await setOrderShippingAddressMutation.mutateAsync({ input: shippingAddress })
          } else {
            console.error('Something went wrong, there is no shipping address and isSameAsBillingAddress is false, this shouldnt be able to happen')
            return
          }
          setButtonIsLoading(false)
          refetchActiveOrder()
          stepper[1].goToNextStep()
        }
      }
      callback()
    },
    [billingAddress, billingAddressIsChanged, createAddressMutation, intl, isSameAsBillingAddress, setOrderBillingAddressMutation, setOrderShippingAddressMutation, shippingAddress, shippingAddressIsChanged, stepper, submitFormBilling, submitFormShipping, validateFormBilling, validateFormShipping, refetchActiveOrder, toast],
  )

  const addressQuickselectOptions = addressProps.addresses?.map((address) => (
    <option
      key={address.id}
      value={address.id}
    >{`${address.streetLine1}, ${address.streetLine2 ? `${address.streetLine2}, ` : ''} ${address.postalCode ?? ''} ${address.city ?? ''}, ${address.province ? `${address.province}, ` : ''} ${address.country.code}`}
    </option>
  ))

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: '20px', lg: '100px' }}
      >
        {/* Lieferadresse */}
        {/* Shipping Address */}
        <GridItem >
          <Heading
            as="h4"
            lineHeight={1}
            fontSize="lg"
            mb={2}
          >{intl.formatMessage({ id: '--delivery-address' })}
          </Heading>
          {/* <Checkbox
            mb={5}
            defaultChecked={!!defaultBillingSameAsShipping}
            onChange={(e) => setIsSameAsBillingAddress(Boolean(e.target.checked))}
            colorScheme="accent"
          >{intl.formatMessage({ id: '--same-as-billing' })}
          </Checkbox> */}
          <Select
            placeholder={intl.formatMessage({ id: 'checkout--choose-delivery-address' })}
            onChange={(e) => {
              setQuickselectShippingAddressId(e.target.value)
            }}
          >
            {addressQuickselectOptions}
          </Select>
          <Divider my={5}/>
          <Form
            onChange={({ values, submitForm, validateForm }) => {
              setSubmitFormShipping(() => submitForm)
              setValidateFormShipping(() => validateForm)
              setShippingAddress(values as IAddressFormData)
              if (isEqual(values, mapAddressToForm(initialShippingFormData()))) {
                setShippingAddressIsChanged(false)
              } else {
                setShippingAddressIsChanged(true)
              }
            }}
            initialValues={mapAddressToForm(initialShippingFormData())}
            enableReinitialize={true}
            onSubmit={(values) => {}}
            blockGap="200px"
            fieldGap="10px"
            items={{
              blocks: [[
                {
                  fields: [
                    [
                      new InputData({ name: 'fullName', title: intl.formatMessage({ id: '--full-name' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new InputData({ name: 'phoneNumber', title: intl.formatMessage({ id: '--phone-number' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new InputData({ name: 'company', title: intl.formatMessage({ id: '--company' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new InputData({ name: 'streetLine1', title: intl.formatMessage({ id: '--street-line-1' }), minWidth: '150px', type: 'text' }),
                      new InputData({ name: 'streetLine2', title: intl.formatMessage({ id: '--street-line-2' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new InputData({ name: 'postalCode', title: intl.formatMessage({ id: '--zip-code' }), minWidth: '150px', type: 'text' }),
                      new InputData({ name: 'city', title: intl.formatMessage({ id: '--city' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new SelectData({
                        name: 'countryCode',
                        title: intl.formatMessage({ id: '--country' }),
                        minWidth: '150px',
                        type: 'select',
                        options: mapCountriesToSelectData((availableCountries?.availableCountries ?? []) as Country[]),
                        inputProps: {
                          placeholder: intl.formatMessage({ id: '--add-country' }),
                        },
                      }),
                      new InputData({ name: 'province', title: intl.formatMessage({ id: '--province' }), minWidth: '150px', type: 'text' }),
                    ],
                  ],
                },
              ]],
            }}
            validationSchema={getShippingSchema(intl)}
          />
          {/* {shippingAddressIsChanged && (
            <Checkbox
              my={5}
              onChange={() => setSaveShippingAddress(!saveShippingAddress)}
              colorScheme="accent"
              defaultChecked={saveShippingAddress}
            >{intl.formatMessage({ id: 'checkout--save-to-address-book' })}
            </Checkbox>
          )} */}
        </GridItem>
        {/* Rechnungsadresse */}
        {/* Billing Address */}
        <GridItem >
          <Heading
            as="h4"
            fontSize="lg"
            lineHeight={1}
            mb={3}
          >{intl.formatMessage({ id: '--billing-address' })}
          </Heading>
          <Alert status="info" my={2}>
            <AlertIcon/>
            {intl.formatMessage({ id: 'checkout--billing-address-info' })}
            {/* Rechnungsadressen kann nur über den Kundenservice geändert werden */}
          </Alert>
          <Form
            onChange={({ values, validateForm: vf, submitForm: sf }) => {
              setSubmitFormBilling(() => sf)
              setValidateFormBilling(() => vf)
              setBillingAddress(values as IBillingFormData)
              if (isEqual(values, mapAddressToForm(initialBillingFormData()))) {
                setBillingAddressIsChanged(false)
              } else {
                setBillingAddressIsChanged(true)
              }
            }}
            initialValues={mapAddressToForm(initialBillingFormData())}
            onSubmit={(values) => {}}
            enableReinitialize={true}
            blockGap="200px"
            fieldGap="10px"
            items={{
              blocks: [[
                {
                  fields: [
                    [
                      new InputData({ name: 'fullName', title: intl.formatMessage({ id: '--full-name' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.fullName } }),
                    ],
                    [
                      new InputData({ name: 'phoneNumber', title: intl.formatMessage({ id: '--phone-number' }), minWidth: '150px', type: 'text' }),
                    ],
                    [
                      new InputData({ name: 'company', title: intl.formatMessage({ id: '--company' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.company } }),
                    ],
                    [
                      new InputData({ name: 'streetLine1', title: intl.formatMessage({ id: '--street-line-1' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.streetLine1 } }),
                      new InputData({ name: 'streetLine2', title: intl.formatMessage({ id: '--street-line-2' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.streetLine1 } }),
                    ],
                    [
                      new InputData({ name: 'postalCode', title: intl.formatMessage({ id: '--zip-code' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.postalCode } }),
                      new InputData({ name: 'city', title: intl.formatMessage({ id: '--city' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.city } }),
                    ],
                    [
                      new SelectData({
                        name: 'countryCode',
                        title: intl.formatMessage({ id: '--country' }),
                        minWidth: '150px',
                        type: 'select',
                        options: mapCountriesToSelectData((availableCountries?.availableCountries ?? []) as Country[]),
                        inputProps: {
                          placeholder: intl.formatMessage({ id: '--select-country' }),
                          // @ts-expect-error types wrong
                          isDisabled: initialBillingFormData()?.countryCode ? String(initialBillingFormData()?.countryCode).length >= 2 : false,
                        },
                      }),
                      new InputData({ name: 'province', title: intl.formatMessage({ id: '--province' }), minWidth: '150px', type: 'text', inputProps: { isDisabled: !!initialBillingFormData()?.province } }),
                    ],
                  ],
                },
              ]],
            }}
            validationSchema={getBillingSchema(intl)}
          />
          {/* {billingAddressIsChanged && (
            <Checkbox
              my={5}
              onChange={() => setSaveBillingAddress(!saveBillingAddress)}
              defaultChecked={saveBillingAddress}
              colorScheme="accent"
            >{intl.formatMessage({ id: 'checkout--save-to-address-book' })}
            </Checkbox>
          )} */}
        </GridItem>

      </SimpleGrid>
      <ProgressStepsIterator
        nextButton={{
          loading: buttonIsLoading,
          onClick: onNextPageClick,
        }}
      />
    </>
  )
}
