import type { IVariant } from '../../'
import type { IOption, IOptionGroup } from './VariantSelector'

export function filterVariants (variants: IVariant[], selectedFacetOptions: IOption[]): IVariant[] {
  return variants.filter(variant => {
    return selectedFacetOptions.every(({ label, value }) => variantHasOption(variant, label, value))
  })
}

export function getAvailableOptions (variants: IVariant[]): IOptionGroup[] {
  const options: IOptionGroup[] = []
  // Fills up the facets array with all distinct available variations.
  for (const variant of variants) {
    for (const optionsValue of variant.options) {
      const alreadySetOption = options.find(option => option.title === optionsValue.group.name)
      if (alreadySetOption) {
        const facetValueNotInOption = !alreadySetOption.options.find(option => option.value === optionsValue.code)
        if (facetValueNotInOption) {
          alreadySetOption.options.push({
            value: optionsValue.code,
            label: optionsValue.group.name,
          })
        }
      } else {
        options.push({
          title: optionsValue.group.name,
          inputPlaceholder: optionsValue.group.name,
          required: false,
          options: [{
            value: optionsValue.code,
            label: optionsValue.group.name,
          }],
        })
      }
    }
  }
  return options
}

function getChangeableOptions (variants: IVariant[], selectedFacetOptions: IOption[]): IOptionGroup[] {
  const options: IOptionGroup[] = []
  // Fills up the options array with all distinct available variations which can be changed and still get a valid Variant
  for (const variant of variants) {
    for (const optionsValue of variant.options) {
      // To see if the facet is changeable with the currently selected Facets, we need to check if there are multiple variations available after filtering if you don't take the current facet filter into account.
      const selectedFacetsWithoutCurrentFacetValue = selectedFacetOptions.filter(selection => selection.label !== optionsValue.group.name)
      const facetOptionIsChangeable = selectedFacetsWithoutCurrentFacetValue.every(({ label, value }) => variantHasOption(variant, label, value))
      if (facetOptionIsChangeable) {
        const alreadySetOption = options.find(option => option.title === optionsValue.group.name)
        if (alreadySetOption) {
          const isFacetValueNotInOptions = !alreadySetOption.options.find(option => option.value === optionsValue.code)
          if (isFacetValueNotInOptions) {
            alreadySetOption.options.push({
              value: optionsValue.code,
              label: optionsValue.group.name,
            })
          }
        } else {
          options.push({
            title: optionsValue.group.name,
            inputPlaceholder: optionsValue.group.name,
            required: false,
            options: [{
              value: optionsValue.code,
              label: optionsValue.group.name,
            }],
          })
        }
      }
    }
  }

  return options.sort((a, b) => a.title.localeCompare(b.title))
}

function variantHasOption (variant: IVariant, optionName: string, optionValue: string): boolean {
  return variant.options.find(option => option.group.name === optionName && option.code === optionValue) !== undefined
}

export function getInitialVariant (variants: IVariant[], sku: string | string[] | undefined, setFirstVariantAsInitial = true): IVariant | null {
  const variant = variants.find(variant => variant.sku === sku)
  if (variant) {
    return variant
  } else if (setFirstVariantAsInitial && variants.length > 0) {
    return variants[0]
  }
  return null
}

export function removeEmptyValuesFromObject (obj: Record<string, string | undefined>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v)) as Record<string, string>
}

export function getSelectableOptions (variants: IVariant[], selectedOptions: IOption[]): IOptionGroup[] {
  const variantsWithOneOption = getAvailableOptions(variants).filter(option => option.options.length === 1).map(option => option.title)
  return getChangeableOptions(variants, selectedOptions).filter(option => !variantsWithOneOption.includes(option.title)).sort((a, b) => a.title.localeCompare(b.title))
}

export function getOptionsFromVariant (facet: IVariant | null): Record<string, string | undefined> {
  if (!facet) {
    return {}
  }
  return facet.options.reduce<Record<string, string | undefined>>((acc, optionsValue) => {
    acc[optionsValue.group.name] = optionsValue.code
    return acc
  }, {})
}
