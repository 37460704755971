import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { FiRsBullseye, FiRsCheck, FiRsCross } from '../../'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IAvailabilityStatusProps {
  availabilityStatus?: 'IN_STOCK' | 'LOW_STOCK' | 'OUT_OF_STOCK' | 'ON_BACKORDER'
  availableStock?: number
  deliveryTime?: string
}

export const AvailabilityStatus: React.FC<IAvailabilityStatusProps> = ({ availabilityStatus, availableStock, deliveryTime }) => {
  const intl = useIntl()
  return (
    <Box whiteSpace="pre-wrap">
      {availabilityStatus && (
        <Box >
          {availabilityStatus === 'IN_STOCK' && (
            <Flex flexDirection="column">
              <HStack color="successColor.500" align="center">
                <FiRsCheck/>
                <Text>
                  {intl.formatMessage({ id: 'generic-availability-available' })}
                </Text>
                {/* Stock currently not implemented due to espionage concerns on inventory */}
                {/* {availableStock && (
                  <Tooltip label={`${availableStock} Stück verfügbar`} fontSize="md" bg="white">
                    <Flex as="span">
                      <FiRsInfo color="primaryText.800"/>
                    </Flex>
                  </Tooltip>
                )} */}
              </HStack>
              {deliveryTime && (
                <Text pl="24px" color="secondaryText.500">
                  {`${intl.formatMessage({ id: '--delivery-time' })}: ${deliveryTime}`}
                </Text>
              )}
            </Flex>
          )}
          {availabilityStatus === 'LOW_STOCK' && (
            <HStack color="warningColor.500" align="center">
              <FiRsCheck/>
              <Text>
                {intl.formatMessage({ id: '--low-stock' })}
              </Text>
            </HStack>
          )}
          {availabilityStatus === 'ON_BACKORDER' && (
            <HStack color="warningColor.500">
              <FiRsBullseye width="0.5em" height="0.5em"/>
              <Text lineHeight={1.2}>
                {intl.formatMessage({ id: '--backorder-stock' })}
              </Text>
            </HStack>
          )}
          {availabilityStatus === 'OUT_OF_STOCK' && (
            <HStack color="red">
              <FiRsCross/>
              <Text lineHeight={1.2}>
                {intl.formatMessage({ id: '--not-in-stock' })}
              </Text>
            </HStack>
          )}
        </Box>
      )}
    </Box>
  )
}
