import { Badge, Box, Flex, Stack, Text } from '@chakra-ui/react'
import type React from 'react'

export interface IProductQuantityDiscountProps {
  discounts: Array<{
    quantity: number
    quantityUnit: string
    listPrice: number
    currency: string
    discountPercent: number
  }>
}

export const ProductQuantityDiscount: React.FC<IProductQuantityDiscountProps> = ({ discounts }) => {
  return (
    <Stack>
      {discounts.map((discount) => (
        <Box key={discount.quantity}>
          <Flex justify="space-between">
            <Stack direction="row">
              <Text>
                ab {discount.quantity} {discount.quantityUnit}
              </Text>
              <Badge variant="solid" colorScheme="accent" w="fit-content" h="fit-content" fontSize="sm">
                {`-${discount.discountPercent}%`}
              </Badge>
            </Stack>
            <Text fontWeight="bold">
              {(discount.listPrice * (100 - discount.discountPercent) / 100).toFixed(2).replace('.', ',')} {discount.currency}
            </Text>
          </Flex>
        </Box>
      ))}
    </Stack>
  )
}
