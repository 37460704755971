import { Box, Button, Container, Grid, GridItem, Heading } from '@chakra-ui/react'
import type { IProductLinesProps } from '../../compounds/ProductLineCard/ProductLineCard'
import { ProductLineCard } from '../../compounds/ProductLineCard/ProductLineCard'
import { Highline } from '../../elements/Highline/Highline'
import { NextLink } from '@stocker/ui-components/design-system'
import type React from 'react'
import type { ReactNode } from 'react'

export interface IProductLineContainerProps {
  textColor?: string
  title: string
  text: ReactNode
  buttonText: string
  buttonLink: {
    absolutePath: string | null
  }
  productLines: IProductLinesProps[]
}

export const ProductLineContainer: React.FC<IProductLineContainerProps> = ({
  textColor = 'secondaryText', title, text, buttonText, buttonLink, productLines,
}) => {
  return (
    <Container maxW="container.page" pt="25px" color={textColor}>
      <Grid
        mt={{ base: '50px', md: '70px' }}
        gap={{ base: '0px', md: '16px' }}
        gridAutoRows={{ base: '', lg: 'minmax(150px, auto)' }}
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
      >
        <GridItem
          gridColumn="span 1"
          gridRow="span 1"
        >
          <Highline>
            <Heading
              as="h1"
              size="2xl"
              pb={10}
            >{title}
            </Heading>
          </Highline>
          <Box
            mb="16px"
            letterSpacing={0.3}
            textAlign="justify"
          >{text}
          </Box>
          {buttonLink.absolutePath && (
            <NextLink
              href={buttonLink.absolutePath}
              w="fit-content"
              h="fit-content"
            >
              <Button
                variant="secondary"
                mt="24px"
                px="48px"
                py="18px"
                fontWeight="300"
                letterSpacing={0.3}
              >{buttonText}
              </Button>
            </NextLink>
          )}
        </GridItem>
        {productLines.map((productLine, index) => (
          <GridItem
            gridColumn="span 1"
            gridRow="span 2"
            key={index}
          >
            <ProductLineCard {...productLine}/>
          </GridItem>
        ))}
      </Grid>
    </Container>
  )
}
