import { SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { CustomText } from '../../'
import type React from 'react'

export interface IGridTableProps {
  title: string
  tableHead: string[]
  tableValues: string[][]
  disclaimer?: string[]
  description?: string[]
}

export const GridTable: React.FC<IGridTableProps> = ({ title, tableHead, tableValues, disclaimer, description }) => {
  return (
    <Stack w="100%" overflowX="auto" overflow="visible">
      <SimpleGrid
        columns={tableHead.length}
        w="100%"
        justifyContent="space-between"
        gap={3}
        minW="500px"
      >
        {tableHead.map((text) => (
          <Text
            w="100%"
            h="auto"
            pb="8px"
            key={text}
            borderBottomWidth="1px"
            borderBottomColor="secondaryText.100"
            fontWeight="semibold"
          >
            {text}
          </Text>
        ),
        )}
        {tableValues.map(rows => rows.map(text => (
          <Text
            w="100%"
            h="auto"
            pb="8px"
            key={text}
            borderBottomWidth="1px"
            wordBreak="break-word"
          >
            {text}
          </Text>
        ),
        ))}
      </SimpleGrid>
      <Stack py="6px">
        {disclaimer?.map((text) => (
          <Text
            fontSize="xs"
            key={text}
          >{text}
          </Text>
        ))}
      </Stack>
      <Stack fontSize="sm">
        {description?.map((text) => (
          <CustomText key={text} text={text}/>
        ))}
      </Stack>
    </Stack>
  )
}
