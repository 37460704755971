import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react'
import type { INewsCard } from '../../compounds/ImageCard/FeaturedNewsCard'
import { FeaturedNewsCard } from '../../compounds/ImageCard/FeaturedNewsCard'
import { Highline } from '../../elements/Highline/Highline'
import type { IImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'

export interface IFeaturedNewsProps {
  title: string
  backgroundImage?: IImage
  newsCards: INewsCard[]
  whiteHeadline?: boolean
  whiteBar?: boolean
}

export const FeaturedNews: React.FC<IFeaturedNewsProps> = ({ whiteBar = false, whiteHeadline = false, newsCards, backgroundImage, title }) => {
  return (
    <Box position="relative">
      <Box w="100%" h="100%" px="0px" position="absolute" overflow="hidden">
        <Box position="relative" w="100%" h="100%">
          {backgroundImage?.fullpath && (
            <NextImage
              src={backgroundImage.fullpath}
              placeholder={backgroundImage.blurDataUrl ? 'blur' : 'empty'}
              blurDataURL={backgroundImage.blurDataUrl}
              quality={100}
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
              alt=""
            />
          )}
        </Box>
      </Box>
      <Container
        maxW="container.page"
        position="relative"
        pt="20px"
      >
        <Highline colorAccent={whiteBar ? 'white' : 'accent.500'}>
          <Heading
            as="h2"
            fontSize={{ base: '3xl', sm: '4xl' }}
            mb="24px"
            mt={{ base: '50px', md: '70px' }}
            color={whiteHeadline ? 'white' : ''}
          >{title}
          </Heading>
        </Highline>
        <SimpleGrid
          columns={{ base: 1, lg: 2, '2xl': 3 }}
          justifyItems="center"
          alignContent="top"
          mx={{ base: '0px', sm: 'auto' }}
          spacingX={{ base: '', lg: '30px' }}
        >
          {newsCards.map((newsCard, index) => {
            return (
              <FeaturedNewsCard
                key={`${newsCard.headline}-${index}`}
                {...newsCard}
              />
            )
          })}
        </SimpleGrid>
      </Container>
    </Box>
  )
}
