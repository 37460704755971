import { Form, ButtonData, InputData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(yup)

export interface IPasswordResetFormData {
  password: string
  passwordConfirm: string
}

interface IPasswordResetFormProps {
  onSubmit: (data: IPasswordResetFormData) => void
  isLoading: boolean
}
export const PasswordResetForm: React.FC<IPasswordResetFormProps> = ({ onSubmit, isLoading }) => {
  const { formatMessage } = useIntl()

  const validationSchema = useMemo(() => yup.object().shape({
    password: yup
      .string()
      .required(formatMessage({ id: 'form--field-is-required' }))
      .min(8, formatMessage({ id: 'form--field-password-min-length' }))
      .minLowercase(1, formatMessage({ id: 'form--field-password-min-lowercase' }))
      .minUppercase(1, formatMessage({ id: 'form--field-password-min-uppercase' }))
      .minSymbols(1, formatMessage({ id: 'form--field-password-min-symbols' })),
    passwordRepeat: yup.string().equals([yup.ref('password'), null], formatMessage({ id: 'form--field-password-must-match' })),
  }), [formatMessage])

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ title: formatMessage({ id: '--password' }), name: 'password', type: 'password' }),
            ],
            [
              new InputData({ title: formatMessage({ id: '--password-repeat' }), name: 'passwordRepeat', type: 'password' }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: 'password-reset--submit' }), type: 'submit', inputProps: { width: 'full', colorScheme: 'accent', isLoading } }),
            ],
          ],
        },
      ],
    ],

  }), [formatMessage, isLoading])
  return (
    <Form
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  )
}
