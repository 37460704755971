import { Box, Button, Heading, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { FiRsCopy, NextLink, type IHeading } from '../../'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ICheckoutThankYouProps {
  heading: IHeading
  orderNumber: string
  customContent?: React.ReactNode
  buttons: Array<{
    text: string
    link: string
    color?: string
  }>
}

export const CheckoutThankYou: React.FC<ICheckoutThankYouProps> = ({ heading, orderNumber, customContent, buttons }) => {
  const intl = useIntl()
  const toast = useToast()

  return (
    <Stack align="center" spacing="30px">
      <Stack align="center">
        <Heading textAlign="center" as={heading.type} fontSize={heading.size} color={heading.color}>{heading.text}</Heading>
        <Text>{intl.formatMessage({ id: '--order-no' })}: {orderNumber}</Text>
        <Button
          size="sm"
          onClick={() => {
            toast({
              title: intl.formatMessage({ id: 'thank-you-toast--copied-to-clipboard' }),
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            navigator.clipboard.writeText(orderNumber)
          }}
        >
          <HStack>
            <FiRsCopy/>
            <Box>
              {intl.formatMessage({ id: '--copy-into-clipboard' })}
            </Box>
          </HStack>
        </Button>
      </Stack>
      {customContent && (
        <Box>
          {customContent}
        </Box>
      )}
      <HStack>
        {buttons.map((button, index) => (
          <NextLink key={index} href={button.link}>
            <Button colorScheme={button.color ? button.color : 'accent'}>
              {button.text}
            </Button>
          </NextLink>
        ))}
      </HStack>
    </Stack>
  )
}
