import { fetchData } from '@stocker/codegen/helpers'

export const fetchPimcore = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  const headers: Record<string, string> = {
    ...(options as Record<string, string>),
  }
  return fetchData(process.env.NEXT_PUBLIC_PIMCORE_GRAPHQL_API_URL!, query, variables, headers)
}
