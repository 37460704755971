import { Box, Button, Checkbox, Container, Flex, FormControl, FormLabel, GridItem, Input, InputGroup, InputRightAddon, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { DatePicker } from '@mantine/dates'
import { PDFViewer } from '@react-pdf/renderer'
import { PDF } from '../../modules/Pdf/wrapper'
import { useDebounce } from '@stocker/ui-components/helpers'
import { useActiveCompanyQuery, useOfferSettingsQuery } from '@stocker/codegen/vendure'
import type React from 'react'
import { createRef, useCallback, useMemo, useState } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import type { OfferPositionData, OfferPositionHandle } from './OfferEditorProductList'
import { OfferEditorProductList } from './OfferEditorProductList'
import { OfferEditorSection } from './OfferEditorSection'

export interface IOffer {
  address: string
  subject: string
  headerText: string
  footerText: string
  reference: string
  additionalDiscount?: number
  positions?: OfferPositionData[]
  termsOfDelivery: string
  termsOfPayment: string
  date: Date
  number: string
}

interface IOfferWithoutEndPrices extends Omit<IOffer, 'positions'> {
  positions?: Array<Omit<OfferPositionData, 'endPrice'>>
}

interface IOfferEditorProps {
  isLoading?: boolean
  onSave?: (offerData: IOfferWithoutEndPrices) => void
  prefillData?: IOffer
  initialTab?: 'preview' | 'editor'
}

export const OfferEditor: React.FC<IOfferEditorProps> = ({ onSave, isLoading, prefillData, initialTab }) => {
  const { formatMessage } = useIntl()
  const [offerDate, setOfferDate] = useState(new Date(prefillData?.date ?? Date.now()))
  const offerPositionRef = createRef<OfferPositionHandle>()
  const { data: activeCompany } = useActiveCompanyQuery()
  const [offerPositionData, setOfferPositionData] = useState<OfferPositionData[]>([])
  const [offerPositionsGrouped, setOfferPositionsGrouped] = useState<Array<[string, OfferPositionData[]]>>([])
  const { isLoading: isOfferSettingsLoading, data: offerSettingsData } = useOfferSettingsQuery()
  const [offerData, setOfferData] = useState<IOffer>(prefillData ?? {
    address: '',
    reference: '',
    subject: '',
    additionalDiscount: 0,
    positions: [],
    footerText: '',
    headerText: '',
    termsOfDelivery: '',
    termsOfPayment: '',
    date: offerDate,
    number: '',
  })

  const debouncedKey = useDebounce(JSON.stringify({ ...offerData, companyName: activeCompany?.activeCompany?.name }), 500)

  const handleOfferPositionDataChange = useCallback((data: OfferPositionData[]) => {
    setOfferPositionData(data)
    setOfferPositionsGrouped(Object.entries(data.reduce<Record<string, OfferPositionData[]>>((acc, pos) => {
      const { taxRate } = pos
      acc[taxRate] = pos.taxRate in acc ? acc[taxRate] : []
      acc[taxRate].push(pos)
      return acc
    }, {})))
  }, [])

  const [showEndPrices, setShowEndPrices] = useState(false)

  const netTotal = useMemo(() => (offerPositionData.reduce((acc, pos) => acc + (((pos.price / 100) * pos.quantity) - (pos.discount * ((pos.price / 100) * pos.quantity) / 100)), 0)), [offerPositionData])

  const grossTotal = useMemo(() => offerPositionData.reduce((acc, pos) => {
    const net = (((pos.price / 100) * pos.quantity) - (pos.discount * ((pos.price / 100) * pos.quantity) / 100))
    const netWithAdditionalDiscount = net - net * ((offerData.additionalDiscount ?? 0) / 100)
    const taxPercentage = (1 + (pos.taxRate / 100))

    return acc + netWithAdditionalDiscount * taxPercentage
  }, 0), [offerData.additionalDiscount, offerPositionData])

  const discountSum = useMemo(() => offerPositionData.reduce((acc, pos) => acc + (pos.discount * (pos.price / 100 * pos.quantity) / 100), 0), [offerPositionData])

  return (
    <Box>
      <Tabs defaultIndex={initialTab === 'preview' ? 1 : 0}>
        <TabList>
          <Tab
            w="100%"
            fontWeight="bold"
            borderColor="accent.100"
            _selected={{ borderColor: 'accent.500' }}
          >{formatMessage({ id: '--edit' })}
          </Tab>
          <Tab
            w="100%"
            fontWeight="bold"
            borderColor="accent.100"
            _selected={{ borderColor: 'accent.500' }}
            onClick={() => {
              setOfferData(prev => ({
                ...prev,
                positions: offerPositionRef.current?.getOfferPositionData().map(pos => ({
                  id: pos.id,
                  endPrice: pos.endPrice,
                  alternative: pos.alternative,
                  discount: pos.discount,
                  name: pos.name,
                  positionTotal: pos.positionTotal,
                  price: pos.price,
                  productVariantId: pos.productVariantId,
                  quantity: pos.quantity,
                  taxRate: pos.taxRate,
                  sku: pos.sku,
                  options: pos.options,
                })),
              }))
            }}
          >{formatMessage({ id: '--preview' })}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-contactinfo' })} hideDivider={false}>
              <FormControl>
                <SimpleGrid gap={10} columns={[1, 2]}>
                  <GridItem>
                    <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--address' })}</FormLabel>
                    <Textarea focusBorderColor="accent.500" rows={8} value={offerData.address} onChange={e => { setOfferData(prev => ({ ...prev, address: e.target.value })) }}/>
                  </GridItem>
                  <GridItem>
                    <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--subject' })}</FormLabel>
                    <Input focusBorderColor="accent.500" value={offerData.subject} onChange={e => { setOfferData(prev => ({ ...prev, subject: e.target.value })) }}/>
                    <Flex mt={3} gap={5}>
                      <Box w="50%">
                        <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-number' })}*</FormLabel>
                        <Input type="text" focusBorderColor="accent.500" value={offerData.number} onChange={e => { setOfferData(prev => ({ ...prev, number: e.target.value || '' })) }}/>
                      </Box>
                      <Box w="50%">
                        <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-date' })}</FormLabel>
                        <Box
                          css={css`
                        .mantine-DatePicker-input {
                          font-family: Abel-Regular, sans-serif;
                          font-size: 16px;
                          height: 40px;
                          border-color: var(--chakra-colors-gray-200);
                        }
                      `}
                        >
                          <DatePicker
                            locale="de"
                            placeholder="Nach Zeitraum filtern"
                            value={offerDate}
                            onChange={date => {
                              setOfferDate(date ?? new Date())
                              setOfferData(prev => ({ ...prev, date: date ?? new Date() }))
                            }}
                            clearable={false}
                            amountOfMonths={2}
                            inputFormat="DD.MM.YYYY"
                            dayStyle={(date, modifiers) => {
                              if (modifiers.selectedInRange) return { backgroundColor: '#ed1c24' }
                              if (modifiers.inRange) return { backgroundColor: 'rgba(226, 0, 38, 0.4)', color: 'white' }
                              if (modifiers.selected) return { backgroundColor: '#ed1c24' }
                              return {}
                            }}
                          />
                        </Box>
                      </Box>
                    </Flex>
                    <Box mt={3}>
                      <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-reference-number' })}</FormLabel>
                      <Input focusBorderColor="accent.500" value={offerData.reference} onChange={e => { setOfferData(prev => ({ ...prev, reference: e.target.value })) }}/>
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </FormControl>
            </OfferEditorSection>
            <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-header' })} hideDivider={false}>
              <Textarea rows={8} focusBorderColor="accent.500" value={offerData.headerText} onChange={e => { setOfferData(prev => ({ ...prev, headerText: e.target.value })) }}/>
            </OfferEditorSection>
            <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-positions' })} hideDivider={false}>
              <OfferEditorProductList
                onChange={data => { handleOfferPositionDataChange(data) }}
                prefillData={offerData.positions?.map((pos, index) => ({ ...pos, id: index }))}
                ref={offerPositionRef}
              />
              <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-additional-discount', defaultMessage: 'Zusatzrabatt' })} hideDivider={false}>
                {/* <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-additional-discount', defaultMessage: 'Gesamtrabatt' })}</FormLabel> */}
                <InputGroup>
                  <Input
                    type="number"
                    textAlign="right"
                    w="10%"
                    min={0}
                    max={100}
                    focusBorderColor="accent.500"
                    value={offerData.additionalDiscount}
                    onChange={e => {
                      const parsedValue = parseInt(e.target.value)
                      if (isNaN(parsedValue)) e.target.value = ''
                      if (parsedValue > 100) e.target.value = '100'
                      if (parsedValue < 0) e.target.value = '0'
                      setOfferData(prev => ({ ...prev, additionalDiscount: parseInt(e.target.value) }))
                    }}
                  />
                  <InputRightAddon>
                    %
                  </InputRightAddon>
                </InputGroup>
              </OfferEditorSection>
            </OfferEditorSection>
            <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-footer' })} hideDivider={false}>
              <Textarea rows={8} focusBorderColor="accent.500" value={offerData.footerText} onChange={e => { setOfferData(prev => ({ ...prev, footerText: e.target.value })) }}/>
            </OfferEditorSection>
            <OfferEditorSection title={formatMessage({ id: 'offer-editor--offer-more-options' })} hideDivider={false}>
              <Flex mt={3} gap={5}>
                <Box w="50%">
                  <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-delivery-terms' })}</FormLabel>
                  <Input focusBorderColor="accent.500" value={offerData.termsOfDelivery} onChange={e => { setOfferData(prev => ({ ...prev, termsOfDelivery: e.target.value })) }}/>
                </Box>
                <Box w="50%">
                  <FormLabel fontWeight="bold">{formatMessage({ id: 'offer-editor--offer-payment-terms' })}</FormLabel>
                  <Input focusBorderColor="accent.500" value={offerData.termsOfPayment} onChange={e => { setOfferData(prev => ({ ...prev, termsOfPayment: e.target.value })) }}/>
                </Box>
              </Flex>
            </OfferEditorSection>
            <Box mt="4" mb="4" fontSize="1.2em">
              <Flex mb="2" justify="space-between" borderBottom="1px solid" borderBottomColor="gray.200">
                <Box>
                  {formatMessage({ id: 'offer-editor--offer-net-total' })}
                </Box>
                <Box>
                  <FormattedNumber
                    value={netTotal}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </Box>
              </Flex>
              {
                (discountSum > 0) && (
                  <Flex mb="2" justify="space-between" borderBottom="1px solid" borderBottomColor="gray.200">
                    <Box>
                      {formatMessage({ id: '--offer-total-discount-sum', defaultMessage: 'Summe Rabatte (exkl. Gesamtrabatt)' })}
                    </Box>
                    <Box>
                      <FormattedNumber
                        value={discountSum}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />

                    </Box>
                  </Flex>
                )
              }
              {
                ((offerData.additionalDiscount ?? 0) / 100 * netTotal) > 0 && (
                  <Flex mb="2" justify="space-between" borderBottom="1px solid" borderBottomColor="gray.200">
                    <Box>
                      {formatMessage({ id: '--offer-total-discounts', defaultMessage: 'Zusatzrabatt' })}
                    </Box>
                    <Box>
                      <FormattedNumber
                        value={((offerData.additionalDiscount ?? 0) / 100 * netTotal)}
                        style="currency"
                        currency="EUR"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />

                    </Box>
                  </Flex>
                )
              }
              <Box mb="2" borderBottom="1px solid" borderBottomColor="gray.200">
                {offerPositionsGrouped.map(([taxRate, positions]) => (
                  <Flex justify="space-between" key={taxRate}>
                    <Box>
                      {taxRate} % {formatMessage({ id: 'offer-editor--offer-net-vat' })}
                    </Box>
                    <Box>
                      <FormattedNumber
                        value={positions.reduce((acc, pos) => {
                          const posNet = (((pos.price / 100) * pos.quantity) - (pos.discount * ((pos.price / 100) * pos.quantity) / 100))
                          return acc + (posNet - ((offerData.additionalDiscount ?? 0) / 100) * posNet) * (pos.taxRate / 100)
                        }, 0)}
                        currency="EUR"
                        style="currency"
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                      />
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Flex fontSize="1.2em" fontWeight="bold" justify="space-between" mt="2">
                <Box>
                  {formatMessage({ id: 'offer-editor--offer-gross-total' })}
                </Box>
                <Box>
                  <FormattedNumber
                    value={grossTotal}
                    currency="EUR"
                    style="currency"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />
                </Box>
              </Flex>
            </Box>
            <Box position="fixed" right={0} left={0} bottom={0} background="white" p={3} zIndex={100} borderTop="1px" borderColor="gray.300" >
              <Container maxW="container.page" display="flex" justifyContent="flex-end">
                <Box
                  width={64}
                >
                  <Button
                    colorScheme="accent"
                    onClick={() => {
                      setOfferData(prev => ({
                        ...prev,
                        positions: offerPositionRef.current?.getOfferPositionData().map(pos => ({
                          id: pos.id,
                          endPrice: pos.endPrice,
                          alternative: pos.alternative,
                          discount: pos.discount,
                          name: pos.name,
                          positionTotal: pos.positionTotal,
                          price: pos.price,
                          productVariantId: pos.productVariantId,
                          quantity: pos.quantity,
                          taxRate: pos.taxRate,
                          options: pos.options,
                        })),
                      }))
                      onSave?.({
                        ...offerData,
                        positions: offerPositionRef.current?.getOfferPositionData().map(pos => ({
                          id: pos.id,
                          alternative: pos.alternative,
                          discount: pos.discount,
                          name: pos.name,
                          positionTotal: Math.trunc(pos.positionTotal),
                          price: Math.trunc(pos.price),
                          productVariantId: pos.productVariantId,
                          quantity: pos.quantity,
                          taxRate: pos.taxRate,
                          options: pos.options,
                        })),
                      })
                    }}
                    w="100%"
                    isLoading={isLoading}
                  >Speichern
                  </Button>
                </Box>
              </Container>
            </Box>
          </TabPanel>
          <TabPanel>
            <Checkbox mb="3" checked={showEndPrices} onChange={v => { setShowEndPrices(v.currentTarget.checked) }}>{formatMessage({ id: 'offer-editor--show-endcustomer-prices' })}</Checkbox>
            <PDFViewer height="900px" width="100%">
              <PDF
                showEndCustomerPrices={showEndPrices}
                key={debouncedKey}
                offer={{ ...offerData, companyName: activeCompany?.activeCompany?.name }}
                offerSettings={offerSettingsData?.offerSettings}
              />
            </PDFViewer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
