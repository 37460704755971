import { Box, Container, useToast } from '@chakra-ui/react'
import { NewsletterSubscriptionForm } from '../../compounds/Forms/NewsletterSubscriptionForm/NewsletterSubscriptionForm'
import { CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import type { IHeading } from '@stocker/ui-components/design-system'
import { useRouter } from 'next/router'
import type { NewsletterAddressInput } from '@stocker/codegen/vendure'
import { useSubscribeToNewsletterMutation, useUnsubscribeToNewsletterMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ISubscribeNewsletterProps {
  text?: string
  heading?: IHeading
  isUnsubscribe?: boolean
}

export const SubscribeNewsletter: React.FC<ISubscribeNewsletterProps> = ({ isUnsubscribe = false, heading, text }) => {
  const toast = useToast()
  const router = useRouter()
  const { formatMessage } = useIntl()

  const subscribeMutation = useSubscribeToNewsletterMutation({
    onSuccess: e => {
      toast({
        title: formatMessage({ id: 'toast--newsletter-subscription-success' }),
        status: 'success',
      })
    },
    onError: error => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })
  const unsubscribeMutation = useUnsubscribeToNewsletterMutation({
    onSuccess: e => {
      toast({
        title: formatMessage({ id: 'toast--newsletter-unsubscribe-success' }),
        status: 'success',
      })
    },
    onError: error => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  return (
    <Container maxW="container.content" mb={20}>
      <Box pb={10}>
        {heading && <CustomHeading heading={heading} pb={5}/>}
        {text && asComponent(text)}
      </Box>
      <NewsletterSubscriptionForm
        onSubmit={isUnsubscribe
          ? (data) => { unsubscribeMutation.mutate(getOutput(data)); router.push('/', undefined, { shallow: true }) }
          : (data) => { subscribeMutation.mutate(getOutput(data)); router.push('/', undefined, { shallow: true }) }}
        isLoading={isUnsubscribe ? unsubscribeMutation.isLoading : subscribeMutation.isLoading}
        isUnsubscribe={isUnsubscribe}
      />
    </Container>
  )
}

function getOutput (data: NewsletterAddressInput) {
  return {
    input: {
      email: data.email,
      name: data.name,
      company: data.company,
      street: data.street,
      zip: data.zip,
      city: data.city,
    },
  }
}
