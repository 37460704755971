import { css, Global } from '@emotion/react'
import type React from 'react'

export const GlobalStyle: React.FC = () => (
  <Global
    styles={css`
    @font-face {
      font-family: "Sora";
      src: url(/fonts/Sora.ttf) format("truetype");
      font-weight: "400";
      font-style: normal
    }
    @font-face {
      font-family: "Inter";
      src: url(/fonts/Inter.ttf) format("truetype");
      font-weight: "400";
      font-style: normal
    }
  `}
  />
)
