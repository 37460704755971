import type { PopoverProps } from '@chakra-ui/react'
import { Box, Center, Flex, Heading, HStack, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Portal, Stack } from '@chakra-ui/react'
import type { IImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'

export interface IInfoPopoverProps extends PopoverProps {
  title: string
  description: string
  image: IImage
  popOverTrigger: JSX.Element
  hideCloseButton?: boolean
}

export const InfoPopover: React.FC<IInfoPopoverProps> = ({ hideCloseButton, popOverTrigger, title, description, image, ...popOverProps }) => {
  return (
    <Popover
      closeOnEsc={true}
      autoFocus={true}
      closeOnBlur={true}
      placement="right"
      {...popOverProps}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Center as="span">
              {popOverTrigger}
            </Center>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              rounded="0"
              pl={image.fullpath ? '150px' : 2}
              minH={image.fullpath ? '150px' : '0'}
              pr={2}
              pt={2}
              pb={2}
              w="fit-content"
              maxW="600px"
            >
              <PopoverBody>
                <HStack>
                  {image.fullpath && (
                    <Box pos="absolute" left="5px" top="5px" h="140px" w="140px">
                      <NextImage src={image.fullpath} alt="" objectFit="contain" layout="fill"/>
                    </Box>
                  )}
                  <Stack>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Heading fontSize="lg" mr={3}>
                        {title}
                      </Heading>
                      {!hideCloseButton && (
                        <PopoverCloseButton
                          style={{ position: 'relative', top: '0', right: '0' }}
                        />
                      )}
                    </Flex>
                    <Box>
                      {description}
                    </Box>
                  </Stack>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}
