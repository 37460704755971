import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { defaultTheme } from '../default'
import { GlobalStyle } from './globals'
import { colors } from './colors'
import { customComponents } from './customComponents'

export const stockerTheme = extendTheme(defaultTheme, {
  components: {
    Checkbox: {
      defaultProps: {
        colorScheme: 'accent',
      },
    },
    ...customComponents,
  },
  fonts: {
    heading: 'Poppins-Bold, sans-serif',
    body: 'Abel-Regular, sans-serif',
    primary: 'Poppins-Bold, sans-serif',
    secondary: 'Abel-Regular, sans-serif',
  },
  styles: {
    global: {
      _selection: {
        color: 'primaryText.500',
        backgroundColor: 'accent.500',
      },
    },
  },
  colors,
  sizes: {
    container: {
      page: '1460px',
      content: '920px',
    },
  },
})

export const stockerDecorator = [
  (Story: any) => (
    <ChakraProvider theme={stockerTheme}>
      <GlobalStyle/>
      {Story()}
    </ChakraProvider>
  ),
]
