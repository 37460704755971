import { Form, ButtonData, InputData, SelectData } from '@stocker/ui-components/design-system'
import type { IFormData } from '@stocker/ui-components/design-system'
import type { CompanyAddress } from '@stocker/codegen/vendure'
import { useAvailableCountriesQuery } from '@stocker/codegen/vendure'
import type React from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export interface IAddressFormData {
  fullName: string
  phoneNumber: string
  company: string
  streetLine1: string
  streetLine2?: string
  postalCode: string
  city: string
  countryCode: string
  province?: string
}

interface IAddressFormProps {
  onSubmit: (data: IAddressFormData) => void
  defaultData?: Omit<CompanyAddress, 'defaultBillingAddress' | 'defaultShippingAddress'>
  isLoading?: boolean
}

export const AddressForm: React.FC<IAddressFormProps> = ({ onSubmit, defaultData, isLoading }) => {
  const { formatMessage } = useIntl()
  const { data } = useAvailableCountriesQuery()

  const validationSchema = useMemo(() => yup.object().shape({
    fullName: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    phoneNumber: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    streetLine1: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    streetLine2: yup.string(),
    postalCode: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    city: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    countryCode: yup.string().required(formatMessage({ id: 'form--field-is-required' })),
    province: yup.string(),
  }), [formatMessage])

  const items: IFormData = useMemo(() => ({
    blocks: [
      [
        {
          fields: [
            [
              new InputData({ name: 'fullName', title: formatMessage({ id: '--company' }), minWidth: '150px', type: 'text' }),
            ],
            [
              new InputData({ name: 'phoneNumber', title: formatMessage({ id: '--phone-number' }), minWidth: '150px', type: 'text' }),
            ],
            [
              new InputData({ name: 'streetLine1', title: formatMessage({ id: '--street-line-1' }), minWidth: '150px', type: 'text' }),
              new InputData({ name: 'streetLine2', title: formatMessage({ id: '--street-line-2' }), minWidth: '150px', type: 'text' }),
            ],
            [
              new InputData({ name: 'postalCode', title: formatMessage({ id: '--zip-code' }), minWidth: '150px', type: 'text' }),
              new InputData({ name: 'city', title: formatMessage({ id: '--city' }), minWidth: '150px', type: 'text' }),
            ],
            [
              new SelectData({
                name: 'countryCode',
                title: formatMessage({ id: '--country' }),
                minWidth: '150px',
                type: 'select',
                options: data?.availableCountries.map((country) => ({ value: country.code, text: country.name })) ?? [],
                inputProps: {
                  placeholder: formatMessage({ id: '--select-country' }),
                },
              }),
              new InputData({ name: 'province', title: formatMessage({ id: '--province' }), minWidth: '150px', type: 'text' }),
            ],
            [
              new ButtonData({ title: formatMessage({ id: '--save' }), type: 'submit', inputProps: { marginTop: '5', colorScheme: 'accent', isLoading } }),
            ],
          ],
        },
      ],
    ],

  }), [data?.availableCountries, formatMessage, isLoading])
  return (
    <Form
      initialValues={defaultData ? Object.entries(defaultData).reduce((acc, [k, v]) => ({ ...acc, ...{ [k]: v === null ? '' : v } }), {}) : undefined}
      blockGap="5"
      fieldGap="1rem"
      items={items}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  )
}
