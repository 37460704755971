import { useToast } from '@chakra-ui/react'
import { ProgressStepsIterator, CheckoutShipping } from '@stocker/ui-components/design-system'
import { useSetOrderShippingMethodMutation } from '@stocker/codegen/vendure'
import type React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import type { ICheckoutPageProps } from '../CheckoutPage.types'

export const CheckoutStepShipping: React.FC<ICheckoutPageProps> = ({ stepper, shippingProps, refetchActiveOrder, activeChannel }) => {
  // const { data: shippingMethods, refetch } = useEligibleShippingMethodsQuery({})
  const intl = useIntl()
  const toast = useToast()
  const [selectedShippingMethodId, setSelectedShippingMethodId] = useState<string | undefined>(shippingProps.initialShippingMethodId)

  const setShippingMethod = useSetOrderShippingMethodMutation({
    onSuccess: (shippingMethod) => {
      if ('errorCode' in shippingMethod.setOrderShippingMethod) {
        return toast({
          title: intl.formatMessage({ id: '--error' }),
          description: shippingMethod.setOrderShippingMethod.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      }
      refetchActiveOrder()
      stepper[1].goToNextStep()
    },
    onError: (error) => {
      let message = intl.formatMessage({ id: '--unknown-error' })
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: intl.formatMessage({ id: '--error' }),
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    },
  })

  return (
    <>
      <CheckoutShipping
        shippingMethods={shippingProps.shippingMethods.map(deliveryMethod => ({ ...deliveryMethod, currencyCode: activeChannel?.currencyCode }))}
        selectedShippingMethod={selectedShippingMethodId}
        onShippingMethodChange={setSelectedShippingMethodId}
        quickActions={[
          // TODO - this should be defined in A11Commerce
          // Delivery time not yet available
          // {
          //   title: intl.formatMessage({id: "checkout--fastest-shipping"}),
          //   onClick: async () => {
          //     const cheapest = deliveryProps.shippingMethods.sort((a, b) => a.price - b.price)[0].id
          //     await setShippingMethod.mutateAsync({ shippingMethodId: cheapest })
          //     stepper[1].goToNextStep()
          //   },
          //   color: 'accent',
          //   icon: <FiRsTachometerFast/>,
          // },
          // {
          //   title: intl.formatMessage({ id: 'checkout--cheapest-delivery' }),
          //   onClick: () => {
          //     const cheapest = shippingProps.shippingMethods.sort((a, b) => a.price - b.price)[0].id
          //     setSelectedShippingMethodId(cheapest)
          //     setShippingMethod.mutateAsync({ shippingMethodId: cheapest })
          //   },
          //   color: 'accent',
          //   icon: <FiRsPiggyBank/>,
          // },
        ]}
        isLoading={setShippingMethod.isLoading}
      />
      <ProgressStepsIterator
        nextButton={{
          onClick: () => {
            if (selectedShippingMethodId) {
              setShippingMethod.mutate({ shippingMethodId: selectedShippingMethodId })
            }
          },
          loading: setShippingMethod.isLoading,
          disabled: !selectedShippingMethodId,
        }}
        prevButton={{ onClick: stepper[1].goToPrevStep }}
      />
    </>
  )
}
