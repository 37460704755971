import type { StackProps } from '@chakra-ui/react'
import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react'

interface ISystemFinderProgressStep extends StackProps {
  title?: string
  selectedValue?: string
  isCompleted: boolean
  isActive: boolean
  isLastStep: boolean
  isFirstStep: boolean
  currentStep?: string
  /** This is used to show the active step as completed when the user is on the last step instead of being only active */
  showActiveAsCompleted?: boolean
  /** This is used to not show the first step as active before starting */
  showActiveAsNotActive?: boolean
}

const SystemFinderProgressStep = (props: ISystemFinderProgressStep) => {
  const { showActiveAsNotActive, currentStep, isActive, isCompleted, isLastStep, isFirstStep, title, selectedValue, showActiveAsCompleted = false, ...stackProps } = props

  return (
    <Stack spacing="0" align="center" direction="row" flex="1" {...stackProps}>
      <Divider
        borderWidth="1px"
        borderColor={isFirstStep ? 'transparent' : 'gray.400'}
      />
      <Stack
        borderWidth="2px"
        borderBottom={isCompleted ? '4px' : isActive ? showActiveAsNotActive ? '2px' : '4px' : '2px'}
        borderBottomColor={isActive ? showActiveAsCompleted ? 'gray.400' : showActiveAsNotActive ? 'inherit' : 'accent.500' : isCompleted ? 'gray.400' : 'inherit'}
        minW={{ base: '30px', lg: '190px' }}
        h={{ base: '30px', lg: '50px', xl: '70px' }}
        justify="center"
        spacing="0"
        px="4"
      >
        <HStack spacing="4">
          <Text fontFamily="primary" fontSize="lg">
            {currentStep}
          </Text>
          <Box>
            <Text fontSize="sm" color="gray.500">
              {selectedValue}
            </Text>
            <Text fontFamily="primary">
              {title}
            </Text>
          </Box>
        </HStack>
      </Stack>
      <Divider
        borderWidth="1px"
        borderColor={isLastStep ? 'transparent' : 'gray.400'}
      />
    </Stack>
  )
}

export default SystemFinderProgressStep
