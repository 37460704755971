import { css, Global } from '@emotion/react'
import type React from 'react'

export const GlobalStyle: React.FC = () => (
  <Global
    styles={css`
    
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--chakra-colors-primaryBackground-450);
      border-radius: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--chakra-colors-accent-500);
    }

    ::-webkit-scrollbar-track {
      background: var(--chakra-colors-secondaryBackground-500);
      border-radius: 0;
    }

    .cookiescriptlogo {
      fill: var(--chakra-colors-accent-500) !important
    }
            
    @font-face {
      font-family: "Poppins-Bold";
      src: url(/fonts/poppins_bold.woff) format("woff");
      font-weight: "400";
      font-style: normal
    }
    @font-face {
      font-family: "Abel-Regular";
      src: url(/fonts/abel_regular.woff) format("woff");
      font-weight: "400";
      font-style: normal
    }
  `}
  />
)
