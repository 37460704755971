import Head from 'next/head'
import type React from 'react'

interface ISeoPros {
  title: string | null
  description?: string | null
  canonicals?: Array<{
    absolutePath: string
  }>
  suffix?: string | null
}

export const Seo: React.FC<ISeoPros> = ({ title, description, canonicals, suffix = '| Stocker Kaminsysteme' }) => {
  return (
    <Head>
      {title && <title>{`${title} ${suffix ?? ''}`}</title>}
      {description && <meta name="description" content={description}/>}
      {canonicals?.map(canonical => (
        <link key={canonical.absolutePath} rel="canonical" href={`${window.location.origin}${canonical.absolutePath}`}/>
      ))}
    </Head>
  )
}
