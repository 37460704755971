import type { useToast } from '@chakra-ui/react'
import type { IntlShape } from 'react-intl'

export const checkError = (typename: string, intl: IntlShape, toast: ReturnType<typeof useToast>) => {
  switch (typename) {
    case 'InvalidVatError':
      toast({
        title: intl.formatMessage({ id: 'toast-vendure--invalid-vat-error-title' }),
        description: intl.formatMessage({ id: 'toast-vendure--invalid-vat-error-description' }),
        status: 'error',
        duration: 5000,
      })
      break
    case 'MissingPasswordError':
      toast({
        title: intl.formatMessage({ id: 'toast-vendure--missing-password-error-title' }),
        description: intl.formatMessage({ id: 'toast-vendure--missing-password-error-description' }),
        status: 'error',
        duration: 5000,
      })
      break
    case 'NativeAuthStrategyError':
      toast({
        title: intl.formatMessage({ id: 'toast-vendure--native-auth-strategy-error-title' }),
        description: intl.formatMessage({ id: 'toast-vendure--native-auth-strategy-error-description' }),
        status: 'error',
        duration: 5000,
      })
      break
    case 'PasswordValidationError':
      toast({
        title: intl.formatMessage({ id: 'toast-vendure--password-validation-error-title' }),
        description: intl.formatMessage({ id: 'toast-vendure--password-validation-error-description' }),
        status: 'error',
        duration: 5000,
      })
      break
  }
}
