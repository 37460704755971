import { Box, Button, Stack, Text, Wrap } from '@chakra-ui/react'
import { CustomHeading, asComponent } from '@stocker/ui-components/helpers'
import type { IHeading } from '@stocker/ui-components/design-system'
import type React from 'react'

export interface IErrorPage404Props {
  heading: IHeading
  customContent?: string
  buttonProps: Array<{
    label: string
    onClick: () => void
    colorScheme?: string
  }>
  errorCode?: number
}

export const ErrorPageTemplate: React.FC<IErrorPage404Props> = ({ heading, customContent, buttonProps, errorCode = 404 }) => {
  return (
    <Box>
      <Stack spacing="15px">
        <CustomHeading heading={heading}/>
        <Box>
          {asComponent(customContent ?? '')}
        </Box>
        <Wrap justify="center" spacing="15px">
          {buttonProps.map((button, index) => (
            <Button key={index} alignSelf="center" w="fit-content" colorScheme={button.colorScheme} onClick={button.onClick}>
              {button.label}
            </Button>
          ))}
        </Wrap>
        <Text alignSelf="center" fontSize="sm">
          Error {errorCode}
        </Text>
      </Stack>
    </Box>
  )
}
