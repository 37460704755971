import type React from 'react'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'

export enum PriceType {
  Partner = '0',
  Base = '1',
}

export function _usePriceType () {
  const [priceType, setPriceType] = useState(PriceType.Base)

  function persistPriceType (value: PriceType) {
    setPriceType(value)
  }

  return {
    priceType,
    persist: persistPriceType,
  }
}

export const PriceContext = createContext<ReturnType<typeof _usePriceType>>({ priceType: PriceType.Base, persist: (value: PriceType) => {} })

export function usePriceType () {
  return useContext(PriceContext)
}

export const PriceTypeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = _usePriceType()

  return (
    <PriceContext.Provider value={value}>
      {children}
    </PriceContext.Provider>
  )
}
