import { Box, Grid, GridItem, Skeleton, SkeletonText, useBreakpointValue, VStack } from '@chakra-ui/react'
import type { IGridTableProps, IImageGallery, ITextProps } from '@stocker/ui-components/design-system'
import { CustomText, GridTable, ImageGallery } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useMemo } from 'react'
import { InformationTabsDesktop } from './InformationTabs.desktop'
import { InformationTabsMobile } from './InformationTabs.mobile'

export type tabProps = IImageGallery | ITextProps | IGridTableProps
export interface IInformationTabsProps {
  tabs: Array<{
    id: string
    name: string
    tabContent: tabProps
    contentLoading?: boolean
  }>
  onChange?: (id: string) => void
}

// The skeleton could be implemented to be a bit prettier (eg. seperated squares for grid table) but this will do for now.
function getTab (tab: IInformationTabsProps['tabs'][number]) {
  const { tabContent } = tab
  if ('images' in tabContent) {
    return (
      <>
        {tab.contentLoading && (
          <Grid templateColumns="1fr 1fr" templateRows="1fr 1fr" gap="3" w="full" h="500px">
            {[...Array(4)].map((_, i) => (
              <GridItem key={`image-loader-${i}`}>
                <Skeleton h="full" w="full"/>
              </GridItem>
            ))}
          </Grid>
        )}
        {!tab.contentLoading && (
          <ImageGallery {...tab.tabContent as IImageGallery}/>
        )}
      </>
      // <Skeleton isLoaded={!tab.contentLoading}>
      // </Skeleton>
    )
  } else if ('tableHead' in tabContent) {
    return (
      <GridTable {...tab.tabContent as IGridTableProps}/>
      // <Skeleton isLoaded={!tab.contentLoading}>
      // </Skeleton>
    )
  } else if ('text' in tabContent) {
    return (
      <>
        {tab.contentLoading && (
          <VStack spacing="7" w="100%">
            {[1, 3, 1, 2, 2, 4].map((noOfLines, index) => (
              <Box w="full" key={`textloader-${index}`}>
                <Skeleton h="4" w="30%"/>
                <SkeletonText mt="2" noOfLines={noOfLines} spacing="2" skeletonHeight="2"/>
              </Box>
            ))}
          </VStack>
        )}
        {!tab.contentLoading && (
          <CustomText text={(tab.tabContent as ITextProps).text} px={0}/>
        )}
      </>
    )
  }
}

export const InformationTabs: React.FC<IInformationTabsProps> = ({ tabs, onChange }) => {
  const variant = useBreakpointValue({
    base: 'mobile',
    lg: 'desktop',
  }, {
    fallback: 'desktop',
  })

  const tabsParsed = useMemo(() => tabs.map((tab) => ({ id: tab.id, title: tab.name, content: getTab(tab) })), [tabs])

  return (
    <>
      <InformationTabsDesktop onChange={onChange} tabs={tabsParsed} hidden={variant === 'mobile'}/>
      <InformationTabsMobile onChange={onChange} tabs={tabsParsed} hidden={variant === 'desktop'}/>
    </>
  )
}
