import { Button, Input, Stack, useNumberInput } from '@chakra-ui/react'
import type React from 'react'

export interface IIncrementAmountInputProps {
  amount: number
  min?: number
  max?: number
  onChange: (amount: number) => void
}

// TODO: implement proper max value
export const IncrementAmountInput: React.FC<IIncrementAmountInputProps> = ({ amount, min = 1, max = 999, onChange }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
    step: 1,
    defaultValue: amount,
    min,
    max,
    onChange: (e) => { onChange(Number(e)) },
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <Stack
      className="not-visible-when-printing"
      direction="row"
      align="center"
      border="1px"
      borderColor="primaryText.550"
      rounded="md"
      overflow="hidden"
      w="fit-content"
      minW="fit-content"
    >
      <Button w="30px" {...dec}>-</Button>
      <Input variant="unstyled" textAlign="center" w="30px" p="0" {...input}/>
      <Button w="30px" {...inc}>+</Button>
    </Stack>
  )
}
