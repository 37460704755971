import { Box, Stack, Text } from '@chakra-ui/react'
import { ProgressStep } from '../../'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IProgressDisplayProps {
  steps: Array<{
    title: string
    description?: string
  }>
  currentStep: number
}
/**
  * Non interactive variant of ProgresssSteps
  * @param param0 available steps
  * @returns on which step the user is
  */
export const ProgressDisplay: React.FC<IProgressDisplayProps> = ({ steps, currentStep }) => {
  const intl = useIntl()

  return (
    <>
      {/* Non Mobile */}
      <Box py={{ base: '4', md: '8' }} display={{ base: 'none', md: 'block' }}>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <ProgressStep
              key={`${step.title}-${step.description ?? '_'}`}
              title={step.title}
              description={step.description ?? ''}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              isFirstStep={index === 0}
              isLastStep={steps.length === index + 1}
              showActiveAsCompleted={true}
            />
          ))}
        </Stack>
      </Box>
      {/* Mobile */}
      <Box py={{ base: '4', md: '8' }} display={{ base: 'block', md: 'none' }}>
        <Box pb="4">
          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {steps[currentStep].title}
          </Text>
          <Text fontSize="md" textAlign="center">
            {intl.formatMessage({ id: 'progress-bar--current-step-of' }, { currentStep: currentStep + 1, maxStep: steps.length })}
          </Text>
        </Box>
        <Stack spacing="0" direction="row">
          {steps.map((step, index) => (
            <ProgressStep
              key={`${step.title}-${step.description ?? '_'}`}
              cursor="pointer"
              isActive={false}
              isCompleted={true}
              isFirstStep={index === 0}
              isLastStep={steps.length === index}
            />
          ))}
        </Stack>
      </Box>
    </>
  )
}
