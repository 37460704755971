import { Box, Select } from '@chakra-ui/react'
import { useState } from 'react'
import type { HitsPerPageProps } from 'react-instantsearch-dom'

export interface ISHitsPerPageProps extends HitsPerPageProps {
  refine: (value: number) => void
}

export const ISHitsPerPage: React.FC<ISHitsPerPageProps> = ({ items, defaultRefinement, refine }) => {
  const [value, setValue] = useState<string | undefined>()

  return (
    <Box>
      <Select
        value={value ?? defaultRefinement}
        size="sm"
        defaultValue={defaultRefinement}
        onChange={({ currentTarget: { value } }) => {
          setValue(value)
          refine(Number(value))
        }}
      >
        {items.map(item => {
          return <option key={item.label} value={item.value}>{item.label}</option>
        })}
      </Select>
    </Box>
  )
}
