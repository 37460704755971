import type { StaticImport } from 'next/dist/shared/lib/get-img-props'
import NextImage from 'next/image'

import type React from 'react'

export interface ILogoProps {
  width: string
  height: string
  file: StaticImport | string
}

// Useless?
export const Logo: React.FC<ILogoProps> = ({ width, height, file }) => {
  return (
    <NextImage
      alt="Stocker"
      src={file}
      width="150"
      height="43"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  )
}
