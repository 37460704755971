import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { asComponent } from '@stocker/ui-components/helpers'
import type React from 'react'

export interface ITextProps extends BoxProps {
  text: string
  isNotFullWidth?: boolean
}

export const CustomText: React.FC<ITextProps> = ({ text, ...boxProps }) => {
  return (
    <Box {...boxProps}>
      {asComponent(text)}
    </Box>
  )
}
