import { Box, Flex, HStack, IconButton, Spacer } from '@chakra-ui/react'
import type React from 'react'
import { IoGridSharp, IoMenuSharp } from 'react-icons/io5'
import { HitsPerPage, SortBy, connectHitsPerPage, connectPagination, connectSortBy } from 'react-instantsearch-dom'
import { ISPagination } from './InstantSearch/ISPagination'

export enum ViewStyle {
  Grid,
  List,
}

interface ISortType {
  value: string
  label: string
}

export interface IProductListingOptionsBarProps {
  /**
   * Array of options that can be selected to change the sorting of the products.
   *
   * @example ['Price ascending', 'Price descending', 'Name ascending', 'Name descending']
   */
  sortTypes: ISortType[]
  viewStyle: ViewStyle
  onViewStyleChange: (viewStyle: ViewStyle) => void
  onSortTypeChange?: (sortType: string) => void
  onPaginationChange?: (page: number) => void
  pagination: {
    currentPage: number
    maxPages: number
  }
}

const CustomPagination = connectPagination(ISPagination)
const CustomSortBy = connectSortBy(SortBy)
const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)

export const ProductListingOptionsBar: React.FC<IProductListingOptionsBarProps> = ({ viewStyle, onViewStyleChange, sortTypes, onPaginationChange, onSortTypeChange, pagination }) => {
  return (
    <Flex pb="15px" w="full" align="center" gap={1}>
      <HStack minW="fit-content">
        <CustomPagination/>
      </HStack>
      <Flex dir="row" gap={2} pl={2} display={{ base: 'none', sm: 'flex' }}>
        <IconButton onClick={() => { onViewStyleChange(ViewStyle.List) }} disabled={viewStyle === ViewStyle.List} variant="outline" icon={<IoMenuSharp/>} aria-label="Click to change the view style to a list"/>
        <IconButton onClick={() => { onViewStyleChange(ViewStyle.Grid) }} disabled={viewStyle === ViewStyle.Grid} variant="outline" icon={<IoGridSharp/>} aria-label="Click to change the view style to a grid"/>
      </Flex>
      <Spacer/>
      <Box display="flex">
        <Box mr="20px" display={{ base: 'none', lg: 'block' }}>
          <CustomHitsPerPage
            defaultRefinement="12"
            items={[
              { label: '12 Produkte pro Seite', value: 12 },
              { label: '24 Produkte pro Seite', value: 24 },
              { label: '100 Produkte pro Seite', value: 100 },
              { label: '250 Produkte pro Seite', value: 250 },
            ]}
          />
        </Box>
        {sortTypes.length > 1 && (
          <CustomSortBy
            items={sortTypes}
            defaultRefinement="vendure_products"
          />
        )}
      </Box>
    </Flex>
  )
}
