import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { Box, Button, Center, HStack, Spacer, Square, StackDivider, useDisclosure, VStack } from '@chakra-ui/react'
import type { INavigationBarProps, ILinkSecondaryProps, IMenu } from '../../'
import { LinkSecondary, NextLink } from '../../'
import type React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-use'
import { NavigationMenuMobile } from '../NavigationMenuMobile/NavigationMenuMobile'

export interface INavigationQuickBarProps {
  menuLinks: Array<{
    text: string
    href: string
  }>
  contactLinks: ILinkSecondaryProps[]
}

interface INavigationBarMobileProps extends INavigationBarProps {
  slug: string
  menus?: IMenu[]
  contactLinks: ILinkSecondaryProps[]
  navigationLinks?: IMenu[]
}
interface IColors {
  colorText?: string
  colorBackground?: string
  colorBackgroundSecondary?: string
  colorAccent?: string
  colorDivider?: string
}
interface ICombined extends IColors, INavigationBarMobileProps {}

const navigationHeight = ['60px', '75px']

export const NavigationBarMobile: React.FC<ICombined> = ({
  menus, contactLinks, navigationLinks, slug,
  colorText = 'primaryText.500', colorBackground = 'primaryBackground.500', colorBackgroundSecondary = 'black', colorAccent = 'accent.500', colorDivider = 'primaryText.700',
}) => {
  const nav = useDisclosure()

  const location = useLocation()

  // this closes the navigation everytime the page is changed
  useEffect(() => {
    nav.onClose()
  }, [location])

  return (
    <>
      <Square
        as={Button}
        size={navigationHeight}
        aria-label="Toggle menu"
        fontSize="3xl"
        borderRadius={0}
        border={0}
        bg={colorAccent}
        color={colorText}
        onClick={nav.onToggle}
        _hover={{ bg: { colorAccent } }}
      >
        {nav.isOpen ? <CloseIcon/> : <HamburgerIcon/>}
      </Square>
      <NavigationMenuMobile
        slug={slug}
        menuTitle="Menü"
        layer={0}
        distanceTop={navigationHeight}
        menus={menus}
        pageIsOpen={nav.isOpen}
        menuIsOpen={nav.isOpen}
        closeMenu={nav.onClose}
      />
      <Box
        position="fixed"
        bottom={0}
        left={0}
        color={colorText}
        h={{ base: '125px', sm: '150px' }}
        bg={colorBackgroundSecondary}
        w="100vw"
        transition="margin-left 0.2s"
        ml={nav.isOpen ? '0 !important' : '100vw !important'} // ChakraUI bug/feature fix, they overwrite margins in Stacks https://github.com/chakra-ui/chakra-ui/issues/2578
      >
        <VStack >
          <Box
          // red divider
            w="100vw"
            h="2px"
            bgColor={colorAccent}
            position="absolute"
            left={0}
          />
          <Spacer/>
          <HStack
            spacing={0}
            divider={<StackDivider borderColor={colorDivider}/>}
          >{navigationLinks?.map((menu, _, arr) => (
            <NextLink key={menu.absolutePath} href={menu.absolutePath} onClick={nav.onClose}>
              <Box
                as="button"
                w={`${Math.floor(100 / arr.length)}vw`}
                textAlign="center"
                fontSize="xl"
                _hover={{ color: colorAccent }}
              >
                {menu.title}
              </Box>
            </NextLink>
          ))}
          </HStack>
          <Spacer/>
          <HStack spacing={0}>
            {contactLinks.map((link, _, arr) => (
              <Center
                w={`${Math.floor(100 / arr.length)}vw`}
                key={link.href}
              >
                <LinkSecondary
                  fontFamily="primary"
                  fontSize="xl"
                  color={colorAccent}
                  textAlign="center"
                  {...link}
                />
              </Center>
            ))}
          </HStack>
        </VStack>
      </Box>
    </>
  )
}
