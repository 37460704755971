import type { BoxProps } from '@chakra-ui/react'
import { Box, useStyleConfig } from '@chakra-ui/react'
import { CustomHeading } from '@stocker/ui-components/helpers'
import type { IHeading } from '@stocker/ui-components/design-system'

export interface IPanelProps extends BoxProps {
  heading?: IHeading
}

export const Panel: React.FC<React.PropsWithChildren<IPanelProps>> = ({ heading, children, ...boxProps }) => {
  const styles = useStyleConfig('FilterPanel', {})
  return (
    <Box width="100%" __css={styles} {...boxProps}>
      {heading && <CustomHeading heading={heading} mb="5px"/>}
      {children}
    </Box>
  )
}
