/* eslint-disable */
import * as Types from './generated-types';

import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchConfigurator } from './fetcher';

export const ConfigurationFragmentDoc = `
    fragment Configuration on SystemConfig {
  id
  name
  moduleFieldValues {
    value
    fieldId
  }
  expertCheckStatus
  configVariantLines {
    id
    configurationSourceKey
    variant {
      variantId
      sku
      name {
        de
        en
      }
      images {
        fileName
        fileSize
        frontendFullPath
      }
      price {
        basePrice
        price
      }
    }
    quantity
  }
}
    `;
export const LanguageFragmentDoc = `
    fragment Language on TranslatedString {
  de
  en
}
    `;
export const SystemTooltipFragmentDoc = `
    fragment SystemTooltip on SystemTooltip {
  title
  description
  image
}
    `;
export const SystemFragmentDoc = `
    fragment System on System {
  id
  name
  modules {
    id
    name
    optional
    code
    tooltip {
      ...SystemTooltip
    }
    fields {
      id
      validation {
        min
        max
      }
      name
      valueType
      unit
      options
      defaultValue
      hideConditions {
        field
        operator
        value
        disableIfHidden
        condition
      }
      code
      tooltip {
        ...SystemTooltip
      }
    }
  }
}
    ${SystemTooltipFragmentDoc}`;
 const AcknowledgeExpertCheckDocument = `
    mutation AcknowledgeExpertCheck($systemConfigId: String!) {
  acknowledgeExpertCheck(systemConfigId: $systemConfigId) {
    __typename
    ... on SystemConfig {
      id
      name
      expertCheckStatus
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;

export const useAcknowledgeExpertCheckMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AcknowledgeExpertCheckMutation, TError, Types.AcknowledgeExpertCheckMutationVariables, TContext>) => {
    
    return useMutation<Types.AcknowledgeExpertCheckMutation, TError, Types.AcknowledgeExpertCheckMutationVariables, TContext>(
      ['AcknowledgeExpertCheck'],
      (variables?: Types.AcknowledgeExpertCheckMutationVariables) => fetchConfigurator<Types.AcknowledgeExpertCheckMutation, Types.AcknowledgeExpertCheckMutationVariables>(AcknowledgeExpertCheckDocument, variables)(),
      options
    )};


useAcknowledgeExpertCheckMutation.fetcher = (variables: Types.AcknowledgeExpertCheckMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.AcknowledgeExpertCheckMutation, Types.AcknowledgeExpertCheckMutationVariables>(AcknowledgeExpertCheckDocument, variables, options);

 const AddConfigToBasketDocument = `
    mutation AddConfigToBasket($configId: String!) {
  addConfigToBasket(configId: $configId) {
    ... on Basket {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;

export const useAddConfigToBasketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddConfigToBasketMutation, TError, Types.AddConfigToBasketMutationVariables, TContext>) => {
    
    return useMutation<Types.AddConfigToBasketMutation, TError, Types.AddConfigToBasketMutationVariables, TContext>(
      ['AddConfigToBasket'],
      (variables?: Types.AddConfigToBasketMutationVariables) => fetchConfigurator<Types.AddConfigToBasketMutation, Types.AddConfigToBasketMutationVariables>(AddConfigToBasketDocument, variables)(),
      options
    )};


useAddConfigToBasketMutation.fetcher = (variables: Types.AddConfigToBasketMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.AddConfigToBasketMutation, Types.AddConfigToBasketMutationVariables>(AddConfigToBasketDocument, variables, options);

 const ClearBasketDocument = `
    mutation ClearBasket {
  clearBasket {
    __typename
    ... on Basket {
      id
    }
    ... on BasketNotFoundError {
      message
    }
  }
}
    `;

export const useClearBasketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ClearBasketMutation, TError, Types.ClearBasketMutationVariables, TContext>) => {
    
    return useMutation<Types.ClearBasketMutation, TError, Types.ClearBasketMutationVariables, TContext>(
      ['ClearBasket'],
      (variables?: Types.ClearBasketMutationVariables) => fetchConfigurator<Types.ClearBasketMutation, Types.ClearBasketMutationVariables>(ClearBasketDocument, variables)(),
      options
    )};


useClearBasketMutation.fetcher = (variables?: Types.ClearBasketMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.ClearBasketMutation, Types.ClearBasketMutationVariables>(ClearBasketDocument, variables, options);

 const CreateConfigVariantLineDocument = `
    mutation createConfigVariantLine($input: CreateConfigVariantLineInput!) {
  createConfigVariantLine(createConfigVariantLineInput: $input) {
    __typename
  }
}
    `;

export const useCreateConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateConfigVariantLineMutation, TError, Types.CreateConfigVariantLineMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateConfigVariantLineMutation, TError, Types.CreateConfigVariantLineMutationVariables, TContext>(
      ['createConfigVariantLine'],
      (variables?: Types.CreateConfigVariantLineMutationVariables) => fetchConfigurator<Types.CreateConfigVariantLineMutation, Types.CreateConfigVariantLineMutationVariables>(CreateConfigVariantLineDocument, variables)(),
      options
    )};


useCreateConfigVariantLineMutation.fetcher = (variables: Types.CreateConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.CreateConfigVariantLineMutation, Types.CreateConfigVariantLineMutationVariables>(CreateConfigVariantLineDocument, variables, options);

 const CreateSystemConfigDocument = `
    mutation CreateSystemConfig($systemId: String!) {
  createSystemConfig(systemId: $systemId) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
  }
}
    `;

export const useCreateSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSystemConfigMutation, TError, Types.CreateSystemConfigMutationVariables, TContext>) => {
    
    return useMutation<Types.CreateSystemConfigMutation, TError, Types.CreateSystemConfigMutationVariables, TContext>(
      ['CreateSystemConfig'],
      (variables?: Types.CreateSystemConfigMutationVariables) => fetchConfigurator<Types.CreateSystemConfigMutation, Types.CreateSystemConfigMutationVariables>(CreateSystemConfigDocument, variables)(),
      options
    )};


useCreateSystemConfigMutation.fetcher = (variables: Types.CreateSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.CreateSystemConfigMutation, Types.CreateSystemConfigMutationVariables>(CreateSystemConfigDocument, variables, options);

 const DeleteSystemConfigDocument = `
    mutation DeleteSystemConfig($systemConfigId: String!) {
  deleteSystemConfig(systemConfigId: $systemConfigId) {
    ... on SystemConfig {
      id
      name
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;

export const useDeleteSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteSystemConfigMutation, TError, Types.DeleteSystemConfigMutationVariables, TContext>) => {
    
    return useMutation<Types.DeleteSystemConfigMutation, TError, Types.DeleteSystemConfigMutationVariables, TContext>(
      ['DeleteSystemConfig'],
      (variables?: Types.DeleteSystemConfigMutationVariables) => fetchConfigurator<Types.DeleteSystemConfigMutation, Types.DeleteSystemConfigMutationVariables>(DeleteSystemConfigDocument, variables)(),
      options
    )};


useDeleteSystemConfigMutation.fetcher = (variables: Types.DeleteSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.DeleteSystemConfigMutation, Types.DeleteSystemConfigMutationVariables>(DeleteSystemConfigDocument, variables, options);

 const FinishExpertCheckDocument = `
    mutation FinishExpertCheck($systemConfigId: String!, $message: String!) {
  finishExpertCheck(systemConfigId: $systemConfigId, message: $message) {
    __typename
    ... on SystemConfig {
      id
      name
      expertCheckStatus
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;

export const useFinishExpertCheckMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.FinishExpertCheckMutation, TError, Types.FinishExpertCheckMutationVariables, TContext>) => {
    
    return useMutation<Types.FinishExpertCheckMutation, TError, Types.FinishExpertCheckMutationVariables, TContext>(
      ['FinishExpertCheck'],
      (variables?: Types.FinishExpertCheckMutationVariables) => fetchConfigurator<Types.FinishExpertCheckMutation, Types.FinishExpertCheckMutationVariables>(FinishExpertCheckDocument, variables)(),
      options
    )};


useFinishExpertCheckMutation.fetcher = (variables: Types.FinishExpertCheckMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.FinishExpertCheckMutation, Types.FinishExpertCheckMutationVariables>(FinishExpertCheckDocument, variables, options);

 const RemoveBasketLineDocument = `
    mutation RemoveBasketLine($id: String!) {
  removeBasketLine(id: $id) {
    ... on BasketLine {
      id
    }
    ... on BasketLineNotFoundError {
      message
    }
  }
}
    `;

export const useRemoveBasketLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveBasketLineMutation, TError, Types.RemoveBasketLineMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveBasketLineMutation, TError, Types.RemoveBasketLineMutationVariables, TContext>(
      ['RemoveBasketLine'],
      (variables?: Types.RemoveBasketLineMutationVariables) => fetchConfigurator<Types.RemoveBasketLineMutation, Types.RemoveBasketLineMutationVariables>(RemoveBasketLineDocument, variables)(),
      options
    )};


useRemoveBasketLineMutation.fetcher = (variables: Types.RemoveBasketLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.RemoveBasketLineMutation, Types.RemoveBasketLineMutationVariables>(RemoveBasketLineDocument, variables, options);

 const RemoveConfigVariantLineDocument = `
    mutation RemoveConfigVariantLine($configVariantLineId: String!) {
  removeConfigVariantLine(id: $configVariantLineId) {
    ... on ConfigVariantLine {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;

export const useRemoveConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveConfigVariantLineMutation, TError, Types.RemoveConfigVariantLineMutationVariables, TContext>) => {
    
    return useMutation<Types.RemoveConfigVariantLineMutation, TError, Types.RemoveConfigVariantLineMutationVariables, TContext>(
      ['RemoveConfigVariantLine'],
      (variables?: Types.RemoveConfigVariantLineMutationVariables) => fetchConfigurator<Types.RemoveConfigVariantLineMutation, Types.RemoveConfigVariantLineMutationVariables>(RemoveConfigVariantLineDocument, variables)(),
      options
    )};


useRemoveConfigVariantLineMutation.fetcher = (variables: Types.RemoveConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.RemoveConfigVariantLineMutation, Types.RemoveConfigVariantLineMutationVariables>(RemoveConfigVariantLineDocument, variables, options);

 const RequestExpertCheckDocument = `
    mutation RequestExpertCheck($id: String!, $message: String!) {
  requestExpertCheck(systemConfigId: $id, message: $message) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;

export const useRequestExpertCheckMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RequestExpertCheckMutation, TError, Types.RequestExpertCheckMutationVariables, TContext>) => {
    
    return useMutation<Types.RequestExpertCheckMutation, TError, Types.RequestExpertCheckMutationVariables, TContext>(
      ['RequestExpertCheck'],
      (variables?: Types.RequestExpertCheckMutationVariables) => fetchConfigurator<Types.RequestExpertCheckMutation, Types.RequestExpertCheckMutationVariables>(RequestExpertCheckDocument, variables)(),
      options
    )};


useRequestExpertCheckMutation.fetcher = (variables: Types.RequestExpertCheckMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.RequestExpertCheckMutation, Types.RequestExpertCheckMutationVariables>(RequestExpertCheckDocument, variables, options);

 const SendBasketToOrderDocument = `
    mutation SendBasketToOrder($id: String!) {
  sendBasketToOrder(id: $id) {
    __typename
  }
}
    `;

export const useSendBasketToOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SendBasketToOrderMutation, TError, Types.SendBasketToOrderMutationVariables, TContext>) => {
    
    return useMutation<Types.SendBasketToOrderMutation, TError, Types.SendBasketToOrderMutationVariables, TContext>(
      ['SendBasketToOrder'],
      (variables?: Types.SendBasketToOrderMutationVariables) => fetchConfigurator<Types.SendBasketToOrderMutation, Types.SendBasketToOrderMutationVariables>(SendBasketToOrderDocument, variables)(),
      options
    )};


useSendBasketToOrderMutation.fetcher = (variables: Types.SendBasketToOrderMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.SendBasketToOrderMutation, Types.SendBasketToOrderMutationVariables>(SendBasketToOrderDocument, variables, options);

 const UpdateBasketLineDocument = `
    mutation UpdateBasketLine($input: UpdateBasketLineInput!) {
  updateBasketLine(updateBasketLineInput: $input) {
    id
    quantity
  }
}
    `;

export const useUpdateBasketLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateBasketLineMutation, TError, Types.UpdateBasketLineMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateBasketLineMutation, TError, Types.UpdateBasketLineMutationVariables, TContext>(
      ['UpdateBasketLine'],
      (variables?: Types.UpdateBasketLineMutationVariables) => fetchConfigurator<Types.UpdateBasketLineMutation, Types.UpdateBasketLineMutationVariables>(UpdateBasketLineDocument, variables)(),
      options
    )};


useUpdateBasketLineMutation.fetcher = (variables: Types.UpdateBasketLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.UpdateBasketLineMutation, Types.UpdateBasketLineMutationVariables>(UpdateBasketLineDocument, variables, options);

 const UpdateConfigVariantLineDocument = `
    mutation UpdateConfigVariantLine($configVariantLineId: String!, $quantity: Int!) {
  updateConfigVariantLine(
    updateConfigVariantLineInput: {id: $configVariantLineId, quantity: $quantity}
  ) {
    ... on ConfigVariantLine {
      id
      quantity
      variant {
        variantId
      }
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;

export const useUpdateConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateConfigVariantLineMutation, TError, Types.UpdateConfigVariantLineMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateConfigVariantLineMutation, TError, Types.UpdateConfigVariantLineMutationVariables, TContext>(
      ['UpdateConfigVariantLine'],
      (variables?: Types.UpdateConfigVariantLineMutationVariables) => fetchConfigurator<Types.UpdateConfigVariantLineMutation, Types.UpdateConfigVariantLineMutationVariables>(UpdateConfigVariantLineDocument, variables)(),
      options
    )};


useUpdateConfigVariantLineMutation.fetcher = (variables: Types.UpdateConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.UpdateConfigVariantLineMutation, Types.UpdateConfigVariantLineMutationVariables>(UpdateConfigVariantLineDocument, variables, options);

 const UpdateSystemConfigDocument = `
    mutation UpdateSystemConfig($config: UpdateSystemConfigInput!) {
  updateSystemConfig(config: $config) {
    ... on SystemConfig {
      ...Configuration
      system {
        ...System
      }
      calculationErrors {
        configurationSourceKey
        type
        message
        filter
      }
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}`;

export const useUpdateSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSystemConfigMutation, TError, Types.UpdateSystemConfigMutationVariables, TContext>) => {
    
    return useMutation<Types.UpdateSystemConfigMutation, TError, Types.UpdateSystemConfigMutationVariables, TContext>(
      ['UpdateSystemConfig'],
      (variables?: Types.UpdateSystemConfigMutationVariables) => fetchConfigurator<Types.UpdateSystemConfigMutation, Types.UpdateSystemConfigMutationVariables>(UpdateSystemConfigDocument, variables)(),
      options
    )};


useUpdateSystemConfigMutation.fetcher = (variables: Types.UpdateSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.UpdateSystemConfigMutation, Types.UpdateSystemConfigMutationVariables>(UpdateSystemConfigDocument, variables, options);

 const AccessoriesDocument = `
    query Accessories($systemId: String!, $filter: JSONObject!) {
  accessories(systemId: $systemId, filter: $filter) {
    variantId
    sku
    name {
      ...Language
    }
    slug {
      ...Language
    }
    absolutePath {
      ...Language
    }
    images {
      fileName
      fileSize
      frontendFullPath
    }
    price {
      basePrice
      price
    }
  }
}
    ${LanguageFragmentDoc}`;

export const useAccessoriesQuery = <
      TData = Types.AccessoriesQuery,
      TError = unknown
    >(
      variables: Types.AccessoriesQueryVariables,
      options?: UseQueryOptions<Types.AccessoriesQuery, TError, TData>
    ) => {
    
    return useQuery<Types.AccessoriesQuery, TError, TData>(
      ['Accessories', variables],
      fetchConfigurator<Types.AccessoriesQuery, Types.AccessoriesQueryVariables>(AccessoriesDocument, variables),
      options
    )};

useAccessoriesQuery.getKey = (variables: Types.AccessoriesQueryVariables) => ['Accessories', variables];


useAccessoriesQuery.fetcher = (variables: Types.AccessoriesQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.AccessoriesQuery, Types.AccessoriesQueryVariables>(AccessoriesDocument, variables, options);

 const ActiveBasketDocument = `
    query ActiveBasket {
  activeBasket {
    id
    basketLines {
      id
      totalCost
      quantity
      systemConfig {
        id
        name
      }
      basket {
        id
        state
      }
    }
  }
}
    `;

export const useActiveBasketQuery = <
      TData = Types.ActiveBasketQuery,
      TError = unknown
    >(
      variables?: Types.ActiveBasketQueryVariables,
      options?: UseQueryOptions<Types.ActiveBasketQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveBasketQuery, TError, TData>(
      variables === undefined ? ['ActiveBasket'] : ['ActiveBasket', variables],
      fetchConfigurator<Types.ActiveBasketQuery, Types.ActiveBasketQueryVariables>(ActiveBasketDocument, variables),
      options
    )};

useActiveBasketQuery.getKey = (variables?: Types.ActiveBasketQueryVariables) => variables === undefined ? ['ActiveBasket'] : ['ActiveBasket', variables];


useActiveBasketQuery.fetcher = (variables?: Types.ActiveBasketQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.ActiveBasketQuery, Types.ActiveBasketQueryVariables>(ActiveBasketDocument, variables, options);

 const ActiveCustomerDocument = `
    query ActiveCustomer {
  activeCustomer {
    id
    firstName
    lastName
    emailAddress
    title
  }
}
    `;

export const useActiveCustomerQuery = <
      TData = Types.ActiveCustomerQuery,
      TError = unknown
    >(
      variables?: Types.ActiveCustomerQueryVariables,
      options?: UseQueryOptions<Types.ActiveCustomerQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ActiveCustomerQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables],
      fetchConfigurator<Types.ActiveCustomerQuery, Types.ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables),
      options
    )};

useActiveCustomerQuery.getKey = (variables?: Types.ActiveCustomerQueryVariables) => variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables];


useActiveCustomerQuery.fetcher = (variables?: Types.ActiveCustomerQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.ActiveCustomerQuery, Types.ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables, options);

 const ContactPersonDocument = `
    query ContactPerson {
  contactPerson {
    image
    name
    position
    phone
    mobile
    email
  }
}
    `;

export const useContactPersonQuery = <
      TData = Types.ContactPersonQuery,
      TError = unknown
    >(
      variables?: Types.ContactPersonQueryVariables,
      options?: UseQueryOptions<Types.ContactPersonQuery, TError, TData>
    ) => {
    
    return useQuery<Types.ContactPersonQuery, TError, TData>(
      variables === undefined ? ['ContactPerson'] : ['ContactPerson', variables],
      fetchConfigurator<Types.ContactPersonQuery, Types.ContactPersonQueryVariables>(ContactPersonDocument, variables),
      options
    )};

useContactPersonQuery.getKey = (variables?: Types.ContactPersonQueryVariables) => variables === undefined ? ['ContactPerson'] : ['ContactPerson', variables];


useContactPersonQuery.fetcher = (variables?: Types.ContactPersonQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.ContactPersonQuery, Types.ContactPersonQueryVariables>(ContactPersonDocument, variables, options);

 const IsAdminDocument = `
    query IsAdmin($companyId: String!) {
  isAdmin(companyId: $companyId)
}
    `;

export const useIsAdminQuery = <
      TData = Types.IsAdminQuery,
      TError = unknown
    >(
      variables: Types.IsAdminQueryVariables,
      options?: UseQueryOptions<Types.IsAdminQuery, TError, TData>
    ) => {
    
    return useQuery<Types.IsAdminQuery, TError, TData>(
      ['IsAdmin', variables],
      fetchConfigurator<Types.IsAdminQuery, Types.IsAdminQueryVariables>(IsAdminDocument, variables),
      options
    )};

useIsAdminQuery.getKey = (variables: Types.IsAdminQueryVariables) => ['IsAdmin', variables];


useIsAdminQuery.fetcher = (variables: Types.IsAdminQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.IsAdminQuery, Types.IsAdminQueryVariables>(IsAdminDocument, variables, options);

 const SystemConfigByIdDocument = `
    query SystemConfigById($systemConfigId: String!) {
  systemConfig(systemConfigId: $systemConfigId) {
    ...Configuration
    system {
      ...System
    }
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}`;

export const useSystemConfigByIdQuery = <
      TData = Types.SystemConfigByIdQuery,
      TError = unknown
    >(
      variables: Types.SystemConfigByIdQueryVariables,
      options?: UseQueryOptions<Types.SystemConfigByIdQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SystemConfigByIdQuery, TError, TData>(
      ['SystemConfigById', variables],
      fetchConfigurator<Types.SystemConfigByIdQuery, Types.SystemConfigByIdQueryVariables>(SystemConfigByIdDocument, variables),
      options
    )};

useSystemConfigByIdQuery.getKey = (variables: Types.SystemConfigByIdQueryVariables) => ['SystemConfigById', variables];


useSystemConfigByIdQuery.fetcher = (variables: Types.SystemConfigByIdQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.SystemConfigByIdQuery, Types.SystemConfigByIdQueryVariables>(SystemConfigByIdDocument, variables, options);

 const SystemConfigsDocument = `
    query SystemConfigs {
  systemConfigs {
    id
    createdAt
    name
    expertCheckStatus
    companyId
    customerId
  }
}
    `;

export const useSystemConfigsQuery = <
      TData = Types.SystemConfigsQuery,
      TError = unknown
    >(
      variables?: Types.SystemConfigsQueryVariables,
      options?: UseQueryOptions<Types.SystemConfigsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SystemConfigsQuery, TError, TData>(
      variables === undefined ? ['SystemConfigs'] : ['SystemConfigs', variables],
      fetchConfigurator<Types.SystemConfigsQuery, Types.SystemConfigsQueryVariables>(SystemConfigsDocument, variables),
      options
    )};

useSystemConfigsQuery.getKey = (variables?: Types.SystemConfigsQueryVariables) => variables === undefined ? ['SystemConfigs'] : ['SystemConfigs', variables];


useSystemConfigsQuery.fetcher = (variables?: Types.SystemConfigsQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.SystemConfigsQuery, Types.SystemConfigsQueryVariables>(SystemConfigsDocument, variables, options);

 const SystemsDocument = `
    query Systems {
  systems {
    id
    name
    modules {
      name
    }
  }
}
    `;

export const useSystemsQuery = <
      TData = Types.SystemsQuery,
      TError = unknown
    >(
      variables?: Types.SystemsQueryVariables,
      options?: UseQueryOptions<Types.SystemsQuery, TError, TData>
    ) => {
    
    return useQuery<Types.SystemsQuery, TError, TData>(
      variables === undefined ? ['Systems'] : ['Systems', variables],
      fetchConfigurator<Types.SystemsQuery, Types.SystemsQueryVariables>(SystemsDocument, variables),
      options
    )};

useSystemsQuery.getKey = (variables?: Types.SystemsQueryVariables) => variables === undefined ? ['Systems'] : ['Systems', variables];


useSystemsQuery.fetcher = (variables?: Types.SystemsQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<Types.SystemsQuery, Types.SystemsQueryVariables>(SystemsDocument, variables, options);
