import { Loader } from '@mantine/core'
import { getTranslations } from '@stocker/ui-components/helpers'
import type { OfferSettingsQuery } from '@stocker/codegen/vendure'
import type React from 'react'
import { IntlProvider } from 'react-intl'
import { useAsync } from 'react-use'
import type { IPDFOfferData } from './pdf'
import OfferPdfDocument from './pdf'

interface IPDFProps {
  offer: IPDFOfferData
  offerSettings: OfferSettingsQuery['offerSettings']
  showEndCustomerPrices?: boolean
}

export const PDF: React.FC<IPDFProps> = ({ offer, offerSettings, showEndCustomerPrices }) => {
  const { value: translations, loading } = useAsync(async () => await getTranslations())
  if (loading) return <Loader/>
  return (
    <IntlProvider locale="de" messages={translations}>
      <OfferPdfDocument
        showEndCustomerPrices={showEndCustomerPrices}
        offer={offer}
        offerSettings={offerSettings}
      />
    </IntlProvider>
  )
}
