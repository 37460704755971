import type { StackProps } from '@chakra-ui/react'
import { HStack, IconButton } from '@chakra-ui/react'
import type React from 'react'
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi'

interface ICarouselButtonsProps extends StackProps {
  onClickLeft: () => void
  onClickRight: () => void
  disableLeftButton?: boolean
  disableRightButton?: boolean
}
interface IColors {
  colorText?: string
  colorTextHover?: string
  colorBackground?: string
  colorBackgroundHover?: string
  colorAccent?: string
  colorAccentHover?: string
}
interface ICombined extends IColors, ICarouselButtonsProps {}

export const CarouselButtons: React.FC<ICombined> = ({
  disableLeftButton = false, disableRightButton = false, onClickLeft, onClickRight,
  colorText = 'primaryText.500', colorTextHover = 'primaryText.750', colorBackground = 'secondaryBackground.850', colorAccent = 'accent.500', colorBackgroundHover = 'secondaryBackground.850', colorAccentHover = 'accent.600',
  ...stackProps
}) => {
  return (
    <HStack spacing={0} {...stackProps}>
      <IconButton
        aria-label="Call Sage"
        fontSize="30px"
        w="100%"
        h="100%"
        border={0}
        borderRadius="0"
        bgColor={disableLeftButton ? colorBackgroundHover : colorBackground}
        color={disableLeftButton ? colorTextHover : colorText}
        _hover={{
          bg: colorBackgroundHover,
        }}
        icon={<HiOutlineArrowNarrowLeft/>}
        onClick={onClickLeft}
        disabled={disableLeftButton}
      />
      <IconButton
        aria-label="Call Sage"
        fontSize="30px"
        w="100%"
        h="100%"
        border={0}
        borderRadius="0"
        bgColor={disableRightButton ? colorAccentHover : colorAccent}
        color={disableRightButton ? colorTextHover : colorText}
        _hover={{
          bg: colorAccentHover,
        }}
        icon={<HiOutlineArrowNarrowRight/>}
        onClick={onClickRight}
        disabled={disableRightButton}
      />
    </HStack>
  )
}
