import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react'
import { ShowMore } from '@stocker/ui-components/design-system'
import type React from 'react'
import type { ReactNode } from 'react'

interface IInformationTabsDesktopProps {
  tabs: Array<{
    id: string
    title: string
    content: ReactNode
  }>
  hidden: boolean
  onChange?: (id: string) => void
}

export const InformationTabsDesktop: React.FC<IInformationTabsDesktopProps> = ({ tabs, hidden, onChange }) => {
  const rerenderToggle = useDisclosure()
  return (
    <Tabs
      w="100%"
      colorScheme="accent"
      bgColor="gray.50"
      hidden={hidden}
    >
      <TabList onClick={rerenderToggle.onToggle}>
        {tabs.map((tab) => (
          <Tab
            key={tab.title}
            w="100%"
            fontFamily="primary"
            _selected={{ color: 'accent.500', borderBottom: '2px solid' }}
            onClick={() => onChange?.(tab.id)}
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel key={index} p={0}>
            <ShowMore maxH={500} rerenderToggle={rerenderToggle.isOpen} backgroundColor="gray.50">
              <Box p={6}>
                {tab.content}
              </Box>
            </ShowMore>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
