import { Box, Center, Container, Flex, Spinner } from '@chakra-ui/react'
import SystemFinderProgressSteps from '../../pages/SystemFinder/ProgressStep/SystemFinderProgressSteps'
import { Breadcrumbs, Seo } from '@stocker/ui-components/design-system'
import { decryptBase64, encryptBase64, useStep } from '@stocker/ui-components/helpers'
import { useRouter } from 'next/router'
import { useProductCategoryListingSystemFinderQuery, useSystemFinderListingQuery } from '@stocker/codegen/pimcore'
import { useEffect, useReducer, useState } from 'react'
import { useIntl } from 'react-intl'
import { SystemFinderSelectionPage } from './steps/SelectionPage'
import { SystemFinderStartPage } from './steps/StartPage'
import { SystemFinderSystemSelectPage } from './steps/SystemSelectPage'

export const SystemFinder = () => {
  const { formatMessage } = useIntl()

  const [currentStep, { setStep }] = useStep({ maxStep: 5, initialStep: 0 })
  const [systemFinderStarted, setSystemFinderStarted] = useState(false)

  const { data: systemFinderOptions, isLoading: isLoadingSystemFinderOptions } = useSystemFinderListingQuery()
  const systemFinderListing = systemFinderOptions?.getSystemFinderListing?.edges?.[0]?.node ?? null

  const [stepsValues, dispatch] = useReducer(stepReducer, [{ step: 0, name: '', id: '' }])
  function stepReducer (state: Array<{ step: number, name: string, id: string }>, action: { step: number, name: string, id: string }) {
    const index = state.findIndex(e => e.step === action.step)
    if (index !== -1) {
      state[index] = action
      return state
    }

    return [
      ...state,
      {
        step: action.step,
        name: action.name,
        id: action.id,
      }]
  }

  const [urlValues, setUrlValues] = useState<string[]>()

  // on reload set the urlValues into stepValues
  useEffect(() => {
    urlValues?.map((value, index) => { dispatch({ step: index, name: '', id: value }) })
  }, [urlValues])

  const router = useRouter()
  // here we put the configuration values in the url as base64
  useEffect(() => {
    if (!systemFinderStarted) return
    router.push(`?values=${encryptBase64(stepsValues.map(e => e.id))}`)
  }, [JSON.stringify(stepsValues)])

  // if the currenStep is smaller than the values length set the length of the values to the currenStep
  // to reset all except the currentValue and all steps before it
  useEffect(() => {
    if (currentStep < stepsValues.length) {
      stepsValues.length = currentStep
    }
  }, [currentStep])

  // here we decrypt the url param again
  useEffect(() => {
    if (router.query.values) {
      const decryptedUrlValues = decryptBase64(String(router.query.values))
      if (decryptedUrlValues) {
        // only setStep if systemFinder hasnt started yet so it only sets steps on page reload not anytime during configuration
        if (!systemFinderStarted) {
          setStep(decryptedUrlValues.length)
        }
        setSystemFinderStarted(true)
      }
      setUrlValues(decryptedUrlValues)
    }
  }, [router.query.values])

  const firstStepValue = urlValues?.length !== 0 ? { step: 0, name: systemFinderListing?.project?.find(e => e?.projectFilter?.id === urlValues?.[0])?.headline, id: urlValues?.[0] } : stepsValues.find(e => e.step === 0)
  const secondStepValue = urlValues?.length !== 0 ? { step: 1, name: systemFinderListing?.operatingMode?.find(e => e?.operatingModeFilter?.id === urlValues?.[1])?.headline, id: urlValues?.[1] } : stepsValues.find(e => e.step === 1)
  const thirdStepValue = urlValues?.length !== 0 ? { step: 2, name: systemFinderListing?.pressure?.find(e => e?.pressureFilter?.id === urlValues?.[2])?.headline, id: urlValues?.[2] } : stepsValues.find(e => e.step === 2)
  const fourthStepValue = urlValues?.length !== 0 ? { step: 3, name: systemFinderListing?.roomAir?.find(e => e?.roomAirFilter?.id === urlValues?.[3])?.headline, id: urlValues?.[3] } : stepsValues.find(e => e.step === 3)
  const fiftStepValue = urlValues?.length !== 0 ? { step: 4, name: systemFinderListing?.execution?.find(e => e?.executionFilter?.id === urlValues?.[4])?.headline, id: urlValues?.[4] } : stepsValues.find(e => e.step === 4)

  // TODO: add real filters here once there are more systems available
  const { data: systemFinderSystems } = useProductCategoryListingSystemFinderQuery({ filter: '{"isSystem": "1"}' })
  const systemFinderSystemsData = systemFinderSystems?.getProductCategoryListing?.edges

  const selectedValuesIds = stepsValues.map(e => e.id)
  // here we get all possible system with the selected values
  const allPosibleSystems = systemFinderSystemsData?.filter(system => {
    const allMatchingValues = system?.node?.systemFinderFilters?.filter(filter => urlValues ? urlValues.includes(filter?.id ?? '') : selectedValuesIds.includes(filter?.id ?? ''))
    // if the array of selected values that match in the system is the same as the currentStep (how many values have to match)
    // return the system otherwise return undefined
    if ((allMatchingValues?.length === currentStep) || ((allMatchingValues?.length ?? 0) > currentStep)) return system
  })

  const steps = [
    { title: formatMessage({ id: 'system-finder--builden-project' }), selectedValue: firstStepValue?.name ?? '' },
    { title: formatMessage({ id: 'system-finder--operating-mode' }), selectedValue: secondStepValue?.name ?? '' },
    { title: formatMessage({ id: 'system-finder--pressure' }), selectedValue: thirdStepValue?.name ?? '' },
    { title: formatMessage({ id: 'system-finder--room-air' }), selectedValue: fourthStepValue?.name ?? '' },
    { title: formatMessage({ id: 'system-finder--version' }), selectedValue: fiftStepValue?.name ?? '' },
  ]

  const pressureStep = systemFinderListing?.pressure?.map(pressure => ({
    id: pressure?.pressureFilter?.id ?? '',
    heading: { text: pressure?.headline ?? '' },
    image: { fullpath: pressure?.image?.fullpath ?? '' },
    infoText: pressure?.text ?? '',
  })) ?? []

  const doubleWalledPressureStep = systemFinderListing?.doubleWalledPressure?.map(pressure => ({
    id: pressure?.doubleWalledPressureFilter?.id ?? '',
    heading: { text: pressure?.headline ?? '' },
    image: { fullpath: pressure?.image?.fullpath ?? '' },
    infoText: pressure?.text ?? '',
  })) ?? []

  const stepValues = [{
    heading: { text: `${currentStep + 1}. ${String(systemFinderListing?.projectHeadline ?? 'Bauvorhaben')}` },
    generalInfoText: systemFinderListing?.projectText ?? '',
    cardData: systemFinderListing?.project?.map(project => ({
      id: project?.projectFilter?.id ?? '',
      heading: { text: project?.headline ?? '' },
      image: { fullpath: project?.image?.fullpath ?? '' },
      infoText: project?.text ?? '',
    })) ?? [{
      id: '1',
      heading: { text: 'No Value' },
      image: { fullpath: '' },
      infoText: 'No Value was Provided',
    }],
    setValue: dispatch,
    alreadySelectedValue: firstStepValue,
  },
  {
    heading: { text: `${currentStep + 1}. ${String(systemFinderListing?.operatingModeHeadline ?? 'Betriebsart')}` },
    generalInfoText: systemFinderListing?.operatingModeText ?? '',
    cardData: systemFinderListing?.operatingMode?.map(operatingMode => ({
      id: operatingMode?.operatingModeFilter?.id ?? '',
      heading: { text: operatingMode?.headline ?? '' },
      image: { fullpath: operatingMode?.image?.fullpath ?? '' },
      infoText: operatingMode?.text ?? '',
    })) ?? [{
      id: '1',
      heading: { text: 'No Value' },
      image: { fullpath: '' },
      infoText: 'No Value was Provided',
    }],
    setValue: dispatch,
    alreadySelectedValue: secondStepValue,
  },
  {
    heading: { text: `${currentStep + 1}. ${String(systemFinderListing?.pressureHeadline ?? 'Druck')}` },
    generalInfoText: systemFinderListing?.pressureText ?? '',
    cardData: steps[0].selectedValue === 'Sanierung' ? pressureStep : doubleWalledPressureStep, // TODO this condition should come from pimcore
    setValue: dispatch,
    alreadySelectedValue: thirdStepValue,
  },
  {
    heading: { text: `${currentStep + 1}. ${String(systemFinderListing?.roomAirHeadline ?? 'Raumluft')}` },
    generalInfoText: systemFinderListing?.roomAirText ?? '',
    cardData: systemFinderListing?.roomAir?.map(roomAir => ({
      id: roomAir?.roomAirFilter?.id ?? '',
      heading: { text: roomAir?.headline ?? '' },
      image: { fullpath: roomAir?.image?.fullpath ?? '' },
      infoText: roomAir?.text ?? '',
    })) ?? [{
      id: '1',
      heading: { text: 'No Value' },
      image: { fullpath: '' },
      infoText: 'No Value was Provided',
    }],
    setValue: dispatch,
    alreadySelectedValue: fourthStepValue,
  },
  {
    heading: { text: `${currentStep + 1}. ${String(systemFinderListing?.executionHeadline ?? 'Ausführung')}` },
    generalInfoText: systemFinderListing?.executionText ?? '',
    cardData: systemFinderListing?.execution?.map(execution => ({
      id: execution?.executionFilter?.id ?? '',
      heading: { text: execution?.headline ?? '' },
      image: { fullpath: execution?.image?.fullpath ?? '' },
      infoText: execution?.text ?? '',
    })) ?? [{
      id: '1',
      heading: { text: 'No Value' },
      image: { fullpath: '' },
      infoText: 'No Value was Provided',
    }],
    setValue: dispatch,
    alreadySelectedValue: fiftStepValue,
  }]

  const systemsData = allPosibleSystems?.map(system => ({
    name: system?.node?.coloredName ?? '',
    description: system?.node?.description ?? '',
    image: { fullpath: system?.node?.infoImage?.fullpath ?? '' },
    detailLink: `/category/${String(system?.node?.slug)}/information`,
    categoryLink: `/category/${String(system?.node?.slug)}`,
    relatedProduct: system?.node?.relatedProduct ? (system.node.relatedProduct.slug ?? '') : undefined,
    configurationLink: process.env.NEXT_PUBLIC_CONFIGURATOR_FRONTEND_URL  ?? 'https://konfigurator.stocker-kaminsysteme.com/configurator',
    systemValues: [
      {
        valueName: formatMessage({ id: 'system-finder--material' }),
        value: system?.node?.material ?? '',
      },
      {
        valueName: formatMessage({ id: 'system-finder--diameter' }),
        value: system?.node?.diameter ?? '',
      },
      {
        valueName: formatMessage({ id: 'system-finder--wall-thickness' }),
        value: system?.node?.wallThickness ?? '',
      },
      {
        valueName: formatMessage({ id: 'system-finder--max-exhaust-temperatur' }),
        value: system?.node?.maxExhaustTemperature ?? '',
      },
    ],
  }))

  if (isLoadingSystemFinderOptions) {
    return (
      <Container maxW="container.page" h="60vh">
        <Center h="100%">
          <Spinner size="xl"/>
        </Center>
      </Container>
    )
  }

  return (
    <Box>
      <Seo
        title={formatMessage({ id: 'seo--system-finder' })}
      />
      <Container maxW="container.page" py={{ base: '15px', xl: '50px' }} h="100%">
        <Breadcrumbs data={[{ label: 'Home', slug: '/' }, { label: 'System Finder', slug: '/systemfinder' }]}/>
        <SystemFinderProgressSteps
          steps={steps}
          currentStep={currentStep === stepValues.length ? stepValues.length - 1 : currentStep}
          setStep={setStep}
          showActiveAsCompleted={currentStep === stepValues.length}
          showActiveAsNotActive={!systemFinderStarted}
        />
        <Flex h="calc(100% - 150px)" align="center" justify="center">
          {!systemFinderStarted && (
            <SystemFinderStartPage
              heading={{ text: systemFinderListing?.introHeadline ?? '' }}
              image={{ fullpath: systemFinderListing?.introImage?.fullpath ?? '' }}
              description={systemFinderListing?.introText ?? ''}
              setSystemFinderStarted={setSystemFinderStarted}
            />
          )}
          {systemFinderStarted && (currentStep !== stepValues.length) && (
            <Box w="100%">
              {stepValues.map((step, index) => (
                <Box key={String(step.heading.text) + String(step.cardData[0].infoText)}>
                  {index === currentStep && (
                    <SystemFinderSelectionPage
                      heading={step.heading}
                      generalInfoText={step.generalInfoText}
                      cardData={step.cardData}
                      currentStep={currentStep}
                      setStep={setStep}
                      setSystemFinderStarted={setSystemFinderStarted}
                      setStepValue={step.setValue}
                      alreadySelectedValue={step.alreadySelectedValue?.name ?? ''}
                      allCurrentPossibleSystem={allPosibleSystems ?? []}
                    />
                  )}
                </Box>
              ))}
            </Box>
          )}
          {currentStep === stepValues.length && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {currentStep === urlValues?.length
                ? (
                  <SystemFinderSystemSelectPage currentStep={currentStep} setStep={setStep} systemsData={systemsData}/>
                  )
                : (
                  <Center h="700px">
                    <Spinner size="xl"/>
                  </Center>
                  )}
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
