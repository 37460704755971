import type { SystemStyleObject } from '@chakra-ui/react'
import { Button, Flex, Icon, IconButton, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react'
import type React from 'react'
import { useMemo } from 'react'

import { BsThreeDots } from 'react-icons/bs'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

export interface IPaginationProps {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  setCurrentPage: (arg0: number) => void
  variant: 'primary' | 'secondary'
  dotButtonsHidden?: boolean
}

interface ICombined extends IPaginationProps {}

export const Pagination: React.FC<ICombined> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
  variant,
  dotButtonsHidden = true,
}) => {
  const styles = useMultiStyleConfig('Pagination', { variant })

  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const visibleNavItems = useBreakpointValue({
    base: 3,
    md: 5,
  })!

  const dotButtonProps = useMemo(() => ({
    'aria-label': 'dots click for quick navigation jump',
    icon: <Icon as={BsThreeDots} boxSize={4}/>,
    __css: styles.iconButton,
    hidden: dotButtonsHidden,
  }), [styles, dotButtonsHidden])

  if (totalPages <= visibleNavItems + 2 + 2 * Number(dotButtonsHidden)) { // Show all pages in the pagination
    return (
      <Flex>
        <PaginationArrows
          styles={styles}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        >
          {Array.apply(null, Array(totalPages)).map(function (_, i) {
            const counter = i + 1
            return (
              <Button
                __css={styles.button}
                key={counter}
                color={counter === currentPage ? 'accent.500' : undefined}
                onClick={() => { setCurrentPage(counter) }}
              >{counter}
              </Button>
            )
          })}
        </PaginationArrows>
      </Flex>
    )
  }

  if (!dotButtonsHidden && currentPage <= Math.ceil(visibleNavItems / 2) + 2) { // don't show left dots and more items visible for consistent pagination length
    return (
      <Flex>
        <PaginationArrows
          styles={styles}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        >
          {Array.apply(null, Array(visibleNavItems + 2)).map(function (_, i) {
            const counter = i + 1
            return (
              <Button
                __css={styles.button}
                key={counter}
                color={counter === currentPage ? 'accent.500' : undefined}
                onClick={() => { setCurrentPage(counter) }}
              >{counter}
              </Button>
            )
          })}
          <IconButton
            {...dotButtonProps}
            onClick={() => { setCurrentPage(currentPage + Math.ceil(visibleNavItems / 2)) }}
          />
          <Button
            __css={styles.button}
            onClick={() => { setCurrentPage(totalPages) }}
          >
            {totalPages}
          </Button>
        </PaginationArrows>
      </Flex>
    )
  }
  if (!dotButtonsHidden && totalPages - currentPage < Math.ceil(visibleNavItems / 2) + 2) { // Don't show right dots and more items visible for consistent pagination length
    return (
      <Flex>
        <PaginationArrows
          styles={styles}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        >
          <Button
            __css={styles.button}
            onClick={() => { setCurrentPage(1) }}
          >
            1
          </Button>
          <IconButton
            {...dotButtonProps}
            onClick={() => { setCurrentPage(currentPage - Math.ceil(visibleNavItems / 2)) }}
          />
          {Array.apply(null, Array(visibleNavItems + 2)).map(function (_, i) {
            const counter = totalPages - i
            return (
              <Button
                __css={styles.button}
                key={counter}
                color={counter === currentPage ? 'accent.500' : undefined}
                onClick={() => { setCurrentPage(counter) }}
              >{counter}
              </Button>
            )
          }).reverse()}
        </PaginationArrows>
      </Flex>
    )
  }
  return ( // normal view with dots left and right
    <Flex>
      <PaginationArrows
        styles={styles}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      >
        <Button
          __css={styles.button}
          color={currentPage === 1 ? 'accent.500' : undefined}
          onClick={() => { setCurrentPage(1) }}
          mx={dotButtonsHidden ? '10px' : '0px'}
        >
          1
        </Button>
        <IconButton
          {...dotButtonProps}
          onClick={() => { setCurrentPage(Math.max(currentPage - Math.ceil(visibleNavItems / 2), 1)) }}
        />
        {Array.apply(null, Array(visibleNavItems)).map(function (_, i) {
          const min = Math.ceil(visibleNavItems / 2) + 1
          const max = totalPages - Math.ceil(visibleNavItems / 2)
          const counter = Math.max(Math.min(currentPage, max), min) - Math.floor(visibleNavItems / 2) + i
          return (
            counter > 1 && counter < totalPages
              ? (
                <Button
                  __css={styles.button}
                  key={counter}
                  color={counter === currentPage ? 'accent.500' : undefined}
                  onClick={() => { setCurrentPage(counter) }}
                >{counter}
                </Button>
                )
              : null
          )
        })}
        <IconButton
          {...dotButtonProps}
          onClick={() => { setCurrentPage(Math.min(currentPage + Math.ceil(visibleNavItems / 2), totalPages)) }}
        />
        <Button
          __css={styles.button}
          color={totalPages === currentPage ? 'accent.500' : undefined}
          onClick={() => { setCurrentPage(totalPages) }}
          mx={dotButtonsHidden ? '10px' : '0px'}
        >
          {totalPages}
        </Button>
      </PaginationArrows>
    </Flex>
  )
}

interface IPaginationArrowsProps {
  styles: Record<string, SystemStyleObject> // TODO change to UseStyles Hook
  currentPage: number
  totalPages: number
  setCurrentPage: (arg0: number) => void
}
const PaginationArrows: React.FC<React.PropsWithChildren<IPaginationArrowsProps>> = ({ currentPage, totalPages, children, setCurrentPage, styles }) => {
  // const styles = useStyles()
  return (
    <>
      <IconButton
        aria-label="previous page"
        __css={styles.button}
        disabled={currentPage === 1}
        onClick={() => { setCurrentPage(currentPage - 1) }}
        icon={<Icon as={IoIosArrowBack} boxSize={4}/>}
      />
      {children}
      <IconButton
        aria-label="next page"
        __css={styles.button}
        disabled={currentPage >= totalPages}
        onClick={() => { setCurrentPage(currentPage + 1) }}
        icon={<Icon as={IoIosArrowForward} boxSize={4}/>}
      />
    </>
  )
}
