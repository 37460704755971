import { useToast } from '@chakra-ui/react'
import { ProgressStepsIterator, CheckoutPaymentOptions } from '@stocker/ui-components/design-system'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useIntl } from 'react-intl'
import type { ICheckoutPageProps } from '../CheckoutPage.types'

export const CheckoutStepPaymentOptions: React.FC<ICheckoutPageProps> = ({ paymentOptionsProps, stepper }) => {
  const toast = useToast()
  const [cookies, setCookie] = useCookies(['paymentMethod'])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>(cookies.paymentMethod?.id)
  const [buttonLoading, setButtonLoading] = useState(false)

  const onButtonClick = () => {
    setButtonLoading(true)
    if (selectedPaymentMethod) {
      setButtonLoading(false)
      stepper[1].goToNextStep()
    } else {
      setButtonLoading(false)
      return toast({
        title: 'Error',
        description: 'Please select a payment method',
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    }
  }
  const intl = useIntl()

  useEffect(() => {
    if (paymentOptionsProps.paymentOptions.length === 1) {
      setSelectedPaymentMethod(paymentOptionsProps.paymentOptions[0].id)
      setCookie('paymentMethod', paymentOptionsProps.paymentOptions[0])
    }
  }, [paymentOptionsProps.paymentOptions, setCookie])

  return (
    <>
      <CheckoutPaymentOptions
        selectedPaymentOption={selectedPaymentMethod}
        onPaymentOptionChange={(value) => {
          setSelectedPaymentMethod(value)
          setCookie('paymentMethod', paymentOptionsProps.paymentOptions.find((e) => e.id === value))
        }}
        paymentOptions={paymentOptionsProps.paymentOptions}
      />
      <ProgressStepsIterator nextButton={{ onClick: onButtonClick, loading: buttonLoading, disabled: !selectedPaymentMethod }} prevButton={{ onClick: stepper[1].goToPrevStep }}/>
    </>
  )
}
