import { Search2Icon } from '@chakra-ui/icons'
import type { InputProps } from '@chakra-ui/react'
import { Box, IconButton, Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import type React from 'react'

interface ISearchBar extends InputProps {
  isLoading?: boolean
  searchOpen?: boolean
}
interface IColors {
  colorBackground?: string
  colorText?: string
  colorAccent?: string
  colorIcon?: string
}
interface ICombined extends IColors, ISearchBar {}

export const SearchBar: React.FC<ICombined> = ({
  colorBackground = 'secondaryBackground.500',
  colorAccent = 'accent.650',
  colorText = 'secondaryText.900',
  colorIcon = 'secondaryText.500',
  isLoading = false,
  searchOpen = false,
  ...inputProps
}) => {
  return (
    <>
      {searchOpen && (
        <Box position="fixed" bg="black" opacity={0.5} left={0} top={0} right={0} bottom={0} zIndex={-1}/>
      )}
      <InputGroup>
        <Input
          w="100%"
          backgroundColor={colorBackground}
          fontSize="md"
          fontWeight="medium"
          color={colorText}
          placeholder="Artikel suchen"
          borderRadius={10}
          py={2}
          px={5}
          _placeholder={{
            opacity: 0.6,
            color: 'black',
          }}
          transitionProperty="outline-color, box-shadow"
          transitionTimingFunction="ease-in-out, ease-in-out"
          transitionDuration="0.15s, 0.15s"
          _focus={{
            outlineWidth: '1.5px',
            outlineStyle: 'solid',
            outlineColor: colorAccent,
            borderColor: 'rgba(255, 255, 255 ,0)',
            shadow: 'none',
          }}
          {...inputProps}
        />
        <InputRightElement>
          <IconButton
            aria-label="Search database"
            variant="link"
            icon={isLoading ? <Spinner/> : <Search2Icon/>}
            fontSize="18"
            pr="4"
            color={colorIcon}
          />
        </InputRightElement>
      </InputGroup>
    </>
  )
}
