import { Box, Button, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Square, Stack, Text } from '@chakra-ui/react'
import { FiRsEnvelope, FiRsPhoneCall, FiRsShoppingCart, FiRsAngleDown, NextLink } from '@stocker/ui-components/design-system'
import type { IImage } from '@stocker/ui-components/design-system'
import NextImage from 'next/image'
import type React from 'react'
import { useIntl } from 'react-intl'
import type { ISystemTotalCartProps } from '../SystemTotalCart/SystemTotalCart'
import { SystemTotalCart } from '../SystemTotalCart/SystemTotalCart'

export interface INavbarProps {
  contactPartner: {
    name: string
    role: string
    image: IImage
    phone?: string
    email?: string
  }
  user: {
    name: string
    shortCode: string
    amountOfNotifications?: number
    menuItems: Array<{
      title: string
      href: string
      hasNotification?: boolean
    }>
  }
  cartProps: ISystemTotalCartProps
}

export const Navbar: React.FC<INavbarProps> = ({ contactPartner, user, cartProps }) => {
  const totalCartItems = cartProps.cartItems.reduce((acc, item) => acc + item.quantity, 0)
  const { formatMessage } = useIntl()

  return (
    <Box pt={3}>
      <HStack justify="space-between">
        <Flex h="50px">
          <Square
            pos="relative"
            size="50px"
            zIndex={1}
            bg="gray.200"
            color="black"
            fontSize="20px"
            fontWeight="bold"
          >
            {user.shortCode}
          </Square>
          <Menu
            placement="bottom"
          >
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={Button}
                  h="100%"
                  bg={isOpen ? 'gray.300' : 'white'}
                  _active={{ transform: 'scale(0.98)', transition: 'scale 0.2s ease-in-out' }}
                  fontSize="lg"
                  size="lg"
                  variant="ghost"
                >
                  <Flex gap={6}>
                    <Text>
                      {user.name}
                    </Text>
                    <FiRsAngleDown alignSelf="center"/>
                  </Flex>
                </MenuButton>
                <MenuList padding={0}>
                  {user.menuItems.map((item, index) => (
                    <NextLink key={item.title} href={item.href}>
                      <MenuItem justifyContent="space-between">
                        {item.title}
                        {item.hasNotification && (
                          <Square size="10px" bg="accent.500"/>
                        )}
                      </MenuItem>
                    </NextLink>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
        <HStack>
          <Menu
            placement="bottom"
          >
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={Button}
                  bg={isOpen ? 'gray.300' : 'white'}
                  _active={{ transform: 'scale(0.98)', transition: 'scale 0.2s ease-in-out' }}
                  fontSize="lg"
                  size="lg"
                  variant="ghost"
                >
                  <Flex gap={6}>
                    <Text>
                      {formatMessage({ id: '--contact' })}
                    </Text>
                    <FiRsPhoneCall alignSelf="center"/>
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem _focus={{ bg: 'white' }} justifyContent="center" mb="15px">
                    <Stack align="center">
                      <Box
                        h="100px"
                        w="100px"
                        position="relative"
                        borderRadius="50%"
                        overflow="hidden"
                      >
                        <NextImage
                          objectFit="cover"
                          width={100}
                          height={100}
                          src={contactPartner.image.fullpath ?? ''}
                          alt={contactPartner.name}
                        />
                      </Box>
                      <Text fontWeight="bold" fontSize="lg">
                        {contactPartner.name}
                      </Text>
                      <Text>
                        {contactPartner.role}
                      </Text>
                    </Stack>
                  </MenuItem>
                  {contactPartner.phone && (
                    <NextLink href={`tel:${contactPartner.phone}`}>
                      <MenuItem gap={4}>
                        <FiRsPhoneCall/>
                        <Text>
                          {formatMessage({ id: '--call' })}
                        </Text>
                      </MenuItem>
                    </NextLink>
                  )}
                  {contactPartner.email && (
                    <NextLink href={`mailto:${contactPartner.email}`}>
                      <MenuItem gap={4}>
                        <FiRsEnvelope/>
                        <Text>
                          {formatMessage({ id: '--email' })}
                        </Text>
                      </MenuItem>
                    </NextLink>
                  )}
                </MenuList>
              </>
            )}
          </Menu>
          <SystemTotalCart
            {...cartProps}
          >
            {totalCartItems !== 0 && (
              <Button
                bg="white"
                variant="ghost"
                fontSize="lg"
                size="lg"
                _active={{ transform: 'scale(0.98)', transition: 'scale 0.2s ease-in-out' }}
              >
                <Flex gap={6}>
                  <Text>
                    {formatMessage({ id: '--configurator-cart' })}
                  </Text>
                  <FiRsShoppingCart alignSelf="center"/>
                </Flex>
                <Square
                  pos="absolute"
                  right="10px"
                  top="5px"
                  size="20px"
                  bg="accent.500"
                  color="white"
                  fontSize="sm"
                >
                  {totalCartItems}
                </Square>
              </Button>
            )}
          </SystemTotalCart>
        </HStack>
      </HStack>
    </Box>
  )
}
