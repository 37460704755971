import { Box } from '@chakra-ui/react'
import type React from 'react'

interface IWrapProps {
  if: boolean
  with: (children: React.ReactNode) => JSX.Element
}

export const Wrap: React.FC<React.PropsWithChildren<IWrapProps>> = ({ if: condition, with: wrapper, children }) => {
  return condition ? wrapper(children) : <Box>{children}</Box>
}
