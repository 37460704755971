import { Box, HStack, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { AnimatedUnderline } from '@stocker/ui-components/custom'
import type { IMenu } from '../../'
import { NextLink, Wrap } from '../../'
import type React from 'react'
import { NavigationMenu } from '../NavigationMenu/NavigationMenu'

export interface INavigationBarProps {
  slug: string
  menus?: IMenu[]
}
interface IColors {
  colorText?: string
  colorTextDeemphasized?: string
}
interface ICombined extends IColors, INavigationBarProps {}

const primaryNav = (slug: string) => slug.split('/')[1]
const navigationIsInSlug = (slug: string, navPath: string) => primaryNav(slug) === navPath

export const NavigationBar: React.FC<ICombined> = ({ menus, slug, colorText = 'primaryText.500', colorTextDeemphasized = 'primaryText.650' }) => {
  const navbarHeight = {
    mobile: '75px',
    desktop: '100px',
  }
  return (
    <HStack spacing={0} bgColor="primaryBackground.500" h={{ base: navbarHeight.mobile, md: navbarHeight.desktop }}>
      {menus?.map((menu) => (
        <Popover
          placement="bottom-start"
          trigger="hover"
          key={menu.title}
          gutter={0}
        >
          <PopoverTrigger>
            <Box
              h="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              p={{ base: 3, lg: 4 }}
              _hover={{ cursor: 'pointer' }}
            >
              <Wrap
                if={!!menu.absolutePath && !menu.linkItems}
                with={children => <NextLink href={menu.absolutePath}>{children}</NextLink>}
              >
                <AnimatedUnderline
                  color={navigationIsInSlug(slug, menu.title) ? colorTextDeemphasized : colorText}
                  display="inline-block"
                >{menu.title}
                </AnimatedUnderline>
              </Wrap>
            </Box>
          </PopoverTrigger>
          {menu.linkItems && (
            <PopoverContent
              border=""
              variants={{}}
              w="100%"
            >
              <NavigationMenu menu={menu} slug={slug}/>
            </PopoverContent>
          )}
        </Popover>
      ))}
    </HStack>
  )
}
