import { Box, Container, Heading, HStack, SimpleGrid, useTheme, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { getContactInformation } from '@stocker/ui-components/custom'
import { LinkSecondary, NextLink } from '@stocker/ui-components/design-system'
import { asComponent } from '@stocker/ui-components/helpers'
import NextImage from 'next/image'
import type React from 'react'
import type { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import type { IFooterNavigationPanel } from '../../compounds/FooterNavigationPanel/FooterNavigationPanel'
import { FooterNavigationPanel } from '../../compounds/FooterNavigationPanel/FooterNavigationPanel'
import { FooterNewsletter } from '../../compounds/FooterNewsletter/FooterNewsletter'
import type { StaticImport } from 'next/dist/shared/lib/get-img-props'

export interface IFooter {
  navigationElements: IFooterNavigationPanel[]
  socialNetworkIcons?: Array<{
    href: string
    icon: ReactNode
    size: string
  }>
  contactHtml: string
  newsletterHtml: string
  colorBackground?: string
  colorText?: string
  logo: StaticImport
}

export const Footer: React.FC<IFooter> = ({
  navigationElements,
  socialNetworkIcons,
  contactHtml,
  newsletterHtml,
  colorBackground = 'primaryBackground.500',
  colorText = 'primaryText.750',
  logo,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const contactInformation = getContactInformation(intl)

  return (
    <Box
      bgColor={colorBackground}
      color={colorText}
      className="not-visible-when-printing"
    >
      <Container
        maxW="container.page"
        py="48px"
      >
        <NextLink
          href="/"
          justifyContent="center"
          display="flex"
          css={css`
            @media (min-width: ${theme.breakpoints.md}) {
              display: none;
            }
          `}
        >
          <NextImage
            src={logo}
            alt="Stocker Kamin Systeme"
            height="55"
            width="177"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </NextLink>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            xl: navigationElements.length + 2,
          }}
          spacing="25px"
        >
          {/* Contact, Semi Hardcoded via commerce.config and wysiwyg */}
          <VStack
            w={{ base: '100%', md: '312px' }}
            spacing="0"
            alignItems={{ base: 'center', md: 'normal' }}
          >
            <Heading
              display={{ base: 'none', md: 'block' }}
              color="primaryText.500"
              fontSize="2xl"
              mb="8px"
            >{intl.formatMessage({ id: '--contact' })}
            </Heading>
            {asComponent(contactHtml)}
            {contactInformation.map((icon, idx) => (
              <LinkSecondary {...icon} key={idx} mx={{ base: 'auto', md: '0' }}/>
            ))}
          </VStack>
          {/* Navigation Blocks */}
          {navigationElements.map((item) => (
            <FooterNavigationPanel
              key={item.headline}
              links={item.links}
              headline={item.headline}
            />
          ))}
          <FooterNewsletter innerHtml={newsletterHtml}/>
        </SimpleGrid>
        <HStack
          justify="space-between"
          w="100%"
          pt="48px"
        >
          <HStack
            mx={{ base: 'auto', md: '0' }}
            spacing="25px"
          >
            {socialNetworkIcons?.map((item) => (
              <NextLink
                _hover={{ color: 'white' }}
                href={item.href}
                fontSize={item.size}
                key={item.href}
                isExternal
              >{item.icon}
              </NextLink>
            ))}
          </HStack>
          <NextLink
            href="/"
            px="15px"
            media=""
            display={{ base: 'none', md: 'block' }}
          >
            <NextImage
              src={logo}
              alt="Stocker Kamin Systeme"
              height="55"
              width="177"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </NextLink>
        </HStack>
      </Container>
    </Box>
  )
}
